import React from 'react';
import { useTranslation } from 'react-i18next';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

import { Box } from '@mui/system';
import { Email } from '@mui/icons-material';

type ContactBoxType = {
  id: string;
};

function ContactBox({ id }: ContactBoxType) {
  const { t } = useTranslation();
  const title = t('LandingPage.ContactBox.title');

  const handleInstagram = () => {
    window.open(t('LandingPage.ContactBox.instagram') as string, '_blank');
  };

  return (
    <section id={id}>
      <Box className='contact-box'>
        {title}

        <Box className='email-box'>
          <Email /> <span>contato@soaressantana.com </span>
        </Box>

        <Box className='flex'>
          <FacebookIcon />
          <InstagramIcon onClick={handleInstagram} />
          <TwitterIcon />
        </Box>
      </Box>
    </section>
  );
}

export default ContactBox;
