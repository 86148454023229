import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@/components/TextField';
import { FileInputAws } from '@/components/FileInputAws';
import { Controller, useFieldArray } from 'react-hook-form';
import { useFormAddProject } from '@/pages/AddProjectPage/context';
import { Box, Button, Typography } from '@mui/material';
import { maskFloatNumber, onlyNumbers } from '@/utils/masks';
import MonthYearSelect from '@/components/MonthYearSelect';
import { Dropzone } from '@/components/Dropzone';

export const PanelsForm = () => {
  const { t } = useTranslation();

  const {
    equipment: {
      register,
      control,
      formState: { errors },
    },
  } = useFormAddProject();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'panels',
  });

  return (
    <>
      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <Typography variant='inherit'>Painel nº {index + 1}</Typography>

          <div className='holder-identify'>
            <TextField
              id={`uc-panels-brand-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucBrand') as string}
              label={t('AddProjectsPage.step-setup.ucBrand')}
              error={
                Array.isArray(errors.panels) &&
                !!errors.panels[index]?.brand?.message
              }
              helperText={
                Array.isArray(errors.panels) &&
                errors.panels[index]?.brand?.message
              }
              {...register(`panels.${index}.brand`)}
            />

            <TextField
              id={`uc-panels-model-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucModules') as string}
              label={t('AddProjectsPage.step-setup.ucModules')}
              error={
                Array.isArray(errors.panels) &&
                !!errors.panels[index]?.model?.message
              }
              helperText={
                Array.isArray(errors.panels) &&
                errors.panels[index]?.model?.message
              }
              {...register(`panels.${index}.model`)}
            />

            <TextField
              id={`uc-panels-potency-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucOutput') as string}
              label={t('AddProjectsPage.step-setup.ucOutput')}
              error={
                Array.isArray(errors.panels) &&
                !!errors.panels[index]?.potency?.message
              }
              helperText={
                Array.isArray(errors.panels) &&
                errors.panels[index]?.potency?.message
              }
              {...register(`panels.${index}.potency`, {
                onChange: (event) => {
                  event.target.value = onlyNumbers(event.target.value);
                },
              })}
            />

            <TextField
              id={`uc-panels-quantity-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucAmount') as string}
              label={t('AddProjectsPage.step-setup.ucAmount')}
              error={
                Array.isArray(errors.panels) &&
                !!errors.panels[index]?.quantity?.message
              }
              helperText={
                Array.isArray(errors.panels) &&
                errors.panels[index]?.quantity?.message
              }
              {...register(`panels.${index}.quantity`, {
                onChange: (event) => {
                  event.target.value = onlyNumbers(event.target.value);
                },
              })}
            />

            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
            >
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'4px'}
              >
                <TextField
                  id={`uc-panels-dimension-height-${index}`}
                  type='number'
                  placeholder={'Altura'}
                  label={'Altura'}
                  error={
                    Array.isArray(errors.panels) &&
                    !!errors.panels[index]?.dimension?.height?.message
                  }
                  helperText={
                    Array.isArray(errors.panels) &&
                    errors.panels[index]?.dimension?.height?.message
                  }
                  {...register(`panels.${index}.dimension.height`)}
                />
                <div>
                  <Typography style={{ margin: '0px' }}>mm</Typography>
                </div>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'4px'}
              >
                <TextField
                  id={`uc-panels-dimension-width-${index}`}
                  type='number'
                  placeholder={'Largura'}
                  label={'Largura'}
                  error={
                    Array.isArray(errors.panels) &&
                    !!errors.panels[index]?.dimension?.width?.message
                  }
                  helperText={
                    Array.isArray(errors.panels) &&
                    errors.panels[index]?.dimension?.width?.message
                  }
                  {...register(`panels.${index}.dimension.width`)}
                />
                <div>
                  <Typography style={{ margin: '0px' }}>mm</Typography>
                </div>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'4px'}
              >
                <TextField
                  id={`uc-panels-dimension-depth-${index}`}
                  type='number'
                  placeholder={'Profundidade'}
                  label={'Profundidade'}
                  error={
                    Array.isArray(errors.panels) &&
                    !!errors.panels[index]?.dimension?.depth?.message
                  }
                  helperText={
                    Array.isArray(errors.panels) &&
                    errors.panels[index]?.dimension?.depth?.message
                  }
                  {...register(`panels.${index}.dimension.depth`)}
                />
                <div>
                  <Typography style={{ margin: '0px' }}>mm</Typography>
                </div>
              </Box>
            </Box>

            <TextField
              id={`uc-panels-weight-${index}`}
              type='text'
              placeholder={'Peso'}
              label={'Peso'}
              error={
                Array.isArray(errors.panels) &&
                !!errors.panels[index]?.weight?.message
              }
              helperText={
                Array.isArray(errors.panels) &&
                errors.panels[index]?.weight?.message
              }
              {...register(`panels.${index}.weight`, {
                onChange: (event) => {
                  event.target.value = maskFloatNumber(event.target.value);
                },
              })}
            />

            <TextField
              id={`uc-panels-warrantyAgainstFactoryDefect-${index}`}
              placeholder={
                t('AddProjectsPage.step-setup.ucDefectWarranty') as string
              }
              label={t('AddProjectsPage.step-setup.ucDefectWarranty')}
              error={
                Array.isArray(errors.panels) &&
                !!errors.panels[index]?.warrantyAgainstFactoryDefect?.message
              }
              helperText={
                Array.isArray(errors.panels) &&
                errors.panels[index]?.warrantyAgainstFactoryDefect?.message
              }
              {...register(`panels.${index}.warrantyAgainstFactoryDefect`, {
                onChange: (event) => {
                  event.target.value = onlyNumbers(event.target.value);
                },
              })}
            />

            <TextField
              id={`uc-panels-warranty80PercentEfficiency-${index}`}
              placeholder={
                t('AddProjectsPage.step-setup.ucEfficiencyWarranty') as string
              }
              label={t('AddProjectsPage.step-setup.ucEfficiencyWarranty')}
              error={
                Array.isArray(errors.panels) &&
                !!errors.panels[index]?.warranty80PercentEfficiency?.message
              }
              helperText={
                Array.isArray(errors.panels) &&
                errors.panels[index]?.warranty80PercentEfficiency?.message
              }
              {...register(`panels.${index}.warranty80PercentEfficiency`, {
                onChange: (event) => {
                  event.target.value = onlyNumbers(event.target.value);
                },
              })}
            />

            <div>
              <div className='space-y-1'>
                <span className='ml-1'>
                  {t('AddProjectsPage.step-setup.ucDataSheets')}
                </span>
              </div>
              <div>
                <Controller
                  name={`panels.${index}.datasheetAttachment`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Dropzone
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <div className='space-y-1'>
                <span className='ml-1'>
                  {t('AddProjectsPage.step-setup.ucInmetroCertification')}
                </span>
              </div>
              <div>
                <Controller
                  name={`panels.${index}.certificateAttachment`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Dropzone
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div>
            <Button
              variant='contained'
              size='small'
              style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
              onClick={() => remove(index)}
            >
              Remover painel
            </Button>
          </div>
        </React.Fragment>
      ))}

      <Button
        variant='contained'
        size='small'
        style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
        onClick={() =>
          append({
            brand: '',
            model: '',
            potency: '',
            quantity: '',
            dimension: { depth: 0, height: 0, width: 0 },
            weight: 0,
            warrantyAgainstFactoryDefect: '',
            warranty80PercentEfficiency: '',
            datasheetAttachment: '',
            certificateAttachment: '',
          })
        }
      >
        Adicionar painel
      </Button>
    </>
  );
};
