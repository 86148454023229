import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { FileInputAws } from '@/components/FileInputAws';
import { TextField } from '@/components/TextField';

import { maskCPF, maskPhone, onlyNumbers } from '@/utils/masks';

import { useFormAddProject } from '../../../context';
import { Dropzone } from '@/components/Dropzone';

export const Identify = () => {
  const { t } = useTranslation();

  const {
    identify: {
      register,
      control,
      setValue,
      formState: { errors },
    },
  } = useFormAddProject();

  useEffect(() => {
    setValue('projectStart', new Date().toLocaleDateString('pt-BR'));
  }, []);

  return (
    <div id='project-form'>
      <Typography variant='h6'>
        {t('AddProjectsPage.step-identify.project')}
      </Typography>

      <div className='project-identify'>
        <TextField
          id='project-name'
          type='text'
          placeholder={t('AddProjectsPage.step-identify.projectName') as string}
          label={t('AddProjectsPage.step-identify.projectName')}
          error={!!errors.projectName}
          helperText={errors.projectName?.message}
          {...register(`projectName`)}
        />

        <TextField
          id='project-start'
          type='date'
          label={t('AddProjectsPage.step-identify.projectStart')}
          error={!!errors.projectStart}
          helperText={errors.projectStart?.message}
          {...register(`projectStart`)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>

      <Typography variant='h6'>
        {t('AddProjectsPage.step-identify.holder')}
      </Typography>

      <div className='holder-identify'>
        <TextField
          id='holder-name'
          type='text'
          placeholder={t('AddProjectsPage.step-identify.holderName') as string}
          label={t('AddProjectsPage.step-identify.holderName')}
          error={!!errors.holderName}
          helperText={errors.holderName?.message}
          {...register(`holderName`)}
        />

        <TextField
          id='holder-email'
          type='text'
          placeholder={t('AddProjectsPage.step-identify.holderEmail') as string}
          label={t('AddProjectsPage.step-identify.holderEmail')}
          error={!!errors.holderEmail}
          helperText={errors.holderEmail?.message}
          {...register(`holderEmail`)}
        />

        <TextField
          id='holder-phone'
          type='text'
          placeholder={t('AddProjectsPage.step-identify.holderPhone') as string}
          label={t('AddProjectsPage.step-identify.holderPhone')}
          error={!!errors.holderPhone}
          helperText={errors.holderPhone?.message}
          {...register(`holderPhone`, {
            onChange: (event) => {
              event.target.value = maskPhone(event.target.value);
            },
          })}
        />

        <TextField
          id='holder-cpf'
          type='text'
          placeholder={t('AddProjectsPage.step-identify.holderCPF') as string}
          label={t('AddProjectsPage.step-identify.holderCPF')}
          error={!!errors.holderCPF}
          helperText={errors.holderCPF?.message}
          {...register(`holderCPF`, {
            onChange: (event) => {
              event.target.value = maskCPF(event.target.value);
            },
          })}
        />

        <TextField
          id='holder-rg'
          type='text'
          placeholder={t('AddProjectsPage.step-identify.holderRG') as string}
          label={t('AddProjectsPage.step-identify.holderRG')}
          error={!!errors.holderRG}
          helperText={errors.holderRG?.message}
          {...register(`holderRG`, {
            onChange: (event) => {
              event.target.value = onlyNumbers(event.target.value);
            },
          })}
        />
      </div>

      <Typography variant='h6'>
        Anexo - Documento de identificação do titular
      </Typography>

      <div>
        <Controller
          name='attachment'
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Dropzone
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>
    </div>
  );
};
