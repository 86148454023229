import { AxiosInstance } from 'axios';

import { AuthUserWithCredentials, UserToken } from '@/models/User';
import { AuthServiceSkeleton } from '../AuthServiceSkeleton';

export class AuthService implements AuthServiceSkeleton {
  constructor(private httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async login({ username, password }: AuthUserWithCredentials) {
    const { data } = await this.httpClient.post<UserToken>(
      '/auth/authenticate',
      {
        username,
        password,
      },
    );

    return data;
  }
}
