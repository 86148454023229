import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ThreeDotsMenu from '../ThreeDotsMenu';

import anchors from '@/constants/anchors';

function NavBar() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#fff',
      },
    },
  });

  const themeClass = `theme-${theme.palette.mode}`;

  return (
    <ThemeProvider theme={theme}>
      <Box className={`header-nav-bar ${themeClass}`}>
        <AppBar position='fixed'>
          <Toolbar>
            <Typography variant='h4' component='div' className='title'>
              {t('LandingPage.title')}
            </Typography>

            <ThreeDotsMenu className='menu-icon' />

            <div className='d-flex'>
              {anchors.map((anchor: string) => (
                <Button color='inherit' key={anchor} href={'#' + anchor}>
                  {t(`LandingPage.anchors.${anchor}`)}
                </Button>
              ))}

              <Button
                color='inherit'
                variant='outlined'
                onClick={() => navigate('/login')}
              >
                {t(`LandingPage.anchors.login`)}
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}

export default NavBar;
