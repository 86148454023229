import {
  Address,
  ApportionmentForm,
  Holder,
  FileAttachmentList,
} from '@/pages/AddProjectPage/types';
import { uploadListFiles } from '@/pages/AddProjectPage/helpers/common';
import { handleSeparateFiles } from '@/pages/EditProjectPage/helpers/common';
import { unmask } from '@/utils/regex';

interface Apportionment {
  apportionmentUcCode: string;
  percentageGiven: number;
  holder: Holder;
  address: Address;
  apportionmentUcAttachmentList: FileAttachmentList[];
}

export const formatAndUploadEditApportionment = async (
  data: ApportionmentForm,
): Promise<Apportionment[]> => {
  const apportionmentArray: Apportionment[] = [];

  for (const {
    address,
    apportionmentUcCode,
    attachment,
    cep,
    city,
    complement,
    cpf,
    district,
    name,
    number,
    percentual,
    state,
  } of data.form) {
    const { filesToUpload, uploadedFiles } = handleSeparateFiles(attachment);

    const newFiles = await uploadListFiles(filesToUpload);

    if (newFiles) {
      const apportionment: Apportionment = {
        apportionmentUcCode: apportionmentUcCode,
        percentageGiven: Number(percentual),
        holder: {
          holderName: name,
          holderCpf: unmask(cpf),
        },
        address: {
          address,
          number: Number(number),
          complement,
          district,
          cep: unmask(cep),
          city,
          state,
        },
        apportionmentUcAttachmentList: [...uploadedFiles, ...newFiles],
      };

      apportionmentArray.push(apportionment);
    }
  }

  return apportionmentArray;
};
