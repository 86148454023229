import React from 'react';

import NavMenu from './parts/NavMenu';
import NavBar from './parts/NavBar';
import BreadCrumbBar from './parts/BreadcrumbBar';
import { MenuProvider } from './context';
import { Outlet } from 'react-router-dom';

function MainPage() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [minimized, setMinimized] = React.useState<boolean>(true);

  const contextProps = { minimized, setMinimized, mobileOpen, setMobileOpen };

  const minimizedClass = minimized ? 'minimized' : '';

  return (
    <MenuProvider {...contextProps}>
      <div className={`page-overlay ${minimizedClass}`}>
        <NavMenu />

        <div className='page-content'>
          <NavBar />
          <BreadCrumbBar />
          <div className='page-body'>
            <Outlet />
          </div>
        </div>
      </div>
    </MenuProvider>
  );
}

export default MainPage;
