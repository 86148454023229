export type optionType = {
  value: string;
  label: string;
};

export const states = {
  '11': 'Rondônia',
  '12': 'Acre',
  '13': 'Amazonas',
  '14': 'Roraima',
  '15': 'Pará',
  '16': 'Amapá',
  '17': 'Tocantins',
  '21': 'Maranhão',
  '22': 'Piauí',
  '23': 'Ceará',
  '24': 'Rio Grande do Norte',
  '25': 'Paraíba',
  '26': 'Pernambuco',
  '27': 'Alagoas',
  '28': 'Sergipe',
  '29': 'Bahia',
  '31': 'Minas Gerais',
  '32': 'Espírito Santo',
  '33': 'Rio de Janeiro',
  '35': 'São Paulo',
  '41': 'Paraná',
  '42': 'Santa Catarina',
  '43': 'Rio Grande do Sul',
  '50': 'Mato Grosso do Sul',
  '51': 'Mato Grosso',
  '52': 'Goiás',
  '53': 'Distrito Federal',
};

export const uf_states = {
  AC: '12',
  AL: '27',
  AP: '16',
  AM: '13',
  BA: '29',
  CE: '23',
  DF: '53',
  ES: '32',
  GO: '52',
  MA: '21',
  MT: '51',
  MS: '50',
  MG: '31',
  PA: '15',
  PB: '25',
  PR: '41',
  PE: '26',
  PI: '22',
  RJ: '33',
  RN: '24',
  RS: '43',
  RO: '11',
  RR: '14',
  SC: '42',
  SP: '35',
  SE: '28',
  TO: '17',
};

export const ufToStateNames: { [key: string]: string } = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export const statesOptions: Array<optionType> = Object.keys(states)
  .map((key) => ({
    value: key,
    label: states[key as keyof typeof states],
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export type Teste = {
  key: string;
  value: string;
};

export const ufToStateNamesOptions: Array<Teste> = Object.entries(
  ufToStateNames,
).map(([key, value]) => ({
  key,
  value,
}));
