import React, { useState } from 'react';

import { FeedbackTypeStep } from './Steps/FeedbackTypeStep';
import { FeedbackContentStep } from './Steps/FeedbackContentStep';
import { FeedbackSuccessStep } from './Steps/FeedbackSuccessStep';
import { FEEDBACK_TYPES } from '../../mocks';

export type FeedbackType = keyof typeof FEEDBACK_TYPES;

export function WidgetForm() {
  const [feedbackType, setFeedbackType] = useState<FeedbackType | null>(null);
  const [feedbackSent, setFeedbackSent] = useState(false);

  function handleRestartFeedback() {
    setFeedbackType(null);
    setFeedbackSent(false);
  }

  function handleSentFeedback() {
    setFeedbackSent(true);
  }

  return (
    <div className='bg-zinc-200 p-4 relative rounded-2xl mb-4 flex flex-col items-center shadow-lg w-[calc(100vw-2rem)] md:w-auto'>
      {feedbackSent ? (
        <FeedbackSuccessStep
          onRestartFeedbackRequested={handleRestartFeedback}
        />
      ) : (
        <>
          {feedbackType ? (
            <FeedbackContentStep
              onRestartFeedbackRequested={handleRestartFeedback}
              feedbackType={feedbackType}
              onFeedbackSent={handleSentFeedback}
            />
          ) : (
            <FeedbackTypeStep onFeedbackTypeChanged={setFeedbackType} />
          )}
        </>
      )}
    </div>
  );
}
