import React from 'react';
import { Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useFormAddProject } from '../../../context';
import { Controller } from 'react-hook-form';
import { Dropzone } from '@/components/Dropzone';

export const Photos = () => {
  const { t } = useTranslation();

  const {
    photos: { control },
  } = useFormAddProject();

  return (
    <div id='uc-photos'>
      <Typography variant='h6'>
        {t('AddProjectsPage.step-photos.photos')}
      </Typography>

      <div className='uc-photos'>
        <div>
          <div className='space-y-1'>
            <span className='ml-1'>
              {t('AddProjectsPage.step-photos.inputBranch')}
            </span>
          </div>
          <div>
            <Controller
              name='inputBranch'
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className='space-y-1'>
            <span className='ml-1'>
              {t('AddProjectsPage.step-photos.ligationBranch')}
            </span>
          </div>
          <div>
            <Controller
              name='connectionBranch'
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className='space-y-1'>
            <span className='ml-1'>
              {t('AddProjectsPage.step-photos.measurementBox')}
            </span>
          </div>
          <div>
            <Controller
              name='measurementBox'
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className='space-y-1'>
            <span className='ml-1'>
              {t('AddProjectsPage.step-photos.measurementBreaker')}
            </span>
          </div>
          <div>
            <Controller
              name='disjointMeasurement'
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className='space-y-1'>
            <span className='ml-1'>
              {t('AddProjectsPage.step-photos.frontage')}
            </span>
          </div>
          <div>
            <Controller
              name='propertyFront'
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className='space-y-1'>
            <span className='ml-1'>
              {t('AddProjectsPage.step-photos.aerialPhotos')}
            </span>
          </div>
          <div>
            <Controller
              name='aerialPhotos'
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className='space-y-1'>
            <span className='ml-1'>
              {t('AddProjectsPage.step-photos.otherPhotos')}
            </span>
          </div>
          <div>
            <Controller
              name='otherPhotos'
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className='space-y-1'>
            <span className='ml-1'>
              {t('AddProjectsPage.step-photos.projects')}
            </span>
          </div>
          <div>
            <Controller
              name='projects'
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
