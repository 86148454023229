import React, { ChangeEvent, MouseEvent } from 'react';

import { EyeOpenIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';

import { OrderType, EnhancedTableToolbar, TableHead } from '@/components/Table';
import { headCells } from '../constants/headers';
import { UseMutationResult } from '@tanstack/react-query';
import { NewIDesigner } from '..';
import { Designer, IDesigner } from '@/models/Designer';

type DesignerPresentationProps = {
  order: OrderType;
  orderBy: keyof NewIDesigner;
  selected: number | null;
  page: number;
  rowsPerPage: number;
  rows: NewIDesigner[];
  designers: Designer.GetDesigners | undefined;
  handleRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof NewIDesigner,
  ) => void;
  handleSelectDesigner: (id: number) => void;
  handleViewDesignerDetails: UseMutationResult<
    IDesigner,
    unknown,
    string,
    unknown
  >;
  handleOpenCreateDesigner: () => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;

  onDeleteDesigner: UseMutationResult<void, unknown, string, unknown>;
};

export const DesignerPresentation = ({
  order,
  orderBy,
  selected,
  page,
  rowsPerPage,
  rows,
  designers,
  handleRequestSort,
  handleSelectDesigner,
  handleViewDesignerDetails,
  handleOpenCreateDesigner,
  handleChangePage,
  handleChangeRowsPerPage,
  onDeleteDesigner,
}: DesignerPresentationProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          title='Projetista'
          numSelected={selected}
          handleDelete={() => onDeleteDesigner.mutate(String(selected))}
        />

        <Button
          variant='contained'
          size='small'
          className='!bg-[#dcaf07] !ml-3 !my-3'
          onClick={handleOpenCreateDesigner}
        >
          Criar projetista
        </Button>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size={'small'}
          >
            <TableHead<NewIDesigner>
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headCells}
            />

            <TableBody>
              {rows?.map((row, index) => {
                const isItemSelected = selected === row.id;
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    key={row.id}
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        onChange={() => handleSelectDesigner(row.id)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell align='left'>
                      {row.firstName} {row.lastName}
                    </TableCell>
                    <TableCell align='left'>
                      {new Date(row.birthdate).toLocaleDateString('pt-BR')}
                    </TableCell>
                    <TableCell align='left'>{row.classCouncil}</TableCell>
                    <TableCell align='left'>{row.rnpNumber}</TableCell>
                    <TableCell align='left'>{row.professionalEmail}</TableCell>
                    <TableCell align='left'>
                      <EyeOpenIcon
                        onClick={() =>
                          handleViewDesignerDetails.mutate(String(row.id))
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component='div'
          count={designers?.totalElements ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};
