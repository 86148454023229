import { httpClient } from '@/infra';
import { InfosToUpload } from '@/models/Attachments';
import { FileAttachmentList } from '@/pages/AddProjectPage/types';
import { AttachmentsService } from '@/services/attachments/implementations/AttachmentsService';

export type UploadSingleFileOutput = Omit<InfosToUpload, 'preSignedUrl'>;

export const getFileName = (file: File): string => {
  return file.name;
};

export const getFileFormat = (file: File): string => {
  return file.type.split('/')[1];
};

export const uploadSingleFile = async (
  file: File,
): Promise<FileAttachmentList> => {
  const attachmentsService = new AttachmentsService(httpClient());

  let retries = 1;

  while (retries >= 0) {
    try {
      const { format, key, preSignedUrl } =
        await attachmentsService.getInfosToUpload({
          format: getFileFormat(file),
        });

      const blob = new Blob([file], { type: file.type });

      await attachmentsService.uploadFile({
        preSignedUrl: preSignedUrl,
        file: blob,
      });

      const data = { fileName: getFileName(file), format, key };

      return data as FileAttachmentList;
    } catch (error) {
      console.error(`Failed to upload ${file.name}, retrying...`, error);

      retries--;
    }
  }

  throw new Error(`Failed to upload ${file.name} after multiple attempts.`);
};

export const transformFilelistToArray = (files: FileList): File[] => {
  return Array.from(files);
};

export const uploadListFiles = async (
  files: File[],
): Promise<FileAttachmentList[]> => {
  const successfulUploads: FileAttachmentList[] = [];

  if (!files.length) {
    return successfulUploads;
  }

  for (const file of files) {
    const result = await uploadSingleFile(file);

    if (result) {
      successfulUploads.push(result);
    }
  }

  return successfulUploads;
};
