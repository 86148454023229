import { ChipProps } from '@mui/material';
import i18n from '../../i18n';
import moment from 'moment';
import { PaginationParams, ProjectsService } from '@/services';
import { httpClient } from '@/infra';
import { HeadCell } from '@/components/Table/types';

export const fetchProjects = async ({
  page = 0,
  size = 20,
}: PaginationParams) => {
  const projectsServices = new ProjectsService(httpClient());

  const { content, totalElements, numberOfElements } =
    await projectsServices.getProjects({
      page,
      size,
    });

  const parsedProjects = content.map(
    ({
      city,
      deadline,
      holderName,
      id,
      name,
      status: oldStatus,
      acceptedByDesigner,
    }: Project) => {
      const { status, color } = parseStatus(oldStatus);

      return {
        id,
        name,
        holderName,
        city,
        deadline: moment(deadline).format('DD/MM/YYYY'),
        status,
        acceptedByDesigner: acceptedByDesigner ? 'Sim' : 'Não',
        props: {
          color: color,
          onClick: () => console.log(status),
        },
      };
    },
  );

  return {
    total: totalElements,
    quantityItems: numberOfElements,
    projects: parsedProjects as Array<ParsedProject>,
  };
};

export const cols: Array<HeadCell> = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: i18n.t('ProjectsPage.cols.id'),
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    type: 'avatar',
    label: i18n.t('ProjectsPage.cols.project'),
  },
  {
    id: 'holderName',
    numeric: false,
    disablePadding: true,
    type: 'avatar',
    label: i18n.t('ProjectsPage.cols.holder'),
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: true,
    label: i18n.t('ProjectsPage.cols.city'),
  },
  {
    id: 'deadline',
    numeric: false,
    disablePadding: true,
    label: i18n.t('ProjectsPage.cols.deadline'),
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    type: 'chip',
    label: i18n.t('ProjectsPage.cols.status'),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    type: 'action',
    label: 'Ações',
  },
];

export const statuses = {
  ACCEPTED: {
    color: 'secondary',
    status: 'Aceito',
  },
  OPEN: {
    color: 'primary',
    status: i18n.t('system.project-status.opened'),
  },
  IN_PROGRESS: {
    color: 'warning',
    status: i18n.t('system.project-status.progress'),
  },
  SUCCESS: {
    color: 'success',
    status: i18n.t('system.project-status.executed'),
  },
  FINISHED: {
    color: 'success',
    status: i18n.t('system.project-status.finished'),
  },
  ERROR: {
    color: 'warning',
    status: i18n.t('system.project-status.failed'),
  },
  default: {
    color: 'default',
    status: '-',
  },
};

export const parseStatus = (status: string) => {
  return statuses[status as keyof typeof statuses] as ParsedStatus;
};

export type ParsedStatus = {
  color: ChipProps['color'];
  status: string;
};

export type ParsedProject = {
  id: number;
  name: string;
  city: string;
  status: string;
  deadline: string;
  holderName: string;
  acceptedByDesigner: string;
  props: {
    color: any;
  };
};

export type Project = {
  id: number;
  city: string;
  name: string;
  status: string;
  deadline: string;
  holderName: string;
  acceptedByDesigner: boolean;
};
