import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Project } from '@/models/Project';
import { maskCPF, maskPhone } from '@/utils/masks';

type IdentifySectionProps = {
  data: Project.GetUniqueProject | undefined;
  handleClickFile: (key: string) => void;
};

export const IdentifySection = ({
  data,
  handleClickFile,
}: IdentifySectionProps) => {
  return (
    <AccordionItem value='identificacao'>
      <AccordionTrigger>
        <Typography>Identificação</Typography>
      </AccordionTrigger>

      <AccordionContent>
        <Typography>Nome do projeto: {data?.name}</Typography>
        <Typography>Previsão de início: {data?.deadline}</Typography>
        <Typography>Nome do titular: {data?.mainUc.holder.name}</Typography>
        <Typography>Email: {data?.mainUc.holder.email}</Typography>
        <Typography>
          Celular: {maskPhone(String(data?.mainUc.holder.phone))}
        </Typography>

        <Typography>CPF: {maskCPF(String(data?.mainUc.holder.cpf))}</Typography>
        <Typography>RG: {data?.mainUc.holder.rg}</Typography>
        <Box className='flex flex-col'>
          <Typography>Anexo(s) do Documento do Titular: </Typography>
          <ul className='ml-3  space-y-2'>
            {data?.mainUc?.holderAttachmentList?.length === 0 && (
              <li>
                <Typography>Não há arquivos a serem exibidos.</Typography>
              </li>
            )}
            {data?.mainUc?.holderAttachmentList?.map(({ key, fileName }) => (
              <li
                key={key}
                onClick={() => handleClickFile(key)}
                className='cursor-pointer underline'
              >
                {fileName}
              </li>
            ))}
          </ul>
        </Box>
      </AccordionContent>
    </AccordionItem>
  );
};
