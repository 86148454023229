import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

type SnackBarType = {
  open: boolean;
  setOpen: Function;
  message: string;
  color?: AlertProps['severity'];
};

function SnackBar(props: SnackBarType) {
  const { open, setOpen, message, color = 'success' } = props;

  const horizontal = 'right';
  const vertical = 'top';

  React.useEffect(() => {
    if (open)
      setTimeout(() => {
        setOpen(false);
      }, 4000);
  }, [open]);

  return (
    <div>
      <Snackbar
        open={open}
        onClick={() => setOpen(false)}
        anchorOrigin={{ horizontal, vertical }}
        className='snackbar'
      >
        <Alert severity={color} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SnackBar;
