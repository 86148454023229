import { uploadSingleFile } from '../common';
import { FileAttachmentList } from '@/pages/AddProjectPage/types';

interface GenericUploadData {
  [key: string]: File[];
}

export const formatAndUploadPhotos = async (data: GenericUploadData) => {
  const files = Object.entries(data)
    .map(([_, value]) => value)
    .flat();

  const successfulUploads: FileAttachmentList[] = [];

  for (const file of files) {
    const result = await uploadSingleFile(file);

    if (result) {
      successfulUploads.push(result);
    }
  }

  return successfulUploads;
};
