import React from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { Typography } from '@mui/material';

import {
  DialogButtonClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/Dialog';
import { optionsModalSelect } from '@/pages/ProjectsPage/constants/optionsModalSelect';
import { Steps } from '../..';

type SelectStepProps = {
  handleChangeStep: (step: Steps) => void;
};

export const SelectStep = ({ handleChangeStep }: SelectStepProps) => {
  const className =
    'flex flex-col justify-center items-center gap-2 py-5 rounded-lg border border-gray-300 bg-transparent hover:bg-gray-100 cursor-pointer';

  return (
    <DialogContent
      className={'ModalProjectInfoContent'}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        <Typography>Selecione uma opção:</Typography>
      </DialogTitle>

      <DialogDescription asChild>
        <div>
          <ToggleGroup.Root
            type='single'
            onValueChange={(value) => handleChangeStep(value as Steps)}
            className='grid grid-cols-1 sm:grid-cols-2 gap-2'
          >
            {optionsModalSelect.map(({ Icon, label, value }) => (
              <ToggleGroup.Item key={label} value={value} asChild>
                <div className={className}>
                  <Icon />
                  <Typography>{label}</Typography>
                </div>
              </ToggleGroup.Item>
            ))}
          </ToggleGroup.Root>
        </div>
      </DialogDescription>

      <DialogButtonClose className='ButtonClose' />
    </DialogContent>
  );
};
