import React from 'react';
import { Popover } from '@headlessui/react';
import { ChatTeardropDots } from 'phosphor-react';
import { WidgetForm } from './WidgetForm';

export function Widget() {
  return (
    <Popover className='absolute bottom-4 right-4 md:bottom-20 md:right-8 flex flex-col items-end'>
      <Popover.Panel>
        <WidgetForm />
      </Popover.Panel>

      <Popover.Button className='bg-[#dcaf07] rounded-full h-12 px-3 text-white flex items-center group'>
        <ChatTeardropDots className='w-6 h-6' />
        <span className='max-w-0 overflow-hidden group-hover:max-w-xs transition-all duration-500 ease-linear'>
          <span className='pl-2'></span>
          <span className='font-semibold'>Feedback</span>
        </span>
      </Popover.Button>
    </Popover>
  );
}
