import { HeadCell } from '@/components/Table/types';

export const headCells: Array<HeadCell> = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    type: 'text',
    label: 'Nome',
  },
  {
    id: 'birthdate',
    numeric: false,
    disablePadding: false,
    type: 'text',
    label: 'Data de aceitação',
  },
  {
    id: 'classCouncil',
    numeric: false,
    disablePadding: false,
    type: 'text',
    label: 'Conselho de Classe',
  },
  {
    id: 'rnpNumber',
    numeric: false,
    disablePadding: false,
    type: 'link',
    label: 'Registro Nacional Profissional',
  },
  {
    id: 'professionalEmail',
    numeric: false,
    disablePadding: false,
    type: 'action',
    label: 'E-mail',
  },
  {
    id: 'details',
    numeric: false,
    disablePadding: false,
    type: 'action',
    label: 'Detalhes',
  },
];
