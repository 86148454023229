import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { useNavigate } from 'react-router';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { AttachmentsService, ProjectsService } from '@/services';
import { unmask } from '@/utils/regex';

import {
  StepsForms,
  IdentifyForm,
  TechnicalDataForm,
  LocationForm,
  PhotosForm,
  ApportionmentForm,
  SetupForm,
  System,
  FileAttachmentList,
} from '@/pages/EditProjectPage/types';
import {
  schemaIdentify,
  schemaTechnicalData,
  schemaLocation,
  schemaPhotos,
  schemaApportionment,
  schemaSetup,
} from '@/pages/EditProjectPage/schemas';
import {
  defaultValueSetupForm,
  SetupFormOutput,
} from '@/pages/EditProjectPage/common/defaultValueSetupForm';
import {
  transformedBranchTypes,
  transformedPolesChains,
  transformedPolesOptions,
} from '@/pages/EditProjectPage/helpers/TechnicalData';
import { t } from 'i18next';
import { maskCEP, maskCPF, maskFloatNumber, maskPhone } from '@/utils/masks';
import { Project } from '@/models/Project';
import {
  branchTypes,
  invertersTypesOptions,
  polesChains,
  polesOptions,
  structuresTypesOptions,
  tensions,
  voltage,
} from '@/constants/breakerOptions';
import { httpClient } from '@/infra';
import {
  transformedTensions,
  transformedVoltage,
} from '@/pages/AddProjectPage/helpers/TechnicalData';
import { uploadListFiles } from '@/pages/AddProjectPage/helpers';
import { formatAndUploadEditApportionment } from '../helpers/Apportionment';
import { handleSeparateFiles } from '../helpers/common';
import { formatAndUploadEditSystem } from '../helpers/Setup';

type Factorys = {
  attachmentsService: AttachmentsService;
  projectsService: ProjectsService;
};

interface FormEditProjectProviderProps {
  children: ReactNode;
  factory: Factorys;
}

interface FormEditProjectContextData {
  activeStep: StepsForms;
  setActiveStep: Dispatch<SetStateAction<StepsForms>>;
  project: Project.GetUniqueProject | undefined;
  setProject: Dispatch<SetStateAction<Project.GetUniqueProject | undefined>>;
  identify: UseFormReturn<IdentifyForm, any, undefined>;
  technicalData: UseFormReturn<TechnicalDataForm, any, undefined>;
  location: UseFormReturn<LocationForm, any, undefined>;
  photos: UseFormReturn<PhotosForm, any, undefined>;
  apportionment: UseFormReturn<ApportionmentForm, any, undefined>;
  equipment: UseFormReturn<SetupForm, any, undefined>;
  handleSubmit: {
    IDENTIFICATION: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    TECHNICAL_DATA: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    LOCALIZATION: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    PHOTOS: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    APPORTIONMENT: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    EQUIPMENT: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
  };
  updateFormValues: (project: Project.GetUniqueProject) => void;
}

export const titleStepsForms = {
  IDENTIFICATION: t('AddProjectsPage.steps.identify'),
  TECHNICAL_DATA: t('AddProjectsPage.steps.mainUc'),
  LOCALIZATION: t('AddProjectsPage.steps.locationUc'),
  PHOTOS: t('AddProjectsPage.steps.photosUc'),
  APPORTIONMENT: t('AddProjectsPage.steps.ratioUcs'),
  EQUIPMENT: t('AddProjectsPage.steps.setup'),
};

const FormEditProjectContext = createContext({} as FormEditProjectContextData);

export const useFormEditProject = () => useContext(FormEditProjectContext);

export const FormEditProjectProvider = ({
  children,
  factory,
}: FormEditProjectProviderProps) => {
  const navigate = useNavigate();
  const projectsServices = new ProjectsService(httpClient());

  const [activeStep, setActiveStep] = useState<StepsForms>('IDENTIFICATION');
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [project, setProject] = useState<Project.GetUniqueProject>();
  const [valuesSetupForm, setValuesSetupForm] = useState<SetupFormOutput>(
    defaultValueSetupForm,
  );

  const identify = useForm<IdentifyForm>({
    mode: 'all',
    resolver: zodResolver(schemaIdentify),
  });
  const technicalData = useForm<TechnicalDataForm>({
    mode: 'all',
    resolver: zodResolver(schemaTechnicalData),
  });
  const location = useForm<LocationForm>({
    mode: 'all',
    resolver: zodResolver(schemaLocation),
  });
  const photos = useForm<PhotosForm>({
    mode: 'all',
    resolver: zodResolver(schemaPhotos),
  });
  const apportionment = useForm<ApportionmentForm>({
    mode: 'all',
    resolver: zodResolver(schemaApportionment),
  });
  const equipment = useForm<SetupForm>({
    mode: 'all',
    resolver: zodResolver(schemaSetup),
  });

  const updateFormValues = (project: Project.GetUniqueProject) => {
    setValuesSetupForm({
      name: project?.name,
      deadline: project?.deadline,
      mainUc: project?.mainUc,
      apportionmentUCList: project?.apportionmentUCList,
      system: {
        inverterList: project?.system.inverterList,
        moduleList: project?.system.moduleList,
        structureList: project?.system.structureList,
      },
      pictureAttachmentList: project?.pictureAttachmentList,
      fileAttachmentList: project?.fileAttachmentList,
    });

    identify.reset({
      holderCPF: maskCPF(project?.mainUc.holder.cpf),
      holderEmail: project?.mainUc.holder.email,
      holderName: project?.mainUc.holder.name,
      holderPhone: maskPhone(project?.mainUc.holder.phone),
      holderRG: project?.mainUc.holder.rg,
      projectName: project?.name,
      attachment: project?.mainUc?.holderAttachmentList,
    });

    identify.setValue('projectStart', project?.deadline);

    technicalData.reset({
      ucNumber: project?.mainUc.mainUcCode,
      ucBreakerPoles: polesOptions.find(
        (pole) => pole.value === project.mainUc.circuitBreakerPolarity,
      )?.key,
      ucBreakerChains: polesChains.find(
        (chain) => chain.value === project.mainUc.circuitBreakerCurrent,
      )?.key,
      ucPotency: parseFloat(
        maskFloatNumber(String(project?.mainUc.installedPotency)),
      ),
      ucTension: tensions.find(
        (tension) => tension.value === project?.mainUc.voltageLevel,
      )?.key,
      ucVoltage: voltage.find(
        (tension) => tension.value === project?.mainUc.voltage,
      )?.key,
      ucBranchType: branchTypes.find(
        (branch) => branch.value === project.mainUc.connectingBranchType,
      )?.key,
      observation: project?.mainUc.comments,
      attachmentEnergyAccount: project?.mainUc.mainUcAttachmentList,
    });

    location.reset({
      zip: maskCEP(project?.mainUc.address.cep ?? ''),
      street: project?.mainUc.address.address ?? '',
      number: String(project?.mainUc.address.number),
      neighborhood: project?.mainUc.address.district ?? '',
      complement: project?.mainUc.address.complement,
      state: project?.mainUc.address.state,
      city: project?.mainUc.address.city,
      location: project?.mainUc.locationUrl,
    });

    photos.reset({
      files: project?.fileAttachmentList,
      pictures: project?.pictureAttachmentList,
    });

    apportionment.reset({
      form: project?.apportionmentUCList.map(
        ({
          address,
          apportionmentUcCode,
          holder,
          percentageGiven,
          apportionmentUcAttachmentList,
        }) => ({
          address: address.address,
          apportionmentUcCode,
          cep: maskCEP(address.cep),
          city: address.city,
          complement: address.complement,
          cpf: maskCPF(holder.holderCpf),
          district: address.district,
          name: holder.holderName,
          number: String(address.number),
          percentual: percentageGiven,
          state: address.state,
          attachment: apportionmentUcAttachmentList,
        }),
      ),
    });

    equipment.reset({
      inverters: project?.system.inverterList.map(
        ({
          brand,
          model,
          potency,
          quantity,
          type,
          warranty,
          certificateAttachmentList,
          datasheetAttachmentList,
        }) => ({
          brand,
          model,
          potency: String(potency),
          quantity: String(quantity),
          type: invertersTypesOptions.find(
            (inverter) => inverter.value === type,
          )?.key,
          warranty: String(warranty),
          certificateAttachment: certificateAttachmentList,
          datasheetAttachment: datasheetAttachmentList,
        }),
      ),
      panels: project?.system.moduleList.map(
        ({
          brand,
          dimension,
          model,
          potency,
          quantity,
          warranty80PercentEfficiency,
          warrantyAgainstFactoryDefect,
          weight,
          certificateAttachmentList,
          datasheetAttachmentList,
        }) => {
          const stringWithoutMM = dimension.replace('mm', '');
          const dimensions = stringWithoutMM.split('x');
          const [height, width, depth] = dimensions;

          return {
            brand,
            dimension: {
              height: Number(height),
              width: Number(width),
              depth: Number(depth),
            },
            model,
            potency: String(potency),
            quantity: String(quantity),
            warranty80PercentEfficiency: String(warranty80PercentEfficiency),
            warrantyAgainstFactoryDefect: String(warrantyAgainstFactoryDefect),
            weight: parseFloat(maskFloatNumber(String(weight))) ?? 0,
            certificateAttachment: certificateAttachmentList,
            datasheetAttachment: datasheetAttachmentList,
          };
        },
      ),
      structure: project?.system.structureList.map(
        ({
          structureBrand,
          structureType,
          datasheetAttachmentList,
          detailsAttachmentList,
        }) => ({
          structureBrand,
          structureType: structuresTypesOptions.find(
            (type) => type.value === structureType,
          )?.key,
          datasheetAttachment: datasheetAttachmentList,
          detailsAttachment: detailsAttachmentList,
        }),
      ),
    });
  };

  const handleSubmit = {
    IDENTIFICATION: identify.handleSubmit(
      async ({
        holderCPF: cpf,
        holderEmail: email,
        holderName: name,
        holderPhone: phone,
        holderRG: rg,
        projectName,
        projectStart,
        attachment,
      }) => {
        try {
          setIsLoadingUpload(true);

          const { uploadedFiles, filesToUpload } =
            handleSeparateFiles(attachment);

          const newFiles = await uploadListFiles(filesToUpload);

          setValuesSetupForm((prevState) => ({
            ...prevState,
            name: projectName,
            deadline: projectStart,
            mainUc: {
              ...prevState.mainUc,
              holder: {
                cpf: unmask(cpf),
                email,
                name,
                phone: unmask(phone),
                rg: unmask(rg),
              },
              holderAttachmentList: [...uploadedFiles, ...newFiles],
            },
          }));

          setActiveStep('TECHNICAL_DATA');
          setIsLoadingUpload(false);
        } catch (error) {
          console.log('error :>> ', error);
        }
      },
    ),
    TECHNICAL_DATA: technicalData.handleSubmit(
      async ({
        ucTension,
        ucVoltage,
        ucPotency,
        ucNumber,
        ucBreakerPoles,
        ucBreakerChains,
        ucBranchType,
        observation,
        attachmentEnergyAccount,
      }) => {
        try {
          setIsLoadingUpload(true);

          const { uploadedFiles, filesToUpload } = handleSeparateFiles(
            attachmentEnergyAccount,
          );

          const newFiles = await uploadListFiles(filesToUpload);

          setValuesSetupForm((prevState) => ({
            ...prevState,
            mainUc: {
              ...prevState.mainUc,
              mainUcCode: ucNumber,
              installedPotency: Number(ucPotency),
              voltageLevel: transformedTensions[ucTension],
              voltage: transformedVoltage[ucVoltage],
              circuitBreakerPolarity: transformedPolesOptions[ucBreakerPoles],
              connectingBranchType: transformedBranchTypes[ucBranchType],
              circuitBreakerCurrent: transformedPolesChains[ucBreakerChains],
              comments: observation,
              mainUcAttachmentList: [...uploadedFiles, ...newFiles],
            },
          }));

          setActiveStep('LOCALIZATION');
          setIsLoadingUpload(false);
        } catch (error) {
          console.log('error :>> ', error);
        }
      },
    ),
    LOCALIZATION: location.handleSubmit(
      async ({
        city,
        complement,
        location,
        neighborhood,
        number,
        state,
        street,
        zip,
      }) => {
        setValuesSetupForm((prevState) => ({
          ...prevState,
          mainUc: {
            ...prevState.mainUc,
            locationUrl: location,
            address: {
              address: street,
              number: Number(number),
              complement,
              district: neighborhood,
              cep: unmask(zip),
              city,
              state,
            },
          },
        }));

        setActiveStep('APPORTIONMENT');
      },
    ),
    PHOTOS: photos.handleSubmit(async ({ files, pictures }) => {
      try {
        setIsLoadingUpload(true);

        const filesAttach = handleSeparateFiles(files);
        const newFiles = await uploadListFiles(filesAttach.filesToUpload);

        const picturesAttach = handleSeparateFiles(pictures);
        const newPictures = await uploadListFiles(picturesAttach.filesToUpload);

        setValuesSetupForm((prevState) => ({
          ...prevState,
          fileAttachmentList: [...filesAttach.uploadedFiles, ...newFiles],
          pictureAttachmentList: [
            ...picturesAttach.uploadedFiles,
            ...newPictures,
          ],
        }));

        setActiveStep('APPORTIONMENT');
        setIsLoadingUpload(false);
      } catch (error) {
        setIsLoadingUpload(false);
        console.log('error :>> ', error);
      }
    }),
    APPORTIONMENT: apportionment.handleSubmit(async (data) => {
      try {
        setIsLoadingUpload(true);

        const apportionments = await formatAndUploadEditApportionment(data);

        setValuesSetupForm((prevState) => ({
          ...prevState,
          apportionmentUCList: [...apportionments],
        }));

        setActiveStep('EQUIPMENT');
        setIsLoadingUpload(false);
      } catch (error) {
        console.log('An error occurred:', error);
      }
    }),
    EQUIPMENT: equipment.handleSubmit(async (data) => {
      try {
        const system = await formatAndUploadEditSystem(data);

        setValuesSetupForm((prevState) => ({
          ...prevState,
          system,
        }));

        const combinedValues = {
          ...valuesSetupForm,
          system,
        };

        await projectsServices.updateUniqueProject(
          String(project?.id),
          combinedValues,
        );

        navigate('/client-page/projects');
      } catch (error) {
        console.log('An error occurred in system:', error);
      }
    }),
  };

  return (
    <FormEditProjectContext.Provider
      value={{
        activeStep,
        setActiveStep,
        project,
        setProject,
        identify,
        technicalData,
        location,
        photos,
        apportionment,
        equipment,
        handleSubmit,
        updateFormValues,
      }}
    >
      {children}
    </FormEditProjectContext.Provider>
  );
};
