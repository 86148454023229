import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Project } from '@/models/Project';
import {
  branchTypes,
  polesChains,
  polesOptions,
  tensions,
} from '@/constants/breakerOptions';

type TechnicalDataSectionProps = {
  data: Project.GetUniqueProject | undefined;
  handleClickFile: (key: string) => void;
};

export const TechnicalDataSection = ({
  data,
  handleClickFile,
}: TechnicalDataSectionProps) => {
  return (
    <AccordionItem value='unidade-consumidora-principal'>
      <AccordionTrigger>
        <Typography>Unidade Consumidora Principal</Typography>
      </AccordionTrigger>

      <AccordionContent>
        <Typography>Unidade consumidora: {data?.mainUc.mainUcCode}</Typography>
        <Typography>
          Potência instalada: {data?.mainUc.installedPotency}kW
        </Typography>
        <Typography>
          Disjuntor de medição (Polo):{' '}
          {
            polesOptions.find(
              (pole) => pole.value === data?.mainUc.circuitBreakerPolarity,
            )?.key
          }
        </Typography>
        <Typography>
          Disjuntor de medição (Corrente Nominal):{' '}
          {
            polesChains.find(
              (chain) => chain.value === data?.mainUc.circuitBreakerCurrent,
            )?.key
          }
        </Typography>
        <Typography>
          Tensão de atendimento:{' '}
          {
            tensions.find(
              (tension) => tension.value === data?.mainUc.voltageLevel,
            )?.key
          }
        </Typography>
        <Typography>
          Tipo do ramal de ligação:{' '}
          {
            branchTypes.find(
              (branch) => branch.value === data?.mainUc.connectingBranchType,
            )?.key
          }
        </Typography>
        <Typography>Observações: {data?.mainUc.comments}</Typography>

        <Box className='flex flex-col'>
          <Typography>Anexo(s) da conta energia:</Typography>
          <ul className='ml-3 space-y-2'>
            {data?.mainUc?.mainUcAttachmentList?.length === 0 && (
              <li>
                <Typography>Não há arquivos a serem exibidos.</Typography>
              </li>
            )}
            {data?.mainUc?.mainUcAttachmentList?.map(({ key, fileName }) => (
              <li
                key={key}
                onClick={() => handleClickFile(key)}
                className='cursor-pointer underline'
              >
                {fileName}
              </li>
            ))}
          </ul>
        </Box>
      </AccordionContent>
    </AccordionItem>
  );
};
