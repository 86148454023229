import React from 'react';
import { useTheme } from '@mui/material/styles';

type CardWithPictureType = {
  picture: any;
  alt: string;
  name?: string;
  role?: string;
  paragraph?: string;
};

function CardWithPicture(props: CardWithPictureType) {
  const { picture = null, alt, name, role, paragraph } = props;

  const [photo, setPhoto] = React.useState<any>();

  const theme = useTheme();
  const themeClass = `theme-${theme.palette.mode}`;

  const parsedParagraph = paragraph?.split(';');

  React.useEffect(() => {
    getPicture();
  }, []);

  const getPicture = async () => {
    const file = await picture();

    setPhoto(file);
  };

  return (
    <div className={`card-with-picture ${themeClass}`}>
      <div className='header'>
        <img src={photo} alt={alt} />
        <label className='card-title'>{name}</label>
        {role && <label className='card-title'>{role}</label>}
      </div>

      <label className='paragraph'>
        {parsedParagraph?.map((p, i) => (
          <p key={i + 'p'}>{p} </p>
        ))}
      </label>
    </div>
  );
}

export default CardWithPicture;
