import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';

import anchors from '@/constants/anchors';

type ThreeDotsMenuType = {
  className: string;
};

function ThreeDotsMenu(props: ThreeDotsMenuType) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { className } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        onClose={handleClose}
        anchorEl={anchorEl}
        open={open}
      >
        {anchors.map((anchor) => (
          <MenuItem key={anchor}>
            <Button
              color='inherit'
              href={'#' + anchor}
              onClick={handleClose}
              sx={{
                minWidth: 'unset !important',
                fontFamily: '"Broadleaf-Regular" !important',
                textTransform: 'none',
                fontSize: '16px',
              }}
            >
              {t(`LandingPage.anchors.${anchor}`)}
            </Button>
          </MenuItem>
        ))}

        <MenuItem>
          <Button
            color='inherit'
            variant='outlined'
            fullWidth
            onClick={() => navigate('/login')}
          >
            {t(`LandingPage.anchors.login`)}
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ThreeDotsMenu;
