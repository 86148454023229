import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { Avatar } from '@mui/material';

import { httpClient } from '@/infra';
import { UsersService } from '@/services';
import { destroyCookie, getCookies } from '@/utils/cookies';
import { Token } from '@/models/User';

function UserMenu() {
  const usersService = new UsersService(httpClient());

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    sessionStorage.removeItem('user');

    destroyCookie('soaressantana-accessToken', { path: '/' });
    destroyCookie('soaressantana-refreshToken', { path: '/' });
    destroyCookie('soaressantana-role', { path: '/' });

    navigate('/login');
  };

  const { data: nameUser } = useQuery({
    queryKey: ['getUniqueUser'],
    queryFn: async () => {
      const { 'soaressantana-accessToken': accessToken } = getCookies();

      const { userId } = jwt_decode<Token>(accessToken);

      const user = await usersService.getUniqueDealership({
        id: String(userId),
      });

      return user.name.split(' ')[0].charAt(0).toUpperCase();
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div className='avatar-menu'>
      <Tooltip title='Account settings'>
        <IconButton
          onClick={handleClick}
          size='small'
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar className='avatar'>{nameUser || '.'}</Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          {t('ClientPage.userMenu.logout')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;
