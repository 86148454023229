import { AxiosInstance } from 'axios';

import {
  PaginationParams,
  ProjectsServiceSkeleton,
  SelectDesignerToProject,
  UniqueParam,
} from '../ProjectsServiceSkeleton';
import { SetupFormOutput } from '@/pages/AddProjectPage/types';
import { Project } from '@/models/Project';

export class ProjectsService implements ProjectsServiceSkeleton {
  constructor(private httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getProjects({ page = 0, size = 20 }: PaginationParams) {
    const { data } = await this.httpClient.get('/projects', {
      params: { page, size },
    });

    return data;
  }

  async getUniqueProject({ id }: UniqueParam) {
    const { data } = await this.httpClient.get<
      Promise<Project.GetUniqueProject>
    >(`/projects/${id}`);

    return data;
  }

  async getProjectsDashboard() {
    const { data } = await this.httpClient.get('/projects/dashboard');

    return data;
  }

  async createProjects(body: SetupFormOutput) {
    const { data } = await this.httpClient.post('/projects', body, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  }

  async acceptProject({ id }: UniqueParam) {
    const { data } = await this.httpClient.put(
      `/projects/${id}/accept`,
      {},
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );

    return data;
  }

  async rejectProject({ id }: UniqueParam) {
    const { data } = await this.httpClient.put(
      `/projects/${id}/reject`,
      {},
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );

    return data;
  }

  async selectDesignerToProject({
    projectId,
    designerId,
  }: SelectDesignerToProject) {
    const { data } = await this.httpClient.put(
      `/projects/${projectId}/forward/${designerId}`,
      {},
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );

    return data;
  }

  async updateUniqueProject(id: string, body: SetupFormOutput) {
    const { data } = await this.httpClient.put(`/projects/${id}`, body, {
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  }

  async deleteUniqueProject({ id }: UniqueParam) {
    const { data } = await this.httpClient.delete(`/projects/${id}`);

    return data;
  }
}
