import { HeadCell } from '@/components/Table/types';
import i18n from '@/i18n';

export const headCells: Array<HeadCell> = [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: i18n.t('ProjectsPage.cols.id'),
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    type: 'avatar',
    label: i18n.t('ProjectsPage.cols.project'),
  },
  {
    id: 'holderName',
    numeric: false,
    disablePadding: false,
    type: 'avatar',
    label: i18n.t('ProjectsPage.cols.holder'),
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: i18n.t('ProjectsPage.cols.city'),
  },
  {
    id: 'deadline',
    numeric: false,
    disablePadding: false,
    label: i18n.t('ProjectsPage.cols.deadline'),
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    type: 'chip',
    label: i18n.t('ProjectsPage.cols.status'),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    type: 'action',
    label: 'Ações',
  },
];
