import { AxiosInstance } from 'axios';

import { Designer } from '@/models/Designer';

import {
  DesignerServiceSkeleton,
  DesignerPaginationParams,
} from '../DesignersServiceSkeleton';

export class DesignersService implements DesignerServiceSkeleton {
  constructor(private httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getDesigners({ page = 0, size = 25 }: DesignerPaginationParams) {
    const { data } = await this.httpClient.get<Designer.GetDesigners>(
      '/designers',
      {
        params: { page, size },
      },
    );

    return data;
  }

  async getUniqueDesigner({ id }: Designer.UniqueParam) {
    const { data } = await this.httpClient.get<Designer.GetUniqueDesigner>(
      `/designers/${id}`,
    );

    return data;
  }

  async createDesigner(body: Designer.CreateDesigner) {
    const { data } = await this.httpClient.post<Designer.CreateDesigner>(
      '/designers',
      body,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );

    return data;
  }

  async deleteUniqueDesigner({ id }: Designer.UniqueParam) {
    const { data } = await this.httpClient.delete<void>(`/designers/${id}`);

    return data;
  }
}
