import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUser } from '../interfaces/user';

import { useGlobalContext } from '../context';

import {
  AccoutingPage,
  AddProjectPage,
  CreateDealership,
  DashBoardPage,
  DealershipsPage,
  DesignersPage,
  EditProjectPage,
  LandingPage,
  LoginPage,
  ProjectsPage,
} from '@/pages';

import MainPage from '../layouts/MainPage';
import { getCookies } from '@/utils/cookies';

type RouteType = {
  path: string;
  element: any;
  requireAuth: boolean;
  children?: Array<RouteType>;
};

export const routes: Array<RouteType> = [
  {
    path: '/',
    element: <LandingPage />,
    requireAuth: false,
  },
  {
    path: '/login',
    element: <LoginPage />,
    requireAuth: false,
  },
  {
    path: '/client-page',
    element: <MainPage />,
    requireAuth: true,
    children: [
      {
        path: 'dashboard',
        element: <DashBoardPage />,
        requireAuth: true,
      },
      {
        path: 'projects',
        element: <ProjectsPage />,
        requireAuth: true,
      },
      {
        path: 'accounting',
        element: <AccoutingPage />,
        requireAuth: true,
      },
      {
        path: 'add-project',
        element: <AddProjectPage />,
        requireAuth: true,
      },
      {
        path: 'edit-project/:projectId',
        element: <EditProjectPage />,
        requireAuth: true,
      },
      {
        path: 'dealerships',
        element: <DealershipsPage />,
        requireAuth: true,
      },
      {
        path: 'create-dealership',
        element: <CreateDealership />,
        requireAuth: true,
      },
      {
        path: 'designers',
        element: <DesignersPage />,
        requireAuth: true,
      },
    ],
  },
];

function Router() {
  const { setCurrentRoute } = useGlobalContext();

  const navigate = useNavigate();
  const location = useLocation();

  const { 'soaressantana-accessToken': accessToken } = getCookies();

  const checkRoute = async () => {
    const path = location.pathname.split('/').at(-1);

    const current: RouteType | null = await new Promise((resolve) =>
      routes.find((route) => {
        if (path === route.path) {
          resolve(route);
          return route;
        }

        if (route.children) {
          route.children.find((subroute) => {
            if (path === subroute.path) {
              resolve(subroute);
              return subroute;
            }

            return false;
          });
        }

        return false;
      }),
    );

    if (current?.requireAuth && !accessToken) return navigate('/login');

    setCurrentRoute(current?.path);
  };

  React.useEffect(() => {
    checkRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Routes>
      {routes.map((route) => {
        return (
          <Route key={route.path} path={route.path} element={route.element}>
            {route.children &&
              route.children.map((subroute) => (
                <Route
                  key={subroute.path}
                  path={subroute.path}
                  element={subroute.element}
                />
              ))}
          </Route>
        );
      })}
    </Routes>
  );
}

export default Router;
