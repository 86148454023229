import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import { httpClient } from '@/infra';
import { DealershipsService } from '@/services';
import { OrderType } from '@/components/Table/types';

import { DealershipsPresentation } from './presentation';
import { useDisclosure } from '@/hooks/useDisclosure';
import { getComparator, stableSort } from '@/utils/tables';
import { Dealership, Dealerships } from '@/models/Dealerships';
import { ModalDealership } from './components/ModalDealerships';
import { useNavigate } from 'react-router';
import { Widget } from '@/components/Feedback/components/Widget';

export type Steps = 'VIEW_DETAILS' | 'NONE';

export const DealershipsPage = () => {
  const navigate = useNavigate();

  const dealershipsServices = new DealershipsService(httpClient());
  const modalDealershipDisclosure = useDisclosure();

  const [order, setOrder] = useState<OrderType>('asc');
  const [orderBy, setOrderBy] = useState<keyof Dealership>('id');
  const [selected, setSelected] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [activeStep, setActiveStep] = useState<Steps>('NONE');

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['dealerships', page, rowsPerPage],
    queryFn: async () =>
      await dealershipsServices.getDealerships({
        page: page,
        size: rowsPerPage,
      }),
    refetchOnWindowFocus: false,
  });

  const onViewDetail = useMutation({
    mutationFn: async (id: string) =>
      await dealershipsServices.getUniqueDealership({ id }),
    onSuccess: () => {
      handleChangeStep('VIEW_DETAILS');
      modalDealershipDisclosure.onOpen();
    },
    onError: () => {
      handleChangeStep('NONE');
      modalDealershipDisclosure.onClose();
    },
  });

  const onDeleteDealership = useMutation({
    mutationFn: async (id: string) =>
      await dealershipsServices.deleteUniqueDealership({ id }),
    onSuccess: () => {
      setSelected(null);
      refetch();
    },
  });

  useEffect(() => {
    onDeleteDealership.reset();
  }, []);

  const handleChangeStep = (step: Steps) => setActiveStep(step);

  const handleOpenCreateDealership = () => {
    navigate('/client-page/create-dealership');
  };

  const handleCloseModal = () => {
    modalDealershipDisclosure.onClose();
    setActiveStep('NONE');
    onViewDetail.reset();
  };

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Dealership,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectProject = (id: number) => {
    setSelected((prev) => (prev === id ? null : id));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = useMemo(
    () =>
      stableSort<Dealership>(
        data?.content ?? [],
        getComparator(order, orderBy),
      ),
    [order, orderBy, page, rowsPerPage, data],
  );

  if (isLoading) {
    return <span>carregando...</span>;
  }

  if (isError) {
    return <span>Não foi possível listar as concessionárias</span>;
  }

  return (
    <>
      <DealershipsPresentation
        order={order}
        orderBy={orderBy}
        selected={selected}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={rows}
        dealerships={data}
        handleRequestSort={handleRequestSort}
        handleSelectProject={handleSelectProject}
        handleViewDealershipDetails={onViewDetail}
        handleOpenCreateDealership={handleOpenCreateDealership}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        onDeleteDealership={onDeleteDealership}
      />

      <ModalDealership
        isOpen={modalDealershipDisclosure.isOpen}
        onClose={handleCloseModal}
        activeStep={activeStep}
        dealershipToView={onViewDetail.data as Dealership}
      />

      <Widget />
    </>
  );
};
