import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  OutlinedInput,
} from '@mui/material';

type Option = {
  key: string;
  value: string | number;
};

type ChipSelectType = {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  options: Array<Option>;
  onChange: Function;
  error: string | undefined;
  isClearable?: boolean;
  isValid?: Function | null;
  disabled?: boolean;
};

export const ChipSelect = ({
  value,
  onChange,
  error,
  isClearable = true,
  isValid = null,
  options,
  id,
  label,
  placeholder,
  disabled = false,
}: ChipSelectType) => {
  const [hasError, setHasError] = React.useState<boolean>(false);

  React.useEffect(() => {
    setHasError(!!error);
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }, [error]);

  const handleChange = (e: SelectChangeEvent) => {
    const value = e.target.value;
    onChange(value);
    if (isValid) setHasError(!isValid(value));
  };

  const handleClear = () => {
    onChange('');
  };

  const clearButton = () =>
    isClearable &&
    value && <CloseIcon className='close-icon' onClick={handleClear} />;

  const hasValueClass = value ? 'has-value' : '';
  const hasErrorClass = hasError ? 'has-error' : '';

  return (
    <FormControl
      margin='dense'
      size='small'
      className={`chip-select ${hasValueClass} ${hasErrorClass}`}
      error={!!error}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        id={id}
        value={value}
        onChange={handleChange}
        renderValue={(opt) => {
          if (value === '') return;

          return (
            <Chip
              label={opt}
              className='chip'
              variant='outlined'
              color='warning'
            />
          );
        }}
        input={
          <OutlinedInput
            label={label}
            placeholder={placeholder}
            endAdornment={clearButton()}
          />
        }
        disabled={disabled}
      >
        {options.map(({ key, value }) => (
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
