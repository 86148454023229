import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import WeAreTheLight from '../../../../assets/landingPage/light-at-the-end-of-tunnel.jpeg';

type LeftInfoBoxType = {
  id: string;
};

function LeftInfoBox({ id }: LeftInfoBoxType) {
  const { t } = useTranslation();

  return (
    <section id={id}>
      <Box sx={{ display: 'flex' }}>
        <Box className='white-info'>
          <Typography variant='h1' className='title'>
            {t('LandingPage.LeftInfoBox.title')}
          </Typography>

          <Typography variant='body1' className='paragraph'>
            <TrackChangesIcon /> {t('LandingPage.LeftInfoBox.mission-title')}
            <span>{t('LandingPage.LeftInfoBox.mission')}</span>
          </Typography>

          <Typography variant='body1' className='paragraph'>
            <VisibilityIcon /> {t('LandingPage.LeftInfoBox.vision-title')}
            <span>{t('LandingPage.LeftInfoBox.vision')}</span>
          </Typography>

          <Typography variant='body1' className='paragraph'>
            <StarIcon /> {t('LandingPage.LeftInfoBox.objective-title')}
            <span>{t('LandingPage.LeftInfoBox.objective')}</span>
          </Typography>
        </Box>

        <div id='we-are-the-light'>
          <img
            src={WeAreTheLight}
            alt='we-are-the-light'
            className='we-are-the-light'
          />
        </div>
      </Box>
    </section>
  );
}

export default LeftInfoBox;
