import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import CardWithPicture from '../../../../components/CardWithPicture';

function FoundersBox() {
  const { t } = useTranslation();

  const founderA = {
    name: t('LandingPage.FoundersBox.founderA.name'),
    picture: () => require('../../../../assets/landingPage/founderA.jpeg'),
    paragraph: t('LandingPage.FoundersBox.founderA.paragraph'),
    alt: t('LandingPage.FoundersBox.founderA.name'),
  };

  const founderB = {
    name: t('LandingPage.FoundersBox.founderB.name'),
    picture: () => require('../../../../assets/landingPage/founderB.jpeg'),
    paragraph: t('LandingPage.FoundersBox.founderB.paragraph'),
    alt: t('LandingPage.FoundersBox.founderB.name'),
  };

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#222020',
      },
    },
  });

  return (
    <section>
      <ThemeProvider theme={theme}>
        <Box className='founders-box'>
          <Typography variant='h3' className='title'>
            {t('LandingPage.FoundersBox.title')}
          </Typography>
          <Box className='flex'>
            <CardWithPicture {...founderA} />
            <CardWithPicture {...founderB} />
          </Box>
        </Box>
      </ThemeProvider>
    </section>
  );
}

export default FoundersBox;
