/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

const MenuContext = React.createContext<MenuContextType | null>(null);

function MenuProvider(props: MenuProviderType) {
  const { children, mobileOpen, setMobileOpen, minimized, setMinimized } =
    props;

  const handleMinimize = () => {
    setMinimized(!minimized);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <MenuContext.Provider
      value={{
        minimized,
        handleMinimize,
        mobileOpen,
        handleDrawerToggle,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}
const useMenuContext = () => React.useContext(MenuContext) as MenuContextType;

export { MenuContext, useMenuContext, MenuProvider };

type MenuContextType = {
  minimized: boolean;
  handleMinimize: Function;
  mobileOpen: boolean;
  handleDrawerToggle: Function;
};

type MenuProviderType = {
  children: any;
  minimized: boolean;
  setMinimized: Function;
  mobileOpen: boolean;
  setMobileOpen: Function;
};
