import { z } from 'zod';

export const schemaDealership = z
  .object({
    fantasyName: z.string().min(1, { message: 'Nome fantasia é obrigatório!' }),
    corporateName: z
      .string()
      .min(1, { message: 'Razão social é obrigatória!' }),
    state: z.string().min(1, { message: 'Estado é obrigatório!' }),
    sendTypeAnalysis: z
      .string()
      .min(1, { message: 'Meio de envio é obrigatório' }),
    emailMiniMicroDG: z
      .string()
      .min(1, { message: 'Email é obrigatório!' })
      .email({
        message: 'Deve ser um email válido!',
      }),
    emailDG: z.string().min(1, { message: 'Email é obrigatório!' }).email({
      message: 'Deve ser um email válido!',
    }),
    emailOthers: z.string().min(1, { message: 'Email é obrigatório!' }).email({
      message: 'Deve ser um email válido!',
    }),
    phoneMiniMicroDG: z.string().min(1, { message: 'Telefone é obrigatório!' }),
    phoneDG: z.string().min(1, { message: 'Telefone é obrigatório!' }),
    phoneOthers: z.string().min(1, { message: 'Telefone é obrigatório!' }),
    urlTechnicalStandards: z
      .string()
      .min(1, { message: 'URL é obrigatória!' })
      .url({ message: 'Insira um link válido!' }),
  })
  .required();

export type DealershipForm = z.infer<typeof schemaDealership>;
