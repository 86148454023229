import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import { httpClient } from '@/infra';
import { DesignersService } from '@/services';
import { OrderType } from '@/components/Table/types';

import { DesignerPresentation } from './presentation';
import { useDisclosure } from '@/hooks/useDisclosure';
import { getComparator, stableSort } from '@/utils/tables';
import { ModalDesigner } from './components/ModalDesigner';
import { Designer, IDesigner } from '@/models/Designer';
import { Widget } from '@/components/Feedback/components/Widget';

export type StepsDesigner = 'CREATE_DESIGNER' | 'VIEW_DETAILS' | 'NONE';

export type NewIDesigner = Omit<IDesigner, 'address'>;

export const DesignersPage = () => {
  const designersServices = new DesignersService(httpClient());

  const modalDesignerDisclosure = useDisclosure();

  const [order, setOrder] = useState<OrderType>('asc');
  const [orderBy, setOrderBy] = useState<keyof NewIDesigner>('id');
  const [selected, setSelected] = useState<number | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [activeStep, setActiveStep] = useState<StepsDesigner>('NONE');

  const { data, isLoading, isSuccess, isError, refetch } = useQuery({
    queryKey: ['designers', page, rowsPerPage],
    queryFn: async () =>
      await designersServices.getDesigners({
        page: page,
        size: rowsPerPage,
      }),
    refetchOnWindowFocus: false,
  });

  const onViewDetail = useMutation({
    mutationFn: async (id: string) =>
      await designersServices.getUniqueDesigner({ id }),
    onSuccess: () => {
      handleChangeStep('VIEW_DETAILS');
      modalDesignerDisclosure.onOpen();
    },
    onError: () => {
      handleChangeStep('NONE');
      modalDesignerDisclosure.onClose();
    },
  });

  const onCreateDesigner = useMutation({
    mutationFn: async (designer: Designer.CreateDesigner) =>
      await designersServices.createDesigner(designer),
    onSuccess: () => {
      modalDesignerDisclosure.onClose();
      setSelected(null);
      refetch();
    },
  });

  const onDeleteDesigner = useMutation({
    mutationFn: async (id: string) =>
      await designersServices.deleteUniqueDesigner({ id }),
    onSuccess: () => {
      setSelected(null);
      refetch();
    },
  });

  useEffect(() => {
    onDeleteDesigner.reset();
  }, []);

  const handleChangeStep = (step: StepsDesigner) => setActiveStep(step);

  const handleOpenCreateDesigner = () => {
    handleChangeStep('CREATE_DESIGNER');
    modalDesignerDisclosure.onOpen();
  };

  const handleCloseModal = () => {
    modalDesignerDisclosure.onClose();
    setActiveStep('NONE');
    onViewDetail.reset();
    onCreateDesigner.reset();
  };

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof NewIDesigner,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectDesigner = (id: number) => {
    setSelected((prev) => (prev === id ? null : id));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = useMemo(
    () =>
      stableSort<NewIDesigner>(
        data?.content ?? [],
        getComparator(order, orderBy),
      ),
    [order, orderBy, page, rowsPerPage, data],
  );

  if (isLoading) {
    return <span>carregando...</span>;
  }

  return (
    <>
      <DesignerPresentation
        order={order}
        orderBy={orderBy}
        selected={selected}
        page={page}
        rowsPerPage={rowsPerPage}
        rows={rows}
        designers={data}
        handleRequestSort={handleRequestSort}
        handleSelectDesigner={handleSelectDesigner}
        handleViewDesignerDetails={onViewDetail}
        handleOpenCreateDesigner={handleOpenCreateDesigner}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        onDeleteDesigner={onDeleteDesigner}
      />

      <ModalDesigner
        isOpen={modalDesignerDisclosure.isOpen}
        onClose={handleCloseModal}
        activeStep={activeStep}
        designerToView={onViewDetail.data as IDesigner}
        onCreateDesigner={onCreateDesigner}
      />

      <Widget />
    </>
  );
};
