import * as React from 'react';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';

import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import LoginIcon from '@mui/icons-material/Login';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useNavigate } from 'react-router';
import { Divider } from '@mui/material';
import { AuthService } from '@/services';
import { httpClient } from '@/infra';
import { setAuthCookies, setCookie } from '@/utils/cookies';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginForm } from './types';
import { schemaLogin } from './schemas';
import { Token } from '@/models/User';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#222020',
    },
  },
});

const authService = new AuthService(httpClient());

export const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [remember, setRemember] = React.useState<boolean>(true);

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginForm>({
    mode: 'all',
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: zodResolver(schemaLogin),
  });

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setValue('username', 'gerente');
      setValue('password', 'p5BiEbe4KZmh3!');
    }
  }, []);

  const onSubmit = async ({ username, password }: LoginForm) => {
    try {
      const { accessToken, refreshToken } = await authService.login({
        username,
        password,
      });

      const { role } = jwt_decode<Token>(accessToken);

      setAuthCookies({ accessToken, refreshToken });
      setCookie('soaressantana-role', role);

      navigate('/client-page/dashboard');
    } catch (err) {
      console.log('err :>> ', err);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className='login-page'>
        <Box className='body' sx={{ boxShadow: 3 }}>
          <div className='icon'>
            <LoginIcon />

            <Typography component='h1' variant='h5'>
              {t('LoginPage.signIn')}
            </Typography>
          </div>

          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              type='text'
              label={t('LoginPage.credential')}
              autoComplete='email'
              error={!!errors.username}
              helperText={errors.username?.message}
              margin='normal'
              autoFocus
              fullWidth
              {...register('username')}
            />

            <TextField
              type='password'
              label={t('LoginPage.password')}
              autoComplete='current-password'
              error={!!errors.password}
              helperText={errors.password?.message}
              margin='normal'
              fullWidth
              {...register('password')}
            />

            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={remember}
                  onChange={() => setRemember(!remember)}
                />
              }
              label={t('LoginPage.remember')}
              className='remember'
            />

            <Button type='submit' variant='contained' fullWidth>
              {t('LoginPage.signIn')}
            </Button>

            <div className='links'>
              <Link href='#' variant='body2'>
                {t('LoginPage.forgot')}
              </Link>

              <Link href='#' variant='body2'>
                {t('LoginPage.signUp')}
              </Link>
            </div>

            <Divider />

            <div className='back-to'>
              <Link onClick={() => navigate('/')} variant='body1'>
                {t('LoginPage.back')}
              </Link>
            </div>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LoginPage;
