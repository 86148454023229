import React from 'react';

import { httpClient } from '@/infra';
import { AttachmentsService, ProjectsService } from '@/services';
import { FormAddProjectProvider } from './context';
import { AddProjectPresentation } from './presentation';
import { Widget } from '@/components/Feedback/components/Widget';

export const AddProjectPage = () => {
  const attachmentsService = new AttachmentsService(httpClient());
  const projectsService = new ProjectsService(httpClient());

  return (
    <FormAddProjectProvider factory={{ attachmentsService, projectsService }}>
      <AddProjectPresentation />
      <Widget />
    </FormAddProjectProvider>
  );
};
