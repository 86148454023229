import * as React from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Menu from '../Drawer';
import { useMenuContext } from '../../context';

interface NavMenuType {
  window?: () => Window;
}

function NavMenu(props: NavMenuType) {
  const { window } = props;
  const { mobileOpen, handleDrawerToggle } = useMenuContext();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className='main-menu-nav' component='nav'>
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={() => handleDrawerToggle()}
        ModalProps={{
          keepMounted: true,
        }}
        className='nav-menu-drawer mobile'
      >
        <Menu />
      </Drawer>

      <Drawer variant='permanent' className='nav-menu-drawer' open>
        <Menu />
      </Drawer>
    </Box>
  );
}

export default NavMenu;
