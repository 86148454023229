import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import './styles.scss';
import classNames from 'classnames';

export const DialogRoot: React.FC<Dialog.DialogProps> = ({
  children,
  ...props
}) => <Dialog.Root {...props}>{children}</Dialog.Root>;

export const DialogTrigger = React.forwardRef<
  HTMLButtonElement,
  Dialog.DialogTriggerProps
>(({ children, ...props }, forwardedRef) => (
  <Dialog.Trigger {...props} ref={forwardedRef}>
    {children}
  </Dialog.Trigger>
));

export const DialogPortal: React.FC<Dialog.DialogPortalProps> = ({
  children,
  ...props
}) => <Dialog.Portal {...props}>{children}</Dialog.Portal>;

export const DialogOverlay = React.forwardRef<
  HTMLDivElement,
  Dialog.DialogOverlayProps
>(({ children, className, ...props }, forwardedRef) => (
  <Dialog.Overlay
    className={classNames('DialogOverlay', className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Dialog.Overlay>
));

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  Dialog.DialogContentProps
>(({ children, className, ...props }, forwardedRef) => (
  <Dialog.Content
    className={classNames('DialogContent', className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Dialog.Content>
));

export const DialogTitle = React.forwardRef<
  HTMLHeadingElement,
  Dialog.DialogTitleProps
>(({ children, className, ...props }, forwardedRef) => (
  <Dialog.Title
    className={classNames('DialogTitle', className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Dialog.Title>
));

export const DialogDescription = React.forwardRef<
  HTMLParagraphElement,
  Dialog.DialogDescriptionProps
>(({ children, className, ...props }, forwardedRef) => (
  <Dialog.Description
    className={classNames('DialogDescription', className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Dialog.Description>
));

export const DialogClose = React.forwardRef<
  HTMLButtonElement,
  Dialog.DialogCloseProps
>(({ children, className, ...props }, forwardedRef) => (
  <Dialog.Close {...props} ref={forwardedRef}>
    {children}
  </Dialog.Close>
));

export const DialogButtonClose = React.forwardRef<
  HTMLButtonElement,
  Dialog.DialogCloseProps
>(({ children, className, ...props }, forwardedRef) => (
  <Dialog.Close asChild {...props} ref={forwardedRef}>
    <button className={classNames('IconButton', className)} aria-label='Close'>
      <Cross2Icon />
    </button>
  </Dialog.Close>
));
