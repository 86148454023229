import {
  invertersTypesOptions,
  structuresTypesOptions,
} from '@/constants/breakerOptions';
import {
  InverterList,
  ModuleList,
  SetupForm,
  StructureList,
  System,
} from '@/pages/EditProjectPage/types';
import { uploadListFiles } from '@/pages/AddProjectPage/helpers';
import { handleSeparateFiles } from '@/pages/EditProjectPage/helpers/common';

export const formatAndUploadEditSystem = async ({
  inverters,
  panels,
  structure,
}: SetupForm): Promise<System> => {
  const inverterList: InverterList[] = [];
  const moduleList: ModuleList[] = [];
  const structureList: StructureList[] = [];

  for (const {
    brand,
    model,
    potency,
    quantity,
    type,
    warranty,
    certificateAttachment,
    datasheetAttachment,
  } of inverters) {
    try {
      const certificate = handleSeparateFiles(certificateAttachment);
      const newCertificates = await uploadListFiles(certificate.filesToUpload);

      const datasheet = handleSeparateFiles(datasheetAttachment);
      const newDatasheets = await uploadListFiles(datasheet.filesToUpload);

      const inverterToPush: InverterList = {
        brand,
        type: invertersTypesOptions.find((inverter) => inverter.key === type)
          ?.value as string,
        model,
        potency: Number(potency),
        quantity: Number(quantity),
        warranty: Number(warranty),
        certificateAttachmentList: [
          ...certificate.uploadedFiles,
          ...newCertificates,
        ],
        datasheetAttachmentList: [...datasheet.uploadedFiles, ...newDatasheets],
      };

      inverterList.push(inverterToPush);
    } catch (err) {
      console.log('Error format/edit inverters :>> ', err);
    }
  }

  for (const {
    brand,
    dimension: { depth, height, width },
    model,
    potency,
    quantity,
    warranty80PercentEfficiency,
    warrantyAgainstFactoryDefect,
    weight,
    certificateAttachment,
    datasheetAttachment,
  } of panels) {
    try {
      const certificate = handleSeparateFiles(certificateAttachment);
      const newCertificates = await uploadListFiles(certificate.filesToUpload);

      const datasheet = handleSeparateFiles(datasheetAttachment);
      const newDatasheets = await uploadListFiles(datasheet.filesToUpload);

      const moduleToPush: ModuleList = {
        brand,
        dimension: `${height}x${width}x${depth}mm`,
        model,
        potency: Number(potency),
        quantity: Number(quantity),
        warranty80PercentEfficiency: Number(warranty80PercentEfficiency),
        warrantyAgainstFactoryDefect: Number(warrantyAgainstFactoryDefect),
        weight: weight,
        certificateAttachmentList: [
          ...certificate.uploadedFiles,
          ...newCertificates,
        ],
        datasheetAttachmentList: [...datasheet.uploadedFiles, ...newDatasheets],
      };

      moduleList.push(moduleToPush);
    } catch (err) {
      console.log('Error format/edit panels :>> ', err);
    }
  }

  for (const {
    structureBrand,
    structureType: type,
    detailsAttachment,
    datasheetAttachment,
  } of structure) {
    try {
      const details = handleSeparateFiles(detailsAttachment);
      const newDetails = await uploadListFiles(details.filesToUpload);

      const datasheet = handleSeparateFiles(datasheetAttachment);
      const newDatasheets = await uploadListFiles(datasheet.filesToUpload);

      const structureToPush: StructureList = {
        structureBrand,
        structureType: structuresTypesOptions.find(
          (structure) => structure.key === type,
        )?.value as string,
        detailsAttachmentList: [...details.uploadedFiles, ...newDetails],
        datasheetAttachmentList: [...datasheet.uploadedFiles, ...newDatasheets],
      };

      structureList.push(structureToPush);
    } catch (err) {
      console.log('Error format/edit structure :>> ', err);
    }
  }

  return { inverterList, moduleList, structureList };
};
