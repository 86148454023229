import * as React from 'react';

export const AccoutingPage = () => {
  return (
    <div className='h-full flex justify-center items-center'>
      <div className='text-center bg-white/70 p-5 rounded-md'>
        <div>Esta página está em desenvolvimento!</div>
        <div>Em breve ela está disponível para utilização.</div>
      </div>
    </div>
  );
};
