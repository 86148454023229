import { HeadCell } from '@/components/Table/types';
import i18n from '@/i18n';

export const headCells: Array<HeadCell> = [
  {
    id: 'fantasyName',
    numeric: false,
    disablePadding: false,
    type: 'text',
    label: 'Concessionária',
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    type: 'text',
    label: 'Cidade/Estado',
  },
  {
    id: 'emailMiniMicroDG',
    numeric: false,
    disablePadding: false,
    type: 'text',
    label: 'Email p/ mini e micro GD',
  },
  {
    id: 'urlTechnicalStandards',
    numeric: false,
    disablePadding: false,
    type: 'link',
    label: 'Link das normas técnicas',
  },
  {
    id: 'details',
    numeric: false,
    disablePadding: false,
    type: 'action',
    label: 'Detalhes',
  },
];
