/* eslint-disable no-useless-escape */
const maskCPF = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

const maskPhone = (value: string): string => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
};

const maskCEP = (value: string): string => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

const maskData = (value: string) => {
  const dataWithMask = value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1');

  return dataWithMask;
};

const onlyNumbers = (input: string): string => {
  return input.replace(/\D/g, '');
};

const maskFloatNumber = (value: string): string => {
  return value
    .replace(/[^\d,\.]/g, '')
    .replace(/,+/g, '.')
    .replace(/\.(?=.*\.)/g, '');
};

export { maskCPF, maskPhone, maskCEP, maskData, onlyNumbers, maskFloatNumber };
