import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Cross2Icon, UploadIcon } from '@radix-ui/react-icons';
import { InfosToUpload } from '@/models/Attachments';
import { Project } from '@/models/Project';

interface DropzoneProps {
  value: any;
  onChange: (...event: any[]) => void;
  error: string | undefined;
}

type AttachmentList = {
  id: number;
  key: string;
  fileName: string;
  format: string;
};

export const Dropzone: React.FC<DropzoneProps> = ({
  onChange,
  value,
  error,
}) => {
  const [files, setFiles] = useState<Array<File | AttachmentList>>([]);

  const fileAlreadyAdded = (newFile: File) => {
    return files.some((file) => {
      if (file instanceof File) {
        return file.name === newFile.name && file.size === newFile.size;
      } else {
        return file.fileName === newFile.name;
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/zip': [],
    },
    multiple: true,
    onDrop: (acceptedFiles: File[]) => {
      const uniqueFiles = acceptedFiles.filter(
        (file) => !fileAlreadyAdded(file),
      );
      const updatedFiles = [...files, ...uniqueFiles];
      setFiles(updatedFiles);
      onChange(updatedFiles);
    },
  });

  const removeFile = (
    event: React.MouseEvent,
    fileToRemove: File | AttachmentList,
  ) => {
    event.stopPropagation();

    const updatedFiles = files.filter((file) => {
      if (file instanceof File && fileToRemove instanceof File) {
        return file !== fileToRemove;
      }

      if (!(file instanceof File) && !(fileToRemove instanceof File)) {
        return file.key !== fileToRemove.key;
      }

      return true;
    });

    setFiles(updatedFiles);
    onChange(updatedFiles);
  };

  const onLoadExistentFiles = useQuery({
    queryKey: ['putImagesInFiles'],
    queryFn: async () => {
      setFiles((prevState) => [...prevState, ...value]);
    },
    enabled: !!value,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    console.log('value :>> ', value);
  }, [value]);

  return (
    <div className='flex flex-col'>
      <div
        {...getRootProps()}
        className={clsx(
          'p-2 rounded-[4px] border !border-neutral-300 border-solid',
          {
            '!border-red-600': !!error,
          },
        )}
      >
        {!files.length && (
          <label
            htmlFor='dropzone-file'
            className='cursor-pointer w-full h-full'
          >
            <div className='flex flex-col items-center justify-center pt-5 pb-6'>
              <UploadIcon className='w-7 h-7 text-gray-600' />

              <p className='mt-1 mb-0.5 text-sm text-gray-500'>
                <span className='font-semibold'>Clique para fazer upload</span>{' '}
                ou arraste os arquivos
              </p>

              <p className='text-xs text-gray-500'>PNG, JPG ou PDF</p>
            </div>
          </label>
        )}

        <input {...getInputProps()} id='dropzone-file' className='hidden' />

        {!!files.length && (
          <ul
            className={clsx({
              'flex justify-start items-center': files.length === 1,
              'grid grid-cols-2 gap-2': files.length > 1,
            })}
          >
            {files.map((file, index) => (
              <li
                key={file instanceof File ? file.name : file.key}
                className='w-full py-1.5 px-2 rounded-md flex items-center justify-between bg-neutral-100 group hover:bg-neutral-100/60 transition-all duration-300'
              >
                <span className='whitespace-nowrap overflow-hidden text-ellipsis'>
                  {file instanceof File ? file.name : file.fileName}{' '}
                </span>
                <button
                  type='button'
                  onClick={(e) => removeFile(e, file)}
                  className='p-1 rounded-full bg-transparent hover:bg-black/25'
                >
                  <Cross2Icon />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className='mt-[3px] mx-3.5'>
        <span className='text-red-600 text-sm'>{error}</span>
      </div>
    </div>
  );
};
