import React, { Fragment, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { Button, Typography } from '@mui/material';

import { TextField } from '@/components/TextField';
import { FileInputAws } from '@/components/FileInputAws';
import { ufToStateNames } from '@/constants/states';
import { citiesOptions } from '@/constants/cities';
import { ApportionmentForm } from '@/pages/AddProjectPage/types';
import { GetAddressService } from '@/provider/Address/implementations/GetAddressService';
import { unmask } from '@/utils/regex';
import { maskCEP, maskCPF, onlyNumbers } from '@/utils/masks';

import { useFormEditProject } from '../../../context';
import { Dropzone } from '@/components/Dropzone';

interface AddressFieldsProps {
  control: Control<ApportionmentForm>;
  register: UseFormRegister<ApportionmentForm>;
  setValue: UseFormSetValue<ApportionmentForm>;
  errors: FieldErrors<ApportionmentForm>;
  index: number;
}

const AddressFields: React.FC<AddressFieldsProps> = ({
  control,
  register,
  setValue,
  errors,
  index,
}) => {
  const cep = useWatch({ control, name: `form.${index}.cep` });

  const fetchAddress = async (cep: string) => {
    if (unmask(cep).length !== 8) {
      throw new Error('CEP deve ter 8 dígitos');
    }

    const getAddressService = new GetAddressService();
    const { bairro, localidade, logradouro, uf } =
      await getAddressService.getAddress(cep);

    const selectedState = ufToStateNames[uf];
    const city = citiesOptions(selectedState).find(
      ({ name }) => name.toLowerCase() === localidade.toLowerCase(),
    )?.name;

    setValue(`form.${index}.address`, logradouro);
    setValue(`form.${index}.district`, bairro);
    setValue(`form.${index}.city`, city || '');
    setValue(`form.${index}.state`, selectedState);

    return { logradouro, bairro, city, selectedState };
  };

  const { data } = useQuery({
    queryKey: ['address', cep],
    queryFn: () => fetchAddress(cep),
    enabled: unmask(cep).length === 8,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <TextField
        id={`apportionment-cep-${index}`}
        type='text'
        placeholder={'CEP'}
        label={'CEP'}
        error={Array.isArray(errors.form) && !!errors.form[index]?.cep?.message}
        helperText={
          Array.isArray(errors.form) && errors.form[index]?.cep?.message
        }
        {...register(`form.${index}.cep`, {
          onChange: (event) => {
            event.target.value = maskCEP(event.target.value);
          },
        })}
      />

      <TextField
        id={`apportionment-address-${index}`}
        type='text'
        placeholder={'Rua'}
        label={'Rua'}
        error={
          Array.isArray(errors.form) && !!errors.form[index]?.address?.message
        }
        helperText={
          Array.isArray(errors.form) && errors.form[index]?.address?.message
        }
        {...register(`form.${index}.address`)}
        InputLabelProps={{
          shrink: !!data?.logradouro && true,
        }}
      />

      <TextField
        id={`apportionment-number-${index}`}
        type='text'
        placeholder={'Número'}
        label={'Número'}
        error={
          Array.isArray(errors.form) && !!errors.form[index]?.number?.message
        }
        helperText={
          Array.isArray(errors.form) && errors.form[index]?.number?.message
        }
        {...register(`form.${index}.number`, {
          onChange: (event) => {
            event.target.value = onlyNumbers(event.target.value);
          },
        })}
      />

      <TextField
        id={`apportionment-complement-${index}`}
        type='text'
        placeholder={'Complemento'}
        label={'Complemento'}
        error={
          Array.isArray(errors.form) &&
          !!errors.form[index]?.complement?.message
        }
        helperText={
          Array.isArray(errors.form) && errors.form[index]?.complement?.message
        }
        {...register(`form.${index}.complement`)}
      />

      <TextField
        id={`apportionment-district-${index}`}
        type='text'
        placeholder={'Bairro'}
        label={'Bairro'}
        error={
          Array.isArray(errors.form) && !!errors.form[index]?.district?.message
        }
        helperText={
          Array.isArray(errors.form) && errors.form[index]?.district?.message
        }
        {...register(`form.${index}.district`)}
        InputLabelProps={{
          shrink: !!data?.bairro && true,
        }}
      />

      <TextField
        id={`apportionment-city-${index}`}
        type='text'
        placeholder={'Cidade'}
        label={'Cidade'}
        error={
          Array.isArray(errors.form) && !!errors.form[index]?.city?.message
        }
        helperText={
          Array.isArray(errors.form) && errors.form[index]?.city?.message
        }
        {...register(`form.${index}.city`)}
        InputLabelProps={{
          shrink: !!data?.city && true,
        }}
      />

      <TextField
        id={`apportionment-state-${index}`}
        type='text'
        placeholder={'Estado'}
        label={'Estado'}
        error={
          Array.isArray(errors.form) && !!errors.form[index]?.state?.message
        }
        helperText={
          Array.isArray(errors.form) && errors.form[index]?.state?.message
        }
        {...register(`form.${index}.state`)}
        InputLabelProps={{
          shrink: !!data?.selectedState && true,
        }}
      />
    </>
  );
};

export const Apportionment = () => {
  const { t } = useTranslation();

  const {
    apportionment: {
      register,
      control,
      formState: { errors },
      clearErrors,
      setError,
      setValue,
      watch,
    },
  } = useFormEditProject();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form',
  });

  const formValues = watch('form', []);

  const percentualValues = formValues.map((item) => item.percentual);

  useEffect(() => {
    const totalPercentual = percentualValues.reduce(
      (sum, value) => sum + Number(value),
      0,
    );

    const anyFieldHasValue = formValues.some((item) =>
      Boolean(item.percentual),
    );

    const areAllPercentualsValid = percentualValues.every(
      (value) => value >= 1 && value <= 100,
    );

    if (anyFieldHasValue && areAllPercentualsValid) {
      if (totalPercentual !== 100) {
        fields.forEach((_, index) => {
          setError(`form.${index}.percentual`, {
            type: 'custom',
            message: 'A soma dos percentuais deve ser igual a 100',
          });
        });
      } else {
        fields.forEach((_, index) => {
          clearErrors(`form.${index}.percentual`);
        });
      }
    }
  }, [fields.length, percentualValues]);

  return (
    <div id='main-uc-form'>
      <Typography variant='h6'>
        {t('AddProjectsPage.step-techData.mainUc')}
      </Typography>

      {fields.map((item, index) => (
        <Fragment key={item.id}>
          <Typography variant='inherit'>Rateio nº {index + 1}</Typography>

          <div className='uc-identify'>
            <TextField
              id={`apportionment-percentual-${index}`}
              type='number'
              placeholder={
                t(
                  'AddProjectsPage.step-apportionment.ucApportionment',
                ) as string
              }
              label={t('AddProjectsPage.step-apportionment.ucApportionment')}
              error={
                Array.isArray(errors.form) &&
                !!errors.form[index]?.percentual?.message
              }
              helperText={
                Array.isArray(errors.form) &&
                errors.form[index]?.percentual?.message
              }
              {...register(`form.${index}.percentual`)}
            />

            <TextField
              id={`apportionment-apportionmentUcCode-${index}`}
              type='text'
              placeholder={'Código de UC'}
              label={'Código de UC'}
              error={
                Array.isArray(errors.form) &&
                !!errors.form[index]?.apportionmentUcCode?.message
              }
              helperText={
                Array.isArray(errors.form) &&
                errors.form[index]?.apportionmentUcCode?.message
              }
              {...register(`form.${index}.apportionmentUcCode`)}
            />

            <TextField
              id={`apportionment-name-${index}`}
              type='text'
              placeholder={
                t(
                  'AddProjectsPage.step-apportionment.ucApportionmentHolder',
                ) as string
              }
              label={t(
                'AddProjectsPage.step-apportionment.ucApportionmentHolder',
              )}
              error={
                Array.isArray(errors.form) &&
                !!errors.form[index]?.name?.message
              }
              helperText={
                Array.isArray(errors.form) && errors.form[index]?.name?.message
              }
              {...register(`form.${index}.name`)}
            />

            <TextField
              type='text'
              id={`apportionment-cpf-${index}`}
              placeholder={
                t(
                  'AddProjectsPage.step-apportionment.ucApportionmentHolderCPF',
                ) as string
              }
              label={t(
                'AddProjectsPage.step-apportionment.ucApportionmentHolderCPF',
              )}
              error={
                Array.isArray(errors.form) && !!errors.form[index]?.cpf?.message
              }
              helperText={
                Array.isArray(errors.form) && errors.form[index]?.cpf?.message
              }
              {...register(`form.${index}.cpf`, {
                onChange: (event) => {
                  event.target.value = maskCPF(event.target.value);
                },
              })}
            />

            <AddressFields
              control={control}
              register={register}
              setValue={setValue}
              errors={errors}
              index={index}
            />
          </div>

          <Typography>Anexo - Conta de energia recente</Typography>

          <div>
            <Controller
              name={`form.${index}.attachment`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Dropzone
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>

          <div>
            <Button
              variant='contained'
              size='small'
              style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
              onClick={() => remove(index)}
            >
              Remover rateio
            </Button>
          </div>
        </Fragment>
      ))}

      <Button
        variant='contained'
        size='small'
        style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
        onClick={() =>
          append({
            percentual: 0,
            apportionmentUcCode: '',
            name: '',
            cpf: '',
            address: '',
            number: '',
            complement: '',
            district: '',
            cep: '',
            city: '',
            state: '',
          })
        }
      >
        Adicionar rateio
      </Button>
    </div>
  );
};
