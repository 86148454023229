import axios, { AxiosInstance } from 'axios';
import {
  Address,
  GetAddressServiceSkeleton,
} from '../GetAddressServiceSkeleton';

export class GetAddressService implements GetAddressServiceSkeleton {
  private readonly httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: 'https://viacep.com.br',
    });
  }

  async getAddress(cep: string) {
    const { data } = await this.httpClient.get<Address>(`/ws/${cep}/json/`);

    return data;
  }
}
