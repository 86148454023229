import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

type BoxWithTitleFooterType = {
  title: string;
  footer?: string;
  children: any;
  className?: string;
};

function BoxWithTitleFooter(props: BoxWithTitleFooterType) {
  const { title, footer, children, className } = props;

  const theme = useTheme();
  const themeClass = `theme-${theme.palette.mode}`;

  return (
    <div className={`box-with-title-footer ${themeClass} ${className}`}>
      <Typography variant='h3' className='title'>
        {title}
      </Typography>

      {children}

      <Typography variant='h3' className='footer'>
        {footer}
      </Typography>
    </div>
  );
}

export default BoxWithTitleFooter;
