import React from 'react';
import { FeedbackType } from '..';
import { FEEDBACK_TYPES } from '../../../mocks';
import { CloseButton } from '../../CloseButton';

interface FeedbackTypeStepProps {
  onFeedbackTypeChanged: (type: FeedbackType) => void;
}

export function FeedbackTypeStep({
  onFeedbackTypeChanged,
}: FeedbackTypeStepProps) {
  return (
    <>
      <header>
        <span className='text-xl leading-5'>Deixe seu feedback</span>
        <CloseButton />
      </header>

      <div className='flex py-6 gap-2 w-full'>
        {Object.entries(FEEDBACK_TYPES).map(([key, value]) => {
          return (
            <button
              key={key}
              className='bg-zinc-100 rounded-lg py-5 w-24 flex-1 flex flex-col items-center gap-2 border-2 border-transparent hover:border-brand-500 focus:border-brand-500 focus:outline-none'
              onClick={() => onFeedbackTypeChanged(key as FeedbackType)}
            >
              <img src={value.image.src} alt={value.image.alt} />
              <span>{value.title}</span>
            </button>
          );
        })}
      </div>
    </>
  );
}
