import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import Router from '@/router';
import * as Tooltip from '@radix-ui/react-tooltip';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { GlobalProvider } from './context';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './styles/index.scss';
import EnvironmentIndicator from './components/EnvironmentIndicator';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <GlobalProvider>
            <BrowserRouter>
              <EnvironmentIndicator />
              <Tooltip.Provider delayDuration={500}>
                <Router />
              </Tooltip.Provider>
            </BrowserRouter>
          </GlobalProvider>
        </I18nextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
