import React, { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import {
  Apportionment,
  Identify,
  Location,
  Photos,
  SetupForm,
  TechnicalData,
} from '../components/Steps';
import { StepsNonLinear } from '../components/Stepper';

import { Button, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { titleStepsForms, useFormEditProject } from '../context';
import { ProjectsService } from '@/services';
import { httpClient } from '@/infra';

export const EditProjectPresentation = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { activeStep, setProject, handleSubmit, updateFormValues } =
    useFormEditProject();

  const projectsServices = new ProjectsService(httpClient());

  const {
    data: project,
    isFetching,
    isLoading,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: ['projects', projectId],
    queryFn: async () =>
      await projectsServices.getUniqueProject({
        id: projectId as string,
      }),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess && project) {
      setProject(project);
      updateFormValues(project);
    }
  }, [isSuccess, project]);

  const forms = {
    IDENTIFICATION: <Identify key={1} />,
    TECHNICAL_DATA: <TechnicalData key={2} />,
    LOCALIZATION: <Location key={3} />,
    PHOTOS: <Photos key={4} />,
    APPORTIONMENT: <Apportionment key={5} />,
    EQUIPMENT: <SetupForm key={6} />,
  };

  if (isLoading || isFetching) {
    return <></>;
  }

  if (isError) {
    return <span>Não foi possível obter informações do projeto!</span>;
  }

  if (isSuccess) {
    return (
      <form onSubmit={handleSubmit[activeStep]} id='add-project-page'>
        <StepsNonLinear
          activeStep={activeStep}
          steps={titleStepsForms}
          forms={forms}
        />

        <Box className='action-buttons'>
          <Button type='submit' variant='contained' className='submit-button'>
            {t('system.common.submit')}
          </Button>
        </Box>
      </form>
    );
  }

  return <></>;
};
