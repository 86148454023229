import { sendTypeAnalysis } from '@/constants/dealerships';

type ObjectWithString = {
  [key: string]: string;
};

type ObjectWithNumber = {
  [key: string]: number;
};

const transformedSendTypeAnalysis = sendTypeAnalysis.reduce<ObjectWithString>(
  (acc, { key, value }) => {
    acc[key] = value;
    return acc;
  },
  {},
);

export { transformedSendTypeAnalysis };
