import { AxiosInstance } from 'axios';

import { Dealerships } from '@/models/Dealerships';

import {
  DealershipsServiceSkeleton,
  PaginationParams,
  UniqueParam,
} from '../DealershipsServiceSkeleton';

export class DealershipsService implements DealershipsServiceSkeleton {
  constructor(private httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getDealerships({ page = 0, size = 25 }: PaginationParams) {
    const { data } = await this.httpClient.get<Dealerships.GetDealerShips>(
      '/dealerships',
      {
        params: { page, size },
      },
    );

    return data;
  }

  async getUniqueDealership({ id }: UniqueParam) {
    const { data } = await this.httpClient.get<Dealerships.GetUniqueDealerShip>(
      `/dealerships/${id}`,
    );

    return data;
  }

  async createDealership(body: Dealerships.CreateDealerShip) {
    const { data } = await this.httpClient.post<Dealerships.CreateDealerShip>(
      '/dealerships',
      body,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );

    return data;
  }

  async deleteUniqueDealership({ id }: UniqueParam) {
    const { data } = await this.httpClient.delete<void>(`/dealerships/${id}`);

    return data;
  }
}
