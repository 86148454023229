import { getCookies, setCookie, destroyCookie } from '@/utils/cookies';

export const setUser = (user: UserType) => {
  setCookie('soaressantana-user', JSON.stringify(user));
};

export const getUser = (): UserType | null => {
  const { 'soaressantana-user': user } = getCookies();

  if (user) return JSON.parse(user);

  return null;
};

export const logout = () => {
  destroyCookie('soaressantana-user');
};

export type UserType = {
  accessToken: string;
  refreshToken: string;
  exp: number;
  iat: number;
  role: ClientType;
  sub: string;
  type: string;
  userId: number;
};

export type ClientType = 'CLIENT';
