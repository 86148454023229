import { z } from 'zod';
import { createFileSchema } from '@/pages/AddProjectPage/common/file';

export const schemaPhotos = z
  .object({
    inputBranch: createFileSchema({}),
    connectionBranch: createFileSchema({}),
    measurementBox: createFileSchema({}),
    disjointMeasurement: createFileSchema({}),
    propertyFront: createFileSchema({}),
    aerialPhotos: createFileSchema({ required: false }),
    otherPhotos: createFileSchema({ required: false }),
    projects: createFileSchema({ required: false }),
  })
  .required();
