import React from 'react';

import { httpClient } from '@/infra';
import { AttachmentsService, ProjectsService } from '@/services';
import { FormEditProjectProvider } from './context';
import { EditProjectPresentation } from './presentation';
import { Widget } from '@/components/Feedback/components/Widget';

export const EditProjectPage = () => {
  const attachmentsService = new AttachmentsService(httpClient());
  const projectsService = new ProjectsService(httpClient());

  return (
    <FormEditProjectProvider factory={{ attachmentsService, projectsService }}>
      <EditProjectPresentation />
      <Widget />
    </FormEditProjectProvider>
  );
};
