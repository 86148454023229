import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { structuresTypesOptions } from '@/constants/breakerOptions';

import { ChipSelect } from '@/components/ChipSelect';
import { TextField } from '@/components/TextField';
import { Dropzone } from '@/components/Dropzone';

import { useFormEditProject } from '@/pages/EditProjectPage/context';

export const StructureForm = () => {
  const { t } = useTranslation();

  const {
    equipment: {
      register,
      control,
      formState: { errors },
    },
  } = useFormEditProject();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'structure',
  });

  return (
    <>
      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <Typography variant='inherit'>Estrutura nº {index + 1}</Typography>

          <div className='holder-identify'>
            <Controller
              name={`structure.${index}.structureType`}
              control={control}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => (
                <ChipSelect
                  id='uc-structure-structureType'
                  placeholder={t('AddProjectsPage.step-setup.ucTypes')}
                  label={t('AddProjectsPage.step-setup.ucTypes')}
                  error={
                    Array.isArray(errors.structure) &&
                    errors.structure[index]?.structureType?.message
                  }
                  options={structuresTypesOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <TextField
              id={`uc-structureBrand-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucBrand') as string}
              label={t('AddProjectsPage.step-setup.ucBrand')}
              error={
                Array.isArray(errors.structure) &&
                !!errors.structure[index]?.structureBrand?.message
              }
              helperText={
                Array.isArray(errors.structure) &&
                errors.structure[index]?.structureBrand?.message
              }
              {...register(`structure.${index}.structureBrand`)}
            />

            <div>
              <div className='space-y-1'>
                <span className='ml-1'>Data sheets</span>
              </div>
              <div>
                <Controller
                  name={`structure.${index}.datasheetAttachment`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Dropzone
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <div className='space-y-1'>
                <span className='ml-1'>Detalhamento</span>
              </div>
              <div>
                <Controller
                  name={`structure.${index}.detailsAttachment`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Dropzone
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div>
            <Button
              variant='contained'
              size='small'
              style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
              onClick={() => remove(index)}
            >
              Remover estrutura
            </Button>
          </div>
        </React.Fragment>
      ))}

      <Button
        variant='contained'
        size='small'
        style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
        onClick={() =>
          append({
            structureType: '',
            structureBrand: '',
          })
        }
      >
        Adicionar estrutura
      </Button>
    </>
  );
};
