import { FileAttachmentList } from '@/pages/EditProjectPage/types';

interface FileArrays {
  filesToUpload: File[];
  uploadedFiles: FileAttachmentList[];
}

export const handleSeparateFiles = (
  array: Array<File | FileAttachmentList>,
): FileArrays => {
  return array.reduce(
    (acc: FileArrays, item) => {
      if (item instanceof File) {
        acc.filesToUpload.push(item);
      } else {
        acc.uploadedFiles.push(item as FileAttachmentList);
      }
      return acc;
    },
    { filesToUpload: [], uploadedFiles: [] } as FileArrays,
  );
};
