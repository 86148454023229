import React from 'react';

import { DialogOverlay, DialogPortal, DialogRoot } from '@/components/Dialog';

import { ViewDetails } from './steps/ViewDetails';
import { Steps } from '../..';
import { Dealership } from '@/models/Dealerships';

type ModalProjectInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  activeStep: Steps;
  dealershipToView: Dealership;
};

export const ModalDealership = ({
  isOpen,
  onClose,
  activeStep,
  dealershipToView,
}: ModalProjectInfoProps) => {
  const stepsToShow = {
    VIEW_DETAILS: <ViewDetails dealership={dealershipToView} />,
    NONE: <></>,
  };

  if (activeStep === 'NONE') {
    return <></>;
  }

  return (
    <DialogRoot open={isOpen} onOpenChange={onClose}>
      <DialogPortal>
        <DialogOverlay />
        {stepsToShow[activeStep]}
      </DialogPortal>
    </DialogRoot>
  );
};
