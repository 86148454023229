import React from 'react';
import { UseMutationResult, useQuery } from '@tanstack/react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Typography } from '@mui/material';

import {
  DialogButtonClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/Dialog';

import { TextField } from '@/components/TextField';
import { maskCEP, maskCPF, maskPhone, onlyNumbers } from '@/utils/masks';
import { IDesigner } from '@/models/Designer';
import { DesignerForm, schemaDesigner } from '@/pages/DesignerPage/schemas';
import { unmask } from '@/utils/regex';
import { GetAddressService } from '@/provider/Address/implementations/GetAddressService';
import { ufToStateNames } from '@/constants/states';

type CreateDesignerProps = {
  onCreateDesigner: UseMutationResult<IDesigner, unknown, IDesigner, unknown>;
};

export const CreateDesigner = ({ onCreateDesigner }: CreateDesignerProps) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<DesignerForm>({
    mode: 'all',
    resolver: zodResolver(schemaDesigner),
  });

  const onSubmit: SubmitHandler<DesignerForm> = ({
    address,
    cellphone,
    cep,
    city,
    complement,
    cpf,
    district,
    number,
    telephone,
    state,
    ...rest
  }) => {
    onCreateDesigner.mutate({
      id: 0,
      cpf: unmask(cpf),
      cellphone: unmask(cellphone),
      telephone: unmask(telephone),
      address: {
        address,
        complement,
        number: Number(number),
        district,
        cep: unmask(cep),
        city,
        state,
      },
      ...rest,
    });
  };

  const zip = watch('cep');

  const fetchAddress = async (cep: string) => {
    if (unmask(String(cep)).length !== 8) {
      throw new Error('CEP deve ter 8 dígitos');
    }

    const getAddressService = new GetAddressService();

    const response = await getAddressService.getAddress(cep);

    if (response?.erro === true) {
      setValue('address', '');
      setValue('district', '');
      setValue('city', '');
      setValue('state', '');

      return;
    }

    const { bairro, localidade, logradouro, uf } = response;

    const stateToComplete = ufToStateNames[uf];

    setValue('address', logradouro ?? '');
    setValue('district', bairro ?? '');
    setValue('city', localidade ?? '');
    setValue('state', stateToComplete ?? '');

    return response;
  };

  const onFetchAddress = useQuery({
    queryKey: ['address', zip],
    queryFn: async () => await fetchAddress(zip),
    enabled: unmask(String(zip)).length === 8,
    refetchOnWindowFocus: false,
  });

  return (
    <DialogContent
      className='ModalProjectInfoContent'
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        <Typography>Cadastro de Projetista</Typography>
      </DialogTitle>

      <DialogDescription>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col gap-2.5'
        >
          <TextField
            id='firstName'
            type='text'
            placeholder={'Digite o nome'}
            label={'Nome'}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            {...register('firstName')}
          />

          <TextField
            id='lastName'
            type='text'
            placeholder={'Digite o sobrenome'}
            label={'Sobrenome'}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            {...register('lastName')}
          />

          <TextField
            id='rg'
            type='text'
            placeholder={'Digite o RG'}
            label={'RG'}
            error={!!errors.rg}
            helperText={errors.rg?.message}
            {...register('rg', {
              onChange: (event) => {
                event.target.value = onlyNumbers(event.target.value);
              },
            })}
          />

          <TextField
            id='cpf'
            type='text'
            placeholder={'Digite o CPF'}
            label={'CPF'}
            error={!!errors.cpf}
            helperText={errors.cpf?.message}
            {...register('cpf', {
              onChange: (event) => {
                event.target.value = maskCPF(event.target.value);
              },
            })}
          />

          <TextField
            id='birthdate'
            type='date'
            placeholder={'Digite a data de nascimento'}
            label={'Data de Nascimento'}
            error={!!errors.birthdate}
            helperText={errors.birthdate?.message}
            {...register('birthdate')}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id='profession'
            type='text'
            placeholder={'Digite a profissão'}
            label={'Profissão'}
            error={!!errors.profession}
            helperText={errors.profession?.message}
            {...register('profession')}
          />

          <TextField
            id='classCouncil'
            type='text'
            placeholder={'Digite o Conselho de Classe'}
            label={'Conselho de Classe'}
            error={!!errors.classCouncil}
            helperText={errors.classCouncil?.message}
            {...register('classCouncil')}
          />

          <TextField
            id='rnpNumber'
            type='text'
            placeholder={'Digite o Registro Nacional Profissional'}
            label={'RNP'}
            error={!!errors.rnpNumber}
            helperText={errors.rnpNumber?.message}
            {...register('rnpNumber')}
          />

          <TextField
            id='cep'
            type='text'
            placeholder={'Digite o CEP'}
            label={'CEP'}
            error={!!errors.cep}
            helperText={errors.cep?.message}
            {...register('cep', {
              onChange: (event) => {
                event.target.value = maskCEP(event.target.value);
              },
            })}
          />

          <Controller
            name='address'
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id='address'
                type='text'
                placeholder={'Digite a rua'}
                label={'Rua'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: !!value && true,
                }}
              />
            )}
          />

          <TextField
            id='number'
            type='text'
            placeholder={'Digite o número'}
            label={'Número'}
            error={!!errors.number}
            helperText={errors.number?.message}
            {...register('number')}
          />

          <TextField
            id='complement'
            type='text'
            placeholder={'Digite o complemento'}
            label={'Complemento'}
            error={!!errors.complement}
            helperText={errors.complement?.message}
            {...register('complement')}
          />

          <Controller
            name='district'
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id='district'
                type='text'
                placeholder={'Digite o bairro'}
                label={'Bairro'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: !!value && true,
                }}
              />
            )}
          />

          <Controller
            name='city'
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id='city'
                type='text'
                placeholder={'Digite a cidade'}
                label={'Cidade'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: !!value && true,
                }}
              />
            )}
          />

          <Controller
            name='state'
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                id='state'
                type='text'
                placeholder={'Digite o estado'}
                label={'Estado'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                InputLabelProps={{
                  shrink: !!value && true,
                }}
              />
            )}
          />

          <TextField
            id='telephone'
            type='text'
            placeholder={'Digite o telefone'}
            label={'Telefone'}
            error={!!errors.telephone}
            helperText={errors.telephone?.message}
            {...register('telephone', {
              onChange: (event) => {
                event.target.value = maskPhone(event.target.value);
              },
            })}
          />

          <TextField
            id='cellphone'
            type='text'
            placeholder={'Digite o celular'}
            label={'Celular'}
            error={!!errors.cellphone}
            helperText={errors.cellphone?.message}
            {...register('cellphone', {
              onChange: (event) => {
                event.target.value = maskPhone(event.target.value);
              },
            })}
          />

          <TextField
            id='professionalEmail'
            type='email'
            placeholder={'Digite o e-mail'}
            label={'E-mail profissional'}
            error={!!errors.professionalEmail}
            helperText={errors.professionalEmail?.message}
            {...register('professionalEmail')}
          />

          <Button
            type='submit'
            variant='contained'
            size='medium'
            className='!bg-[#dcaf07]'
          >
            Cadastrar Projetista
          </Button>
        </form>
      </DialogDescription>

      <DialogButtonClose className='ButtonClose' />
    </DialogContent>
  );
};
