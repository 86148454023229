import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/system';
import BoxWithTitleFooter from '../../../../components/BoxWithTitleFooter';
import ImageWithFooter from '../../../../components/ImageWithFooter';

import CivilProjects from '../../../../assets/landingPage/civil-projects.jpg';
import SharedDistProject from '../../../../assets/landingPage/light-project.jpeg';
import StructureProject from '../../../../assets/landingPage/structure-project.webp';

type ServicesBoxType = {
  id: string;
};

function ServicesBox({ id }: ServicesBoxType) {
  const { t } = useTranslation();

  const servicesTitle = t('LandingPage.anchors.services');
  const footerImg1 = t('LandingPage.ServicesBox.footer-img1');
  const footerImg2 = t('LandingPage.ServicesBox.footer-img2');
  const footerImg3 = t('LandingPage.ServicesBox.footer-img3');

  return (
    <section id={id}>
      <BoxWithTitleFooter
        title={servicesTitle}
        footer='Realizamos projetos de todos os tipos!'
      >
        <Box className='image-group-box'>
          <ImageWithFooter
            src={StructureProject}
            alt={footerImg1}
            footer={footerImg1}
          />
          <ImageWithFooter
            src={SharedDistProject}
            alt={footerImg2}
            footer={footerImg2}
          />
          <ImageWithFooter
            src={CivilProjects}
            alt={footerImg3}
            footer={footerImg3}
          />
        </Box>
      </BoxWithTitleFooter>
    </section>
  );
}

export default ServicesBox;
