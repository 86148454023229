import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { TextField } from '@/components/TextField';
import StatesSelect from '@/components/StatesSelect';
import CitiesSelect from '@/components/CitiesSelect';
import { Controller, useWatch } from 'react-hook-form';
import { maskCEP, onlyNumbers } from '@/utils/masks';
import { useFormEditProject } from '../../../context';
import { GetAddressService } from '@/provider/Address/implementations/GetAddressService';
import { unmask } from '@/utils/regex';
import { citiesOptions } from '@/constants/cities';
import { ufToStateNames } from '@/constants/states';
import { useQuery } from '@tanstack/react-query';

export const Location = () => {
  const { t } = useTranslation();

  const {
    location: {
      register,
      control,
      setValue,
      formState: { errors },
    },
  } = useFormEditProject();

  const state = useWatch({
    control,
    name: 'state',
  });

  const cepValue = useWatch({
    control,
    name: 'zip',
  });

  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);

  const fetchAddress = async (cep: string) => {
    if (unmask(cep).length !== 8) {
      throw new Error('CEP deve ter 8 dígitos');
    }

    const getAddressService = new GetAddressService();
    const { bairro, localidade, logradouro, uf } =
      await getAddressService.getAddress(cep);

    const selectedState = ufToStateNames[uf];
    const city = citiesOptions(selectedState).find(
      ({ name }) => name.toLowerCase() === localidade.toLowerCase(),
    )?.name;

    setValue('street', logradouro);
    setValue('neighborhood', bairro);
    setValue('city', city || '');
    setValue('state', selectedState);

    return { logradouro, bairro, city, selectedState };
  };

  const getAddress = useQuery({
    queryKey: ['address', cepValue],
    queryFn: () => fetchAddress(cepValue),
    enabled: unmask(cepValue).length === 8 && !!isInitialFetchDone,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    console.log('isInitialFetchDone :>> ', isInitialFetchDone);
  }, [isInitialFetchDone]);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const unmaskedText = unmask(pastedText);
    setValue('zip', maskCEP(unmaskedText));
  };

  return (
    <div id='location-form'>
      <Typography variant='h6'>
        {t('AddProjectsPage.step-location.location')}
      </Typography>

      <div className='uc-location'>
        <TextField
          id='uc-cep'
          type='text'
          placeholder={t('AddProjectsPage.step-location.ucCep') as string}
          label={t('AddProjectsPage.step-location.ucCep')}
          onPaste={handlePaste}
          error={!!errors.zip}
          helperText={errors.zip?.message}
          {...register('zip', {
            onChange: (event) => {
              event.target.value = maskCEP(event.target.value);
              setIsInitialFetchDone(true);
            },
          })}
          InputLabelProps={{
            shrink: !!cepValue && true,
          }}
        />

        <Controller
          name='street'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id='uc-street'
              type='text'
              placeholder={
                t('AddProjectsPage.step-location.ucStreet') as string
              }
              label={t('AddProjectsPage.step-location.ucStreet')}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name='number'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id='uc-street-number'
              type='text'
              placeholder={
                t('AddProjectsPage.step-location.ucStreetNumber') as string
              }
              label={t('AddProjectsPage.step-location.ucStreetNumber')}
              value={value}
              onChange={(event) => onChange(onlyNumbers(event.target.value))}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name='neighborhood'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              id='uc-neighborhood'
              type='text'
              placeholder={
                t('AddProjectsPage.step-location.ucDistrict') as string
              }
              label={t('AddProjectsPage.step-location.ucDistrict')}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name='state'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <StatesSelect
              id='uc-state'
              placeholder={t('AddProjectsPage.step-location.ucState') as string}
              label={t('AddProjectsPage.step-location.ucState')}
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />

        <Controller
          name='city'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CitiesSelect
              id='uc-city'
              placeholder={t('AddProjectsPage.step-location.ucCity') as string}
              label={t('AddProjectsPage.step-location.ucCity')}
              state={state}
              value={value}
              onChange={onChange}
              disabled={!state}
              error={error}
            />
          )}
        />

        <TextField
          id='uc-complement'
          type='text'
          placeholder={
            t('AddProjectsPage.step-location.ucComplement') as string
          }
          label={t('AddProjectsPage.step-location.ucComplement')}
          error={!!errors.complement}
          helperText={errors.complement?.message}
          {...register('complement')}
        />

        <TextField
          id='uc-location'
          type='text'
          placeholder={t('AddProjectsPage.step-location.ucLocation') as string}
          label={t('AddProjectsPage.step-location.ucLocation')}
          error={!!errors.location}
          helperText={errors.location?.message}
          {...register('location')}
        />
      </div>
    </div>
  );
};
