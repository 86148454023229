import React, { MouseEvent } from 'react';
import {
  Box,
  TableCell,
  TableHead as Head,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { HeadCell, OrderType } from '../types';

interface EnhancedTableProps<T> {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof T) => void;
  order: OrderType;
  orderBy: string;
  headers: Array<HeadCell>;
  hasHeaderToCheckbox?: boolean;
}

export function TableHead<T>({
  order,
  orderBy,
  onRequestSort,
  headers,
  hasHeaderToCheckbox = true,
}: EnhancedTableProps<T>) {
  const createSortHandler =
    (property: keyof T) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <Head>
      <TableRow>
        {hasHeaderToCheckbox && <TableCell padding='checkbox' />}

        {headers.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id as keyof T)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </Head>
  );
}
