export type City = {
  id: number;
  state: string;
  name: string;
};

export const cities: City[] = [
  { state: 'Rondônia', id: 1100015, name: "Alta Floresta D'Oeste" },
  { state: 'Rondônia', id: 1100379, name: 'Alto Alegre dos Parecis' },
  { state: 'Rondônia', id: 1100403, name: 'Alto Paraíso' },
  { state: 'Rondônia', id: 1100346, name: "Alvorada D'Oeste" },
  { state: 'Rondônia', id: 1100023, name: 'Ariquemes' },
  { state: 'Rondônia', id: 1100452, name: 'Buritis' },
  { state: 'Rondônia', id: 1100031, name: 'Cabixi' },
  { state: 'Rondônia', id: 1100601, name: 'Cacaulândia' },
  { state: 'Rondônia', id: 1100049, name: 'Cacoal' },
  { state: 'Rondônia', id: 1100700, name: 'Campo Novo de Rondônia' },
  { state: 'Rondônia', id: 1100809, name: 'Candeias do Jamari' },
  { state: 'Rondônia', id: 1100908, name: 'Castanheiras' },
  { state: 'Rondônia', id: 1100056, name: 'Cerejeiras' },
  { state: 'Rondônia', id: 1100924, name: 'Chupinguaia' },
  { state: 'Rondônia', id: 1100064, name: 'Colorado do Oeste' },
  { state: 'Rondônia', id: 1100072, name: 'Corumbiara' },
  { state: 'Rondônia', id: 1100080, name: 'Costa Marques' },
  { state: 'Rondônia', id: 1100940, name: 'Cujubim' },
  { state: 'Rondônia', id: 1100098, name: "Espigão D'Oeste" },
  { state: 'Rondônia', id: 1101005, name: 'Governador Jorge Teixeira' },
  { state: 'Rondônia', id: 1100106, name: 'Guajará-Mirim' },
  { state: 'Rondônia', id: 1101104, name: 'Itapuã do Oeste' },
  { state: 'Rondônia', id: 1100114, name: 'Jaru' },
  { state: 'Rondônia', id: 1100122, name: 'Ji-Paraná' },
  { state: 'Rondônia', id: 1100130, name: "Machadinho D'Oeste" },
  { state: 'Rondônia', id: 1101203, name: 'Ministro Andreazza' },
  { state: 'Rondônia', id: 1101302, name: 'Mirante da Serra' },
  { state: 'Rondônia', id: 1101401, name: 'Monte Negro' },
  { state: 'Rondônia', id: 1100148, name: "Nova Brasilândia D'Oeste" },
  { state: 'Rondônia', id: 1100338, name: 'Nova Mamoré' },
  { state: 'Rondônia', id: 1101435, name: 'Nova União' },
  { state: 'Rondônia', id: 1100502, name: 'Novo Horizonte do Oeste' },
  { state: 'Rondônia', id: 1100155, name: 'Ouro Preto do Oeste' },
  { state: 'Rondônia', id: 1101450, name: 'Parecis' },
  { state: 'Rondônia', id: 1100189, name: 'Pimenta Bueno' },
  { state: 'Rondônia', id: 1101468, name: 'Pimenteiras do Oeste' },
  { state: 'Rondônia', id: 1100205, name: 'Porto Velho' },
  { state: 'Rondônia', id: 1100254, name: 'Presidente Médici' },
  { state: 'Rondônia', id: 1101476, name: 'Primavera de Rondônia' },
  { state: 'Rondônia', id: 1100262, name: 'Rio Crespo' },
  { state: 'Rondônia', id: 1100288, name: 'Rolim de Moura' },
  { state: 'Rondônia', id: 1100296, name: "Santa Luzia D'Oeste" },
  { state: 'Rondônia', id: 1101484, name: "São Felipe D'Oeste" },
  { state: 'Rondônia', id: 1101492, name: 'São Francisco do Guaporé' },
  { state: 'Rondônia', id: 1100320, name: 'São Miguel do Guaporé' },
  { state: 'Rondônia', id: 1101500, name: 'Seringueiras' },
  { state: 'Rondônia', id: 1101559, name: 'Teixeirópolis' },
  { state: 'Rondônia', id: 1101609, name: 'Theobroma' },
  { state: 'Rondônia', id: 1101708, name: 'Urupá' },
  { state: 'Rondônia', id: 1101757, name: 'Vale do Anari' },
  { state: 'Rondônia', id: 1101807, name: 'Vale do Paraíso' },
  { state: 'Rondônia', id: 1100304, name: 'Vilhena' },
  { state: 'Acre', id: 1200013, name: 'Acrelândia' },
  { state: 'Acre', id: 1200054, name: 'Assis Brasil' },
  { state: 'Acre', id: 1200104, name: 'Brasiléia' },
  { state: 'Acre', id: 1200138, name: 'Bujari' },
  { state: 'Acre', id: 1200179, name: 'Capixaba' },
  { state: 'Acre', id: 1200203, name: 'Cruzeiro do Sul' },
  { state: 'Acre', id: 1200252, name: 'Epitaciolândia' },
  { state: 'Acre', id: 1200302, name: 'Feijó' },
  { state: 'Acre', id: 1200328, name: 'Jordão' },
  { state: 'Acre', id: 1200336, name: 'Mâncio Lima' },
  { state: 'Acre', id: 1200344, name: 'Manoel Urbano' },
  { state: 'Acre', id: 1200351, name: 'Marechal Thaumaturgo' },
  { state: 'Acre', id: 1200385, name: 'Plácido de Castro' },
  { state: 'Acre', id: 1200807, name: 'Porto Acre' },
  { state: 'Acre', id: 1200393, name: 'Porto Walter' },
  { state: 'Acre', id: 1200401, name: 'Rio Branco' },
  { state: 'Acre', id: 1200427, name: 'Rodrigues Alves' },
  { state: 'Acre', id: 1200435, name: 'Santa Rosa do Purus' },
  { state: 'Acre', id: 1200500, name: 'Sena Madureira' },
  { state: 'Acre', id: 1200450, name: 'Senador Guiomard' },
  { state: 'Acre', id: 1200609, name: 'Tarauacá' },
  { state: 'Acre', id: 1200708, name: 'Xapuri' },
  { state: 'Amazonas', id: 1300029, name: 'Alvarães' },
  { state: 'Amazonas', id: 1300060, name: 'Amaturá' },
  { state: 'Amazonas', id: 1300086, name: 'Anamã' },
  { state: 'Amazonas', id: 1300102, name: 'Anori' },
  { state: 'Amazonas', id: 1300144, name: 'Apuí' },
  { state: 'Amazonas', id: 1300201, name: 'Atalaia do Norte' },
  { state: 'Amazonas', id: 1300300, name: 'Autazes' },
  { state: 'Amazonas', id: 1300409, name: 'Barcelos' },
  { state: 'Amazonas', id: 1300508, name: 'Barreirinha' },
  { state: 'Amazonas', id: 1300607, name: 'Benjamin Constant' },
  { state: 'Amazonas', id: 1300631, name: 'Beruri' },
  { state: 'Amazonas', id: 1300680, name: 'Boa Vista do Ramos' },
  { state: 'Amazonas', id: 1300706, name: 'Boca do Acre' },
  { state: 'Amazonas', id: 1300805, name: 'Borba' },
  { state: 'Amazonas', id: 1300839, name: 'Caapiranga' },
  { state: 'Amazonas', id: 1300904, name: 'Canutama' },
  { state: 'Amazonas', id: 1301001, name: 'Carauari' },
  { state: 'Amazonas', id: 1301100, name: 'Careiro' },
  { state: 'Amazonas', id: 1301159, name: 'Careiro da Várzea' },
  { state: 'Amazonas', id: 1301209, name: 'Coari' },
  { state: 'Amazonas', id: 1301308, name: 'Codajás' },
  { state: 'Amazonas', id: 1301407, name: 'Eirunepé' },
  { state: 'Amazonas', id: 1301506, name: 'Envira' },
  { state: 'Amazonas', id: 1301605, name: 'Fonte Boa' },
  { state: 'Amazonas', id: 1301654, name: 'Guajará' },
  { state: 'Amazonas', id: 1301704, name: 'Humaitá' },
  { state: 'Amazonas', id: 1301803, name: 'Ipixuna' },
  { state: 'Amazonas', id: 1301852, name: 'Iranduba' },
  { state: 'Amazonas', id: 1301902, name: 'Itacoatiara' },
  { state: 'Amazonas', id: 1301951, name: 'Itamarati' },
  { state: 'Amazonas', id: 1302009, name: 'Itapiranga' },
  { state: 'Amazonas', id: 1302108, name: 'Japurá' },
  { state: 'Amazonas', id: 1302207, name: 'Juruá' },
  { state: 'Amazonas', id: 1302306, name: 'Jutaí' },
  { state: 'Amazonas', id: 1302405, name: 'Lábrea' },
  { state: 'Amazonas', id: 1302504, name: 'Manacapuru' },
  { state: 'Amazonas', id: 1302553, name: 'Manaquiri' },
  { state: 'Amazonas', id: 1302603, name: 'Manaus' },
  { state: 'Amazonas', id: 1302702, name: 'Manicoré' },
  { state: 'Amazonas', id: 1302801, name: 'Maraã' },
  { state: 'Amazonas', id: 1302900, name: 'Maués' },
  { state: 'Amazonas', id: 1303007, name: 'Nhamundá' },
  { state: 'Amazonas', id: 1303106, name: 'Nova Olinda do Norte' },
  { state: 'Amazonas', id: 1303205, name: 'Novo Airão' },
  { state: 'Amazonas', id: 1303304, name: 'Novo Aripuanã' },
  { state: 'Amazonas', id: 1303403, name: 'Parintins' },
  { state: 'Amazonas', id: 1303502, name: 'Pauini' },
  { state: 'Amazonas', id: 1303536, name: 'Presidente Figueiredo' },
  { state: 'Amazonas', id: 1303569, name: 'Rio Preto da Eva' },
  { state: 'Amazonas', id: 1303601, name: 'Santa Isabel do Rio Negro' },
  { state: 'Amazonas', id: 1303700, name: 'Santo Antônio do Içá' },
  { state: 'Amazonas', id: 1303809, name: 'São Gabriel da Cachoeira' },
  { state: 'Amazonas', id: 1303908, name: 'São Paulo de Olivença' },
  { state: 'Amazonas', id: 1303957, name: 'São Sebastião do Uatumã' },
  { state: 'Amazonas', id: 1304005, name: 'Silves' },
  { state: 'Amazonas', id: 1304062, name: 'Tabatinga' },
  { state: 'Amazonas', id: 1304104, name: 'Tapauá' },
  { state: 'Amazonas', id: 1304203, name: 'Tefé' },
  { state: 'Amazonas', id: 1304237, name: 'Tonantins' },
  { state: 'Amazonas', id: 1304260, name: 'Uarini' },
  { state: 'Amazonas', id: 1304302, name: 'Urucará' },
  { state: 'Amazonas', id: 1304401, name: 'Urucurituba' },
  { state: 'Roraima', id: 1400050, name: 'Alto Alegre' },
  { state: 'Roraima', id: 1400027, name: 'Amajari' },
  { state: 'Roraima', id: 1400100, name: 'Boa Vista' },
  { state: 'Roraima', id: 1400159, name: 'Bonfim' },
  { state: 'Roraima', id: 1400175, name: 'Cantá' },
  { state: 'Roraima', id: 1400209, name: 'Caracaraí' },
  { state: 'Roraima', id: 1400233, name: 'Caroebe' },
  { state: 'Roraima', id: 1400282, name: 'Iracema' },
  { state: 'Roraima', id: 1400308, name: 'Mucajaí' },
  { state: 'Roraima', id: 1400407, name: 'Normandia' },
  { state: 'Roraima', id: 1400456, name: 'Pacaraima' },
  { state: 'Roraima', id: 1400472, name: 'Rorainópolis' },
  { state: 'Roraima', id: 1400506, name: 'São João da Baliza' },
  { state: 'Roraima', id: 1400605, name: 'São Luiz' },
  { state: 'Roraima', id: 1400704, name: 'Uiramutã' },
  { state: 'Pará', id: 1500107, name: 'Abaetetuba' },
  { state: 'Pará', id: 1500131, name: 'Abel Figueiredo' },
  { state: 'Pará', id: 1500206, name: 'Acará' },
  { state: 'Pará', id: 1500305, name: 'Afuá' },
  { state: 'Pará', id: 1500347, name: 'Água Azul do Norte' },
  { state: 'Pará', id: 1500404, name: 'Alenquer' },
  { state: 'Pará', id: 1500503, name: 'Almeirim' },
  { state: 'Pará', id: 1500602, name: 'Altamira' },
  { state: 'Pará', id: 1500701, name: 'Anajás' },
  { state: 'Pará', id: 1500800, name: 'Ananindeua' },
  { state: 'Pará', id: 1500859, name: 'Anapu' },
  { state: 'Pará', id: 1500909, name: 'Augusto Corrêa' },
  { state: 'Pará', id: 1500958, name: 'Aurora do Pará' },
  { state: 'Pará', id: 1501006, name: 'Aveiro' },
  { state: 'Pará', id: 1501105, name: 'Bagre' },
  { state: 'Pará', id: 1501204, name: 'Baião' },
  { state: 'Pará', id: 1501253, name: 'Bannach' },
  { state: 'Pará', id: 1501303, name: 'Barcarena' },
  { state: 'Pará', id: 1501402, name: 'Belém' },
  { state: 'Pará', id: 1501451, name: 'Belterra' },
  { state: 'Pará', id: 1501501, name: 'Benevides' },
  { state: 'Pará', id: 1501576, name: 'Bom Jesus do Tocantins' },
  { state: 'Pará', id: 1501600, name: 'Bonito' },
  { state: 'Pará', id: 1501709, name: 'Bragança' },
  { state: 'Pará', id: 1501725, name: 'Brasil Novo' },
  { state: 'Pará', id: 1501758, name: 'Brejo Grande do Araguaia' },
  { state: 'Pará', id: 1501782, name: 'Breu Branco' },
  { state: 'Pará', id: 1501808, name: 'Breves' },
  { state: 'Pará', id: 1501907, name: 'Bujaru' },
  { state: 'Pará', id: 1502004, name: 'Cachoeira do Arari' },
  { state: 'Pará', id: 1501956, name: 'Cachoeira do Piriá' },
  { state: 'Pará', id: 1502103, name: 'Cametá' },
  { state: 'Pará', id: 1502152, name: 'Canaã dos Carajás' },
  { state: 'Pará', id: 1502202, name: 'Capanema' },
  { state: 'Pará', id: 1502301, name: 'Capitão Poço' },
  { state: 'Pará', id: 1502400, name: 'Castanhal' },
  { state: 'Pará', id: 1502509, name: 'Chaves' },
  { state: 'Pará', id: 1502608, name: 'Colares' },
  { state: 'Pará', id: 1502707, name: 'Conceição do Araguaia' },
  { state: 'Pará', id: 1502756, name: 'Concórdia do Pará' },
  { state: 'Pará', id: 1502764, name: 'Cumaru do Norte' },
  { state: 'Pará', id: 1502772, name: 'Curionópolis' },
  { state: 'Pará', id: 1502806, name: 'Curralinho' },
  { state: 'Pará', id: 1502855, name: 'Curuá' },
  { state: 'Pará', id: 1502905, name: 'Curuçá' },
  { state: 'Pará', id: 1502939, name: 'Dom Eliseu' },
  { state: 'Pará', id: 1502954, name: 'Eldorado do Carajás' },
  { state: 'Pará', id: 1503002, name: 'Faro' },
  { state: 'Pará', id: 1503044, name: 'Floresta do Araguaia' },
  { state: 'Pará', id: 1503077, name: 'Garrafão do Norte' },
  { state: 'Pará', id: 1503093, name: 'Goianésia do Pará' },
  { state: 'Pará', id: 1503101, name: 'Gurupá' },
  { state: 'Pará', id: 1503200, name: 'Igarapé-Açu' },
  { state: 'Pará', id: 1503309, name: 'Igarapé-Miri' },
  { state: 'Pará', id: 1503408, name: 'Inhangapi' },
  { state: 'Pará', id: 1503457, name: 'Ipixuna do Pará' },
  { state: 'Pará', id: 1503507, name: 'Irituia' },
  { state: 'Pará', id: 1503606, name: 'Itaituba' },
  { state: 'Pará', id: 1503705, name: 'Itupiranga' },
  { state: 'Pará', id: 1503754, name: 'Jacareacanga' },
  { state: 'Pará', id: 1503804, name: 'Jacundá' },
  { state: 'Pará', id: 1503903, name: 'Juruti' },
  { state: 'Pará', id: 1504000, name: 'Limoeiro do Ajuru' },
  { state: 'Pará', id: 1504059, name: 'Mãe do Rio' },
  { state: 'Pará', id: 1504109, name: 'Magalhães Barata' },
  { state: 'Pará', id: 1504208, name: 'Marabá' },
  { state: 'Pará', id: 1504307, name: 'Maracanã' },
  { state: 'Pará', id: 1504406, name: 'Marapanim' },
  { state: 'Pará', id: 1504422, name: 'Marituba' },
  { state: 'Pará', id: 1504455, name: 'Medicilândia' },
  { state: 'Pará', id: 1504505, name: 'Melgaço' },
  { state: 'Pará', id: 1504604, name: 'Mocajuba' },
  { state: 'Pará', id: 1504703, name: 'Moju' },
  { state: 'Pará', id: 1504752, name: 'Mojuí dos Campos' },
  { state: 'Pará', id: 1504802, name: 'Monte Alegre' },
  { state: 'Pará', id: 1504901, name: 'Muaná' },
  { state: 'Pará', id: 1504950, name: 'Nova Esperança do Piriá' },
  { state: 'Pará', id: 1504976, name: 'Nova Ipixuna' },
  { state: 'Pará', id: 1505007, name: 'Nova Timboteua' },
  { state: 'Pará', id: 1505031, name: 'Novo Progresso' },
  { state: 'Pará', id: 1505064, name: 'Novo Repartimento' },
  { state: 'Pará', id: 1505106, name: 'Óbidos' },
  { state: 'Pará', id: 1505205, name: 'Oeiras do Pará' },
  { state: 'Pará', id: 1505304, name: 'Oriximiná' },
  { state: 'Pará', id: 1505403, name: 'Ourém' },
  { state: 'Pará', id: 1505437, name: 'Ourilândia do Norte' },
  { state: 'Pará', id: 1505486, name: 'Pacajá' },
  { state: 'Pará', id: 1505494, name: 'Palestina do Pará' },
  { state: 'Pará', id: 1505502, name: 'Paragominas' },
  { state: 'Pará', id: 1505536, name: 'Parauapebas' },
  { state: 'Pará', id: 1505551, name: "Pau D'Arco" },
  { state: 'Pará', id: 1505601, name: 'Peixe-Boi' },
  { state: 'Pará', id: 1505635, name: 'Piçarra' },
  { state: 'Pará', id: 1505650, name: 'Placas' },
  { state: 'Pará', id: 1505700, name: 'Ponta de Pedras' },
  { state: 'Pará', id: 1505809, name: 'Portel' },
  { state: 'Pará', id: 1505908, name: 'Porto de Moz' },
  { state: 'Pará', id: 1506005, name: 'Prainha' },
  { state: 'Pará', id: 1506104, name: 'Primavera' },
  { state: 'Pará', id: 1506112, name: 'Quatipuru' },
  { state: 'Pará', id: 1506138, name: 'Redenção' },
  { state: 'Pará', id: 1506161, name: 'Rio Maria' },
  { state: 'Pará', id: 1506187, name: 'Rondon do Pará' },
  { state: 'Pará', id: 1506195, name: 'Rurópolis' },
  { state: 'Pará', id: 1506203, name: 'Salinópolis' },
  { state: 'Pará', id: 1506302, name: 'Salvaterra' },
  { state: 'Pará', id: 1506351, name: 'Santa Bárbara do Pará' },
  { state: 'Pará', id: 1506401, name: 'Santa Cruz do Arari' },
  { state: 'Pará', id: 1506500, name: 'Santa Izabel do Pará' },
  { state: 'Pará', id: 1506559, name: 'Santa Luzia do Pará' },
  { state: 'Pará', id: 1506583, name: 'Santa Maria das Barreiras' },
  { state: 'Pará', id: 1506609, name: 'Santa Maria do Pará' },
  { state: 'Pará', id: 1506708, name: 'Santana do Araguaia' },
  { state: 'Pará', id: 1506807, name: 'Santarém' },
  { state: 'Pará', id: 1506906, name: 'Santarém Novo' },
  { state: 'Pará', id: 1507003, name: 'Santo Antônio do Tauá' },
  { state: 'Pará', id: 1507102, name: 'São Caetano de Odivelas' },
  { state: 'Pará', id: 1507151, name: 'São Domingos do Araguaia' },
  { state: 'Pará', id: 1507201, name: 'São Domingos do Capim' },
  { state: 'Pará', id: 1507300, name: 'São Félix do Xingu' },
  { state: 'Pará', id: 1507409, name: 'São Francisco do Pará' },
  { state: 'Pará', id: 1507458, name: 'São Geraldo do Araguaia' },
  { state: 'Pará', id: 1507466, name: 'São João da Ponta' },
  { state: 'Pará', id: 1507474, name: 'São João de Pirabas' },
  { state: 'Pará', id: 1507508, name: 'São João do Araguaia' },
  { state: 'Pará', id: 1507607, name: 'São Miguel do Guamá' },
  { state: 'Pará', id: 1507706, name: 'São Sebastião da Boa Vista' },
  { state: 'Pará', id: 1507755, name: 'Sapucaia' },
  { state: 'Pará', id: 1507805, name: 'Senador José Porfírio' },
  { state: 'Pará', id: 1507904, name: 'Soure' },
  { state: 'Pará', id: 1507953, name: 'Tailândia' },
  { state: 'Pará', id: 1507961, name: 'Terra Alta' },
  { state: 'Pará', id: 1507979, name: 'Terra Santa' },
  { state: 'Pará', id: 1508001, name: 'Tomé-Açu' },
  { state: 'Pará', id: 1508035, name: 'Tracuateua' },
  { state: 'Pará', id: 1508050, name: 'Trairão' },
  { state: 'Pará', id: 1508084, name: 'Tucumã' },
  { state: 'Pará', id: 1508100, name: 'Tucuruí' },
  { state: 'Pará', id: 1508126, name: 'Ulianópolis' },
  { state: 'Pará', id: 1508159, name: 'Uruará' },
  { state: 'Pará', id: 1508209, name: 'Vigia' },
  { state: 'Pará', id: 1508308, name: 'Viseu' },
  { state: 'Pará', id: 1508357, name: 'Vitória do Xingu' },
  { state: 'Pará', id: 1508407, name: 'Xinguara' },
  { state: 'Amapá', id: 1600105, name: 'Amapá' },
  { state: 'Amapá', id: 1600204, name: 'Calçoene' },
  { state: 'Amapá', id: 1600212, name: 'Cutias' },
  { state: 'Amapá', id: 1600238, name: 'Ferreira Gomes' },
  { state: 'Amapá', id: 1600253, name: 'Itaubal' },
  { state: 'Amapá', id: 1600279, name: 'Laranjal do Jari' },
  { state: 'Amapá', id: 1600303, name: 'Macapá' },
  { state: 'Amapá', id: 1600402, name: 'Mazagão' },
  { state: 'Amapá', id: 1600501, name: 'Oiapoque' },
  { state: 'Amapá', id: 1600154, name: 'Pedra Branca do Amapari' },
  { state: 'Amapá', id: 1600535, name: 'Porto Grande' },
  { state: 'Amapá', id: 1600550, name: 'Pracuúba' },
  { state: 'Amapá', id: 1600600, name: 'Santana' },
  { state: 'Amapá', id: 1600055, name: 'Serra do Navio' },
  { state: 'Amapá', id: 1600709, name: 'Tartarugalzinho' },
  { state: 'Amapá', id: 1600808, name: 'Vitória do Jari' },
  { state: 'Tocantins', id: 1700251, name: 'Abreulândia' },
  { state: 'Tocantins', id: 1700301, name: 'Aguiarnópolis' },
  { state: 'Tocantins', id: 1700350, name: 'Aliança do Tocantins' },
  { state: 'Tocantins', id: 1700400, name: 'Almas' },
  { state: 'Tocantins', id: 1700707, name: 'Alvorada' },
  { state: 'Tocantins', id: 1701002, name: 'Ananás' },
  { state: 'Tocantins', id: 1701051, name: 'Angico' },
  { state: 'Tocantins', id: 1701101, name: 'Aparecida do Rio Negro' },
  { state: 'Tocantins', id: 1701309, name: 'Aragominas' },
  { state: 'Tocantins', id: 1701903, name: 'Araguacema' },
  { state: 'Tocantins', id: 1702000, name: 'Araguaçu' },
  { state: 'Tocantins', id: 1702109, name: 'Araguaína' },
  { state: 'Tocantins', id: 1702158, name: 'Araguanã' },
  { state: 'Tocantins', id: 1702208, name: 'Araguatins' },
  { state: 'Tocantins', id: 1702307, name: 'Arapoema' },
  { state: 'Tocantins', id: 1702406, name: 'Arraias' },
  { state: 'Tocantins', id: 1702554, name: 'Augustinópolis' },
  { state: 'Tocantins', id: 1702703, name: 'Aurora do Tocantins' },
  { state: 'Tocantins', id: 1702901, name: 'Axixá do Tocantins' },
  { state: 'Tocantins', id: 1703008, name: 'Babaçulândia' },
  { state: 'Tocantins', id: 1703057, name: 'Bandeirantes do Tocantins' },
  { state: 'Tocantins', id: 1703073, name: 'Barra do Ouro' },
  { state: 'Tocantins', id: 1703107, name: 'Barrolândia' },
  { state: 'Tocantins', id: 1703206, name: 'Bernardo Sayão' },
  { state: 'Tocantins', id: 1703305, name: 'Bom Jesus do Tocantins' },
  { state: 'Tocantins', id: 1703602, name: 'Brasilândia do Tocantins' },
  { state: 'Tocantins', id: 1703701, name: 'Brejinho de Nazaré' },
  { state: 'Tocantins', id: 1703800, name: 'Buriti do Tocantins' },
  { state: 'Tocantins', id: 1703826, name: 'Cachoeirinha' },
  { state: 'Tocantins', id: 1703842, name: 'Campos Lindos' },
  { state: 'Tocantins', id: 1703867, name: 'Cariri do Tocantins' },
  { state: 'Tocantins', id: 1703883, name: 'Carmolândia' },
  { state: 'Tocantins', id: 1703891, name: 'Carrasco Bonito' },
  { state: 'Tocantins', id: 1703909, name: 'Caseara' },
  { state: 'Tocantins', id: 1704105, name: 'Centenário' },
  { state: 'Tocantins', id: 1705102, name: 'Chapada da Natividade' },
  { state: 'Tocantins', id: 1704600, name: 'Chapada de Areia' },
  { state: 'Tocantins', id: 1705508, name: 'Colinas do Tocantins' },
  { state: 'Tocantins', id: 1716703, name: 'Colméia' },
  { state: 'Tocantins', id: 1705557, name: 'Combinado' },
  { state: 'Tocantins', id: 1705607, name: 'Conceição do Tocantins' },
  { state: 'Tocantins', id: 1706001, name: 'Couto Magalhães' },
  { state: 'Tocantins', id: 1706100, name: 'Cristalândia' },
  { state: 'Tocantins', id: 1706258, name: 'Crixás do Tocantins' },
  { state: 'Tocantins', id: 1706506, name: 'Darcinópolis' },
  { state: 'Tocantins', id: 1707009, name: 'Dianópolis' },
  { state: 'Tocantins', id: 1707108, name: 'Divinópolis do Tocantins' },
  { state: 'Tocantins', id: 1707207, name: 'Dois Irmãos do Tocantins' },
  { state: 'Tocantins', id: 1707306, name: 'Dueré' },
  { state: 'Tocantins', id: 1707405, name: 'Esperantina' },
  { state: 'Tocantins', id: 1707553, name: 'Fátima' },
  { state: 'Tocantins', id: 1707652, name: 'Figueirópolis' },
  { state: 'Tocantins', id: 1707702, name: 'Filadélfia' },
  { state: 'Tocantins', id: 1708205, name: 'Formoso do Araguaia' },
  { state: 'Tocantins', id: 1708254, name: 'Fortaleza do Tabocão' },
  { state: 'Tocantins', id: 1708304, name: 'Goianorte' },
  { state: 'Tocantins', id: 1709005, name: 'Goiatins' },
  { state: 'Tocantins', id: 1709302, name: 'Guaraí' },
  { state: 'Tocantins', id: 1709500, name: 'Gurupi' },
  { state: 'Tocantins', id: 1709807, name: 'Ipueiras' },
  { state: 'Tocantins', id: 1710508, name: 'Itacajá' },
  { state: 'Tocantins', id: 1710706, name: 'Itaguatins' },
  { state: 'Tocantins', id: 1710904, name: 'Itapiratins' },
  { state: 'Tocantins', id: 1711100, name: 'Itaporã do Tocantins' },
  { state: 'Tocantins', id: 1711506, name: 'Jaú do Tocantins' },
  { state: 'Tocantins', id: 1711803, name: 'Juarina' },
  { state: 'Tocantins', id: 1711902, name: 'Lagoa da Confusão' },
  { state: 'Tocantins', id: 1711951, name: 'Lagoa do Tocantins' },
  { state: 'Tocantins', id: 1712009, name: 'Lajeado' },
  { state: 'Tocantins', id: 1712157, name: 'Lavandeira' },
  { state: 'Tocantins', id: 1712405, name: 'Lizarda' },
  { state: 'Tocantins', id: 1712454, name: 'Luzinópolis' },
  { state: 'Tocantins', id: 1712504, name: 'Marianópolis do Tocantins' },
  { state: 'Tocantins', id: 1712702, name: 'Mateiros' },
  { state: 'Tocantins', id: 1712801, name: 'Maurilândia do Tocantins' },
  { state: 'Tocantins', id: 1713205, name: 'Miracema do Tocantins' },
  { state: 'Tocantins', id: 1713304, name: 'Miranorte' },
  { state: 'Tocantins', id: 1713601, name: 'Monte do Carmo' },
  { state: 'Tocantins', id: 1713700, name: 'Monte Santo do Tocantins' },
  { state: 'Tocantins', id: 1713957, name: 'Muricilândia' },
  { state: 'Tocantins', id: 1714203, name: 'Natividade' },
  { state: 'Tocantins', id: 1714302, name: 'Nazaré' },
  { state: 'Tocantins', id: 1714880, name: 'Nova Olinda' },
  { state: 'Tocantins', id: 1715002, name: 'Nova Rosalândia' },
  { state: 'Tocantins', id: 1715101, name: 'Novo Acordo' },
  { state: 'Tocantins', id: 1715150, name: 'Novo Alegre' },
  { state: 'Tocantins', id: 1715259, name: 'Novo Jardim' },
  { state: 'Tocantins', id: 1715507, name: 'Oliveira de Fátima' },
  { state: 'Tocantins', id: 1721000, name: 'Palmas' },
  { state: 'Tocantins', id: 1715705, name: 'Palmeirante' },
  { state: 'Tocantins', id: 1713809, name: 'Palmeiras do Tocantins' },
  { state: 'Tocantins', id: 1715754, name: 'Palmeirópolis' },
  { state: 'Tocantins', id: 1716109, name: 'Paraíso do Tocantins' },
  { state: 'Tocantins', id: 1716208, name: 'Paranã' },
  { state: 'Tocantins', id: 1716307, name: "Pau D'Arco" },
  { state: 'Tocantins', id: 1716505, name: 'Pedro Afonso' },
  { state: 'Tocantins', id: 1716604, name: 'Peixe' },
  { state: 'Tocantins', id: 1716653, name: 'Pequizeiro' },
  { state: 'Tocantins', id: 1717008, name: 'Pindorama do Tocantins' },
  { state: 'Tocantins', id: 1717206, name: 'Piraquê' },
  { state: 'Tocantins', id: 1717503, name: 'Pium' },
  { state: 'Tocantins', id: 1717800, name: 'Ponte Alta do Bom Jesus' },
  { state: 'Tocantins', id: 1717909, name: 'Ponte Alta do Tocantins' },
  { state: 'Tocantins', id: 1718006, name: 'Porto Alegre do Tocantins' },
  { state: 'Tocantins', id: 1718204, name: 'Porto Nacional' },
  { state: 'Tocantins', id: 1718303, name: 'Praia Norte' },
  { state: 'Tocantins', id: 1718402, name: 'Presidente Kennedy' },
  { state: 'Tocantins', id: 1718451, name: 'Pugmil' },
  { state: 'Tocantins', id: 1718501, name: 'Recursolândia' },
  { state: 'Tocantins', id: 1718550, name: 'Riachinho' },
  { state: 'Tocantins', id: 1718659, name: 'Rio da Conceição' },
  { state: 'Tocantins', id: 1718709, name: 'Rio dos Bois' },
  { state: 'Tocantins', id: 1718758, name: 'Rio Sono' },
  { state: 'Tocantins', id: 1718808, name: 'Sampaio' },
  { state: 'Tocantins', id: 1718840, name: 'Sandolândia' },
  { state: 'Tocantins', id: 1718865, name: 'Santa Fé do Araguaia' },
  { state: 'Tocantins', id: 1718881, name: 'Santa Maria do Tocantins' },
  { state: 'Tocantins', id: 1718899, name: 'Santa Rita do Tocantins' },
  { state: 'Tocantins', id: 1718907, name: 'Santa Rosa do Tocantins' },
  { state: 'Tocantins', id: 1719004, name: 'Santa Tereza do Tocantins' },
  { state: 'Tocantins', id: 1720002, name: 'Santa Terezinha do Tocantins' },
  { state: 'Tocantins', id: 1720101, name: 'São Bento do Tocantins' },
  { state: 'Tocantins', id: 1720150, name: 'São Félix do Tocantins' },
  { state: 'Tocantins', id: 1720200, name: 'São Miguel do Tocantins' },
  { state: 'Tocantins', id: 1720259, name: 'São Salvador do Tocantins' },
  { state: 'Tocantins', id: 1720309, name: 'São Sebastião do Tocantins' },
  { state: 'Tocantins', id: 1720499, name: 'São Valério' },
  { state: 'Tocantins', id: 1720655, name: 'Silvanópolis' },
  { state: 'Tocantins', id: 1720804, name: 'Sítio Novo do Tocantins' },
  { state: 'Tocantins', id: 1720853, name: 'Sucupira' },
  { state: 'Tocantins', id: 1720903, name: 'Taguatinga' },
  { state: 'Tocantins', id: 1720937, name: 'Taipas do Tocantins' },
  { state: 'Tocantins', id: 1720978, name: 'Talismã' },
  { state: 'Tocantins', id: 1721109, name: 'Tocantínia' },
  { state: 'Tocantins', id: 1721208, name: 'Tocantinópolis' },
  { state: 'Tocantins', id: 1721257, name: 'Tupirama' },
  { state: 'Tocantins', id: 1721307, name: 'Tupiratins' },
  { state: 'Tocantins', id: 1722081, name: 'Wanderlândia' },
  { state: 'Tocantins', id: 1722107, name: 'Xambioá' },
  { state: 'Maranhão', id: 2100055, name: 'Açailândia' },
  { state: 'Maranhão', id: 2100105, name: 'Afonso Cunha' },
  { state: 'Maranhão', id: 2100154, name: 'Água Doce do Maranhão' },
  { state: 'Maranhão', id: 2100204, name: 'Alcântara' },
  { state: 'Maranhão', id: 2100303, name: 'Aldeias Altas' },
  { state: 'Maranhão', id: 2100402, name: 'Altamira do Maranhão' },
  { state: 'Maranhão', id: 2100436, name: 'Alto Alegre do Maranhão' },
  { state: 'Maranhão', id: 2100477, name: 'Alto Alegre do Pindaré' },
  { state: 'Maranhão', id: 2100501, name: 'Alto Parnaíba' },
  { state: 'Maranhão', id: 2100550, name: 'Amapá do Maranhão' },
  { state: 'Maranhão', id: 2100600, name: 'Amarante do Maranhão' },
  { state: 'Maranhão', id: 2100709, name: 'Anajatuba' },
  { state: 'Maranhão', id: 2100808, name: 'Anapurus' },
  { state: 'Maranhão', id: 2100832, name: 'Apicum-Açu' },
  { state: 'Maranhão', id: 2100873, name: 'Araguanã' },
  { state: 'Maranhão', id: 2100907, name: 'Araioses' },
  { state: 'Maranhão', id: 2100956, name: 'Arame' },
  { state: 'Maranhão', id: 2101004, name: 'Arari' },
  { state: 'Maranhão', id: 2101103, name: 'Axixá' },
  { state: 'Maranhão', id: 2101202, name: 'Bacabal' },
  { state: 'Maranhão', id: 2101251, name: 'Bacabeira' },
  { state: 'Maranhão', id: 2101301, name: 'Bacuri' },
  { state: 'Maranhão', id: 2101350, name: 'Bacurituba' },
  { state: 'Maranhão', id: 2101400, name: 'Balsas' },
  { state: 'Maranhão', id: 2101509, name: 'Barão de Grajaú' },
  { state: 'Maranhão', id: 2101608, name: 'Barra do Corda' },
  { state: 'Maranhão', id: 2101707, name: 'Barreirinhas' },
  { state: 'Maranhão', id: 2101772, name: 'Bela Vista do Maranhão' },
  { state: 'Maranhão', id: 2101731, name: 'Belágua' },
  { state: 'Maranhão', id: 2101806, name: 'Benedito Leite' },
  { state: 'Maranhão', id: 2101905, name: 'Bequimão' },
  { state: 'Maranhão', id: 2101939, name: 'Bernardo do Mearim' },
  { state: 'Maranhão', id: 2101970, name: 'Boa Vista do Gurupi' },
  { state: 'Maranhão', id: 2102002, name: 'Bom Jardim' },
  { state: 'Maranhão', id: 2102036, name: 'Bom Jesus das Selvas' },
  { state: 'Maranhão', id: 2102077, name: 'Bom Lugar' },
  { state: 'Maranhão', id: 2102101, name: 'Brejo' },
  { state: 'Maranhão', id: 2102150, name: 'Brejo de Areia' },
  { state: 'Maranhão', id: 2102200, name: 'Buriti' },
  { state: 'Maranhão', id: 2102309, name: 'Buriti Bravo' },
  { state: 'Maranhão', id: 2102325, name: 'Buriticupu' },
  { state: 'Maranhão', id: 2102358, name: 'Buritirana' },
  { state: 'Maranhão', id: 2102374, name: 'Cachoeira Grande' },
  { state: 'Maranhão', id: 2102408, name: 'Cajapió' },
  { state: 'Maranhão', id: 2102507, name: 'Cajari' },
  { state: 'Maranhão', id: 2102556, name: 'Campestre do Maranhão' },
  { state: 'Maranhão', id: 2102606, name: 'Cândido Mendes' },
  { state: 'Maranhão', id: 2102705, name: 'Cantanhede' },
  { state: 'Maranhão', id: 2102754, name: 'Capinzal do Norte' },
  { state: 'Maranhão', id: 2102804, name: 'Carolina' },
  { state: 'Maranhão', id: 2102903, name: 'Carutapera' },
  { state: 'Maranhão', id: 2103000, name: 'Caxias' },
  { state: 'Maranhão', id: 2103109, name: 'Cedral' },
  { state: 'Maranhão', id: 2103125, name: 'Central do Maranhão' },
  { state: 'Maranhão', id: 2103158, name: 'Centro do Guilherme' },
  { state: 'Maranhão', id: 2103174, name: 'Centro Novo do Maranhão' },
  { state: 'Maranhão', id: 2103208, name: 'Chapadinha' },
  { state: 'Maranhão', id: 2103257, name: 'Cidelândia' },
  { state: 'Maranhão', id: 2103307, name: 'Codó' },
  { state: 'Maranhão', id: 2103406, name: 'Coelho Neto' },
  { state: 'Maranhão', id: 2103505, name: 'Colinas' },
  { state: 'Maranhão', id: 2103554, name: 'Conceição do Lago-Açu' },
  { state: 'Maranhão', id: 2103604, name: 'Coroatá' },
  { state: 'Maranhão', id: 2103703, name: 'Cururupu' },
  { state: 'Maranhão', id: 2103752, name: 'Davinópolis' },
  { state: 'Maranhão', id: 2103802, name: 'Dom Pedro' },
  { state: 'Maranhão', id: 2103901, name: 'Duque Bacelar' },
  { state: 'Maranhão', id: 2104008, name: 'Esperantinópolis' },
  { state: 'Maranhão', id: 2104057, name: 'Estreito' },
  { state: 'Maranhão', id: 2104073, name: 'Feira Nova do Maranhão' },
  { state: 'Maranhão', id: 2104081, name: 'Fernando Falcão' },
  { state: 'Maranhão', id: 2104099, name: 'Formosa da Serra Negra' },
  { state: 'Maranhão', id: 2104107, name: 'Fortaleza dos Nogueiras' },
  { state: 'Maranhão', id: 2104206, name: 'Fortuna' },
  { state: 'Maranhão', id: 2104305, name: 'Godofredo Viana' },
  { state: 'Maranhão', id: 2104404, name: 'Gonçalves Dias' },
  { state: 'Maranhão', id: 2104503, name: 'Governador Archer' },
  { state: 'Maranhão', id: 2104552, name: 'Governador Edison Lobão' },
  { state: 'Maranhão', id: 2104602, name: 'Governador Eugênio Barros' },
  { state: 'Maranhão', id: 2104628, name: 'Governador Luiz Rocha' },
  { state: 'Maranhão', id: 2104651, name: 'Governador Newton Bello' },
  { state: 'Maranhão', id: 2104677, name: 'Governador Nunes Freire' },
  { state: 'Maranhão', id: 2104701, name: 'Graça Aranha' },
  { state: 'Maranhão', id: 2104800, name: 'Grajaú' },
  { state: 'Maranhão', id: 2104909, name: 'Guimarães' },
  { state: 'Maranhão', id: 2105005, name: 'Humberto de Campos' },
  { state: 'Maranhão', id: 2105104, name: 'Icatu' },
  { state: 'Maranhão', id: 2105153, name: 'Igarapé do Meio' },
  { state: 'Maranhão', id: 2105203, name: 'Igarapé Grande' },
  { state: 'Maranhão', id: 2105302, name: 'Imperatriz' },
  { state: 'Maranhão', id: 2105351, name: 'Itaipava do Grajaú' },
  { state: 'Maranhão', id: 2105401, name: 'Itapecuru Mirim' },
  { state: 'Maranhão', id: 2105427, name: 'Itinga do Maranhão' },
  { state: 'Maranhão', id: 2105450, name: 'Jatobá' },
  { state: 'Maranhão', id: 2105476, name: 'Jenipapo dos Vieiras' },
  { state: 'Maranhão', id: 2105500, name: 'João Lisboa' },
  { state: 'Maranhão', id: 2105609, name: 'Joselândia' },
  { state: 'Maranhão', id: 2105658, name: 'Junco do Maranhão' },
  { state: 'Maranhão', id: 2105708, name: 'Lago da Pedra' },
  { state: 'Maranhão', id: 2105807, name: 'Lago do Junco' },
  { state: 'Maranhão', id: 2105948, name: 'Lago dos Rodrigues' },
  { state: 'Maranhão', id: 2105906, name: 'Lago Verde' },
  { state: 'Maranhão', id: 2105922, name: 'Lagoa do Mato' },
  { state: 'Maranhão', id: 2105963, name: 'Lagoa Grande do Maranhão' },
  { state: 'Maranhão', id: 2105989, name: 'Lajeado Novo' },
  { state: 'Maranhão', id: 2106003, name: 'Lima Campos' },
  { state: 'Maranhão', id: 2106102, name: 'Loreto' },
  { state: 'Maranhão', id: 2106201, name: 'Luís Domingues' },
  { state: 'Maranhão', id: 2106300, name: 'Magalhães de Almeida' },
  { state: 'Maranhão', id: 2106326, name: 'Maracaçumé' },
  { state: 'Maranhão', id: 2106359, name: 'Marajá do Sena' },
  { state: 'Maranhão', id: 2106375, name: 'Maranhãozinho' },
  { state: 'Maranhão', id: 2106409, name: 'Mata Roma' },
  { state: 'Maranhão', id: 2106508, name: 'Matinha' },
  { state: 'Maranhão', id: 2106607, name: 'Matões' },
  { state: 'Maranhão', id: 2106631, name: 'Matões do Norte' },
  { state: 'Maranhão', id: 2106672, name: 'Milagres do Maranhão' },
  { state: 'Maranhão', id: 2106706, name: 'Mirador' },
  { state: 'Maranhão', id: 2106755, name: 'Miranda do Norte' },
  { state: 'Maranhão', id: 2106805, name: 'Mirinzal' },
  { state: 'Maranhão', id: 2106904, name: 'Monção' },
  { state: 'Maranhão', id: 2107001, name: 'Montes Altos' },
  { state: 'Maranhão', id: 2107100, name: 'Morros' },
  { state: 'Maranhão', id: 2107209, name: 'Nina Rodrigues' },
  { state: 'Maranhão', id: 2107258, name: 'Nova Colinas' },
  { state: 'Maranhão', id: 2107308, name: 'Nova Iorque' },
  { state: 'Maranhão', id: 2107357, name: 'Nova Olinda do Maranhão' },
  { state: 'Maranhão', id: 2107407, name: "Olho d'Água das Cunhãs" },
  { state: 'Maranhão', id: 2107456, name: 'Olinda Nova do Maranhão' },
  { state: 'Maranhão', id: 2107506, name: 'Paço do Lumiar' },
  { state: 'Maranhão', id: 2107605, name: 'Palmeirândia' },
  { state: 'Maranhão', id: 2107704, name: 'Paraibano' },
  { state: 'Maranhão', id: 2107803, name: 'Parnarama' },
  { state: 'Maranhão', id: 2107902, name: 'Passagem Franca' },
  { state: 'Maranhão', id: 2108009, name: 'Pastos Bons' },
  { state: 'Maranhão', id: 2108058, name: 'Paulino Neves' },
  { state: 'Maranhão', id: 2108108, name: 'Paulo Ramos' },
  { state: 'Maranhão', id: 2108207, name: 'Pedreiras' },
  { state: 'Maranhão', id: 2108256, name: 'Pedro do Rosário' },
  { state: 'Maranhão', id: 2108306, name: 'Penalva' },
  { state: 'Maranhão', id: 2108405, name: 'Peri Mirim' },
  { state: 'Maranhão', id: 2108454, name: 'Peritoró' },
  { state: 'Maranhão', id: 2108504, name: 'Pindaré-Mirim' },
  { state: 'Maranhão', id: 2108603, name: 'Pinheiro' },
  { state: 'Maranhão', id: 2108702, name: 'Pio XII' },
  { state: 'Maranhão', id: 2108801, name: 'Pirapemas' },
  { state: 'Maranhão', id: 2108900, name: 'Poção de Pedras' },
  { state: 'Maranhão', id: 2109007, name: 'Porto Franco' },
  { state: 'Maranhão', id: 2109056, name: 'Porto Rico do Maranhão' },
  { state: 'Maranhão', id: 2109106, name: 'Presidente Dutra' },
  { state: 'Maranhão', id: 2109205, name: 'Presidente Juscelino' },
  { state: 'Maranhão', id: 2109239, name: 'Presidente Médici' },
  { state: 'Maranhão', id: 2109270, name: 'Presidente Sarney' },
  { state: 'Maranhão', id: 2109304, name: 'Presidente Vargas' },
  { state: 'Maranhão', id: 2109403, name: 'Primeira Cruz' },
  { state: 'Maranhão', id: 2109452, name: 'Raposa' },
  { state: 'Maranhão', id: 2109502, name: 'Riachão' },
  { state: 'Maranhão', id: 2109551, name: 'Ribamar Fiquene' },
  { state: 'Maranhão', id: 2109601, name: 'Rosário' },
  { state: 'Maranhão', id: 2109700, name: 'Sambaíba' },
  { state: 'Maranhão', id: 2109759, name: 'Santa Filomena do Maranhão' },
  { state: 'Maranhão', id: 2109809, name: 'Santa Helena' },
  { state: 'Maranhão', id: 2109908, name: 'Santa Inês' },
  { state: 'Maranhão', id: 2110005, name: 'Santa Luzia' },
  { state: 'Maranhão', id: 2110039, name: 'Santa Luzia do Paruá' },
  { state: 'Maranhão', id: 2110104, name: 'Santa Quitéria do Maranhão' },
  { state: 'Maranhão', id: 2110203, name: 'Santa Rita' },
  { state: 'Maranhão', id: 2110237, name: 'Santana do Maranhão' },
  { state: 'Maranhão', id: 2110278, name: 'Santo Amaro do Maranhão' },
  { state: 'Maranhão', id: 2110302, name: 'Santo Antônio dos Lopes' },
  { state: 'Maranhão', id: 2110401, name: 'São Benedito do Rio Preto' },
  { state: 'Maranhão', id: 2110500, name: 'São Bento' },
  { state: 'Maranhão', id: 2110609, name: 'São Bernardo' },
  { state: 'Maranhão', id: 2110658, name: 'São Domingos do Azeitão' },
  { state: 'Maranhão', id: 2110708, name: 'São Domingos do Maranhão' },
  { state: 'Maranhão', id: 2110807, name: 'São Félix de Balsas' },
  { state: 'Maranhão', id: 2110856, name: 'São Francisco do Brejão' },
  { state: 'Maranhão', id: 2110906, name: 'São Francisco do Maranhão' },
  { state: 'Maranhão', id: 2111003, name: 'São João Batista' },
  { state: 'Maranhão', id: 2111029, name: 'São João do Carú' },
  { state: 'Maranhão', id: 2111052, name: 'São João do Paraíso' },
  { state: 'Maranhão', id: 2111078, name: 'São João do Soter' },
  { state: 'Maranhão', id: 2111102, name: 'São João dos Patos' },
  { state: 'Maranhão', id: 2111201, name: 'São José de Ribamar' },
  { state: 'Maranhão', id: 2111250, name: 'São José dos Basílios' },
  { state: 'Maranhão', id: 2111300, name: 'São Luís' },
  { state: 'Maranhão', id: 2111409, name: 'São Luís Gonzaga do Maranhão' },
  { state: 'Maranhão', id: 2111508, name: 'São Mateus do Maranhão' },
  { state: 'Maranhão', id: 2111532, name: 'São Pedro da Água Branca' },
  { state: 'Maranhão', id: 2111573, name: 'São Pedro dos Crentes' },
  { state: 'Maranhão', id: 2111607, name: 'São Raimundo das Mangabeiras' },
  { state: 'Maranhão', id: 2111631, name: 'São Raimundo do Doca Bezerra' },
  { state: 'Maranhão', id: 2111672, name: 'São Roberto' },
  { state: 'Maranhão', id: 2111706, name: 'São Vicente Ferrer' },
  { state: 'Maranhão', id: 2111722, name: 'Satubinha' },
  { state: 'Maranhão', id: 2111748, name: 'Senador Alexandre Costa' },
  { state: 'Maranhão', id: 2111763, name: 'Senador La Rocque' },
  { state: 'Maranhão', id: 2111789, name: 'Serrano do Maranhão' },
  { state: 'Maranhão', id: 2111805, name: 'Sítio Novo' },
  { state: 'Maranhão', id: 2111904, name: 'Sucupira do Norte' },
  { state: 'Maranhão', id: 2111953, name: 'Sucupira do Riachão' },
  { state: 'Maranhão', id: 2112001, name: 'Tasso Fragoso' },
  { state: 'Maranhão', id: 2112100, name: 'Timbiras' },
  { state: 'Maranhão', id: 2112209, name: 'Timon' },
  { state: 'Maranhão', id: 2112233, name: 'Trizidela do Vale' },
  { state: 'Maranhão', id: 2112274, name: 'Tufilândia' },
  { state: 'Maranhão', id: 2112308, name: 'Tuntum' },
  { state: 'Maranhão', id: 2112407, name: 'Turiaçu' },
  { state: 'Maranhão', id: 2112456, name: 'Turilândia' },
  { state: 'Maranhão', id: 2112506, name: 'Tutóia' },
  { state: 'Maranhão', id: 2112605, name: 'Urbano Santos' },
  { state: 'Maranhão', id: 2112704, name: 'Vargem Grande' },
  { state: 'Maranhão', id: 2112803, name: 'Viana' },
  { state: 'Maranhão', id: 2112852, name: 'Vila Nova dos Martírios' },
  { state: 'Maranhão', id: 2112902, name: 'Vitória do Mearim' },
  { state: 'Maranhão', id: 2113009, name: 'Vitorino Freire' },
  { state: 'Maranhão', id: 2114007, name: 'Zé Doca' },
  { state: 'Piauí', id: 2200053, name: 'Acauã' },
  { state: 'Piauí', id: 2200103, name: 'Agricolândia' },
  { state: 'Piauí', id: 2200202, name: 'Água Branca' },
  { state: 'Piauí', id: 2200251, name: 'Alagoinha do Piauí' },
  { state: 'Piauí', id: 2200277, name: 'Alegrete do Piauí' },
  { state: 'Piauí', id: 2200301, name: 'Alto Longá' },
  { state: 'Piauí', id: 2200400, name: 'Altos' },
  { state: 'Piauí', id: 2200459, name: 'Alvorada do Gurguéia' },
  { state: 'Piauí', id: 2200509, name: 'Amarante' },
  { state: 'Piauí', id: 2200608, name: 'Angical do Piauí' },
  { state: 'Piauí', id: 2200707, name: 'Anísio de Abreu' },
  { state: 'Piauí', id: 2200806, name: 'Antônio Almeida' },
  { state: 'Piauí', id: 2200905, name: 'Aroazes' },
  { state: 'Piauí', id: 2200954, name: 'Aroeiras do Itaim' },
  { state: 'Piauí', id: 2201002, name: 'Arraial' },
  { state: 'Piauí', id: 2201051, name: 'Assunção do Piauí' },
  { state: 'Piauí', id: 2201101, name: 'Avelino Lopes' },
  { state: 'Piauí', id: 2201150, name: 'Baixa Grande do Ribeiro' },
  { state: 'Piauí', id: 2201176, name: "Barra D'Alcântara" },
  { state: 'Piauí', id: 2201200, name: 'Barras' },
  { state: 'Piauí', id: 2201309, name: 'Barreiras do Piauí' },
  { state: 'Piauí', id: 2201408, name: 'Barro Duro' },
  { state: 'Piauí', id: 2201507, name: 'Batalha' },
  { state: 'Piauí', id: 2201556, name: 'Bela Vista do Piauí' },
  { state: 'Piauí', id: 2201572, name: 'Belém do Piauí' },
  { state: 'Piauí', id: 2201606, name: 'Beneditinos' },
  { state: 'Piauí', id: 2201705, name: 'Bertolínia' },
  { state: 'Piauí', id: 2201739, name: 'Betânia do Piauí' },
  { state: 'Piauí', id: 2201770, name: 'Boa Hora' },
  { state: 'Piauí', id: 2201804, name: 'Bocaina' },
  { state: 'Piauí', id: 2201903, name: 'Bom Jesus' },
  { state: 'Piauí', id: 2201919, name: 'Bom Princípio do Piauí' },
  { state: 'Piauí', id: 2201929, name: 'Bonfim do Piauí' },
  { state: 'Piauí', id: 2201945, name: 'Boqueirão do Piauí' },
  { state: 'Piauí', id: 2201960, name: 'Brasileira' },
  { state: 'Piauí', id: 2201988, name: 'Brejo do Piauí' },
  { state: 'Piauí', id: 2202000, name: 'Buriti dos Lopes' },
  { state: 'Piauí', id: 2202026, name: 'Buriti dos Montes' },
  { state: 'Piauí', id: 2202059, name: 'Cabeceiras do Piauí' },
  { state: 'Piauí', id: 2202075, name: 'Cajazeiras do Piauí' },
  { state: 'Piauí', id: 2202083, name: 'Cajueiro da Praia' },
  { state: 'Piauí', id: 2202091, name: 'Caldeirão Grande do Piauí' },
  { state: 'Piauí', id: 2202109, name: 'Campinas do Piauí' },
  { state: 'Piauí', id: 2202117, name: 'Campo Alegre do Fidalgo' },
  { state: 'Piauí', id: 2202133, name: 'Campo Grande do Piauí' },
  { state: 'Piauí', id: 2202174, name: 'Campo Largo do Piauí' },
  { state: 'Piauí', id: 2202208, name: 'Campo Maior' },
  { state: 'Piauí', id: 2202251, name: 'Canavieira' },
  { state: 'Piauí', id: 2202307, name: 'Canto do Buriti' },
  { state: 'Piauí', id: 2202406, name: 'Capitão de Campos' },
  { state: 'Piauí', id: 2202455, name: 'Capitão Gervásio Oliveira' },
  { state: 'Piauí', id: 2202505, name: 'Caracol' },
  { state: 'Piauí', id: 2202539, name: 'Caraúbas do Piauí' },
  { state: 'Piauí', id: 2202554, name: 'Caridade do Piauí' },
  { state: 'Piauí', id: 2202604, name: 'Castelo do Piauí' },
  { state: 'Piauí', id: 2202653, name: 'Caxingó' },
  { state: 'Piauí', id: 2202703, name: 'Cocal' },
  { state: 'Piauí', id: 2202711, name: 'Cocal de Telha' },
  { state: 'Piauí', id: 2202729, name: 'Cocal dos Alves' },
  { state: 'Piauí', id: 2202737, name: 'Coivaras' },
  { state: 'Piauí', id: 2202752, name: 'Colônia do Gurguéia' },
  { state: 'Piauí', id: 2202778, name: 'Colônia do Piauí' },
  { state: 'Piauí', id: 2202802, name: 'Conceição do Canindé' },
  { state: 'Piauí', id: 2202851, name: 'Coronel José Dias' },
  { state: 'Piauí', id: 2202901, name: 'Corrente' },
  { state: 'Piauí', id: 2203008, name: 'Cristalândia do Piauí' },
  { state: 'Piauí', id: 2203107, name: 'Cristino Castro' },
  { state: 'Piauí', id: 2203206, name: 'Curimatá' },
  { state: 'Piauí', id: 2203230, name: 'Currais' },
  { state: 'Piauí', id: 2203271, name: 'Curral Novo do Piauí' },
  { state: 'Piauí', id: 2203255, name: 'Curralinhos' },
  { state: 'Piauí', id: 2203305, name: 'Demerval Lobão' },
  { state: 'Piauí', id: 2203354, name: 'Dirceu Arcoverde' },
  { state: 'Piauí', id: 2203404, name: 'Dom Expedito Lopes' },
  { state: 'Piauí', id: 2203453, name: 'Dom Inocêncio' },
  { state: 'Piauí', id: 2203420, name: 'Domingos Mourão' },
  { state: 'Piauí', id: 2203503, name: 'Elesbão Veloso' },
  { state: 'Piauí', id: 2203602, name: 'Eliseu Martins' },
  { state: 'Piauí', id: 2203701, name: 'Esperantina' },
  { state: 'Piauí', id: 2203750, name: 'Fartura do Piauí' },
  { state: 'Piauí', id: 2203800, name: 'Flores do Piauí' },
  { state: 'Piauí', id: 2203859, name: 'Floresta do Piauí' },
  { state: 'Piauí', id: 2203909, name: 'Floriano' },
  { state: 'Piauí', id: 2204006, name: 'Francinópolis' },
  { state: 'Piauí', id: 2204105, name: 'Francisco Ayres' },
  { state: 'Piauí', id: 2204154, name: 'Francisco Macedo' },
  { state: 'Piauí', id: 2204204, name: 'Francisco Santos' },
  { state: 'Piauí', id: 2204303, name: 'Fronteiras' },
  { state: 'Piauí', id: 2204352, name: 'Geminiano' },
  { state: 'Piauí', id: 2204402, name: 'Gilbués' },
  { state: 'Piauí', id: 2204501, name: 'Guadalupe' },
  { state: 'Piauí', id: 2204550, name: 'Guaribas' },
  { state: 'Piauí', id: 2204600, name: 'Hugo Napoleão' },
  { state: 'Piauí', id: 2204659, name: 'Ilha Grande' },
  { state: 'Piauí', id: 2204709, name: 'Inhuma' },
  { state: 'Piauí', id: 2204808, name: 'Ipiranga do Piauí' },
  { state: 'Piauí', id: 2204907, name: 'Isaías Coelho' },
  { state: 'Piauí', id: 2205003, name: 'Itainópolis' },
  { state: 'Piauí', id: 2205102, name: 'Itaueira' },
  { state: 'Piauí', id: 2205151, name: 'Jacobina do Piauí' },
  { state: 'Piauí', id: 2205201, name: 'Jaicós' },
  { state: 'Piauí', id: 2205250, name: 'Jardim do Mulato' },
  { state: 'Piauí', id: 2205276, name: 'Jatobá do Piauí' },
  { state: 'Piauí', id: 2205300, name: 'Jerumenha' },
  { state: 'Piauí', id: 2205359, name: 'João Costa' },
  { state: 'Piauí', id: 2205409, name: 'Joaquim Pires' },
  { state: 'Piauí', id: 2205458, name: 'Joca Marques' },
  { state: 'Piauí', id: 2205508, name: 'José de Freitas' },
  { state: 'Piauí', id: 2205516, name: 'Juazeiro do Piauí' },
  { state: 'Piauí', id: 2205524, name: 'Júlio Borges' },
  { state: 'Piauí', id: 2205532, name: 'Jurema' },
  { state: 'Piauí', id: 2205557, name: 'Lagoa Alegre' },
  { state: 'Piauí', id: 2205573, name: 'Lagoa de São Francisco' },
  { state: 'Piauí', id: 2205565, name: 'Lagoa do Barro do Piauí' },
  { state: 'Piauí', id: 2205581, name: 'Lagoa do Piauí' },
  { state: 'Piauí', id: 2205599, name: 'Lagoa do Sítio' },
  { state: 'Piauí', id: 2205540, name: 'Lagoinha do Piauí' },
  { state: 'Piauí', id: 2205607, name: 'Landri Sales' },
  { state: 'Piauí', id: 2205706, name: 'Luís Correia' },
  { state: 'Piauí', id: 2205805, name: 'Luzilândia' },
  { state: 'Piauí', id: 2205854, name: 'Madeiro' },
  { state: 'Piauí', id: 2205904, name: 'Manoel Emídio' },
  { state: 'Piauí', id: 2205953, name: 'Marcolândia' },
  { state: 'Piauí', id: 2206001, name: 'Marcos Parente' },
  { state: 'Piauí', id: 2206050, name: 'Massapê do Piauí' },
  { state: 'Piauí', id: 2206100, name: 'Matias Olímpio' },
  { state: 'Piauí', id: 2206209, name: 'Miguel Alves' },
  { state: 'Piauí', id: 2206308, name: 'Miguel Leão' },
  { state: 'Piauí', id: 2206357, name: 'Milton Brandão' },
  { state: 'Piauí', id: 2206407, name: 'Monsenhor Gil' },
  { state: 'Piauí', id: 2206506, name: 'Monsenhor Hipólito' },
  { state: 'Piauí', id: 2206605, name: 'Monte Alegre do Piauí' },
  { state: 'Piauí', id: 2206654, name: 'Morro Cabeça no Tempo' },
  { state: 'Piauí', id: 2206670, name: 'Morro do Chapéu do Piauí' },
  { state: 'Piauí', id: 2206696, name: 'Murici dos Portelas' },
  { state: 'Piauí', id: 2206704, name: 'Nazaré do Piauí' },
  { state: 'Piauí', id: 2206720, name: 'Nazária' },
  { state: 'Piauí', id: 2206753, name: 'Nossa Senhora de Nazaré' },
  { state: 'Piauí', id: 2206803, name: 'Nossa Senhora dos Remédios' },
  { state: 'Piauí', id: 2207959, name: 'Nova Santa Rita' },
  { state: 'Piauí', id: 2206902, name: 'Novo Oriente do Piauí' },
  { state: 'Piauí', id: 2206951, name: 'Novo Santo Antônio' },
  { state: 'Piauí', id: 2207009, name: 'Oeiras' },
  { state: 'Piauí', id: 2207108, name: "Olho D'Água do Piauí" },
  { state: 'Piauí', id: 2207207, name: 'Padre Marcos' },
  { state: 'Piauí', id: 2207306, name: 'Paes Landim' },
  { state: 'Piauí', id: 2207355, name: 'Pajeú do Piauí' },
  { state: 'Piauí', id: 2207405, name: 'Palmeira do Piauí' },
  { state: 'Piauí', id: 2207504, name: 'Palmeirais' },
  { state: 'Piauí', id: 2207553, name: 'Paquetá' },
  { state: 'Piauí', id: 2207603, name: 'Parnaguá' },
  { state: 'Piauí', id: 2207702, name: 'Parnaíba' },
  { state: 'Piauí', id: 2207751, name: 'Passagem Franca do Piauí' },
  { state: 'Piauí', id: 2207777, name: 'Patos do Piauí' },
  { state: 'Piauí', id: 2207793, name: "Pau D'Arco do Piauí" },
  { state: 'Piauí', id: 2207801, name: 'Paulistana' },
  { state: 'Piauí', id: 2207850, name: 'Pavussu' },
  { state: 'Piauí', id: 2207900, name: 'Pedro II' },
  { state: 'Piauí', id: 2207934, name: 'Pedro Laurentino' },
  { state: 'Piauí', id: 2208007, name: 'Picos' },
  { state: 'Piauí', id: 2208106, name: 'Pimenteiras' },
  { state: 'Piauí', id: 2208205, name: 'Pio IX' },
  { state: 'Piauí', id: 2208304, name: 'Piracuruca' },
  { state: 'Piauí', id: 2208403, name: 'Piripiri' },
  { state: 'Piauí', id: 2208502, name: 'Porto' },
  { state: 'Piauí', id: 2208551, name: 'Porto Alegre do Piauí' },
  { state: 'Piauí', id: 2208601, name: 'Prata do Piauí' },
  { state: 'Piauí', id: 2208650, name: 'Queimada Nova' },
  { state: 'Piauí', id: 2208700, name: 'Redenção do Gurguéia' },
  { state: 'Piauí', id: 2208809, name: 'Regeneração' },
  { state: 'Piauí', id: 2208858, name: 'Riacho Frio' },
  { state: 'Piauí', id: 2208874, name: 'Ribeira do Piauí' },
  { state: 'Piauí', id: 2208908, name: 'Ribeiro Gonçalves' },
  { state: 'Piauí', id: 2209005, name: 'Rio Grande do Piauí' },
  { state: 'Piauí', id: 2209104, name: 'Santa Cruz do Piauí' },
  { state: 'Piauí', id: 2209153, name: 'Santa Cruz dos Milagres' },
  { state: 'Piauí', id: 2209203, name: 'Santa Filomena' },
  { state: 'Piauí', id: 2209302, name: 'Santa Luz' },
  { state: 'Piauí', id: 2209377, name: 'Santa Rosa do Piauí' },
  { state: 'Piauí', id: 2209351, name: 'Santana do Piauí' },
  { state: 'Piauí', id: 2209401, name: 'Santo Antônio de Lisboa' },
  { state: 'Piauí', id: 2209450, name: 'Santo Antônio dos Milagres' },
  { state: 'Piauí', id: 2209500, name: 'Santo Inácio do Piauí' },
  { state: 'Piauí', id: 2209559, name: 'São Braz do Piauí' },
  { state: 'Piauí', id: 2209609, name: 'São Félix do Piauí' },
  { state: 'Piauí', id: 2209658, name: 'São Francisco de Assis do Piauí' },
  { state: 'Piauí', id: 2209708, name: 'São Francisco do Piauí' },
  { state: 'Piauí', id: 2209757, name: 'São Gonçalo do Gurguéia' },
  { state: 'Piauí', id: 2209807, name: 'São Gonçalo do Piauí' },
  { state: 'Piauí', id: 2209856, name: 'São João da Canabrava' },
  { state: 'Piauí', id: 2209872, name: 'São João da Fronteira' },
  { state: 'Piauí', id: 2209906, name: 'São João da Serra' },
  { state: 'Piauí', id: 2209955, name: 'São João da Varjota' },
  { state: 'Piauí', id: 2209971, name: 'São João do Arraial' },
  { state: 'Piauí', id: 2210003, name: 'São João do Piauí' },
  { state: 'Piauí', id: 2210052, name: 'São José do Divino' },
  { state: 'Piauí', id: 2210102, name: 'São José do Peixe' },
  { state: 'Piauí', id: 2210201, name: 'São José do Piauí' },
  { state: 'Piauí', id: 2210300, name: 'São Julião' },
  { state: 'Piauí', id: 2210359, name: 'São Lourenço do Piauí' },
  { state: 'Piauí', id: 2210375, name: 'São Luis do Piauí' },
  { state: 'Piauí', id: 2210383, name: 'São Miguel da Baixa Grande' },
  { state: 'Piauí', id: 2210391, name: 'São Miguel do Fidalgo' },
  { state: 'Piauí', id: 2210409, name: 'São Miguel do Tapuio' },
  { state: 'Piauí', id: 2210508, name: 'São Pedro do Piauí' },
  { state: 'Piauí', id: 2210607, name: 'São Raimundo Nonato' },
  { state: 'Piauí', id: 2210623, name: 'Sebastião Barros' },
  { state: 'Piauí', id: 2210631, name: 'Sebastião Leal' },
  { state: 'Piauí', id: 2210656, name: 'Sigefredo Pacheco' },
  { state: 'Piauí', id: 2210706, name: 'Simões' },
  { state: 'Piauí', id: 2210805, name: 'Simplício Mendes' },
  { state: 'Piauí', id: 2210904, name: 'Socorro do Piauí' },
  { state: 'Piauí', id: 2210938, name: 'Sussuapara' },
  { state: 'Piauí', id: 2210953, name: 'Tamboril do Piauí' },
  { state: 'Piauí', id: 2210979, name: 'Tanque do Piauí' },
  { state: 'Piauí', id: 2211001, name: 'Teresina' },
  { state: 'Piauí', id: 2211100, name: 'União' },
  { state: 'Piauí', id: 2211209, name: 'Uruçuí' },
  { state: 'Piauí', id: 2211308, name: 'Valença do Piauí' },
  { state: 'Piauí', id: 2211357, name: 'Várzea Branca' },
  { state: 'Piauí', id: 2211407, name: 'Várzea Grande' },
  { state: 'Piauí', id: 2211506, name: 'Vera Mendes' },
  { state: 'Piauí', id: 2211605, name: 'Vila Nova do Piauí' },
  { state: 'Piauí', id: 2211704, name: 'Wall Ferraz' },
  { state: 'Ceará', id: 2300101, name: 'Abaiara' },
  { state: 'Ceará', id: 2300150, name: 'Acarape' },
  { state: 'Ceará', id: 2300200, name: 'Acaraú' },
  { state: 'Ceará', id: 2300309, name: 'Acopiara' },
  { state: 'Ceará', id: 2300408, name: 'Aiuaba' },
  { state: 'Ceará', id: 2300507, name: 'Alcântaras' },
  { state: 'Ceará', id: 2300606, name: 'Altaneira' },
  { state: 'Ceará', id: 2300705, name: 'Alto Santo' },
  { state: 'Ceará', id: 2300754, name: 'Amontada' },
  { state: 'Ceará', id: 2300804, name: 'Antonina do Norte' },
  { state: 'Ceará', id: 2300903, name: 'Apuiarés' },
  { state: 'Ceará', id: 2301000, name: 'Aquiraz' },
  { state: 'Ceará', id: 2301109, name: 'Aracati' },
  { state: 'Ceará', id: 2301208, name: 'Aracoiaba' },
  { state: 'Ceará', id: 2301257, name: 'Ararendá' },
  { state: 'Ceará', id: 2301307, name: 'Araripe' },
  { state: 'Ceará', id: 2301406, name: 'Aratuba' },
  { state: 'Ceará', id: 2301505, name: 'Arneiroz' },
  { state: 'Ceará', id: 2301604, name: 'Assaré' },
  { state: 'Ceará', id: 2301703, name: 'Aurora' },
  { state: 'Ceará', id: 2301802, name: 'Baixio' },
  { state: 'Ceará', id: 2301851, name: 'Banabuiú' },
  { state: 'Ceará', id: 2301901, name: 'Barbalha' },
  { state: 'Ceará', id: 2301950, name: 'Barreira' },
  { state: 'Ceará', id: 2302008, name: 'Barro' },
  { state: 'Ceará', id: 2302057, name: 'Barroquinha' },
  { state: 'Ceará', id: 2302107, name: 'Baturité' },
  { state: 'Ceará', id: 2302206, name: 'Beberibe' },
  { state: 'Ceará', id: 2302305, name: 'Bela Cruz' },
  { state: 'Ceará', id: 2302404, name: 'Boa Viagem' },
  { state: 'Ceará', id: 2302503, name: 'Brejo Santo' },
  { state: 'Ceará', id: 2302602, name: 'Camocim' },
  { state: 'Ceará', id: 2302701, name: 'Campos Sales' },
  { state: 'Ceará', id: 2302800, name: 'Canindé' },
  { state: 'Ceará', id: 2302909, name: 'Capistrano' },
  { state: 'Ceará', id: 2303006, name: 'Caridade' },
  { state: 'Ceará', id: 2303105, name: 'Cariré' },
  { state: 'Ceará', id: 2303204, name: 'Caririaçu' },
  { state: 'Ceará', id: 2303303, name: 'Cariús' },
  { state: 'Ceará', id: 2303402, name: 'Carnaubal' },
  { state: 'Ceará', id: 2303501, name: 'Cascavel' },
  { state: 'Ceará', id: 2303600, name: 'Catarina' },
  { state: 'Ceará', id: 2303659, name: 'Catunda' },
  { state: 'Ceará', id: 2303709, name: 'Caucaia' },
  { state: 'Ceará', id: 2303808, name: 'Cedro' },
  { state: 'Ceará', id: 2303907, name: 'Chaval' },
  { state: 'Ceará', id: 2303931, name: 'Choró' },
  { state: 'Ceará', id: 2303956, name: 'Chorozinho' },
  { state: 'Ceará', id: 2304004, name: 'Coreaú' },
  { state: 'Ceará', id: 2304103, name: 'Crateús' },
  { state: 'Ceará', id: 2304202, name: 'Crato' },
  { state: 'Ceará', id: 2304236, name: 'Croatá' },
  { state: 'Ceará', id: 2304251, name: 'Cruz' },
  { state: 'Ceará', id: 2304269, name: 'Deputado Irapuan Pinheiro' },
  { state: 'Ceará', id: 2304277, name: 'Ererê' },
  { state: 'Ceará', id: 2304285, name: 'Eusébio' },
  { state: 'Ceará', id: 2304301, name: 'Farias Brito' },
  { state: 'Ceará', id: 2304350, name: 'Forquilha' },
  { state: 'Ceará', id: 2304400, name: 'Fortaleza' },
  { state: 'Ceará', id: 2304459, name: 'Fortim' },
  { state: 'Ceará', id: 2304509, name: 'Frecheirinha' },
  { state: 'Ceará', id: 2304608, name: 'General Sampaio' },
  { state: 'Ceará', id: 2304657, name: 'Graça' },
  { state: 'Ceará', id: 2304707, name: 'Granja' },
  { state: 'Ceará', id: 2304806, name: 'Granjeiro' },
  { state: 'Ceará', id: 2304905, name: 'Groaíras' },
  { state: 'Ceará', id: 2304954, name: 'Guaiúba' },
  { state: 'Ceará', id: 2305001, name: 'Guaraciaba do Norte' },
  { state: 'Ceará', id: 2305100, name: 'Guaramiranga' },
  { state: 'Ceará', id: 2305209, name: 'Hidrolândia' },
  { state: 'Ceará', id: 2305233, name: 'Horizonte' },
  { state: 'Ceará', id: 2305266, name: 'Ibaretama' },
  { state: 'Ceará', id: 2305308, name: 'Ibiapina' },
  { state: 'Ceará', id: 2305332, name: 'Ibicuitinga' },
  { state: 'Ceará', id: 2305357, name: 'Icapuí' },
  { state: 'Ceará', id: 2305407, name: 'Icó' },
  { state: 'Ceará', id: 2305506, name: 'Iguatu' },
  { state: 'Ceará', id: 2305605, name: 'Independência' },
  { state: 'Ceará', id: 2305654, name: 'Ipaporanga' },
  { state: 'Ceará', id: 2305704, name: 'Ipaumirim' },
  { state: 'Ceará', id: 2305803, name: 'Ipu' },
  { state: 'Ceará', id: 2305902, name: 'Ipueiras' },
  { state: 'Ceará', id: 2306009, name: 'Iracema' },
  { state: 'Ceará', id: 2306108, name: 'Irauçuba' },
  { state: 'Ceará', id: 2306207, name: 'Itaiçaba' },
  { state: 'Ceará', id: 2306256, name: 'Itaitinga' },
  { state: 'Ceará', id: 2306306, name: 'Itapajé' },
  { state: 'Ceará', id: 2306405, name: 'Itapipoca' },
  { state: 'Ceará', id: 2306504, name: 'Itapiúna' },
  { state: 'Ceará', id: 2306553, name: 'Itarema' },
  { state: 'Ceará', id: 2306603, name: 'Itatira' },
  { state: 'Ceará', id: 2306702, name: 'Jaguaretama' },
  { state: 'Ceará', id: 2306801, name: 'Jaguaribara' },
  { state: 'Ceará', id: 2306900, name: 'Jaguaribe' },
  { state: 'Ceará', id: 2307007, name: 'Jaguaruana' },
  { state: 'Ceará', id: 2307106, name: 'Jardim' },
  { state: 'Ceará', id: 2307205, name: 'Jati' },
  { state: 'Ceará', id: 2307254, name: 'Jijoca de Jericoacoara' },
  { state: 'Ceará', id: 2307304, name: 'Juazeiro do Norte' },
  { state: 'Ceará', id: 2307403, name: 'Jucás' },
  { state: 'Ceará', id: 2307502, name: 'Lavras da Mangabeira' },
  { state: 'Ceará', id: 2307601, name: 'Limoeiro do Norte' },
  { state: 'Ceará', id: 2307635, name: 'Madalena' },
  { state: 'Ceará', id: 2307650, name: 'Maracanaú' },
  { state: 'Ceará', id: 2307700, name: 'Maranguape' },
  { state: 'Ceará', id: 2307809, name: 'Marco' },
  { state: 'Ceará', id: 2307908, name: 'Martinópole' },
  { state: 'Ceará', id: 2308005, name: 'Massapê' },
  { state: 'Ceará', id: 2308104, name: 'Mauriti' },
  { state: 'Ceará', id: 2308203, name: 'Meruoca' },
  { state: 'Ceará', id: 2308302, name: 'Milagres' },
  { state: 'Ceará', id: 2308351, name: 'Milhã' },
  { state: 'Ceará', id: 2308377, name: 'Miraíma' },
  { state: 'Ceará', id: 2308401, name: 'Missão Velha' },
  { state: 'Ceará', id: 2308500, name: 'Mombaça' },
  { state: 'Ceará', id: 2308609, name: 'Monsenhor Tabosa' },
  { state: 'Ceará', id: 2308708, name: 'Morada Nova' },
  { state: 'Ceará', id: 2308807, name: 'Moraújo' },
  { state: 'Ceará', id: 2308906, name: 'Morrinhos' },
  { state: 'Ceará', id: 2309003, name: 'Mucambo' },
  { state: 'Ceará', id: 2309102, name: 'Mulungu' },
  { state: 'Ceará', id: 2309201, name: 'Nova Olinda' },
  { state: 'Ceará', id: 2309300, name: 'Nova Russas' },
  { state: 'Ceará', id: 2309409, name: 'Novo Oriente' },
  { state: 'Ceará', id: 2309458, name: 'Ocara' },
  { state: 'Ceará', id: 2309508, name: 'Orós' },
  { state: 'Ceará', id: 2309607, name: 'Pacajus' },
  { state: 'Ceará', id: 2309706, name: 'Pacatuba' },
  { state: 'Ceará', id: 2309805, name: 'Pacoti' },
  { state: 'Ceará', id: 2309904, name: 'Pacujá' },
  { state: 'Ceará', id: 2310001, name: 'Palhano' },
  { state: 'Ceará', id: 2310100, name: 'Palmácia' },
  { state: 'Ceará', id: 2310209, name: 'Paracuru' },
  { state: 'Ceará', id: 2310258, name: 'Paraipaba' },
  { state: 'Ceará', id: 2310308, name: 'Parambu' },
  { state: 'Ceará', id: 2310407, name: 'Paramoti' },
  { state: 'Ceará', id: 2310506, name: 'Pedra Branca' },
  { state: 'Ceará', id: 2310605, name: 'Penaforte' },
  { state: 'Ceará', id: 2310704, name: 'Pentecoste' },
  { state: 'Ceará', id: 2310803, name: 'Pereiro' },
  { state: 'Ceará', id: 2310852, name: 'Pindoretama' },
  { state: 'Ceará', id: 2310902, name: 'Piquet Carneiro' },
  { state: 'Ceará', id: 2310951, name: 'Pires Ferreira' },
  { state: 'Ceará', id: 2311009, name: 'Poranga' },
  { state: 'Ceará', id: 2311108, name: 'Porteiras' },
  { state: 'Ceará', id: 2311207, name: 'Potengi' },
  { state: 'Ceará', id: 2311231, name: 'Potiretama' },
  { state: 'Ceará', id: 2311264, name: 'Quiterianópolis' },
  { state: 'Ceará', id: 2311306, name: 'Quixadá' },
  { state: 'Ceará', id: 2311355, name: 'Quixelô' },
  { state: 'Ceará', id: 2311405, name: 'Quixeramobim' },
  { state: 'Ceará', id: 2311504, name: 'Quixeré' },
  { state: 'Ceará', id: 2311603, name: 'Redenção' },
  { state: 'Ceará', id: 2311702, name: 'Reriutaba' },
  { state: 'Ceará', id: 2311801, name: 'Russas' },
  { state: 'Ceará', id: 2311900, name: 'Saboeiro' },
  { state: 'Ceará', id: 2311959, name: 'Salitre' },
  { state: 'Ceará', id: 2312205, name: 'Santa Quitéria' },
  { state: 'Ceará', id: 2312007, name: 'Santana do Acaraú' },
  { state: 'Ceará', id: 2312106, name: 'Santana do Cariri' },
  { state: 'Ceará', id: 2312304, name: 'São Benedito' },
  { state: 'Ceará', id: 2312403, name: 'São Gonçalo do Amarante' },
  { state: 'Ceará', id: 2312502, name: 'São João do Jaguaribe' },
  { state: 'Ceará', id: 2312601, name: 'São Luís do Curu' },
  { state: 'Ceará', id: 2312700, name: 'Senador Pompeu' },
  { state: 'Ceará', id: 2312809, name: 'Senador Sá' },
  { state: 'Ceará', id: 2312908, name: 'Sobral' },
  { state: 'Ceará', id: 2313005, name: 'Solonópole' },
  { state: 'Ceará', id: 2313104, name: 'Tabuleiro do Norte' },
  { state: 'Ceará', id: 2313203, name: 'Tamboril' },
  { state: 'Ceará', id: 2313252, name: 'Tarrafas' },
  { state: 'Ceará', id: 2313302, name: 'Tauá' },
  { state: 'Ceará', id: 2313351, name: 'Tejuçuoca' },
  { state: 'Ceará', id: 2313401, name: 'Tianguá' },
  { state: 'Ceará', id: 2313500, name: 'Trairi' },
  { state: 'Ceará', id: 2313559, name: 'Tururu' },
  { state: 'Ceará', id: 2313609, name: 'Ubajara' },
  { state: 'Ceará', id: 2313708, name: 'Umari' },
  { state: 'Ceará', id: 2313757, name: 'Umirim' },
  { state: 'Ceará', id: 2313807, name: 'Uruburetama' },
  { state: 'Ceará', id: 2313906, name: 'Uruoca' },
  { state: 'Ceará', id: 2313955, name: 'Varjota' },
  { state: 'Ceará', id: 2314003, name: 'Várzea Alegre' },
  { state: 'Ceará', id: 2314102, name: 'Viçosa do Ceará' },
  { state: 'Rio Grande do Norte', id: 2400109, name: 'Acari' },
  { state: 'Rio Grande do Norte', id: 2400208, name: 'Açu' },
  { state: 'Rio Grande do Norte', id: 2400307, name: 'Afonso Bezerra' },
  { state: 'Rio Grande do Norte', id: 2400406, name: 'Água Nova' },
  { state: 'Rio Grande do Norte', id: 2400505, name: 'Alexandria' },
  { state: 'Rio Grande do Norte', id: 2400604, name: 'Almino Afonso' },
  { state: 'Rio Grande do Norte', id: 2400703, name: 'Alto do Rodrigues' },
  { state: 'Rio Grande do Norte', id: 2400802, name: 'Angicos' },
  { state: 'Rio Grande do Norte', id: 2400901, name: 'Antônio Martins' },
  { state: 'Rio Grande do Norte', id: 2401008, name: 'Apodi' },
  { state: 'Rio Grande do Norte', id: 2401107, name: 'Areia Branca' },
  { state: 'Rio Grande do Norte', id: 2401206, name: 'Arês' },
  { state: 'Rio Grande do Norte', id: 2401305, name: 'Augusto Severo' },
  { state: 'Rio Grande do Norte', id: 2401404, name: 'Baía Formosa' },
  { state: 'Rio Grande do Norte', id: 2401453, name: 'Baraúna' },
  { state: 'Rio Grande do Norte', id: 2401503, name: 'Barcelona' },
  { state: 'Rio Grande do Norte', id: 2401602, name: 'Bento Fernandes' },
  { state: 'Rio Grande do Norte', id: 2401651, name: 'Bodó' },
  { state: 'Rio Grande do Norte', id: 2401701, name: 'Bom Jesus' },
  { state: 'Rio Grande do Norte', id: 2401800, name: 'Brejinho' },
  { state: 'Rio Grande do Norte', id: 2401859, name: 'Caiçara do Norte' },
  {
    state: 'Rio Grande do Norte',
    id: 2401909,
    name: 'Caiçara do Rio do Vento',
  },
  { state: 'Rio Grande do Norte', id: 2402006, name: 'Caicó' },
  { state: 'Rio Grande do Norte', id: 2402105, name: 'Campo Redondo' },
  { state: 'Rio Grande do Norte', id: 2402204, name: 'Canguaretama' },
  { state: 'Rio Grande do Norte', id: 2402303, name: 'Caraúbas' },
  { state: 'Rio Grande do Norte', id: 2402402, name: 'Carnaúba dos Dantas' },
  { state: 'Rio Grande do Norte', id: 2402501, name: 'Carnaubais' },
  { state: 'Rio Grande do Norte', id: 2402600, name: 'Ceará-Mirim' },
  { state: 'Rio Grande do Norte', id: 2402709, name: 'Cerro Corá' },
  { state: 'Rio Grande do Norte', id: 2402808, name: 'Coronel Ezequiel' },
  { state: 'Rio Grande do Norte', id: 2402907, name: 'Coronel João Pessoa' },
  { state: 'Rio Grande do Norte', id: 2403004, name: 'Cruzeta' },
  { state: 'Rio Grande do Norte', id: 2403103, name: 'Currais Novos' },
  { state: 'Rio Grande do Norte', id: 2403202, name: 'Doutor Severiano' },
  { state: 'Rio Grande do Norte', id: 2403301, name: 'Encanto' },
  { state: 'Rio Grande do Norte', id: 2403400, name: 'Equador' },
  { state: 'Rio Grande do Norte', id: 2403509, name: 'Espírito Santo' },
  { state: 'Rio Grande do Norte', id: 2403608, name: 'Extremoz' },
  { state: 'Rio Grande do Norte', id: 2403707, name: 'Felipe Guerra' },
  { state: 'Rio Grande do Norte', id: 2403756, name: 'Fernando Pedroza' },
  { state: 'Rio Grande do Norte', id: 2403806, name: 'Florânia' },
  { state: 'Rio Grande do Norte', id: 2403905, name: 'Francisco Dantas' },
  { state: 'Rio Grande do Norte', id: 2404002, name: 'Frutuoso Gomes' },
  { state: 'Rio Grande do Norte', id: 2404101, name: 'Galinhos' },
  { state: 'Rio Grande do Norte', id: 2404200, name: 'Goianinha' },
  {
    state: 'Rio Grande do Norte',
    id: 2404309,
    name: 'Governador Dix-Sept Rosado',
  },
  { state: 'Rio Grande do Norte', id: 2404408, name: 'Grossos' },
  { state: 'Rio Grande do Norte', id: 2404507, name: 'Guamaré' },
  { state: 'Rio Grande do Norte', id: 2404606, name: 'Ielmo Marinho' },
  { state: 'Rio Grande do Norte', id: 2404705, name: 'Ipanguaçu' },
  { state: 'Rio Grande do Norte', id: 2404804, name: 'Ipueira' },
  { state: 'Rio Grande do Norte', id: 2404853, name: 'Itajá' },
  { state: 'Rio Grande do Norte', id: 2404903, name: 'Itaú' },
  { state: 'Rio Grande do Norte', id: 2405009, name: 'Jaçanã' },
  { state: 'Rio Grande do Norte', id: 2405108, name: 'Jandaíra' },
  { state: 'Rio Grande do Norte', id: 2405207, name: 'Janduís' },
  { state: 'Rio Grande do Norte', id: 2405306, name: 'Januário Cicco' },
  { state: 'Rio Grande do Norte', id: 2405405, name: 'Japi' },
  { state: 'Rio Grande do Norte', id: 2405504, name: 'Jardim de Angicos' },
  { state: 'Rio Grande do Norte', id: 2405603, name: 'Jardim de Piranhas' },
  { state: 'Rio Grande do Norte', id: 2405702, name: 'Jardim do Seridó' },
  { state: 'Rio Grande do Norte', id: 2405801, name: 'João Câmara' },
  { state: 'Rio Grande do Norte', id: 2405900, name: 'João Dias' },
  { state: 'Rio Grande do Norte', id: 2406007, name: 'José da Penha' },
  { state: 'Rio Grande do Norte', id: 2406106, name: 'Jucurutu' },
  { state: 'Rio Grande do Norte', id: 2406155, name: 'Jundiá' },
  { state: 'Rio Grande do Norte', id: 2406205, name: "Lagoa d'Anta" },
  { state: 'Rio Grande do Norte', id: 2406304, name: 'Lagoa de Pedras' },
  { state: 'Rio Grande do Norte', id: 2406403, name: 'Lagoa de Velhos' },
  { state: 'Rio Grande do Norte', id: 2406502, name: 'Lagoa Nova' },
  { state: 'Rio Grande do Norte', id: 2406601, name: 'Lagoa Salgada' },
  { state: 'Rio Grande do Norte', id: 2406700, name: 'Lajes' },
  { state: 'Rio Grande do Norte', id: 2406809, name: 'Lajes Pintadas' },
  { state: 'Rio Grande do Norte', id: 2406908, name: 'Lucrécia' },
  { state: 'Rio Grande do Norte', id: 2407005, name: 'Luís Gomes' },
  { state: 'Rio Grande do Norte', id: 2407104, name: 'Macaíba' },
  { state: 'Rio Grande do Norte', id: 2407203, name: 'Macau' },
  { state: 'Rio Grande do Norte', id: 2407252, name: 'Major Sales' },
  { state: 'Rio Grande do Norte', id: 2407302, name: 'Marcelino Vieira' },
  { state: 'Rio Grande do Norte', id: 2407401, name: 'Martins' },
  { state: 'Rio Grande do Norte', id: 2407500, name: 'Maxaranguape' },
  { state: 'Rio Grande do Norte', id: 2407609, name: 'Messias Targino' },
  { state: 'Rio Grande do Norte', id: 2407708, name: 'Montanhas' },
  { state: 'Rio Grande do Norte', id: 2407807, name: 'Monte Alegre' },
  {
    state: 'Rio Grande do Norte',
    id: 2407906,
    name: 'Monte das Gameleiras',
  },
  { state: 'Rio Grande do Norte', id: 2408003, name: 'Mossoró' },
  { state: 'Rio Grande do Norte', id: 2408102, name: 'Natal' },
  { state: 'Rio Grande do Norte', id: 2408201, name: 'Nísia Floresta' },
  { state: 'Rio Grande do Norte', id: 2408300, name: 'Nova Cruz' },
  {
    state: 'Rio Grande do Norte',
    id: 2408409,
    name: "Olho d'Água do Borges",
  },
  { state: 'Rio Grande do Norte', id: 2408508, name: 'Ouro Branco' },
  { state: 'Rio Grande do Norte', id: 2408607, name: 'Paraná' },
  { state: 'Rio Grande do Norte', id: 2408706, name: 'Paraú' },
  { state: 'Rio Grande do Norte', id: 2408805, name: 'Parazinho' },
  { state: 'Rio Grande do Norte', id: 2408904, name: 'Parelhas' },
  { state: 'Rio Grande do Norte', id: 2403251, name: 'Parnamirim' },
  { state: 'Rio Grande do Norte', id: 2409100, name: 'Passa e Fica' },
  { state: 'Rio Grande do Norte', id: 2409209, name: 'Passagem' },
  { state: 'Rio Grande do Norte', id: 2409308, name: 'Patu' },
  { state: 'Rio Grande do Norte', id: 2409407, name: 'Pau dos Ferros' },
  { state: 'Rio Grande do Norte', id: 2409506, name: 'Pedra Grande' },
  { state: 'Rio Grande do Norte', id: 2409605, name: 'Pedra Preta' },
  { state: 'Rio Grande do Norte', id: 2409704, name: 'Pedro Avelino' },
  { state: 'Rio Grande do Norte', id: 2409803, name: 'Pedro Velho' },
  { state: 'Rio Grande do Norte', id: 2409902, name: 'Pendências' },
  { state: 'Rio Grande do Norte', id: 2410009, name: 'Pilões' },
  { state: 'Rio Grande do Norte', id: 2410108, name: 'Poço Branco' },
  { state: 'Rio Grande do Norte', id: 2410207, name: 'Portalegre' },
  { state: 'Rio Grande do Norte', id: 2410256, name: 'Porto do Mangue' },
  { state: 'Rio Grande do Norte', id: 2410405, name: 'Pureza' },
  { state: 'Rio Grande do Norte', id: 2410504, name: 'Rafael Fernandes' },
  { state: 'Rio Grande do Norte', id: 2410603, name: 'Rafael Godeiro' },
  { state: 'Rio Grande do Norte', id: 2410702, name: 'Riacho da Cruz' },
  { state: 'Rio Grande do Norte', id: 2410801, name: 'Riacho de Santana' },
  { state: 'Rio Grande do Norte', id: 2410900, name: 'Riachuelo' },
  { state: 'Rio Grande do Norte', id: 2408953, name: 'Rio do Fogo' },
  { state: 'Rio Grande do Norte', id: 2411007, name: 'Rodolfo Fernandes' },
  { state: 'Rio Grande do Norte', id: 2411106, name: 'Ruy Barbosa' },
  { state: 'Rio Grande do Norte', id: 2411205, name: 'Santa Cruz' },
  { state: 'Rio Grande do Norte', id: 2409332, name: 'Santa Maria' },
  { state: 'Rio Grande do Norte', id: 2411403, name: 'Santana do Matos' },
  { state: 'Rio Grande do Norte', id: 2411429, name: 'Santana do Seridó' },
  { state: 'Rio Grande do Norte', id: 2411502, name: 'Santo Antônio' },
  { state: 'Rio Grande do Norte', id: 2411601, name: 'São Bento do Norte' },
  { state: 'Rio Grande do Norte', id: 2411700, name: 'São Bento do Trairí' },
  { state: 'Rio Grande do Norte', id: 2411809, name: 'São Fernando' },
  {
    state: 'Rio Grande do Norte',
    id: 2411908,
    name: 'São Francisco do Oeste',
  },
  {
    state: 'Rio Grande do Norte',
    id: 2412005,
    name: 'São Gonçalo do Amarante',
  },
  { state: 'Rio Grande do Norte', id: 2412104, name: 'São João do Sabugi' },
  { state: 'Rio Grande do Norte', id: 2412203, name: 'São José de Mipibu' },
  {
    state: 'Rio Grande do Norte',
    id: 2412302,
    name: 'São José do Campestre',
  },
  { state: 'Rio Grande do Norte', id: 2412401, name: 'São José do Seridó' },
  { state: 'Rio Grande do Norte', id: 2412500, name: 'São Miguel' },
  {
    state: 'Rio Grande do Norte',
    id: 2412559,
    name: 'São Miguel do Gostoso',
  },
  {
    state: 'Rio Grande do Norte',
    id: 2412609,
    name: 'São Paulo do Potengi',
  },
  { state: 'Rio Grande do Norte', id: 2412708, name: 'São Pedro' },
  { state: 'Rio Grande do Norte', id: 2412807, name: 'São Rafael' },
  { state: 'Rio Grande do Norte', id: 2412906, name: 'São Tomé' },
  { state: 'Rio Grande do Norte', id: 2413003, name: 'São Vicente' },
  {
    state: 'Rio Grande do Norte',
    id: 2413102,
    name: 'Senador Elói de Souza',
  },
  {
    state: 'Rio Grande do Norte',
    id: 2413201,
    name: 'Senador Georgino Avelino',
  },
  { state: 'Rio Grande do Norte', id: 2410306, name: 'Serra Caiada' },
  { state: 'Rio Grande do Norte', id: 2413300, name: 'Serra de São Bento' },
  { state: 'Rio Grande do Norte', id: 2413359, name: 'Serra do Mel' },
  {
    state: 'Rio Grande do Norte',
    id: 2413409,
    name: 'Serra Negra do Norte',
  },
  { state: 'Rio Grande do Norte', id: 2413508, name: 'Serrinha' },
  { state: 'Rio Grande do Norte', id: 2413557, name: 'Serrinha dos Pintos' },
  { state: 'Rio Grande do Norte', id: 2413607, name: 'Severiano Melo' },
  { state: 'Rio Grande do Norte', id: 2413706, name: 'Sítio Novo' },
  { state: 'Rio Grande do Norte', id: 2413805, name: 'Taboleiro Grande' },
  { state: 'Rio Grande do Norte', id: 2413904, name: 'Taipu' },
  { state: 'Rio Grande do Norte', id: 2414001, name: 'Tangará' },
  { state: 'Rio Grande do Norte', id: 2414100, name: 'Tenente Ananias' },
  {
    state: 'Rio Grande do Norte',
    id: 2414159,
    name: 'Tenente Laurentino Cruz',
  },
  { state: 'Rio Grande do Norte', id: 2411056, name: 'Tibau' },
  { state: 'Rio Grande do Norte', id: 2414209, name: 'Tibau do Sul' },
  {
    state: 'Rio Grande do Norte',
    id: 2414308,
    name: 'Timbaúba dos Batistas',
  },
  { state: 'Rio Grande do Norte', id: 2414407, name: 'Touros' },
  { state: 'Rio Grande do Norte', id: 2414456, name: 'Triunfo Potiguar' },
  { state: 'Rio Grande do Norte', id: 2414506, name: 'Umarizal' },
  { state: 'Rio Grande do Norte', id: 2414605, name: 'Upanema' },
  { state: 'Rio Grande do Norte', id: 2414704, name: 'Várzea' },
  { state: 'Rio Grande do Norte', id: 2414753, name: 'Venha-Ver' },
  { state: 'Rio Grande do Norte', id: 2414803, name: 'Vera Cruz' },
  { state: 'Rio Grande do Norte', id: 2414902, name: 'Viçosa' },
  { state: 'Rio Grande do Norte', id: 2415008, name: 'Vila Flor' },
  { state: 'Paraíba', id: 2500106, name: 'Água Branca' },
  { state: 'Paraíba', id: 2500205, name: 'Aguiar' },
  { state: 'Paraíba', id: 2500304, name: 'Alagoa Grande' },
  { state: 'Paraíba', id: 2500403, name: 'Alagoa Nova' },
  { state: 'Paraíba', id: 2500502, name: 'Alagoinha' },
  { state: 'Paraíba', id: 2500536, name: 'Alcantil' },
  { state: 'Paraíba', id: 2500577, name: 'Algodão de Jandaíra' },
  { state: 'Paraíba', id: 2500601, name: 'Alhandra' },
  { state: 'Paraíba', id: 2500734, name: 'Amparo' },
  { state: 'Paraíba', id: 2500775, name: 'Aparecida' },
  { state: 'Paraíba', id: 2500809, name: 'Araçagi' },
  { state: 'Paraíba', id: 2500908, name: 'Arara' },
  { state: 'Paraíba', id: 2501005, name: 'Araruna' },
  { state: 'Paraíba', id: 2501104, name: 'Areia' },
  { state: 'Paraíba', id: 2501153, name: 'Areia de Baraúnas' },
  { state: 'Paraíba', id: 2501203, name: 'Areial' },
  { state: 'Paraíba', id: 2501302, name: 'Aroeiras' },
  { state: 'Paraíba', id: 2501351, name: 'Assunção' },
  { state: 'Paraíba', id: 2501401, name: 'Baía da Traição' },
  { state: 'Paraíba', id: 2501500, name: 'Bananeiras' },
  { state: 'Paraíba', id: 2501534, name: 'Baraúna' },
  { state: 'Paraíba', id: 2501609, name: 'Barra de Santa Rosa' },
  { state: 'Paraíba', id: 2501575, name: 'Barra de Santana' },
  { state: 'Paraíba', id: 2501708, name: 'Barra de São Miguel' },
  { state: 'Paraíba', id: 2501807, name: 'Bayeux' },
  { state: 'Paraíba', id: 2501906, name: 'Belém' },
  { state: 'Paraíba', id: 2502003, name: 'Belém do Brejo do Cruz' },
  { state: 'Paraíba', id: 2502052, name: 'Bernardino Batista' },
  { state: 'Paraíba', id: 2502102, name: 'Boa Ventura' },
  { state: 'Paraíba', id: 2502151, name: 'Boa Vista' },
  { state: 'Paraíba', id: 2502201, name: 'Bom Jesus' },
  { state: 'Paraíba', id: 2502300, name: 'Bom Sucesso' },
  { state: 'Paraíba', id: 2502409, name: 'Bonito de Santa Fé' },
  { state: 'Paraíba', id: 2502508, name: 'Boqueirão' },
  { state: 'Paraíba', id: 2502706, name: 'Borborema' },
  { state: 'Paraíba', id: 2502805, name: 'Brejo do Cruz' },
  { state: 'Paraíba', id: 2502904, name: 'Brejo dos Santos' },
  { state: 'Paraíba', id: 2503001, name: 'Caaporã' },
  { state: 'Paraíba', id: 2503100, name: 'Cabaceiras' },
  { state: 'Paraíba', id: 2503209, name: 'Cabedelo' },
  { state: 'Paraíba', id: 2503308, name: 'Cachoeira dos Índios' },
  { state: 'Paraíba', id: 2503407, name: 'Cacimba de Areia' },
  { state: 'Paraíba', id: 2503506, name: 'Cacimba de Dentro' },
  { state: 'Paraíba', id: 2503555, name: 'Cacimbas' },
  { state: 'Paraíba', id: 2503605, name: 'Caiçara' },
  { state: 'Paraíba', id: 2503704, name: 'Cajazeiras' },
  { state: 'Paraíba', id: 2503753, name: 'Cajazeirinhas' },
  { state: 'Paraíba', id: 2503803, name: 'Caldas Brandão' },
  { state: 'Paraíba', id: 2503902, name: 'Camalaú' },
  { state: 'Paraíba', id: 2504009, name: 'Campina Grande' },
  { state: 'Paraíba', id: 2504033, name: 'Capim' },
  { state: 'Paraíba', id: 2504074, name: 'Caraúbas' },
  { state: 'Paraíba', id: 2504108, name: 'Carrapateira' },
  { state: 'Paraíba', id: 2504157, name: 'Casserengue' },
  { state: 'Paraíba', id: 2504207, name: 'Catingueira' },
  { state: 'Paraíba', id: 2504306, name: 'Catolé do Rocha' },
  { state: 'Paraíba', id: 2504355, name: 'Caturité' },
  { state: 'Paraíba', id: 2504405, name: 'Conceição' },
  { state: 'Paraíba', id: 2504504, name: 'Condado' },
  { state: 'Paraíba', id: 2504603, name: 'Conde' },
  { state: 'Paraíba', id: 2504702, name: 'Congo' },
  { state: 'Paraíba', id: 2504801, name: 'Coremas' },
  { state: 'Paraíba', id: 2504850, name: 'Coxixola' },
  { state: 'Paraíba', id: 2504900, name: 'Cruz do Espírito Santo' },
  { state: 'Paraíba', id: 2505006, name: 'Cubati' },
  { state: 'Paraíba', id: 2505105, name: 'Cuité' },
  { state: 'Paraíba', id: 2505238, name: 'Cuité de Mamanguape' },
  { state: 'Paraíba', id: 2505204, name: 'Cuitegi' },
  { state: 'Paraíba', id: 2505279, name: 'Curral de Cima' },
  { state: 'Paraíba', id: 2505303, name: 'Curral Velho' },
  { state: 'Paraíba', id: 2505352, name: 'Damião' },
  { state: 'Paraíba', id: 2505402, name: 'Desterro' },
  { state: 'Paraíba', id: 2505600, name: 'Diamante' },
  { state: 'Paraíba', id: 2505709, name: 'Dona Inês' },
  { state: 'Paraíba', id: 2505808, name: 'Duas Estradas' },
  { state: 'Paraíba', id: 2505907, name: 'Emas' },
  { state: 'Paraíba', id: 2506004, name: 'Esperança' },
  { state: 'Paraíba', id: 2506103, name: 'Fagundes' },
  { state: 'Paraíba', id: 2506202, name: 'Frei Martinho' },
  { state: 'Paraíba', id: 2506251, name: 'Gado Bravo' },
  { state: 'Paraíba', id: 2506301, name: 'Guarabira' },
  { state: 'Paraíba', id: 2506400, name: 'Gurinhém' },
  { state: 'Paraíba', id: 2506509, name: 'Gurjão' },
  { state: 'Paraíba', id: 2506608, name: 'Ibiara' },
  { state: 'Paraíba', id: 2502607, name: 'Igaracy' },
  { state: 'Paraíba', id: 2506707, name: 'Imaculada' },
  { state: 'Paraíba', id: 2506806, name: 'Ingá' },
  { state: 'Paraíba', id: 2506905, name: 'Itabaiana' },
  { state: 'Paraíba', id: 2507002, name: 'Itaporanga' },
  { state: 'Paraíba', id: 2507101, name: 'Itapororoca' },
  { state: 'Paraíba', id: 2507200, name: 'Itatuba' },
  { state: 'Paraíba', id: 2507309, name: 'Jacaraú' },
  { state: 'Paraíba', id: 2507408, name: 'Jericó' },
  { state: 'Paraíba', id: 2507507, name: 'João Pessoa' },
  { state: 'Paraíba', id: 2513653, name: 'Joca Claudino' },
  { state: 'Paraíba', id: 2507606, name: 'Juarez Távora' },
  { state: 'Paraíba', id: 2507705, name: 'Juazeirinho' },
  { state: 'Paraíba', id: 2507804, name: 'Junco do Seridó' },
  { state: 'Paraíba', id: 2507903, name: 'Juripiranga' },
  { state: 'Paraíba', id: 2508000, name: 'Juru' },
  { state: 'Paraíba', id: 2508109, name: 'Lagoa' },
  { state: 'Paraíba', id: 2508208, name: 'Lagoa de Dentro' },
  { state: 'Paraíba', id: 2508307, name: 'Lagoa Seca' },
  { state: 'Paraíba', id: 2508406, name: 'Lastro' },
  { state: 'Paraíba', id: 2508505, name: 'Livramento' },
  { state: 'Paraíba', id: 2508554, name: 'Logradouro' },
  { state: 'Paraíba', id: 2508604, name: 'Lucena' },
  { state: 'Paraíba', id: 2508703, name: "Mãe d'Água" },
  { state: 'Paraíba', id: 2508802, name: 'Malta' },
  { state: 'Paraíba', id: 2508901, name: 'Mamanguape' },
  { state: 'Paraíba', id: 2509008, name: 'Manaíra' },
  { state: 'Paraíba', id: 2509057, name: 'Marcação' },
  { state: 'Paraíba', id: 2509107, name: 'Mari' },
  { state: 'Paraíba', id: 2509156, name: 'Marizópolis' },
  { state: 'Paraíba', id: 2509206, name: 'Massaranduba' },
  { state: 'Paraíba', id: 2509305, name: 'Mataraca' },
  { state: 'Paraíba', id: 2509339, name: 'Matinhas' },
  { state: 'Paraíba', id: 2509370, name: 'Mato Grosso' },
  { state: 'Paraíba', id: 2509396, name: 'Maturéia' },
  { state: 'Paraíba', id: 2509404, name: 'Mogeiro' },
  { state: 'Paraíba', id: 2509503, name: 'Montadas' },
  { state: 'Paraíba', id: 2509602, name: 'Monte Horebe' },
  { state: 'Paraíba', id: 2509701, name: 'Monteiro' },
  { state: 'Paraíba', id: 2509800, name: 'Mulungu' },
  { state: 'Paraíba', id: 2509909, name: 'Natuba' },
  { state: 'Paraíba', id: 2510006, name: 'Nazarezinho' },
  { state: 'Paraíba', id: 2510105, name: 'Nova Floresta' },
  { state: 'Paraíba', id: 2510204, name: 'Nova Olinda' },
  { state: 'Paraíba', id: 2510303, name: 'Nova Palmeira' },
  { state: 'Paraíba', id: 2510402, name: "Olho d'Água" },
  { state: 'Paraíba', id: 2510501, name: 'Olivedos' },
  { state: 'Paraíba', id: 2510600, name: 'Ouro Velho' },
  { state: 'Paraíba', id: 2510659, name: 'Parari' },
  { state: 'Paraíba', id: 2510709, name: 'Passagem' },
  { state: 'Paraíba', id: 2510808, name: 'Patos' },
  { state: 'Paraíba', id: 2510907, name: 'Paulista' },
  { state: 'Paraíba', id: 2511004, name: 'Pedra Branca' },
  { state: 'Paraíba', id: 2511103, name: 'Pedra Lavrada' },
  { state: 'Paraíba', id: 2511202, name: 'Pedras de Fogo' },
  { state: 'Paraíba', id: 2512721, name: 'Pedro Régis' },
  { state: 'Paraíba', id: 2511301, name: 'Piancó' },
  { state: 'Paraíba', id: 2511400, name: 'Picuí' },
  { state: 'Paraíba', id: 2511509, name: 'Pilar' },
  { state: 'Paraíba', id: 2511608, name: 'Pilões' },
  { state: 'Paraíba', id: 2511707, name: 'Pilõezinhos' },
  { state: 'Paraíba', id: 2511806, name: 'Pirpirituba' },
  { state: 'Paraíba', id: 2511905, name: 'Pitimbu' },
  { state: 'Paraíba', id: 2512002, name: 'Pocinhos' },
  { state: 'Paraíba', id: 2512036, name: 'Poço Dantas' },
  { state: 'Paraíba', id: 2512077, name: 'Poço de José de Moura' },
  { state: 'Paraíba', id: 2512101, name: 'Pombal' },
  { state: 'Paraíba', id: 2512200, name: 'Prata' },
  { state: 'Paraíba', id: 2512309, name: 'Princesa Isabel' },
  { state: 'Paraíba', id: 2512408, name: 'Puxinanã' },
  { state: 'Paraíba', id: 2512507, name: 'Queimadas' },
  { state: 'Paraíba', id: 2512606, name: 'Quixaba' },
  { state: 'Paraíba', id: 2512705, name: 'Remígio' },
  { state: 'Paraíba', id: 2512747, name: 'Riachão' },
  { state: 'Paraíba', id: 2512754, name: 'Riachão do Bacamarte' },
  { state: 'Paraíba', id: 2512762, name: 'Riachão do Poço' },
  { state: 'Paraíba', id: 2512788, name: 'Riacho de Santo Antônio' },
  { state: 'Paraíba', id: 2512804, name: 'Riacho dos Cavalos' },
  { state: 'Paraíba', id: 2512903, name: 'Rio Tinto' },
  { state: 'Paraíba', id: 2513000, name: 'Salgadinho' },
  { state: 'Paraíba', id: 2513109, name: 'Salgado de São Félix' },
  { state: 'Paraíba', id: 2513158, name: 'Santa Cecília' },
  { state: 'Paraíba', id: 2513208, name: 'Santa Cruz' },
  { state: 'Paraíba', id: 2513307, name: 'Santa Helena' },
  { state: 'Paraíba', id: 2513356, name: 'Santa Inês' },
  { state: 'Paraíba', id: 2513406, name: 'Santa Luzia' },
  { state: 'Paraíba', id: 2513703, name: 'Santa Rita' },
  { state: 'Paraíba', id: 2513802, name: 'Santa Teresinha' },
  { state: 'Paraíba', id: 2513505, name: 'Santana de Mangueira' },
  { state: 'Paraíba', id: 2513604, name: 'Santana dos Garrotes' },
  { state: 'Paraíba', id: 2513851, name: 'Santo André' },
  { state: 'Paraíba', id: 2513927, name: 'São Bentinho' },
  { state: 'Paraíba', id: 2513901, name: 'São Bento' },
  { state: 'Paraíba', id: 2513968, name: 'São Domingos' },
  { state: 'Paraíba', id: 2513943, name: 'São Domingos do Cariri' },
  { state: 'Paraíba', id: 2513984, name: 'São Francisco' },
  { state: 'Paraíba', id: 2514008, name: 'São João do Cariri' },
  { state: 'Paraíba', id: 2500700, name: 'São João do Rio do Peixe' },
  { state: 'Paraíba', id: 2514107, name: 'São João do Tigre' },
  { state: 'Paraíba', id: 2514206, name: 'São José da Lagoa Tapada' },
  { state: 'Paraíba', id: 2514305, name: 'São José de Caiana' },
  { state: 'Paraíba', id: 2514404, name: 'São José de Espinharas' },
  { state: 'Paraíba', id: 2514503, name: 'São José de Piranhas' },
  { state: 'Paraíba', id: 2514552, name: 'São José de Princesa' },
  { state: 'Paraíba', id: 2514602, name: 'São José do Bonfim' },
  { state: 'Paraíba', id: 2514651, name: 'São José do Brejo do Cruz' },
  { state: 'Paraíba', id: 2514701, name: 'São José do Sabugi' },
  { state: 'Paraíba', id: 2514800, name: 'São José dos Cordeiros' },
  { state: 'Paraíba', id: 2514453, name: 'São José dos Ramos' },
  { state: 'Paraíba', id: 2514909, name: 'São Mamede' },
  { state: 'Paraíba', id: 2515005, name: 'São Miguel de Taipu' },
  { state: 'Paraíba', id: 2515104, name: 'São Sebastião de Lagoa de Roça' },
  { state: 'Paraíba', id: 2515203, name: 'São Sebastião do Umbuzeiro' },
  { state: 'Paraíba', id: 2515401, name: 'São Vicente do Seridó' },
  { state: 'Paraíba', id: 2515302, name: 'Sapé' },
  { state: 'Paraíba', id: 2515500, name: 'Serra Branca' },
  { state: 'Paraíba', id: 2515609, name: 'Serra da Raiz' },
  { state: 'Paraíba', id: 2515708, name: 'Serra Grande' },
  { state: 'Paraíba', id: 2515807, name: 'Serra Redonda' },
  { state: 'Paraíba', id: 2515906, name: 'Serraria' },
  { state: 'Paraíba', id: 2515930, name: 'Sertãozinho' },
  { state: 'Paraíba', id: 2515971, name: 'Sobrado' },
  { state: 'Paraíba', id: 2516003, name: 'Solânea' },
  { state: 'Paraíba', id: 2516102, name: 'Soledade' },
  { state: 'Paraíba', id: 2516151, name: 'Sossêgo' },
  { state: 'Paraíba', id: 2516201, name: 'Sousa' },
  { state: 'Paraíba', id: 2516300, name: 'Sumé' },
  { state: 'Paraíba', id: 2516409, name: 'Tacima' },
  { state: 'Paraíba', id: 2516508, name: 'Taperoá' },
  { state: 'Paraíba', id: 2516607, name: 'Tavares' },
  { state: 'Paraíba', id: 2516706, name: 'Teixeira' },
  { state: 'Paraíba', id: 2516755, name: 'Tenório' },
  { state: 'Paraíba', id: 2516805, name: 'Triunfo' },
  { state: 'Paraíba', id: 2516904, name: 'Uiraúna' },
  { state: 'Paraíba', id: 2517001, name: 'Umbuzeiro' },
  { state: 'Paraíba', id: 2517100, name: 'Várzea' },
  { state: 'Paraíba', id: 2517209, name: 'Vieirópolis' },
  { state: 'Paraíba', id: 2505501, name: 'Vista Serrana' },
  { state: 'Paraíba', id: 2517407, name: 'Zabelê' },
  { state: 'Pernambuco', id: 2600054, name: 'Abreu e Lima' },
  { state: 'Pernambuco', id: 2600104, name: 'Afogados da Ingazeira' },
  { state: 'Pernambuco', id: 2600203, name: 'Afrânio' },
  { state: 'Pernambuco', id: 2600302, name: 'Agrestina' },
  { state: 'Pernambuco', id: 2600401, name: 'Água Preta' },
  { state: 'Pernambuco', id: 2600500, name: 'Águas Belas' },
  { state: 'Pernambuco', id: 2600609, name: 'Alagoinha' },
  { state: 'Pernambuco', id: 2600708, name: 'Aliança' },
  { state: 'Pernambuco', id: 2600807, name: 'Altinho' },
  { state: 'Pernambuco', id: 2600906, name: 'Amaraji' },
  { state: 'Pernambuco', id: 2601003, name: 'Angelim' },
  { state: 'Pernambuco', id: 2601052, name: 'Araçoiaba' },
  { state: 'Pernambuco', id: 2601102, name: 'Araripina' },
  { state: 'Pernambuco', id: 2601201, name: 'Arcoverde' },
  { state: 'Pernambuco', id: 2601300, name: 'Barra de Guabiraba' },
  { state: 'Pernambuco', id: 2601409, name: 'Barreiros' },
  { state: 'Pernambuco', id: 2601508, name: 'Belém de Maria' },
  { state: 'Pernambuco', id: 2601607, name: 'Belém do São Francisco' },
  { state: 'Pernambuco', id: 2601706, name: 'Belo Jardim' },
  { state: 'Pernambuco', id: 2601805, name: 'Betânia' },
  { state: 'Pernambuco', id: 2601904, name: 'Bezerros' },
  { state: 'Pernambuco', id: 2602001, name: 'Bodocó' },
  { state: 'Pernambuco', id: 2602100, name: 'Bom Conselho' },
  { state: 'Pernambuco', id: 2602209, name: 'Bom Jardim' },
  { state: 'Pernambuco', id: 2602308, name: 'Bonito' },
  { state: 'Pernambuco', id: 2602407, name: 'Brejão' },
  { state: 'Pernambuco', id: 2602506, name: 'Brejinho' },
  { state: 'Pernambuco', id: 2602605, name: 'Brejo da Madre de Deus' },
  { state: 'Pernambuco', id: 2602704, name: 'Buenos Aires' },
  { state: 'Pernambuco', id: 2602803, name: 'Buíque' },
  { state: 'Pernambuco', id: 2602902, name: 'Cabo de Santo Agostinho' },
  { state: 'Pernambuco', id: 2603009, name: 'Cabrobó' },
  { state: 'Pernambuco', id: 2603108, name: 'Cachoeirinha' },
  { state: 'Pernambuco', id: 2603207, name: 'Caetés' },
  { state: 'Pernambuco', id: 2603306, name: 'Calçado' },
  { state: 'Pernambuco', id: 2603405, name: 'Calumbi' },
  { state: 'Pernambuco', id: 2603454, name: 'Camaragibe' },
  { state: 'Pernambuco', id: 2603504, name: 'Camocim de São Félix' },
  { state: 'Pernambuco', id: 2603603, name: 'Camutanga' },
  { state: 'Pernambuco', id: 2603702, name: 'Canhotinho' },
  { state: 'Pernambuco', id: 2603801, name: 'Capoeiras' },
  { state: 'Pernambuco', id: 2603900, name: 'Carnaíba' },
  { state: 'Pernambuco', id: 2603926, name: 'Carnaubeira da Penha' },
  { state: 'Pernambuco', id: 2604007, name: 'Carpina' },
  { state: 'Pernambuco', id: 2604106, name: 'Caruaru' },
  { state: 'Pernambuco', id: 2604155, name: 'Casinhas' },
  { state: 'Pernambuco', id: 2604205, name: 'Catende' },
  { state: 'Pernambuco', id: 2604304, name: 'Cedro' },
  { state: 'Pernambuco', id: 2604403, name: 'Chã de Alegria' },
  { state: 'Pernambuco', id: 2604502, name: 'Chã Grande' },
  { state: 'Pernambuco', id: 2604601, name: 'Condado' },
  { state: 'Pernambuco', id: 2604700, name: 'Correntes' },
  { state: 'Pernambuco', id: 2604809, name: 'Cortês' },
  { state: 'Pernambuco', id: 2604908, name: 'Cumaru' },
  { state: 'Pernambuco', id: 2605004, name: 'Cupira' },
  { state: 'Pernambuco', id: 2605103, name: 'Custódia' },
  { state: 'Pernambuco', id: 2605152, name: 'Dormentes' },
  { state: 'Pernambuco', id: 2605202, name: 'Escada' },
  { state: 'Pernambuco', id: 2605301, name: 'Exu' },
  { state: 'Pernambuco', id: 2605400, name: 'Feira Nova' },
  { state: 'Pernambuco', id: 2605459, name: 'Fernando de Noronha' },
  { state: 'Pernambuco', id: 2605509, name: 'Ferreiros' },
  { state: 'Pernambuco', id: 2605608, name: 'Flores' },
  { state: 'Pernambuco', id: 2605707, name: 'Floresta' },
  { state: 'Pernambuco', id: 2605806, name: 'Frei Miguelinho' },
  { state: 'Pernambuco', id: 2605905, name: 'Gameleira' },
  { state: 'Pernambuco', id: 2606002, name: 'Garanhuns' },
  { state: 'Pernambuco', id: 2606101, name: 'Glória do Goitá' },
  { state: 'Pernambuco', id: 2606200, name: 'Goiana' },
  { state: 'Pernambuco', id: 2606309, name: 'Granito' },
  { state: 'Pernambuco', id: 2606408, name: 'Gravatá' },
  { state: 'Pernambuco', id: 2606507, name: 'Iati' },
  { state: 'Pernambuco', id: 2606606, name: 'Ibimirim' },
  { state: 'Pernambuco', id: 2606705, name: 'Ibirajuba' },
  { state: 'Pernambuco', id: 2606804, name: 'Igarassu' },
  { state: 'Pernambuco', id: 2606903, name: 'Iguaracy' },
  { state: 'Pernambuco', id: 2607604, name: 'Ilha de Itamaracá' },
  { state: 'Pernambuco', id: 2607000, name: 'Inajá' },
  { state: 'Pernambuco', id: 2607109, name: 'Ingazeira' },
  { state: 'Pernambuco', id: 2607208, name: 'Ipojuca' },
  { state: 'Pernambuco', id: 2607307, name: 'Ipubi' },
  { state: 'Pernambuco', id: 2607406, name: 'Itacuruba' },
  { state: 'Pernambuco', id: 2607505, name: 'Itaíba' },
  { state: 'Pernambuco', id: 2607653, name: 'Itambé' },
  { state: 'Pernambuco', id: 2607703, name: 'Itapetim' },
  { state: 'Pernambuco', id: 2607752, name: 'Itapissuma' },
  { state: 'Pernambuco', id: 2607802, name: 'Itaquitinga' },
  { state: 'Pernambuco', id: 2607901, name: 'Jaboatão dos Guararapes' },
  { state: 'Pernambuco', id: 2607950, name: 'Jaqueira' },
  { state: 'Pernambuco', id: 2608008, name: 'Jataúba' },
  { state: 'Pernambuco', id: 2608057, name: 'Jatobá' },
  { state: 'Pernambuco', id: 2608107, name: 'João Alfredo' },
  { state: 'Pernambuco', id: 2608206, name: 'Joaquim Nabuco' },
  { state: 'Pernambuco', id: 2608255, name: 'Jucati' },
  { state: 'Pernambuco', id: 2608305, name: 'Jupi' },
  { state: 'Pernambuco', id: 2608404, name: 'Jurema' },
  { state: 'Pernambuco', id: 2608503, name: 'Lagoa de Itaenga' },
  { state: 'Pernambuco', id: 2608453, name: 'Lagoa do Carro' },
  { state: 'Pernambuco', id: 2608602, name: 'Lagoa do Ouro' },
  { state: 'Pernambuco', id: 2608701, name: 'Lagoa dos Gatos' },
  { state: 'Pernambuco', id: 2608750, name: 'Lagoa Grande' },
  { state: 'Pernambuco', id: 2608800, name: 'Lajedo' },
  { state: 'Pernambuco', id: 2608909, name: 'Limoeiro' },
  { state: 'Pernambuco', id: 2609006, name: 'Macaparana' },
  { state: 'Pernambuco', id: 2609105, name: 'Machados' },
  { state: 'Pernambuco', id: 2609154, name: 'Manari' },
  { state: 'Pernambuco', id: 2609204, name: 'Maraial' },
  { state: 'Pernambuco', id: 2609303, name: 'Mirandiba' },
  { state: 'Pernambuco', id: 2614303, name: 'Moreilândia' },
  { state: 'Pernambuco', id: 2609402, name: 'Moreno' },
  { state: 'Pernambuco', id: 2609501, name: 'Nazaré da Mata' },
  { state: 'Pernambuco', id: 2609600, name: 'Olinda' },
  { state: 'Pernambuco', id: 2609709, name: 'Orobó' },
  { state: 'Pernambuco', id: 2609808, name: 'Orocó' },
  { state: 'Pernambuco', id: 2609907, name: 'Ouricuri' },
  { state: 'Pernambuco', id: 2610004, name: 'Palmares' },
  { state: 'Pernambuco', id: 2610103, name: 'Palmeirina' },
  { state: 'Pernambuco', id: 2610202, name: 'Panelas' },
  { state: 'Pernambuco', id: 2610301, name: 'Paranatama' },
  { state: 'Pernambuco', id: 2610400, name: 'Parnamirim' },
  { state: 'Pernambuco', id: 2610509, name: 'Passira' },
  { state: 'Pernambuco', id: 2610608, name: 'Paudalho' },
  { state: 'Pernambuco', id: 2610707, name: 'Paulista' },
  { state: 'Pernambuco', id: 2610806, name: 'Pedra' },
  { state: 'Pernambuco', id: 2610905, name: 'Pesqueira' },
  { state: 'Pernambuco', id: 2611002, name: 'Petrolândia' },
  { state: 'Pernambuco', id: 2611101, name: 'Petrolina' },
  { state: 'Pernambuco', id: 2611200, name: 'Poção' },
  { state: 'Pernambuco', id: 2611309, name: 'Pombos' },
  { state: 'Pernambuco', id: 2611408, name: 'Primavera' },
  { state: 'Pernambuco', id: 2611507, name: 'Quipapá' },
  { state: 'Pernambuco', id: 2611533, name: 'Quixaba' },
  { state: 'Pernambuco', id: 2611606, name: 'Recife' },
  { state: 'Pernambuco', id: 2611705, name: 'Riacho das Almas' },
  { state: 'Pernambuco', id: 2611804, name: 'Ribeirão' },
  { state: 'Pernambuco', id: 2611903, name: 'Rio Formoso' },
  { state: 'Pernambuco', id: 2612000, name: 'Sairé' },
  { state: 'Pernambuco', id: 2612109, name: 'Salgadinho' },
  { state: 'Pernambuco', id: 2612208, name: 'Salgueiro' },
  { state: 'Pernambuco', id: 2612307, name: 'Saloá' },
  { state: 'Pernambuco', id: 2612406, name: 'Sanharó' },
  { state: 'Pernambuco', id: 2612455, name: 'Santa Cruz' },
  { state: 'Pernambuco', id: 2612471, name: 'Santa Cruz da Baixa Verde' },
  { state: 'Pernambuco', id: 2612505, name: 'Santa Cruz do Capibaribe' },
  { state: 'Pernambuco', id: 2612554, name: 'Santa Filomena' },
  { state: 'Pernambuco', id: 2612604, name: 'Santa Maria da Boa Vista' },
  { state: 'Pernambuco', id: 2612703, name: 'Santa Maria do Cambucá' },
  { state: 'Pernambuco', id: 2612802, name: 'Santa Terezinha' },
  { state: 'Pernambuco', id: 2612901, name: 'São Benedito do Sul' },
  { state: 'Pernambuco', id: 2613008, name: 'São Bento do Una' },
  { state: 'Pernambuco', id: 2613107, name: 'São Caitano' },
  { state: 'Pernambuco', id: 2613206, name: 'São João' },
  { state: 'Pernambuco', id: 2613305, name: 'São Joaquim do Monte' },
  { state: 'Pernambuco', id: 2613404, name: 'São José da Coroa Grande' },
  { state: 'Pernambuco', id: 2613503, name: 'São José do Belmonte' },
  { state: 'Pernambuco', id: 2613602, name: 'São José do Egito' },
  { state: 'Pernambuco', id: 2613701, name: 'São Lourenço da Mata' },
  { state: 'Pernambuco', id: 2613800, name: 'São Vicente Férrer' },
  { state: 'Pernambuco', id: 2613909, name: 'Serra Talhada' },
  { state: 'Pernambuco', id: 2614006, name: 'Serrita' },
  { state: 'Pernambuco', id: 2614105, name: 'Sertânia' },
  { state: 'Pernambuco', id: 2614204, name: 'Sirinhaém' },
  { state: 'Pernambuco', id: 2614402, name: 'Solidão' },
  { state: 'Pernambuco', id: 2614501, name: 'Surubim' },
  { state: 'Pernambuco', id: 2614600, name: 'Tabira' },
  { state: 'Pernambuco', id: 2614709, name: 'Tacaimbó' },
  { state: 'Pernambuco', id: 2614808, name: 'Tacaratu' },
  { state: 'Pernambuco', id: 2614857, name: 'Tamandaré' },
  { state: 'Pernambuco', id: 2615003, name: 'Taquaritinga do Norte' },
  { state: 'Pernambuco', id: 2615102, name: 'Terezinha' },
  { state: 'Pernambuco', id: 2615201, name: 'Terra Nova' },
  { state: 'Pernambuco', id: 2615300, name: 'Timbaúba' },
  { state: 'Pernambuco', id: 2615409, name: 'Toritama' },
  { state: 'Pernambuco', id: 2615508, name: 'Tracunhaém' },
  { state: 'Pernambuco', id: 2615607, name: 'Trindade' },
  { state: 'Pernambuco', id: 2615706, name: 'Triunfo' },
  { state: 'Pernambuco', id: 2615805, name: 'Tupanatinga' },
  { state: 'Pernambuco', id: 2615904, name: 'Tuparetama' },
  { state: 'Pernambuco', id: 2616001, name: 'Venturosa' },
  { state: 'Pernambuco', id: 2616100, name: 'Verdejante' },
  { state: 'Pernambuco', id: 2616183, name: 'Vertente do Lério' },
  { state: 'Pernambuco', id: 2616209, name: 'Vertentes' },
  { state: 'Pernambuco', id: 2616308, name: 'Vicência' },
  { state: 'Pernambuco', id: 2616407, name: 'Vitória de Santo Antão' },
  { state: 'Pernambuco', id: 2616506, name: 'Xexéu' },
  { state: 'Alagoas', id: 2700102, name: 'Água Branca' },
  { state: 'Alagoas', id: 2700201, name: 'Anadia' },
  { state: 'Alagoas', id: 2700300, name: 'Arapiraca' },
  { state: 'Alagoas', id: 2700409, name: 'Atalaia' },
  { state: 'Alagoas', id: 2700508, name: 'Barra de Santo Antônio' },
  { state: 'Alagoas', id: 2700607, name: 'Barra de São Miguel' },
  { state: 'Alagoas', id: 2700706, name: 'Batalha' },
  { state: 'Alagoas', id: 2700805, name: 'Belém' },
  { state: 'Alagoas', id: 2700904, name: 'Belo Monte' },
  { state: 'Alagoas', id: 2701001, name: 'Boca da Mata' },
  { state: 'Alagoas', id: 2701100, name: 'Branquinha' },
  { state: 'Alagoas', id: 2701209, name: 'Cacimbinhas' },
  { state: 'Alagoas', id: 2701308, name: 'Cajueiro' },
  { state: 'Alagoas', id: 2701357, name: 'Campestre' },
  { state: 'Alagoas', id: 2701407, name: 'Campo Alegre' },
  { state: 'Alagoas', id: 2701506, name: 'Campo Grande' },
  { state: 'Alagoas', id: 2701605, name: 'Canapi' },
  { state: 'Alagoas', id: 2701704, name: 'Capela' },
  { state: 'Alagoas', id: 2701803, name: 'Carneiros' },
  { state: 'Alagoas', id: 2701902, name: 'Chã Preta' },
  { state: 'Alagoas', id: 2702009, name: 'Coité do Nóia' },
  { state: 'Alagoas', id: 2702108, name: 'Colônia Leopoldina' },
  { state: 'Alagoas', id: 2702207, name: 'Coqueiro Seco' },
  { state: 'Alagoas', id: 2702306, name: 'Coruripe' },
  { state: 'Alagoas', id: 2702355, name: 'Craíbas' },
  { state: 'Alagoas', id: 2702405, name: 'Delmiro Gouveia' },
  { state: 'Alagoas', id: 2702504, name: 'Dois Riachos' },
  { state: 'Alagoas', id: 2702553, name: 'Estrela de Alagoas' },
  { state: 'Alagoas', id: 2702603, name: 'Feira Grande' },
  { state: 'Alagoas', id: 2702702, name: 'Feliz Deserto' },
  { state: 'Alagoas', id: 2702801, name: 'Flexeiras' },
  { state: 'Alagoas', id: 2702900, name: 'Girau do Ponciano' },
  { state: 'Alagoas', id: 2703007, name: 'Ibateguara' },
  { state: 'Alagoas', id: 2703106, name: 'Igaci' },
  { state: 'Alagoas', id: 2703205, name: 'Igreja Nova' },
  { state: 'Alagoas', id: 2703304, name: 'Inhapi' },
  { state: 'Alagoas', id: 2703403, name: 'Jacaré dos Homens' },
  { state: 'Alagoas', id: 2703502, name: 'Jacuípe' },
  { state: 'Alagoas', id: 2703601, name: 'Japaratinga' },
  { state: 'Alagoas', id: 2703700, name: 'Jaramataia' },
  { state: 'Alagoas', id: 2703759, name: 'Jequiá da Praia' },
  { state: 'Alagoas', id: 2703809, name: 'Joaquim Gomes' },
  { state: 'Alagoas', id: 2703908, name: 'Jundiá' },
  { state: 'Alagoas', id: 2704005, name: 'Junqueiro' },
  { state: 'Alagoas', id: 2704104, name: 'Lagoa da Canoa' },
  { state: 'Alagoas', id: 2704203, name: 'Limoeiro de Anadia' },
  { state: 'Alagoas', id: 2704302, name: 'Maceió' },
  { state: 'Alagoas', id: 2704401, name: 'Major Isidoro' },
  { state: 'Alagoas', id: 2704906, name: 'Mar Vermelho' },
  { state: 'Alagoas', id: 2704500, name: 'Maragogi' },
  { state: 'Alagoas', id: 2704609, name: 'Maravilha' },
  { state: 'Alagoas', id: 2704708, name: 'Marechal Deodoro' },
  { state: 'Alagoas', id: 2704807, name: 'Maribondo' },
  { state: 'Alagoas', id: 2705002, name: 'Mata Grande' },
  { state: 'Alagoas', id: 2705101, name: 'Matriz de Camaragibe' },
  { state: 'Alagoas', id: 2705200, name: 'Messias' },
  { state: 'Alagoas', id: 2705309, name: 'Minador do Negrão' },
  { state: 'Alagoas', id: 2705408, name: 'Monteirópolis' },
  { state: 'Alagoas', id: 2705507, name: 'Murici' },
  { state: 'Alagoas', id: 2705606, name: 'Novo Lino' },
  { state: 'Alagoas', id: 2705705, name: "Olho d'Água das Flores" },
  { state: 'Alagoas', id: 2705804, name: "Olho d'Água do Casado" },
  { state: 'Alagoas', id: 2705903, name: "Olho d'Água Grande" },
  { state: 'Alagoas', id: 2706000, name: 'Olivença' },
  { state: 'Alagoas', id: 2706109, name: 'Ouro Branco' },
  { state: 'Alagoas', id: 2706208, name: 'Palestina' },
  { state: 'Alagoas', id: 2706307, name: 'Palmeira dos Índios' },
  { state: 'Alagoas', id: 2706406, name: 'Pão de Açúcar' },
  { state: 'Alagoas', id: 2706422, name: 'Pariconha' },
  { state: 'Alagoas', id: 2706448, name: 'Paripueira' },
  { state: 'Alagoas', id: 2706505, name: 'Passo de Camaragibe' },
  { state: 'Alagoas', id: 2706604, name: 'Paulo Jacinto' },
  { state: 'Alagoas', id: 2706703, name: 'Penedo' },
  { state: 'Alagoas', id: 2706802, name: 'Piaçabuçu' },
  { state: 'Alagoas', id: 2706901, name: 'Pilar' },
  { state: 'Alagoas', id: 2707008, name: 'Pindoba' },
  { state: 'Alagoas', id: 2707107, name: 'Piranhas' },
  { state: 'Alagoas', id: 2707206, name: 'Poço das Trincheiras' },
  { state: 'Alagoas', id: 2707305, name: 'Porto Calvo' },
  { state: 'Alagoas', id: 2707404, name: 'Porto de Pedras' },
  { state: 'Alagoas', id: 2707503, name: 'Porto Real do Colégio' },
  { state: 'Alagoas', id: 2707602, name: 'Quebrangulo' },
  { state: 'Alagoas', id: 2707701, name: 'Rio Largo' },
  { state: 'Alagoas', id: 2707800, name: 'Roteiro' },
  { state: 'Alagoas', id: 2707909, name: 'Santa Luzia do Norte' },
  { state: 'Alagoas', id: 2708006, name: 'Santana do Ipanema' },
  { state: 'Alagoas', id: 2708105, name: 'Santana do Mundaú' },
  { state: 'Alagoas', id: 2708204, name: 'São Brás' },
  { state: 'Alagoas', id: 2708303, name: 'São José da Laje' },
  { state: 'Alagoas', id: 2708402, name: 'São José da Tapera' },
  { state: 'Alagoas', id: 2708501, name: 'São Luís do Quitunde' },
  { state: 'Alagoas', id: 2708600, name: 'São Miguel dos Campos' },
  { state: 'Alagoas', id: 2708709, name: 'São Miguel dos Milagres' },
  { state: 'Alagoas', id: 2708808, name: 'São Sebastião' },
  { state: 'Alagoas', id: 2708907, name: 'Satuba' },
  { state: 'Alagoas', id: 2708956, name: 'Senador Rui Palmeira' },
  { state: 'Alagoas', id: 2709004, name: "Tanque d'Arca" },
  { state: 'Alagoas', id: 2709103, name: 'Taquarana' },
  { state: 'Alagoas', id: 2709152, name: 'Teotônio Vilela' },
  { state: 'Alagoas', id: 2709202, name: 'Traipu' },
  { state: 'Alagoas', id: 2709301, name: 'União dos Palmares' },
  { state: 'Alagoas', id: 2709400, name: 'Viçosa' },
  { state: 'Sergipe', id: 2800100, name: 'Amparo de São Francisco' },
  { state: 'Sergipe', id: 2800209, name: 'Aquidabã' },
  { state: 'Sergipe', id: 2800308, name: 'Aracaju' },
  { state: 'Sergipe', id: 2800407, name: 'Arauá' },
  { state: 'Sergipe', id: 2800506, name: 'Areia Branca' },
  { state: 'Sergipe', id: 2800605, name: 'Barra dos Coqueiros' },
  { state: 'Sergipe', id: 2800670, name: 'Boquim' },
  { state: 'Sergipe', id: 2800704, name: 'Brejo Grande' },
  { state: 'Sergipe', id: 2801009, name: 'Campo do Brito' },
  { state: 'Sergipe', id: 2801108, name: 'Canhoba' },
  { state: 'Sergipe', id: 2801207, name: 'Canindé de São Francisco' },
  { state: 'Sergipe', id: 2801306, name: 'Capela' },
  { state: 'Sergipe', id: 2801405, name: 'Carira' },
  { state: 'Sergipe', id: 2801504, name: 'Carmópolis' },
  { state: 'Sergipe', id: 2801603, name: 'Cedro de São João' },
  { state: 'Sergipe', id: 2801702, name: 'Cristinápolis' },
  { state: 'Sergipe', id: 2801900, name: 'Cumbe' },
  { state: 'Sergipe', id: 2802007, name: 'Divina Pastora' },
  { state: 'Sergipe', id: 2802106, name: 'Estância' },
  { state: 'Sergipe', id: 2802205, name: 'Feira Nova' },
  { state: 'Sergipe', id: 2802304, name: 'Frei Paulo' },
  { state: 'Sergipe', id: 2802403, name: 'Gararu' },
  { state: 'Sergipe', id: 2802502, name: 'General Maynard' },
  { state: 'Sergipe', id: 2802601, name: 'Gracho Cardoso' },
  { state: 'Sergipe', id: 2802700, name: 'Ilha das Flores' },
  { state: 'Sergipe', id: 2802809, name: 'Indiaroba' },
  { state: 'Sergipe', id: 2802908, name: 'Itabaiana' },
  { state: 'Sergipe', id: 2803005, name: 'Itabaianinha' },
  { state: 'Sergipe', id: 2803104, name: 'Itabi' },
  { state: 'Sergipe', id: 2803203, name: "Itaporanga d'Ajuda" },
  { state: 'Sergipe', id: 2803302, name: 'Japaratuba' },
  { state: 'Sergipe', id: 2803401, name: 'Japoatã' },
  { state: 'Sergipe', id: 2803500, name: 'Lagarto' },
  { state: 'Sergipe', id: 2803609, name: 'Laranjeiras' },
  { state: 'Sergipe', id: 2803708, name: 'Macambira' },
  { state: 'Sergipe', id: 2803807, name: 'Malhada dos Bois' },
  { state: 'Sergipe', id: 2803906, name: 'Malhador' },
  { state: 'Sergipe', id: 2804003, name: 'Maruim' },
  { state: 'Sergipe', id: 2804102, name: 'Moita Bonita' },
  { state: 'Sergipe', id: 2804201, name: 'Monte Alegre de Sergipe' },
  { state: 'Sergipe', id: 2804300, name: 'Muribeca' },
  { state: 'Sergipe', id: 2804409, name: 'Neópolis' },
  { state: 'Sergipe', id: 2804458, name: 'Nossa Senhora Aparecida' },
  { state: 'Sergipe', id: 2804508, name: 'Nossa Senhora da Glória' },
  { state: 'Sergipe', id: 2804607, name: 'Nossa Senhora das Dores' },
  { state: 'Sergipe', id: 2804706, name: 'Nossa Senhora de Lourdes' },
  { state: 'Sergipe', id: 2804805, name: 'Nossa Senhora do Socorro' },
  { state: 'Sergipe', id: 2804904, name: 'Pacatuba' },
  { state: 'Sergipe', id: 2805000, name: 'Pedra Mole' },
  { state: 'Sergipe', id: 2805109, name: 'Pedrinhas' },
  { state: 'Sergipe', id: 2805208, name: 'Pinhão' },
  { state: 'Sergipe', id: 2805307, name: 'Pirambu' },
  { state: 'Sergipe', id: 2805406, name: 'Poço Redondo' },
  { state: 'Sergipe', id: 2805505, name: 'Poço Verde' },
  { state: 'Sergipe', id: 2805604, name: 'Porto da Folha' },
  { state: 'Sergipe', id: 2805703, name: 'Propriá' },
  { state: 'Sergipe', id: 2805802, name: 'Riachão do Dantas' },
  { state: 'Sergipe', id: 2805901, name: 'Riachuelo' },
  { state: 'Sergipe', id: 2806008, name: 'Ribeirópolis' },
  { state: 'Sergipe', id: 2806107, name: 'Rosário do Catete' },
  { state: 'Sergipe', id: 2806206, name: 'Salgado' },
  { state: 'Sergipe', id: 2806305, name: 'Santa Luzia do Itanhy' },
  { state: 'Sergipe', id: 2806503, name: 'Santa Rosa de Lima' },
  { state: 'Sergipe', id: 2806404, name: 'Santana do São Francisco' },
  { state: 'Sergipe', id: 2806602, name: 'Santo Amaro das Brotas' },
  { state: 'Sergipe', id: 2806701, name: 'São Cristóvão' },
  { state: 'Sergipe', id: 2806800, name: 'São Domingos' },
  { state: 'Sergipe', id: 2806909, name: 'São Francisco' },
  { state: 'Sergipe', id: 2807006, name: 'São Miguel do Aleixo' },
  { state: 'Sergipe', id: 2807105, name: 'Simão Dias' },
  { state: 'Sergipe', id: 2807204, name: 'Siriri' },
  { state: 'Sergipe', id: 2807303, name: 'Telha' },
  { state: 'Sergipe', id: 2807402, name: 'Tobias Barreto' },
  { state: 'Sergipe', id: 2807501, name: 'Tomar do Geru' },
  { state: 'Sergipe', id: 2807600, name: 'Umbaúba' },
  { state: 'Bahia', id: 2900108, name: 'Abaíra' },
  { state: 'Bahia', id: 2900207, name: 'Abaré' },
  { state: 'Bahia', id: 2900306, name: 'Acajutiba' },
  { state: 'Bahia', id: 2900355, name: 'Adustina' },
  { state: 'Bahia', id: 2900405, name: 'Água Fria' },
  { state: 'Bahia', id: 2900603, name: 'Aiquara' },
  { state: 'Bahia', id: 2900702, name: 'Alagoinhas' },
  { state: 'Bahia', id: 2900801, name: 'Alcobaça' },
  { state: 'Bahia', id: 2900900, name: 'Almadina' },
  { state: 'Bahia', id: 2901007, name: 'Amargosa' },
  { state: 'Bahia', id: 2901106, name: 'Amélia Rodrigues' },
  { state: 'Bahia', id: 2901155, name: 'América Dourada' },
  { state: 'Bahia', id: 2901205, name: 'Anagé' },
  { state: 'Bahia', id: 2901304, name: 'Andaraí' },
  { state: 'Bahia', id: 2901353, name: 'Andorinha' },
  { state: 'Bahia', id: 2901403, name: 'Angical' },
  { state: 'Bahia', id: 2901502, name: 'Anguera' },
  { state: 'Bahia', id: 2901601, name: 'Antas' },
  { state: 'Bahia', id: 2901700, name: 'Antônio Cardoso' },
  { state: 'Bahia', id: 2901809, name: 'Antônio Gonçalves' },
  { state: 'Bahia', id: 2901908, name: 'Aporá' },
  { state: 'Bahia', id: 2901957, name: 'Apuarema' },
  { state: 'Bahia', id: 2902054, name: 'Araçás' },
  { state: 'Bahia', id: 2902005, name: 'Aracatu' },
  { state: 'Bahia', id: 2902104, name: 'Araci' },
  { state: 'Bahia', id: 2902203, name: 'Aramari' },
  { state: 'Bahia', id: 2902252, name: 'Arataca' },
  { state: 'Bahia', id: 2902302, name: 'Aratuípe' },
  { state: 'Bahia', id: 2902401, name: 'Aurelino Leal' },
  { state: 'Bahia', id: 2902500, name: 'Baianópolis' },
  { state: 'Bahia', id: 2902609, name: 'Baixa Grande' },
  { state: 'Bahia', id: 2902658, name: 'Banzaê' },
  { state: 'Bahia', id: 2902708, name: 'Barra' },
  { state: 'Bahia', id: 2902807, name: 'Barra da Estiva' },
  { state: 'Bahia', id: 2902906, name: 'Barra do Choça' },
  { state: 'Bahia', id: 2903003, name: 'Barra do Mendes' },
  { state: 'Bahia', id: 2903102, name: 'Barra do Rocha' },
  { state: 'Bahia', id: 2903201, name: 'Barreiras' },
  { state: 'Bahia', id: 2903235, name: 'Barro Alto' },
  { state: 'Bahia', id: 2903300, name: 'Barro Preto' },
  { state: 'Bahia', id: 2903276, name: 'Barrocas' },
  { state: 'Bahia', id: 2903409, name: 'Belmonte' },
  { state: 'Bahia', id: 2903508, name: 'Belo Campo' },
  { state: 'Bahia', id: 2903607, name: 'Biritinga' },
  { state: 'Bahia', id: 2903706, name: 'Boa Nova' },
  { state: 'Bahia', id: 2903805, name: 'Boa Vista do Tupim' },
  { state: 'Bahia', id: 2903904, name: 'Bom Jesus da Lapa' },
  { state: 'Bahia', id: 2903953, name: 'Bom Jesus da Serra' },
  { state: 'Bahia', id: 2904001, name: 'Boninal' },
  { state: 'Bahia', id: 2904050, name: 'Bonito' },
  { state: 'Bahia', id: 2904100, name: 'Boquira' },
  { state: 'Bahia', id: 2904209, name: 'Botuporã' },
  { state: 'Bahia', id: 2904308, name: 'Brejões' },
  { state: 'Bahia', id: 2904407, name: 'Brejolândia' },
  { state: 'Bahia', id: 2904506, name: 'Brotas de Macaúbas' },
  { state: 'Bahia', id: 2904605, name: 'Brumado' },
  { state: 'Bahia', id: 2904704, name: 'Buerarema' },
  { state: 'Bahia', id: 2904753, name: 'Buritirama' },
  { state: 'Bahia', id: 2904803, name: 'Caatiba' },
  { state: 'Bahia', id: 2904852, name: 'Cabaceiras do Paraguaçu' },
  { state: 'Bahia', id: 2904902, name: 'Cachoeira' },
  { state: 'Bahia', id: 2905008, name: 'Caculé' },
  { state: 'Bahia', id: 2905107, name: 'Caém' },
  { state: 'Bahia', id: 2905156, name: 'Caetanos' },
  { state: 'Bahia', id: 2905206, name: 'Caetité' },
  { state: 'Bahia', id: 2905305, name: 'Cafarnaum' },
  { state: 'Bahia', id: 2905404, name: 'Cairu' },
  { state: 'Bahia', id: 2905503, name: 'Caldeirão Grande' },
  { state: 'Bahia', id: 2905602, name: 'Camacan' },
  { state: 'Bahia', id: 2905701, name: 'Camaçari' },
  { state: 'Bahia', id: 2905800, name: 'Camamu' },
  { state: 'Bahia', id: 2905909, name: 'Campo Alegre de Lourdes' },
  { state: 'Bahia', id: 2906006, name: 'Campo Formoso' },
  { state: 'Bahia', id: 2906105, name: 'Canápolis' },
  { state: 'Bahia', id: 2906204, name: 'Canarana' },
  { state: 'Bahia', id: 2906303, name: 'Canavieiras' },
  { state: 'Bahia', id: 2906402, name: 'Candeal' },
  { state: 'Bahia', id: 2906501, name: 'Candeias' },
  { state: 'Bahia', id: 2906600, name: 'Candiba' },
  { state: 'Bahia', id: 2906709, name: 'Cândido Sales' },
  { state: 'Bahia', id: 2906808, name: 'Cansanção' },
  { state: 'Bahia', id: 2906824, name: 'Canudos' },
  { state: 'Bahia', id: 2906857, name: 'Capela do Alto Alegre' },
  { state: 'Bahia', id: 2906873, name: 'Capim Grosso' },
  { state: 'Bahia', id: 2906899, name: 'Caraíbas' },
  { state: 'Bahia', id: 2906907, name: 'Caravelas' },
  { state: 'Bahia', id: 2907004, name: 'Cardeal da Silva' },
  { state: 'Bahia', id: 2907103, name: 'Carinhanha' },
  { state: 'Bahia', id: 2907202, name: 'Casa Nova' },
  { state: 'Bahia', id: 2907301, name: 'Castro Alves' },
  { state: 'Bahia', id: 2907400, name: 'Catolândia' },
  { state: 'Bahia', id: 2907509, name: 'Catu' },
  { state: 'Bahia', id: 2907558, name: 'Caturama' },
  { state: 'Bahia', id: 2907608, name: 'Central' },
  { state: 'Bahia', id: 2907707, name: 'Chorrochó' },
  { state: 'Bahia', id: 2907806, name: 'Cícero Dantas' },
  { state: 'Bahia', id: 2907905, name: 'Cipó' },
  { state: 'Bahia', id: 2908002, name: 'Coaraci' },
  { state: 'Bahia', id: 2908101, name: 'Cocos' },
  { state: 'Bahia', id: 2908200, name: 'Conceição da Feira' },
  { state: 'Bahia', id: 2908309, name: 'Conceição do Almeida' },
  { state: 'Bahia', id: 2908408, name: 'Conceição do Coité' },
  { state: 'Bahia', id: 2908507, name: 'Conceição do Jacuípe' },
  { state: 'Bahia', id: 2908606, name: 'Conde' },
  { state: 'Bahia', id: 2908705, name: 'Condeúba' },
  { state: 'Bahia', id: 2908804, name: 'Contendas do Sincorá' },
  { state: 'Bahia', id: 2908903, name: 'Coração de Maria' },
  { state: 'Bahia', id: 2909000, name: 'Cordeiros' },
  { state: 'Bahia', id: 2909109, name: 'Coribe' },
  { state: 'Bahia', id: 2909208, name: 'Coronel João Sá' },
  { state: 'Bahia', id: 2909307, name: 'Correntina' },
  { state: 'Bahia', id: 2909406, name: 'Cotegipe' },
  { state: 'Bahia', id: 2909505, name: 'Cravolândia' },
  { state: 'Bahia', id: 2909604, name: 'Crisópolis' },
  { state: 'Bahia', id: 2909703, name: 'Cristópolis' },
  { state: 'Bahia', id: 2909802, name: 'Cruz das Almas' },
  { state: 'Bahia', id: 2909901, name: 'Curaçá' },
  { state: 'Bahia', id: 2910008, name: 'Dário Meira' },
  { state: 'Bahia', id: 2910057, name: "Dias d'Ávila" },
  { state: 'Bahia', id: 2910107, name: 'Dom Basílio' },
  { state: 'Bahia', id: 2910206, name: 'Dom Macedo Costa' },
  { state: 'Bahia', id: 2910305, name: 'Elísio Medrado' },
  { state: 'Bahia', id: 2910404, name: 'Encruzilhada' },
  { state: 'Bahia', id: 2910503, name: 'Entre Rios' },
  { state: 'Bahia', id: 2900504, name: 'Érico Cardoso' },
  { state: 'Bahia', id: 2910602, name: 'Esplanada' },
  { state: 'Bahia', id: 2910701, name: 'Euclides da Cunha' },
  { state: 'Bahia', id: 2910727, name: 'Eunápolis' },
  { state: 'Bahia', id: 2910750, name: 'Fátima' },
  { state: 'Bahia', id: 2910776, name: 'Feira da Mata' },
  { state: 'Bahia', id: 2910800, name: 'Feira de Santana' },
  { state: 'Bahia', id: 2910859, name: 'Filadélfia' },
  { state: 'Bahia', id: 2910909, name: 'Firmino Alves' },
  { state: 'Bahia', id: 2911006, name: 'Floresta Azul' },
  { state: 'Bahia', id: 2911105, name: 'Formosa do Rio Preto' },
  { state: 'Bahia', id: 2911204, name: 'Gandu' },
  { state: 'Bahia', id: 2911253, name: 'Gavião' },
  { state: 'Bahia', id: 2911303, name: 'Gentio do Ouro' },
  { state: 'Bahia', id: 2911402, name: 'Glória' },
  { state: 'Bahia', id: 2911501, name: 'Gongogi' },
  { state: 'Bahia', id: 2911600, name: 'Governador Mangabeira' },
  { state: 'Bahia', id: 2911659, name: 'Guajeru' },
  { state: 'Bahia', id: 2911709, name: 'Guanambi' },
  { state: 'Bahia', id: 2911808, name: 'Guaratinga' },
  { state: 'Bahia', id: 2911857, name: 'Heliópolis' },
  { state: 'Bahia', id: 2911907, name: 'Iaçu' },
  { state: 'Bahia', id: 2912004, name: 'Ibiassucê' },
  { state: 'Bahia', id: 2912103, name: 'Ibicaraí' },
  { state: 'Bahia', id: 2912202, name: 'Ibicoara' },
  { state: 'Bahia', id: 2912301, name: 'Ibicuí' },
  { state: 'Bahia', id: 2912400, name: 'Ibipeba' },
  { state: 'Bahia', id: 2912509, name: 'Ibipitanga' },
  { state: 'Bahia', id: 2912608, name: 'Ibiquera' },
  { state: 'Bahia', id: 2912707, name: 'Ibirapitanga' },
  { state: 'Bahia', id: 2912806, name: 'Ibirapuã' },
  { state: 'Bahia', id: 2912905, name: 'Ibirataia' },
  { state: 'Bahia', id: 2913002, name: 'Ibitiara' },
  { state: 'Bahia', id: 2913101, name: 'Ibititá' },
  { state: 'Bahia', id: 2913200, name: 'Ibotirama' },
  { state: 'Bahia', id: 2913309, name: 'Ichu' },
  { state: 'Bahia', id: 2913408, name: 'Igaporã' },
  { state: 'Bahia', id: 2913457, name: 'Igrapiúna' },
  { state: 'Bahia', id: 2913507, name: 'Iguaí' },
  { state: 'Bahia', id: 2913606, name: 'Ilhéus' },
  { state: 'Bahia', id: 2913705, name: 'Inhambupe' },
  { state: 'Bahia', id: 2913804, name: 'Ipecaetá' },
  { state: 'Bahia', id: 2913903, name: 'Ipiaú' },
  { state: 'Bahia', id: 2914000, name: 'Ipirá' },
  { state: 'Bahia', id: 2914109, name: 'Ipupiara' },
  { state: 'Bahia', id: 2914208, name: 'Irajuba' },
  { state: 'Bahia', id: 2914307, name: 'Iramaia' },
  { state: 'Bahia', id: 2914406, name: 'Iraquara' },
  { state: 'Bahia', id: 2914505, name: 'Irará' },
  { state: 'Bahia', id: 2914604, name: 'Irecê' },
  { state: 'Bahia', id: 2914653, name: 'Itabela' },
  { state: 'Bahia', id: 2914703, name: 'Itaberaba' },
  { state: 'Bahia', id: 2914802, name: 'Itabuna' },
  { state: 'Bahia', id: 2914901, name: 'Itacaré' },
  { state: 'Bahia', id: 2915007, name: 'Itaeté' },
  { state: 'Bahia', id: 2915106, name: 'Itagi' },
  { state: 'Bahia', id: 2915205, name: 'Itagibá' },
  { state: 'Bahia', id: 2915304, name: 'Itagimirim' },
  { state: 'Bahia', id: 2915353, name: 'Itaguaçu da Bahia' },
  { state: 'Bahia', id: 2915403, name: 'Itaju do Colônia' },
  { state: 'Bahia', id: 2915502, name: 'Itajuípe' },
  { state: 'Bahia', id: 2915601, name: 'Itamaraju' },
  { state: 'Bahia', id: 2915700, name: 'Itamari' },
  { state: 'Bahia', id: 2915809, name: 'Itambé' },
  { state: 'Bahia', id: 2915908, name: 'Itanagra' },
  { state: 'Bahia', id: 2916005, name: 'Itanhém' },
  { state: 'Bahia', id: 2916104, name: 'Itaparica' },
  { state: 'Bahia', id: 2916203, name: 'Itapé' },
  { state: 'Bahia', id: 2916302, name: 'Itapebi' },
  { state: 'Bahia', id: 2916401, name: 'Itapetinga' },
  { state: 'Bahia', id: 2916500, name: 'Itapicuru' },
  { state: 'Bahia', id: 2916609, name: 'Itapitanga' },
  { state: 'Bahia', id: 2916708, name: 'Itaquara' },
  { state: 'Bahia', id: 2916807, name: 'Itarantim' },
  { state: 'Bahia', id: 2916856, name: 'Itatim' },
  { state: 'Bahia', id: 2916906, name: 'Itiruçu' },
  { state: 'Bahia', id: 2917003, name: 'Itiúba' },
  { state: 'Bahia', id: 2917102, name: 'Itororó' },
  { state: 'Bahia', id: 2917201, name: 'Ituaçu' },
  { state: 'Bahia', id: 2917300, name: 'Ituberá' },
  { state: 'Bahia', id: 2917334, name: 'Iuiu' },
  { state: 'Bahia', id: 2917359, name: 'Jaborandi' },
  { state: 'Bahia', id: 2917409, name: 'Jacaraci' },
  { state: 'Bahia', id: 2917508, name: 'Jacobina' },
  { state: 'Bahia', id: 2917607, name: 'Jaguaquara' },
  { state: 'Bahia', id: 2917706, name: 'Jaguarari' },
  { state: 'Bahia', id: 2917805, name: 'Jaguaripe' },
  { state: 'Bahia', id: 2917904, name: 'Jandaíra' },
  { state: 'Bahia', id: 2918001, name: 'Jequié' },
  { state: 'Bahia', id: 2918100, name: 'Jeremoabo' },
  { state: 'Bahia', id: 2918209, name: 'Jiquiriçá' },
  { state: 'Bahia', id: 2918308, name: 'Jitaúna' },
  { state: 'Bahia', id: 2918357, name: 'João Dourado' },
  { state: 'Bahia', id: 2918407, name: 'Juazeiro' },
  { state: 'Bahia', id: 2918456, name: 'Jucuruçu' },
  { state: 'Bahia', id: 2918506, name: 'Jussara' },
  { state: 'Bahia', id: 2918555, name: 'Jussari' },
  { state: 'Bahia', id: 2918605, name: 'Jussiape' },
  { state: 'Bahia', id: 2918704, name: 'Lafaiete Coutinho' },
  { state: 'Bahia', id: 2918753, name: 'Lagoa Real' },
  { state: 'Bahia', id: 2918803, name: 'Laje' },
  { state: 'Bahia', id: 2918902, name: 'Lajedão' },
  { state: 'Bahia', id: 2919009, name: 'Lajedinho' },
  { state: 'Bahia', id: 2919058, name: 'Lajedo do Tabocal' },
  { state: 'Bahia', id: 2919108, name: 'Lamarão' },
  { state: 'Bahia', id: 2919157, name: 'Lapão' },
  { state: 'Bahia', id: 2919207, name: 'Lauro de Freitas' },
  { state: 'Bahia', id: 2919306, name: 'Lençóis' },
  { state: 'Bahia', id: 2919405, name: 'Licínio de Almeida' },
  { state: 'Bahia', id: 2919504, name: 'Livramento de Nossa Senhora' },
  { state: 'Bahia', id: 2919553, name: 'Luís Eduardo Magalhães' },
  { state: 'Bahia', id: 2919603, name: 'Macajuba' },
  { state: 'Bahia', id: 2919702, name: 'Macarani' },
  { state: 'Bahia', id: 2919801, name: 'Macaúbas' },
  { state: 'Bahia', id: 2919900, name: 'Macururé' },
  { state: 'Bahia', id: 2919926, name: 'Madre de Deus' },
  { state: 'Bahia', id: 2919959, name: 'Maetinga' },
  { state: 'Bahia', id: 2920007, name: 'Maiquinique' },
  { state: 'Bahia', id: 2920106, name: 'Mairi' },
  { state: 'Bahia', id: 2920205, name: 'Malhada' },
  { state: 'Bahia', id: 2920304, name: 'Malhada de Pedras' },
  { state: 'Bahia', id: 2920403, name: 'Manoel Vitorino' },
  { state: 'Bahia', id: 2920452, name: 'Mansidão' },
  { state: 'Bahia', id: 2920502, name: 'Maracás' },
  { state: 'Bahia', id: 2920601, name: 'Maragogipe' },
  { state: 'Bahia', id: 2920700, name: 'Maraú' },
  { state: 'Bahia', id: 2920809, name: 'Marcionílio Souza' },
  { state: 'Bahia', id: 2920908, name: 'Mascote' },
  { state: 'Bahia', id: 2921005, name: 'Mata de São João' },
  { state: 'Bahia', id: 2921054, name: 'Matina' },
  { state: 'Bahia', id: 2921104, name: 'Medeiros Neto' },
  { state: 'Bahia', id: 2921203, name: 'Miguel Calmon' },
  { state: 'Bahia', id: 2921302, name: 'Milagres' },
  { state: 'Bahia', id: 2921401, name: 'Mirangaba' },
  { state: 'Bahia', id: 2921450, name: 'Mirante' },
  { state: 'Bahia', id: 2921500, name: 'Monte Santo' },
  { state: 'Bahia', id: 2921609, name: 'Morpará' },
  { state: 'Bahia', id: 2921708, name: 'Morro do Chapéu' },
  { state: 'Bahia', id: 2921807, name: 'Mortugaba' },
  { state: 'Bahia', id: 2921906, name: 'Mucugê' },
  { state: 'Bahia', id: 2922003, name: 'Mucuri' },
  { state: 'Bahia', id: 2922052, name: 'Mulungu do Morro' },
  { state: 'Bahia', id: 2922102, name: 'Mundo Novo' },
  { state: 'Bahia', id: 2922201, name: 'Muniz Ferreira' },
  { state: 'Bahia', id: 2922250, name: 'Muquém do São Francisco' },
  { state: 'Bahia', id: 2922300, name: 'Muritiba' },
  { state: 'Bahia', id: 2922409, name: 'Mutuípe' },
  { state: 'Bahia', id: 2922508, name: 'Nazaré' },
  { state: 'Bahia', id: 2922607, name: 'Nilo Peçanha' },
  { state: 'Bahia', id: 2922656, name: 'Nordestina' },
  { state: 'Bahia', id: 2922706, name: 'Nova Canaã' },
  { state: 'Bahia', id: 2922730, name: 'Nova Fátima' },
  { state: 'Bahia', id: 2922755, name: 'Nova Ibiá' },
  { state: 'Bahia', id: 2922805, name: 'Nova Itarana' },
  { state: 'Bahia', id: 2922854, name: 'Nova Redenção' },
  { state: 'Bahia', id: 2922904, name: 'Nova Soure' },
  { state: 'Bahia', id: 2923001, name: 'Nova Viçosa' },
  { state: 'Bahia', id: 2923035, name: 'Novo Horizonte' },
  { state: 'Bahia', id: 2923050, name: 'Novo Triunfo' },
  { state: 'Bahia', id: 2923100, name: 'Olindina' },
  { state: 'Bahia', id: 2923209, name: 'Oliveira dos Brejinhos' },
  { state: 'Bahia', id: 2923308, name: 'Ouriçangas' },
  { state: 'Bahia', id: 2923357, name: 'Ourolândia' },
  { state: 'Bahia', id: 2923407, name: 'Palmas de Monte Alto' },
  { state: 'Bahia', id: 2923506, name: 'Palmeiras' },
  { state: 'Bahia', id: 2923605, name: 'Paramirim' },
  { state: 'Bahia', id: 2923704, name: 'Paratinga' },
  { state: 'Bahia', id: 2923803, name: 'Paripiranga' },
  { state: 'Bahia', id: 2923902, name: 'Pau Brasil' },
  { state: 'Bahia', id: 2924009, name: 'Paulo Afonso' },
  { state: 'Bahia', id: 2924058, name: 'Pé de Serra' },
  { state: 'Bahia', id: 2924108, name: 'Pedrão' },
  { state: 'Bahia', id: 2924207, name: 'Pedro Alexandre' },
  { state: 'Bahia', id: 2924306, name: 'Piatã' },
  { state: 'Bahia', id: 2924405, name: 'Pilão Arcado' },
  { state: 'Bahia', id: 2924504, name: 'Pindaí' },
  { state: 'Bahia', id: 2924603, name: 'Pindobaçu' },
  { state: 'Bahia', id: 2924652, name: 'Pintadas' },
  { state: 'Bahia', id: 2924678, name: 'Piraí do Norte' },
  { state: 'Bahia', id: 2924702, name: 'Piripá' },
  { state: 'Bahia', id: 2924801, name: 'Piritiba' },
  { state: 'Bahia', id: 2924900, name: 'Planaltino' },
  { state: 'Bahia', id: 2925006, name: 'Planalto' },
  { state: 'Bahia', id: 2925105, name: 'Poções' },
  { state: 'Bahia', id: 2925204, name: 'Pojuca' },
  { state: 'Bahia', id: 2925253, name: 'Ponto Novo' },
  { state: 'Bahia', id: 2925303, name: 'Porto Seguro' },
  { state: 'Bahia', id: 2925402, name: 'Potiraguá' },
  { state: 'Bahia', id: 2925501, name: 'Prado' },
  { state: 'Bahia', id: 2925600, name: 'Presidente Dutra' },
  { state: 'Bahia', id: 2925709, name: 'Presidente Jânio Quadros' },
  { state: 'Bahia', id: 2925758, name: 'Presidente Tancredo Neves' },
  { state: 'Bahia', id: 2925808, name: 'Queimadas' },
  { state: 'Bahia', id: 2925907, name: 'Quijingue' },
  { state: 'Bahia', id: 2925931, name: 'Quixabeira' },
  { state: 'Bahia', id: 2925956, name: 'Rafael Jambeiro' },
  { state: 'Bahia', id: 2926004, name: 'Remanso' },
  { state: 'Bahia', id: 2926103, name: 'Retirolândia' },
  { state: 'Bahia', id: 2926202, name: 'Riachão das Neves' },
  { state: 'Bahia', id: 2926301, name: 'Riachão do Jacuípe' },
  { state: 'Bahia', id: 2926400, name: 'Riacho de Santana' },
  { state: 'Bahia', id: 2926509, name: 'Ribeira do Amparo' },
  { state: 'Bahia', id: 2926608, name: 'Ribeira do Pombal' },
  { state: 'Bahia', id: 2926657, name: 'Ribeirão do Largo' },
  { state: 'Bahia', id: 2926707, name: 'Rio de Contas' },
  { state: 'Bahia', id: 2926806, name: 'Rio do Antônio' },
  { state: 'Bahia', id: 2926905, name: 'Rio do Pires' },
  { state: 'Bahia', id: 2927002, name: 'Rio Real' },
  { state: 'Bahia', id: 2927101, name: 'Rodelas' },
  { state: 'Bahia', id: 2927200, name: 'Ruy Barbosa' },
  { state: 'Bahia', id: 2927309, name: 'Salinas da Margarida' },
  { state: 'Bahia', id: 2927408, name: 'Salvador' },
  { state: 'Bahia', id: 2927507, name: 'Santa Bárbara' },
  { state: 'Bahia', id: 2927606, name: 'Santa Brígida' },
  { state: 'Bahia', id: 2927705, name: 'Santa Cruz Cabrália' },
  { state: 'Bahia', id: 2927804, name: 'Santa Cruz da Vitória' },
  { state: 'Bahia', id: 2927903, name: 'Santa Inês' },
  { state: 'Bahia', id: 2928059, name: 'Santa Luzia' },
  { state: 'Bahia', id: 2928109, name: 'Santa Maria da Vitória' },
  { state: 'Bahia', id: 2928406, name: 'Santa Rita de Cássia' },
  { state: 'Bahia', id: 2928505, name: 'Santa Terezinha' },
  { state: 'Bahia', id: 2928000, name: 'Santaluz' },
  { state: 'Bahia', id: 2928208, name: 'Santana' },
  { state: 'Bahia', id: 2928307, name: 'Santanópolis' },
  { state: 'Bahia', id: 2928604, name: 'Santo Amaro' },
  { state: 'Bahia', id: 2928703, name: 'Santo Antônio de Jesus' },
  { state: 'Bahia', id: 2928802, name: 'Santo Estêvão' },
  { state: 'Bahia', id: 2928901, name: 'São Desidério' },
  { state: 'Bahia', id: 2928950, name: 'São Domingos' },
  { state: 'Bahia', id: 2929107, name: 'São Felipe' },
  { state: 'Bahia', id: 2929008, name: 'São Félix' },
  { state: 'Bahia', id: 2929057, name: 'São Félix do Coribe' },
  { state: 'Bahia', id: 2929206, name: 'São Francisco do Conde' },
  { state: 'Bahia', id: 2929255, name: 'São Gabriel' },
  { state: 'Bahia', id: 2929305, name: 'São Gonçalo dos Campos' },
  { state: 'Bahia', id: 2929354, name: 'São José da Vitória' },
  { state: 'Bahia', id: 2929370, name: 'São José do Jacuípe' },
  { state: 'Bahia', id: 2929404, name: 'São Miguel das Matas' },
  { state: 'Bahia', id: 2929503, name: 'São Sebastião do Passé' },
  { state: 'Bahia', id: 2929602, name: 'Sapeaçu' },
  { state: 'Bahia', id: 2929701, name: 'Sátiro Dias' },
  { state: 'Bahia', id: 2929750, name: 'Saubara' },
  { state: 'Bahia', id: 2929800, name: 'Saúde' },
  { state: 'Bahia', id: 2929909, name: 'Seabra' },
  { state: 'Bahia', id: 2930006, name: 'Sebastião Laranjeiras' },
  { state: 'Bahia', id: 2930105, name: 'Senhor do Bonfim' },
  { state: 'Bahia', id: 2930204, name: 'Sento Sé' },
  { state: 'Bahia', id: 2930154, name: 'Serra do Ramalho' },
  { state: 'Bahia', id: 2930303, name: 'Serra Dourada' },
  { state: 'Bahia', id: 2930402, name: 'Serra Preta' },
  { state: 'Bahia', id: 2930501, name: 'Serrinha' },
  { state: 'Bahia', id: 2930600, name: 'Serrolândia' },
  { state: 'Bahia', id: 2930709, name: 'Simões Filho' },
  { state: 'Bahia', id: 2930758, name: 'Sítio do Mato' },
  { state: 'Bahia', id: 2930766, name: 'Sítio do Quinto' },
  { state: 'Bahia', id: 2930774, name: 'Sobradinho' },
  { state: 'Bahia', id: 2930808, name: 'Souto Soares' },
  { state: 'Bahia', id: 2930907, name: 'Tabocas do Brejo Velho' },
  { state: 'Bahia', id: 2931004, name: 'Tanhaçu' },
  { state: 'Bahia', id: 2931053, name: 'Tanque Novo' },
  { state: 'Bahia', id: 2931103, name: 'Tanquinho' },
  { state: 'Bahia', id: 2931202, name: 'Taperoá' },
  { state: 'Bahia', id: 2931301, name: 'Tapiramutá' },
  { state: 'Bahia', id: 2931350, name: 'Teixeira de Freitas' },
  { state: 'Bahia', id: 2931400, name: 'Teodoro Sampaio' },
  { state: 'Bahia', id: 2931509, name: 'Teofilândia' },
  { state: 'Bahia', id: 2931608, name: 'Teolândia' },
  { state: 'Bahia', id: 2931707, name: 'Terra Nova' },
  { state: 'Bahia', id: 2931806, name: 'Tremedal' },
  { state: 'Bahia', id: 2931905, name: 'Tucano' },
  { state: 'Bahia', id: 2932002, name: 'Uauá' },
  { state: 'Bahia', id: 2932101, name: 'Ubaíra' },
  { state: 'Bahia', id: 2932200, name: 'Ubaitaba' },
  { state: 'Bahia', id: 2932309, name: 'Ubatã' },
  { state: 'Bahia', id: 2932408, name: 'Uibaí' },
  { state: 'Bahia', id: 2932457, name: 'Umburanas' },
  { state: 'Bahia', id: 2932507, name: 'Una' },
  { state: 'Bahia', id: 2932606, name: 'Urandi' },
  { state: 'Bahia', id: 2932705, name: 'Uruçuca' },
  { state: 'Bahia', id: 2932804, name: 'Utinga' },
  { state: 'Bahia', id: 2932903, name: 'Valença' },
  { state: 'Bahia', id: 2933000, name: 'Valente' },
  { state: 'Bahia', id: 2933059, name: 'Várzea da Roça' },
  { state: 'Bahia', id: 2933109, name: 'Várzea do Poço' },
  { state: 'Bahia', id: 2933158, name: 'Várzea Nova' },
  { state: 'Bahia', id: 2933174, name: 'Varzedo' },
  { state: 'Bahia', id: 2933208, name: 'Vera Cruz' },
  { state: 'Bahia', id: 2933257, name: 'Vereda' },
  { state: 'Bahia', id: 2933307, name: 'Vitória da Conquista' },
  { state: 'Bahia', id: 2933406, name: 'Wagner' },
  { state: 'Bahia', id: 2933455, name: 'Wanderley' },
  { state: 'Bahia', id: 2933505, name: 'Wenceslau Guimarães' },
  { state: 'Bahia', id: 2933604, name: 'Xique-Xique' },
  { state: 'Minas Gerais', id: 3100104, name: 'Abadia dos Dourados' },
  { state: 'Minas Gerais', id: 3100203, name: 'Abaeté' },
  { state: 'Minas Gerais', id: 3100302, name: 'Abre Campo' },
  { state: 'Minas Gerais', id: 3100401, name: 'Acaiaca' },
  { state: 'Minas Gerais', id: 3100500, name: 'Açucena' },
  { state: 'Minas Gerais', id: 3100609, name: 'Água Boa' },
  { state: 'Minas Gerais', id: 3100708, name: 'Água Comprida' },
  { state: 'Minas Gerais', id: 3100807, name: 'Aguanil' },
  { state: 'Minas Gerais', id: 3100906, name: 'Águas Formosas' },
  { state: 'Minas Gerais', id: 3101003, name: 'Águas Vermelhas' },
  { state: 'Minas Gerais', id: 3101102, name: 'Aimorés' },
  { state: 'Minas Gerais', id: 3101201, name: 'Aiuruoca' },
  { state: 'Minas Gerais', id: 3101300, name: 'Alagoa' },
  { state: 'Minas Gerais', id: 3101409, name: 'Albertina' },
  { state: 'Minas Gerais', id: 3101508, name: 'Além Paraíba' },
  { state: 'Minas Gerais', id: 3101607, name: 'Alfenas' },
  { state: 'Minas Gerais', id: 3101631, name: 'Alfredo Vasconcelos' },
  { state: 'Minas Gerais', id: 3101706, name: 'Almenara' },
  { state: 'Minas Gerais', id: 3101805, name: 'Alpercata' },
  { state: 'Minas Gerais', id: 3101904, name: 'Alpinópolis' },
  { state: 'Minas Gerais', id: 3102001, name: 'Alterosa' },
  { state: 'Minas Gerais', id: 3102050, name: 'Alto Caparaó' },
  { state: 'Minas Gerais', id: 3153509, name: 'Alto Jequitibá' },
  { state: 'Minas Gerais', id: 3102100, name: 'Alto Rio Doce' },
  { state: 'Minas Gerais', id: 3102209, name: 'Alvarenga' },
  { state: 'Minas Gerais', id: 3102308, name: 'Alvinópolis' },
  { state: 'Minas Gerais', id: 3102407, name: 'Alvorada de Minas' },
  { state: 'Minas Gerais', id: 3102506, name: 'Amparo do Serra' },
  { state: 'Minas Gerais', id: 3102605, name: 'Andradas' },
  { state: 'Minas Gerais', id: 3102803, name: 'Andrelândia' },
  { state: 'Minas Gerais', id: 3102852, name: 'Angelândia' },
  { state: 'Minas Gerais', id: 3102902, name: 'Antônio Carlos' },
  { state: 'Minas Gerais', id: 3103009, name: 'Antônio Dias' },
  { state: 'Minas Gerais', id: 3103108, name: 'Antônio Prado de Minas' },
  { state: 'Minas Gerais', id: 3103207, name: 'Araçaí' },
  { state: 'Minas Gerais', id: 3103306, name: 'Aracitaba' },
  { state: 'Minas Gerais', id: 3103405, name: 'Araçuaí' },
  { state: 'Minas Gerais', id: 3103504, name: 'Araguari' },
  { state: 'Minas Gerais', id: 3103603, name: 'Arantina' },
  { state: 'Minas Gerais', id: 3103702, name: 'Araponga' },
  { state: 'Minas Gerais', id: 3103751, name: 'Araporã' },
  { state: 'Minas Gerais', id: 3103801, name: 'Arapuá' },
  { state: 'Minas Gerais', id: 3103900, name: 'Araújos' },
  { state: 'Minas Gerais', id: 3104007, name: 'Araxá' },
  { state: 'Minas Gerais', id: 3104106, name: 'Arceburgo' },
  { state: 'Minas Gerais', id: 3104205, name: 'Arcos' },
  { state: 'Minas Gerais', id: 3104304, name: 'Areado' },
  { state: 'Minas Gerais', id: 3104403, name: 'Argirita' },
  { state: 'Minas Gerais', id: 3104452, name: 'Aricanduva' },
  { state: 'Minas Gerais', id: 3104502, name: 'Arinos' },
  { state: 'Minas Gerais', id: 3104601, name: 'Astolfo Dutra' },
  { state: 'Minas Gerais', id: 3104700, name: 'Ataléia' },
  { state: 'Minas Gerais', id: 3104809, name: 'Augusto de Lima' },
  { state: 'Minas Gerais', id: 3104908, name: 'Baependi' },
  { state: 'Minas Gerais', id: 3105004, name: 'Baldim' },
  { state: 'Minas Gerais', id: 3105103, name: 'Bambuí' },
  { state: 'Minas Gerais', id: 3105202, name: 'Bandeira' },
  { state: 'Minas Gerais', id: 3105301, name: 'Bandeira do Sul' },
  { state: 'Minas Gerais', id: 3105400, name: 'Barão de Cocais' },
  { state: 'Minas Gerais', id: 3105509, name: 'Barão de Monte Alto' },
  { state: 'Minas Gerais', id: 3105608, name: 'Barbacena' },
  { state: 'Minas Gerais', id: 3105707, name: 'Barra Longa' },
  { state: 'Minas Gerais', id: 3105905, name: 'Barroso' },
  { state: 'Minas Gerais', id: 3106002, name: 'Bela Vista de Minas' },
  { state: 'Minas Gerais', id: 3106101, name: 'Belmiro Braga' },
  { state: 'Minas Gerais', id: 3106200, name: 'Belo Horizonte' },
  { state: 'Minas Gerais', id: 3106309, name: 'Belo Oriente' },
  { state: 'Minas Gerais', id: 3106408, name: 'Belo Vale' },
  { state: 'Minas Gerais', id: 3106507, name: 'Berilo' },
  { state: 'Minas Gerais', id: 3106655, name: 'Berizal' },
  { state: 'Minas Gerais', id: 3106606, name: 'Bertópolis' },
  { state: 'Minas Gerais', id: 3106705, name: 'Betim' },
  { state: 'Minas Gerais', id: 3106804, name: 'Bias Fortes' },
  { state: 'Minas Gerais', id: 3106903, name: 'Bicas' },
  { state: 'Minas Gerais', id: 3107000, name: 'Biquinhas' },
  { state: 'Minas Gerais', id: 3107109, name: 'Boa Esperança' },
  { state: 'Minas Gerais', id: 3107208, name: 'Bocaina de Minas' },
  { state: 'Minas Gerais', id: 3107307, name: 'Bocaiúva' },
  { state: 'Minas Gerais', id: 3107406, name: 'Bom Despacho' },
  { state: 'Minas Gerais', id: 3107505, name: 'Bom Jardim de Minas' },
  { state: 'Minas Gerais', id: 3107604, name: 'Bom Jesus da Penha' },
  { state: 'Minas Gerais', id: 3107703, name: 'Bom Jesus do Amparo' },
  { state: 'Minas Gerais', id: 3107802, name: 'Bom Jesus do Galho' },
  { state: 'Minas Gerais', id: 3107901, name: 'Bom Repouso' },
  { state: 'Minas Gerais', id: 3108008, name: 'Bom Sucesso' },
  { state: 'Minas Gerais', id: 3108107, name: 'Bonfim' },
  { state: 'Minas Gerais', id: 3108206, name: 'Bonfinópolis de Minas' },
  { state: 'Minas Gerais', id: 3108255, name: 'Bonito de Minas' },
  { state: 'Minas Gerais', id: 3108305, name: 'Borda da Mata' },
  { state: 'Minas Gerais', id: 3108404, name: 'Botelhos' },
  { state: 'Minas Gerais', id: 3108503, name: 'Botumirim' },
  { state: 'Minas Gerais', id: 3108701, name: 'Brás Pires' },
  { state: 'Minas Gerais', id: 3108552, name: 'Brasilândia de Minas' },
  { state: 'Minas Gerais', id: 3108602, name: 'Brasília de Minas' },
  { state: 'Minas Gerais', id: 3108800, name: 'Braúnas' },
  { state: 'Minas Gerais', id: 3108909, name: 'Brazópolis' },
  { state: 'Minas Gerais', id: 3109006, name: 'Brumadinho' },
  { state: 'Minas Gerais', id: 3109105, name: 'Bueno Brandão' },
  { state: 'Minas Gerais', id: 3109204, name: 'Buenópolis' },
  { state: 'Minas Gerais', id: 3109253, name: 'Bugre' },
  { state: 'Minas Gerais', id: 3109303, name: 'Buritis' },
  { state: 'Minas Gerais', id: 3109402, name: 'Buritizeiro' },
  { state: 'Minas Gerais', id: 3109451, name: 'Cabeceira Grande' },
  { state: 'Minas Gerais', id: 3109501, name: 'Cabo Verde' },
  { state: 'Minas Gerais', id: 3109600, name: 'Cachoeira da Prata' },
  { state: 'Minas Gerais', id: 3109709, name: 'Cachoeira de Minas' },
  { state: 'Minas Gerais', id: 3102704, name: 'Cachoeira de Pajeú' },
  { state: 'Minas Gerais', id: 3109808, name: 'Cachoeira Dourada' },
  { state: 'Minas Gerais', id: 3109907, name: 'Caetanópolis' },
  { state: 'Minas Gerais', id: 3110004, name: 'Caeté' },
  { state: 'Minas Gerais', id: 3110103, name: 'Caiana' },
  { state: 'Minas Gerais', id: 3110202, name: 'Cajuri' },
  { state: 'Minas Gerais', id: 3110301, name: 'Caldas' },
  { state: 'Minas Gerais', id: 3110400, name: 'Camacho' },
  { state: 'Minas Gerais', id: 3110509, name: 'Camanducaia' },
  { state: 'Minas Gerais', id: 3110608, name: 'Cambuí' },
  { state: 'Minas Gerais', id: 3110707, name: 'Cambuquira' },
  { state: 'Minas Gerais', id: 3110806, name: 'Campanário' },
  { state: 'Minas Gerais', id: 3110905, name: 'Campanha' },
  { state: 'Minas Gerais', id: 3111002, name: 'Campestre' },
  { state: 'Minas Gerais', id: 3111101, name: 'Campina Verde' },
  { state: 'Minas Gerais', id: 3111150, name: 'Campo Azul' },
  { state: 'Minas Gerais', id: 3111200, name: 'Campo Belo' },
  { state: 'Minas Gerais', id: 3111309, name: 'Campo do Meio' },
  { state: 'Minas Gerais', id: 3111408, name: 'Campo Florido' },
  { state: 'Minas Gerais', id: 3111507, name: 'Campos Altos' },
  { state: 'Minas Gerais', id: 3111606, name: 'Campos Gerais' },
  { state: 'Minas Gerais', id: 3111903, name: 'Cana Verde' },
  { state: 'Minas Gerais', id: 3111705, name: 'Canaã' },
  { state: 'Minas Gerais', id: 3111804, name: 'Canápolis' },
  { state: 'Minas Gerais', id: 3112000, name: 'Candeias' },
  { state: 'Minas Gerais', id: 3112059, name: 'Cantagalo' },
  { state: 'Minas Gerais', id: 3112109, name: 'Caparaó' },
  { state: 'Minas Gerais', id: 3112208, name: 'Capela Nova' },
  { state: 'Minas Gerais', id: 3112307, name: 'Capelinha' },
  { state: 'Minas Gerais', id: 3112406, name: 'Capetinga' },
  { state: 'Minas Gerais', id: 3112505, name: 'Capim Branco' },
  { state: 'Minas Gerais', id: 3112604, name: 'Capinópolis' },
  { state: 'Minas Gerais', id: 3112653, name: 'Capitão Andrade' },
  { state: 'Minas Gerais', id: 3112703, name: 'Capitão Enéas' },
  { state: 'Minas Gerais', id: 3112802, name: 'Capitólio' },
  { state: 'Minas Gerais', id: 3112901, name: 'Caputira' },
  { state: 'Minas Gerais', id: 3113008, name: 'Caraí' },
  { state: 'Minas Gerais', id: 3113107, name: 'Caranaíba' },
  { state: 'Minas Gerais', id: 3113206, name: 'Carandaí' },
  { state: 'Minas Gerais', id: 3113305, name: 'Carangola' },
  { state: 'Minas Gerais', id: 3113404, name: 'Caratinga' },
  { state: 'Minas Gerais', id: 3113503, name: 'Carbonita' },
  { state: 'Minas Gerais', id: 3113602, name: 'Careaçu' },
  { state: 'Minas Gerais', id: 3113701, name: 'Carlos Chagas' },
  { state: 'Minas Gerais', id: 3113800, name: 'Carmésia' },
  { state: 'Minas Gerais', id: 3113909, name: 'Carmo da Cachoeira' },
  { state: 'Minas Gerais', id: 3114006, name: 'Carmo da Mata' },
  { state: 'Minas Gerais', id: 3114105, name: 'Carmo de Minas' },
  { state: 'Minas Gerais', id: 3114204, name: 'Carmo do Cajuru' },
  { state: 'Minas Gerais', id: 3114303, name: 'Carmo do Paranaíba' },
  { state: 'Minas Gerais', id: 3114402, name: 'Carmo do Rio Claro' },
  { state: 'Minas Gerais', id: 3114501, name: 'Carmópolis de Minas' },
  { state: 'Minas Gerais', id: 3114550, name: 'Carneirinho' },
  { state: 'Minas Gerais', id: 3114600, name: 'Carrancas' },
  { state: 'Minas Gerais', id: 3114709, name: 'Carvalhópolis' },
  { state: 'Minas Gerais', id: 3114808, name: 'Carvalhos' },
  { state: 'Minas Gerais', id: 3114907, name: 'Casa Grande' },
  { state: 'Minas Gerais', id: 3115003, name: 'Cascalho Rico' },
  { state: 'Minas Gerais', id: 3115102, name: 'Cássia' },
  { state: 'Minas Gerais', id: 3115300, name: 'Cataguases' },
  { state: 'Minas Gerais', id: 3115359, name: 'Catas Altas' },
  { state: 'Minas Gerais', id: 3115409, name: 'Catas Altas da Noruega' },
  { state: 'Minas Gerais', id: 3115458, name: 'Catuji' },
  { state: 'Minas Gerais', id: 3115474, name: 'Catuti' },
  { state: 'Minas Gerais', id: 3115508, name: 'Caxambu' },
  { state: 'Minas Gerais', id: 3115607, name: 'Cedro do Abaeté' },
  { state: 'Minas Gerais', id: 3115706, name: 'Central de Minas' },
  { state: 'Minas Gerais', id: 3115805, name: 'Centralina' },
  { state: 'Minas Gerais', id: 3115904, name: 'Chácara' },
  { state: 'Minas Gerais', id: 3116001, name: 'Chalé' },
  { state: 'Minas Gerais', id: 3116100, name: 'Chapada do Norte' },
  { state: 'Minas Gerais', id: 3116159, name: 'Chapada Gaúcha' },
  { state: 'Minas Gerais', id: 3116209, name: 'Chiador' },
  { state: 'Minas Gerais', id: 3116308, name: 'Cipotânea' },
  { state: 'Minas Gerais', id: 3116407, name: 'Claraval' },
  { state: 'Minas Gerais', id: 3116506, name: 'Claro dos Poções' },
  { state: 'Minas Gerais', id: 3116605, name: 'Cláudio' },
  { state: 'Minas Gerais', id: 3116704, name: 'Coimbra' },
  { state: 'Minas Gerais', id: 3116803, name: 'Coluna' },
  { state: 'Minas Gerais', id: 3116902, name: 'Comendador Gomes' },
  { state: 'Minas Gerais', id: 3117009, name: 'Comercinho' },
  { state: 'Minas Gerais', id: 3117108, name: 'Conceição da Aparecida' },
  {
    state: 'Minas Gerais',
    id: 3115201,
    name: 'Conceição da Barra de Minas',
  },
  { state: 'Minas Gerais', id: 3117306, name: 'Conceição das Alagoas' },
  { state: 'Minas Gerais', id: 3117207, name: 'Conceição das Pedras' },
  { state: 'Minas Gerais', id: 3117405, name: 'Conceição de Ipanema' },
  { state: 'Minas Gerais', id: 3117504, name: 'Conceição do Mato Dentro' },
  { state: 'Minas Gerais', id: 3117603, name: 'Conceição do Pará' },
  { state: 'Minas Gerais', id: 3117702, name: 'Conceição do Rio Verde' },
  { state: 'Minas Gerais', id: 3117801, name: 'Conceição dos Ouros' },
  { state: 'Minas Gerais', id: 3117836, name: 'Cônego Marinho' },
  { state: 'Minas Gerais', id: 3117876, name: 'Confins' },
  { state: 'Minas Gerais', id: 3117900, name: 'Congonhal' },
  { state: 'Minas Gerais', id: 3118007, name: 'Congonhas' },
  { state: 'Minas Gerais', id: 3118106, name: 'Congonhas do Norte' },
  { state: 'Minas Gerais', id: 3118205, name: 'Conquista' },
  { state: 'Minas Gerais', id: 3118304, name: 'Conselheiro Lafaiete' },
  { state: 'Minas Gerais', id: 3118403, name: 'Conselheiro Pena' },
  { state: 'Minas Gerais', id: 3118502, name: 'Consolação' },
  { state: 'Minas Gerais', id: 3118601, name: 'Contagem' },
  { state: 'Minas Gerais', id: 3118700, name: 'Coqueiral' },
  { state: 'Minas Gerais', id: 3118809, name: 'Coração de Jesus' },
  { state: 'Minas Gerais', id: 3118908, name: 'Cordisburgo' },
  { state: 'Minas Gerais', id: 3119005, name: 'Cordislândia' },
  { state: 'Minas Gerais', id: 3119104, name: 'Corinto' },
  { state: 'Minas Gerais', id: 3119203, name: 'Coroaci' },
  { state: 'Minas Gerais', id: 3119302, name: 'Coromandel' },
  { state: 'Minas Gerais', id: 3119401, name: 'Coronel Fabriciano' },
  { state: 'Minas Gerais', id: 3119500, name: 'Coronel Murta' },
  { state: 'Minas Gerais', id: 3119609, name: 'Coronel Pacheco' },
  { state: 'Minas Gerais', id: 3119708, name: 'Coronel Xavier Chaves' },
  { state: 'Minas Gerais', id: 3119807, name: 'Córrego Danta' },
  { state: 'Minas Gerais', id: 3119906, name: 'Córrego do Bom Jesus' },
  { state: 'Minas Gerais', id: 3119955, name: 'Córrego Fundo' },
  { state: 'Minas Gerais', id: 3120003, name: 'Córrego Novo' },
  {
    state: 'Minas Gerais',
    id: 3120102,
    name: 'Couto de Magalhães de Minas',
  },
  { state: 'Minas Gerais', id: 3120151, name: 'Crisólita' },
  { state: 'Minas Gerais', id: 3120201, name: 'Cristais' },
  { state: 'Minas Gerais', id: 3120300, name: 'Cristália' },
  { state: 'Minas Gerais', id: 3120409, name: 'Cristiano Otoni' },
  { state: 'Minas Gerais', id: 3120508, name: 'Cristina' },
  { state: 'Minas Gerais', id: 3120607, name: 'Crucilândia' },
  { state: 'Minas Gerais', id: 3120706, name: 'Cruzeiro da Fortaleza' },
  { state: 'Minas Gerais', id: 3120805, name: 'Cruzília' },
  { state: 'Minas Gerais', id: 3120839, name: 'Cuparaque' },
  { state: 'Minas Gerais', id: 3120870, name: 'Curral de Dentro' },
  { state: 'Minas Gerais', id: 3120904, name: 'Curvelo' },
  { state: 'Minas Gerais', id: 3121001, name: 'Datas' },
  { state: 'Minas Gerais', id: 3121100, name: 'Delfim Moreira' },
  { state: 'Minas Gerais', id: 3121209, name: 'Delfinópolis' },
  { state: 'Minas Gerais', id: 3121258, name: 'Delta' },
  { state: 'Minas Gerais', id: 3121308, name: 'Descoberto' },
  { state: 'Minas Gerais', id: 3121407, name: 'Desterro de Entre Rios' },
  { state: 'Minas Gerais', id: 3121506, name: 'Desterro do Melo' },
  { state: 'Minas Gerais', id: 3121605, name: 'Diamantina' },
  { state: 'Minas Gerais', id: 3121704, name: 'Diogo de Vasconcelos' },
  { state: 'Minas Gerais', id: 3121803, name: 'Dionísio' },
  { state: 'Minas Gerais', id: 3121902, name: 'Divinésia' },
  { state: 'Minas Gerais', id: 3122009, name: 'Divino' },
  { state: 'Minas Gerais', id: 3122108, name: 'Divino das Laranjeiras' },
  { state: 'Minas Gerais', id: 3122207, name: 'Divinolândia de Minas' },
  { state: 'Minas Gerais', id: 3122306, name: 'Divinópolis' },
  { state: 'Minas Gerais', id: 3122355, name: 'Divisa Alegre' },
  { state: 'Minas Gerais', id: 3122405, name: 'Divisa Nova' },
  { state: 'Minas Gerais', id: 3122454, name: 'Divisópolis' },
  { state: 'Minas Gerais', id: 3122470, name: 'Dom Bosco' },
  { state: 'Minas Gerais', id: 3122504, name: 'Dom Cavati' },
  { state: 'Minas Gerais', id: 3122603, name: 'Dom Joaquim' },
  { state: 'Minas Gerais', id: 3122702, name: 'Dom Silvério' },
  { state: 'Minas Gerais', id: 3122801, name: 'Dom Viçoso' },
  { state: 'Minas Gerais', id: 3122900, name: 'Dona Eusébia' },
  { state: 'Minas Gerais', id: 3123007, name: 'Dores de Campos' },
  { state: 'Minas Gerais', id: 3123106, name: 'Dores de Guanhães' },
  { state: 'Minas Gerais', id: 3123205, name: 'Dores do Indaiá' },
  { state: 'Minas Gerais', id: 3123304, name: 'Dores do Turvo' },
  { state: 'Minas Gerais', id: 3123403, name: 'Doresópolis' },
  { state: 'Minas Gerais', id: 3123502, name: 'Douradoquara' },
  { state: 'Minas Gerais', id: 3123528, name: 'Durandé' },
  { state: 'Minas Gerais', id: 3123601, name: 'Elói Mendes' },
  { state: 'Minas Gerais', id: 3123700, name: 'Engenheiro Caldas' },
  { state: 'Minas Gerais', id: 3123809, name: 'Engenheiro Navarro' },
  { state: 'Minas Gerais', id: 3123858, name: 'Entre Folhas' },
  { state: 'Minas Gerais', id: 3123908, name: 'Entre Rios de Minas' },
  { state: 'Minas Gerais', id: 3124005, name: 'Ervália' },
  { state: 'Minas Gerais', id: 3124104, name: 'Esmeraldas' },
  { state: 'Minas Gerais', id: 3124203, name: 'Espera Feliz' },
  { state: 'Minas Gerais', id: 3124302, name: 'Espinosa' },
  { state: 'Minas Gerais', id: 3124401, name: 'Espírito Santo do Dourado' },
  { state: 'Minas Gerais', id: 3124500, name: 'Estiva' },
  { state: 'Minas Gerais', id: 3124609, name: 'Estrela Dalva' },
  { state: 'Minas Gerais', id: 3124708, name: 'Estrela do Indaiá' },
  { state: 'Minas Gerais', id: 3124807, name: 'Estrela do Sul' },
  { state: 'Minas Gerais', id: 3124906, name: 'Eugenópolis' },
  { state: 'Minas Gerais', id: 3125002, name: 'Ewbank da Câmara' },
  { state: 'Minas Gerais', id: 3125101, name: 'Extrema' },
  { state: 'Minas Gerais', id: 3125200, name: 'Fama' },
  { state: 'Minas Gerais', id: 3125309, name: 'Faria Lemos' },
  { state: 'Minas Gerais', id: 3125408, name: 'Felício dos Santos' },
  { state: 'Minas Gerais', id: 3125606, name: 'Felisburgo' },
  { state: 'Minas Gerais', id: 3125705, name: 'Felixlândia' },
  { state: 'Minas Gerais', id: 3125804, name: 'Fernandes Tourinho' },
  { state: 'Minas Gerais', id: 3125903, name: 'Ferros' },
  { state: 'Minas Gerais', id: 3125952, name: 'Fervedouro' },
  { state: 'Minas Gerais', id: 3126000, name: 'Florestal' },
  { state: 'Minas Gerais', id: 3126109, name: 'Formiga' },
  { state: 'Minas Gerais', id: 3126208, name: 'Formoso' },
  { state: 'Minas Gerais', id: 3126307, name: 'Fortaleza de Minas' },
  { state: 'Minas Gerais', id: 3126406, name: 'Fortuna de Minas' },
  { state: 'Minas Gerais', id: 3126505, name: 'Francisco Badaró' },
  { state: 'Minas Gerais', id: 3126604, name: 'Francisco Dumont' },
  { state: 'Minas Gerais', id: 3126703, name: 'Francisco Sá' },
  { state: 'Minas Gerais', id: 3126752, name: 'Franciscópolis' },
  { state: 'Minas Gerais', id: 3126802, name: 'Frei Gaspar' },
  { state: 'Minas Gerais', id: 3126901, name: 'Frei Inocêncio' },
  { state: 'Minas Gerais', id: 3126950, name: 'Frei Lagonegro' },
  { state: 'Minas Gerais', id: 3127008, name: 'Fronteira' },
  { state: 'Minas Gerais', id: 3127057, name: 'Fronteira dos Vales' },
  { state: 'Minas Gerais', id: 3127073, name: 'Fruta de Leite' },
  { state: 'Minas Gerais', id: 3127107, name: 'Frutal' },
  { state: 'Minas Gerais', id: 3127206, name: 'Funilândia' },
  { state: 'Minas Gerais', id: 3127305, name: 'Galiléia' },
  { state: 'Minas Gerais', id: 3127339, name: 'Gameleiras' },
  { state: 'Minas Gerais', id: 3127354, name: 'Glaucilândia' },
  { state: 'Minas Gerais', id: 3127370, name: 'Goiabeira' },
  { state: 'Minas Gerais', id: 3127388, name: 'Goianá' },
  { state: 'Minas Gerais', id: 3127404, name: 'Gonçalves' },
  { state: 'Minas Gerais', id: 3127503, name: 'Gonzaga' },
  { state: 'Minas Gerais', id: 3127602, name: 'Gouveia' },
  { state: 'Minas Gerais', id: 3127701, name: 'Governador Valadares' },
  { state: 'Minas Gerais', id: 3127800, name: 'Grão Mogol' },
  { state: 'Minas Gerais', id: 3127909, name: 'Grupiara' },
  { state: 'Minas Gerais', id: 3128006, name: 'Guanhães' },
  { state: 'Minas Gerais', id: 3128105, name: 'Guapé' },
  { state: 'Minas Gerais', id: 3128204, name: 'Guaraciaba' },
  { state: 'Minas Gerais', id: 3128253, name: 'Guaraciama' },
  { state: 'Minas Gerais', id: 3128303, name: 'Guaranésia' },
  { state: 'Minas Gerais', id: 3128402, name: 'Guarani' },
  { state: 'Minas Gerais', id: 3128501, name: 'Guarará' },
  { state: 'Minas Gerais', id: 3128600, name: 'Guarda-Mor' },
  { state: 'Minas Gerais', id: 3128709, name: 'Guaxupé' },
  { state: 'Minas Gerais', id: 3128808, name: 'Guidoval' },
  { state: 'Minas Gerais', id: 3128907, name: 'Guimarânia' },
  { state: 'Minas Gerais', id: 3129004, name: 'Guiricema' },
  { state: 'Minas Gerais', id: 3129103, name: 'Gurinhatã' },
  { state: 'Minas Gerais', id: 3129202, name: 'Heliodora' },
  { state: 'Minas Gerais', id: 3129301, name: 'Iapu' },
  { state: 'Minas Gerais', id: 3129400, name: 'Ibertioga' },
  { state: 'Minas Gerais', id: 3129509, name: 'Ibiá' },
  { state: 'Minas Gerais', id: 3129608, name: 'Ibiaí' },
  { state: 'Minas Gerais', id: 3129657, name: 'Ibiracatu' },
  { state: 'Minas Gerais', id: 3129707, name: 'Ibiraci' },
  { state: 'Minas Gerais', id: 3129806, name: 'Ibirité' },
  { state: 'Minas Gerais', id: 3129905, name: 'Ibitiúra de Minas' },
  { state: 'Minas Gerais', id: 3130002, name: 'Ibituruna' },
  { state: 'Minas Gerais', id: 3130051, name: 'Icaraí de Minas' },
  { state: 'Minas Gerais', id: 3130101, name: 'Igarapé' },
  { state: 'Minas Gerais', id: 3130200, name: 'Igaratinga' },
  { state: 'Minas Gerais', id: 3130309, name: 'Iguatama' },
  { state: 'Minas Gerais', id: 3130408, name: 'Ijaci' },
  { state: 'Minas Gerais', id: 3130507, name: 'Ilicínea' },
  { state: 'Minas Gerais', id: 3130556, name: 'Imbé de Minas' },
  { state: 'Minas Gerais', id: 3130606, name: 'Inconfidentes' },
  { state: 'Minas Gerais', id: 3130655, name: 'Indaiabira' },
  { state: 'Minas Gerais', id: 3130705, name: 'Indianópolis' },
  { state: 'Minas Gerais', id: 3130804, name: 'Ingaí' },
  { state: 'Minas Gerais', id: 3130903, name: 'Inhapim' },
  { state: 'Minas Gerais', id: 3131000, name: 'Inhaúma' },
  { state: 'Minas Gerais', id: 3131109, name: 'Inimutaba' },
  { state: 'Minas Gerais', id: 3131158, name: 'Ipaba' },
  { state: 'Minas Gerais', id: 3131208, name: 'Ipanema' },
  { state: 'Minas Gerais', id: 3131307, name: 'Ipatinga' },
  { state: 'Minas Gerais', id: 3131406, name: 'Ipiaçu' },
  { state: 'Minas Gerais', id: 3131505, name: 'Ipuiúna' },
  { state: 'Minas Gerais', id: 3131604, name: 'Iraí de Minas' },
  { state: 'Minas Gerais', id: 3131703, name: 'Itabira' },
  { state: 'Minas Gerais', id: 3131802, name: 'Itabirinha' },
  { state: 'Minas Gerais', id: 3131901, name: 'Itabirito' },
  { state: 'Minas Gerais', id: 3132008, name: 'Itacambira' },
  { state: 'Minas Gerais', id: 3132107, name: 'Itacarambi' },
  { state: 'Minas Gerais', id: 3132206, name: 'Itaguara' },
  { state: 'Minas Gerais', id: 3132305, name: 'Itaipé' },
  { state: 'Minas Gerais', id: 3132404, name: 'Itajubá' },
  { state: 'Minas Gerais', id: 3132503, name: 'Itamarandiba' },
  { state: 'Minas Gerais', id: 3132602, name: 'Itamarati de Minas' },
  { state: 'Minas Gerais', id: 3132701, name: 'Itambacuri' },
  { state: 'Minas Gerais', id: 3132800, name: 'Itambé do Mato Dentro' },
  { state: 'Minas Gerais', id: 3132909, name: 'Itamogi' },
  { state: 'Minas Gerais', id: 3133006, name: 'Itamonte' },
  { state: 'Minas Gerais', id: 3133105, name: 'Itanhandu' },
  { state: 'Minas Gerais', id: 3133204, name: 'Itanhomi' },
  { state: 'Minas Gerais', id: 3133303, name: 'Itaobim' },
  { state: 'Minas Gerais', id: 3133402, name: 'Itapagipe' },
  { state: 'Minas Gerais', id: 3133501, name: 'Itapecerica' },
  { state: 'Minas Gerais', id: 3133600, name: 'Itapeva' },
  { state: 'Minas Gerais', id: 3133709, name: 'Itatiaiuçu' },
  { state: 'Minas Gerais', id: 3133758, name: 'Itaú de Minas' },
  { state: 'Minas Gerais', id: 3133808, name: 'Itaúna' },
  { state: 'Minas Gerais', id: 3133907, name: 'Itaverava' },
  { state: 'Minas Gerais', id: 3134004, name: 'Itinga' },
  { state: 'Minas Gerais', id: 3134103, name: 'Itueta' },
  { state: 'Minas Gerais', id: 3134202, name: 'Ituiutaba' },
  { state: 'Minas Gerais', id: 3134301, name: 'Itumirim' },
  { state: 'Minas Gerais', id: 3134400, name: 'Iturama' },
  { state: 'Minas Gerais', id: 3134509, name: 'Itutinga' },
  { state: 'Minas Gerais', id: 3134608, name: 'Jaboticatubas' },
  { state: 'Minas Gerais', id: 3134707, name: 'Jacinto' },
  { state: 'Minas Gerais', id: 3134806, name: 'Jacuí' },
  { state: 'Minas Gerais', id: 3134905, name: 'Jacutinga' },
  { state: 'Minas Gerais', id: 3135001, name: 'Jaguaraçu' },
  { state: 'Minas Gerais', id: 3135050, name: 'Jaíba' },
  { state: 'Minas Gerais', id: 3135076, name: 'Jampruca' },
  { state: 'Minas Gerais', id: 3135100, name: 'Janaúba' },
  { state: 'Minas Gerais', id: 3135209, name: 'Januária' },
  { state: 'Minas Gerais', id: 3135308, name: 'Japaraíba' },
  { state: 'Minas Gerais', id: 3135357, name: 'Japonvar' },
  { state: 'Minas Gerais', id: 3135407, name: 'Jeceaba' },
  { state: 'Minas Gerais', id: 3135456, name: 'Jenipapo de Minas' },
  { state: 'Minas Gerais', id: 3135506, name: 'Jequeri' },
  { state: 'Minas Gerais', id: 3135605, name: 'Jequitaí' },
  { state: 'Minas Gerais', id: 3135704, name: 'Jequitibá' },
  { state: 'Minas Gerais', id: 3135803, name: 'Jequitinhonha' },
  { state: 'Minas Gerais', id: 3135902, name: 'Jesuânia' },
  { state: 'Minas Gerais', id: 3136009, name: 'Joaíma' },
  { state: 'Minas Gerais', id: 3136108, name: 'Joanésia' },
  { state: 'Minas Gerais', id: 3136207, name: 'João Monlevade' },
  { state: 'Minas Gerais', id: 3136306, name: 'João Pinheiro' },
  { state: 'Minas Gerais', id: 3136405, name: 'Joaquim Felício' },
  { state: 'Minas Gerais', id: 3136504, name: 'Jordânia' },
  { state: 'Minas Gerais', id: 3136520, name: 'José Gonçalves de Minas' },
  { state: 'Minas Gerais', id: 3136553, name: 'José Raydan' },
  { state: 'Minas Gerais', id: 3136579, name: 'Josenópolis' },
  { state: 'Minas Gerais', id: 3136652, name: 'Juatuba' },
  { state: 'Minas Gerais', id: 3136702, name: 'Juiz de Fora' },
  { state: 'Minas Gerais', id: 3136801, name: 'Juramento' },
  { state: 'Minas Gerais', id: 3136900, name: 'Juruaia' },
  { state: 'Minas Gerais', id: 3136959, name: 'Juvenília' },
  { state: 'Minas Gerais', id: 3137007, name: 'Ladainha' },
  { state: 'Minas Gerais', id: 3137106, name: 'Lagamar' },
  { state: 'Minas Gerais', id: 3137205, name: 'Lagoa da Prata' },
  { state: 'Minas Gerais', id: 3137304, name: 'Lagoa dos Patos' },
  { state: 'Minas Gerais', id: 3137403, name: 'Lagoa Dourada' },
  { state: 'Minas Gerais', id: 3137502, name: 'Lagoa Formosa' },
  { state: 'Minas Gerais', id: 3137536, name: 'Lagoa Grande' },
  { state: 'Minas Gerais', id: 3137601, name: 'Lagoa Santa' },
  { state: 'Minas Gerais', id: 3137700, name: 'Lajinha' },
  { state: 'Minas Gerais', id: 3137809, name: 'Lambari' },
  { state: 'Minas Gerais', id: 3137908, name: 'Lamim' },
  { state: 'Minas Gerais', id: 3138005, name: 'Laranjal' },
  { state: 'Minas Gerais', id: 3138104, name: 'Lassance' },
  { state: 'Minas Gerais', id: 3138203, name: 'Lavras' },
  { state: 'Minas Gerais', id: 3138302, name: 'Leandro Ferreira' },
  { state: 'Minas Gerais', id: 3138351, name: 'Leme do Prado' },
  { state: 'Minas Gerais', id: 3138401, name: 'Leopoldina' },
  { state: 'Minas Gerais', id: 3138500, name: 'Liberdade' },
  { state: 'Minas Gerais', id: 3138609, name: 'Lima Duarte' },
  { state: 'Minas Gerais', id: 3138625, name: 'Limeira do Oeste' },
  { state: 'Minas Gerais', id: 3138658, name: 'Lontra' },
  { state: 'Minas Gerais', id: 3138674, name: 'Luisburgo' },
  { state: 'Minas Gerais', id: 3138682, name: 'Luislândia' },
  { state: 'Minas Gerais', id: 3138708, name: 'Luminárias' },
  { state: 'Minas Gerais', id: 3138807, name: 'Luz' },
  { state: 'Minas Gerais', id: 3138906, name: 'Machacalis' },
  { state: 'Minas Gerais', id: 3139003, name: 'Machado' },
  { state: 'Minas Gerais', id: 3139102, name: 'Madre de Deus de Minas' },
  { state: 'Minas Gerais', id: 3139201, name: 'Malacacheta' },
  { state: 'Minas Gerais', id: 3139250, name: 'Mamonas' },
  { state: 'Minas Gerais', id: 3139300, name: 'Manga' },
  { state: 'Minas Gerais', id: 3139409, name: 'Manhuaçu' },
  { state: 'Minas Gerais', id: 3139508, name: 'Manhumirim' },
  { state: 'Minas Gerais', id: 3139607, name: 'Mantena' },
  { state: 'Minas Gerais', id: 3139805, name: 'Mar de Espanha' },
  { state: 'Minas Gerais', id: 3139706, name: 'Maravilhas' },
  { state: 'Minas Gerais', id: 3139904, name: 'Maria da Fé' },
  { state: 'Minas Gerais', id: 3140001, name: 'Mariana' },
  { state: 'Minas Gerais', id: 3140100, name: 'Marilac' },
  { state: 'Minas Gerais', id: 3140159, name: 'Mário Campos' },
  { state: 'Minas Gerais', id: 3140209, name: 'Maripá de Minas' },
  { state: 'Minas Gerais', id: 3140308, name: 'Marliéria' },
  { state: 'Minas Gerais', id: 3140407, name: 'Marmelópolis' },
  { state: 'Minas Gerais', id: 3140506, name: 'Martinho Campos' },
  { state: 'Minas Gerais', id: 3140530, name: 'Martins Soares' },
  { state: 'Minas Gerais', id: 3140555, name: 'Mata Verde' },
  { state: 'Minas Gerais', id: 3140605, name: 'Materlândia' },
  { state: 'Minas Gerais', id: 3140704, name: 'Mateus Leme' },
  { state: 'Minas Gerais', id: 3171501, name: 'Mathias Lobato' },
  { state: 'Minas Gerais', id: 3140803, name: 'Matias Barbosa' },
  { state: 'Minas Gerais', id: 3140852, name: 'Matias Cardoso' },
  { state: 'Minas Gerais', id: 3140902, name: 'Matipó' },
  { state: 'Minas Gerais', id: 3141009, name: 'Mato Verde' },
  { state: 'Minas Gerais', id: 3141108, name: 'Matozinhos' },
  { state: 'Minas Gerais', id: 3141207, name: 'Matutina' },
  { state: 'Minas Gerais', id: 3141306, name: 'Medeiros' },
  { state: 'Minas Gerais', id: 3141405, name: 'Medina' },
  { state: 'Minas Gerais', id: 3141504, name: 'Mendes Pimentel' },
  { state: 'Minas Gerais', id: 3141603, name: 'Mercês' },
  { state: 'Minas Gerais', id: 3141702, name: 'Mesquita' },
  { state: 'Minas Gerais', id: 3141801, name: 'Minas Novas' },
  { state: 'Minas Gerais', id: 3141900, name: 'Minduri' },
  { state: 'Minas Gerais', id: 3142007, name: 'Mirabela' },
  { state: 'Minas Gerais', id: 3142106, name: 'Miradouro' },
  { state: 'Minas Gerais', id: 3142205, name: 'Miraí' },
  { state: 'Minas Gerais', id: 3142254, name: 'Miravânia' },
  { state: 'Minas Gerais', id: 3142304, name: 'Moeda' },
  { state: 'Minas Gerais', id: 3142403, name: 'Moema' },
  { state: 'Minas Gerais', id: 3142502, name: 'Monjolos' },
  { state: 'Minas Gerais', id: 3142601, name: 'Monsenhor Paulo' },
  { state: 'Minas Gerais', id: 3142700, name: 'Montalvânia' },
  { state: 'Minas Gerais', id: 3142809, name: 'Monte Alegre de Minas' },
  { state: 'Minas Gerais', id: 3142908, name: 'Monte Azul' },
  { state: 'Minas Gerais', id: 3143005, name: 'Monte Belo' },
  { state: 'Minas Gerais', id: 3143104, name: 'Monte Carmelo' },
  { state: 'Minas Gerais', id: 3143153, name: 'Monte Formoso' },
  { state: 'Minas Gerais', id: 3143203, name: 'Monte Santo de Minas' },
  { state: 'Minas Gerais', id: 3143401, name: 'Monte Sião' },
  { state: 'Minas Gerais', id: 3143302, name: 'Montes Claros' },
  { state: 'Minas Gerais', id: 3143450, name: 'Montezuma' },
  { state: 'Minas Gerais', id: 3143500, name: 'Morada Nova de Minas' },
  { state: 'Minas Gerais', id: 3143609, name: 'Morro da Garça' },
  { state: 'Minas Gerais', id: 3143708, name: 'Morro do Pilar' },
  { state: 'Minas Gerais', id: 3143807, name: 'Munhoz' },
  { state: 'Minas Gerais', id: 3143906, name: 'Muriaé' },
  { state: 'Minas Gerais', id: 3144003, name: 'Mutum' },
  { state: 'Minas Gerais', id: 3144102, name: 'Muzambinho' },
  { state: 'Minas Gerais', id: 3144201, name: 'Nacip Raydan' },
  { state: 'Minas Gerais', id: 3144300, name: 'Nanuque' },
  { state: 'Minas Gerais', id: 3144359, name: 'Naque' },
  { state: 'Minas Gerais', id: 3144375, name: 'Natalândia' },
  { state: 'Minas Gerais', id: 3144409, name: 'Natércia' },
  { state: 'Minas Gerais', id: 3144508, name: 'Nazareno' },
  { state: 'Minas Gerais', id: 3144607, name: 'Nepomuceno' },
  { state: 'Minas Gerais', id: 3144656, name: 'Ninheira' },
  { state: 'Minas Gerais', id: 3144672, name: 'Nova Belém' },
  { state: 'Minas Gerais', id: 3144706, name: 'Nova Era' },
  { state: 'Minas Gerais', id: 3144805, name: 'Nova Lima' },
  { state: 'Minas Gerais', id: 3144904, name: 'Nova Módica' },
  { state: 'Minas Gerais', id: 3145000, name: 'Nova Ponte' },
  { state: 'Minas Gerais', id: 3145059, name: 'Nova Porteirinha' },
  { state: 'Minas Gerais', id: 3145109, name: 'Nova Resende' },
  { state: 'Minas Gerais', id: 3145208, name: 'Nova Serrana' },
  { state: 'Minas Gerais', id: 3136603, name: 'Nova União' },
  { state: 'Minas Gerais', id: 3145307, name: 'Novo Cruzeiro' },
  { state: 'Minas Gerais', id: 3145356, name: 'Novo Oriente de Minas' },
  { state: 'Minas Gerais', id: 3145372, name: 'Novorizonte' },
  { state: 'Minas Gerais', id: 3145406, name: 'Olaria' },
  { state: 'Minas Gerais', id: 3145455, name: "Olhos-d'Água" },
  { state: 'Minas Gerais', id: 3145505, name: 'Olímpio Noronha' },
  { state: 'Minas Gerais', id: 3145604, name: 'Oliveira' },
  { state: 'Minas Gerais', id: 3145703, name: 'Oliveira Fortes' },
  { state: 'Minas Gerais', id: 3145802, name: 'Onça de Pitangui' },
  { state: 'Minas Gerais', id: 3145851, name: 'Oratórios' },
  { state: 'Minas Gerais', id: 3145877, name: 'Orizânia' },
  { state: 'Minas Gerais', id: 3145901, name: 'Ouro Branco' },
  { state: 'Minas Gerais', id: 3146008, name: 'Ouro Fino' },
  { state: 'Minas Gerais', id: 3146107, name: 'Ouro Preto' },
  { state: 'Minas Gerais', id: 3146206, name: 'Ouro Verde de Minas' },
  { state: 'Minas Gerais', id: 3146255, name: 'Padre Carvalho' },
  { state: 'Minas Gerais', id: 3146305, name: 'Padre Paraíso' },
  { state: 'Minas Gerais', id: 3146552, name: 'Pai Pedro' },
  { state: 'Minas Gerais', id: 3146404, name: 'Paineiras' },
  { state: 'Minas Gerais', id: 3146503, name: 'Pains' },
  { state: 'Minas Gerais', id: 3146602, name: 'Paiva' },
  { state: 'Minas Gerais', id: 3146701, name: 'Palma' },
  { state: 'Minas Gerais', id: 3146750, name: 'Palmópolis' },
  { state: 'Minas Gerais', id: 3146909, name: 'Papagaios' },
  { state: 'Minas Gerais', id: 3147105, name: 'Pará de Minas' },
  { state: 'Minas Gerais', id: 3147006, name: 'Paracatu' },
  { state: 'Minas Gerais', id: 3147204, name: 'Paraguaçu' },
  { state: 'Minas Gerais', id: 3147303, name: 'Paraisópolis' },
  { state: 'Minas Gerais', id: 3147402, name: 'Paraopeba' },
  { state: 'Minas Gerais', id: 3147600, name: 'Passa Quatro' },
  { state: 'Minas Gerais', id: 3147709, name: 'Passa Tempo' },
  { state: 'Minas Gerais', id: 3147808, name: 'Passa Vinte' },
  { state: 'Minas Gerais', id: 3147501, name: 'Passabém' },
  { state: 'Minas Gerais', id: 3147907, name: 'Passos' },
  { state: 'Minas Gerais', id: 3147956, name: 'Patis' },
  { state: 'Minas Gerais', id: 3148004, name: 'Patos de Minas' },
  { state: 'Minas Gerais', id: 3148103, name: 'Patrocínio' },
  { state: 'Minas Gerais', id: 3148202, name: 'Patrocínio do Muriaé' },
  { state: 'Minas Gerais', id: 3148301, name: 'Paula Cândido' },
  { state: 'Minas Gerais', id: 3148400, name: 'Paulistas' },
  { state: 'Minas Gerais', id: 3148509, name: 'Pavão' },
  { state: 'Minas Gerais', id: 3148608, name: 'Peçanha' },
  { state: 'Minas Gerais', id: 3148707, name: 'Pedra Azul' },
  { state: 'Minas Gerais', id: 3148756, name: 'Pedra Bonita' },
  { state: 'Minas Gerais', id: 3148806, name: 'Pedra do Anta' },
  { state: 'Minas Gerais', id: 3148905, name: 'Pedra do Indaiá' },
  { state: 'Minas Gerais', id: 3149002, name: 'Pedra Dourada' },
  { state: 'Minas Gerais', id: 3149101, name: 'Pedralva' },
  { state: 'Minas Gerais', id: 3149150, name: 'Pedras de Maria da Cruz' },
  { state: 'Minas Gerais', id: 3149200, name: 'Pedrinópolis' },
  { state: 'Minas Gerais', id: 3149309, name: 'Pedro Leopoldo' },
  { state: 'Minas Gerais', id: 3149408, name: 'Pedro Teixeira' },
  { state: 'Minas Gerais', id: 3149507, name: 'Pequeri' },
  { state: 'Minas Gerais', id: 3149606, name: 'Pequi' },
  { state: 'Minas Gerais', id: 3149705, name: 'Perdigão' },
  { state: 'Minas Gerais', id: 3149804, name: 'Perdizes' },
  { state: 'Minas Gerais', id: 3149903, name: 'Perdões' },
  { state: 'Minas Gerais', id: 3149952, name: 'Periquito' },
  { state: 'Minas Gerais', id: 3150000, name: 'Pescador' },
  { state: 'Minas Gerais', id: 3150109, name: 'Piau' },
  { state: 'Minas Gerais', id: 3150158, name: 'Piedade de Caratinga' },
  { state: 'Minas Gerais', id: 3150208, name: 'Piedade de Ponte Nova' },
  { state: 'Minas Gerais', id: 3150307, name: 'Piedade do Rio Grande' },
  { state: 'Minas Gerais', id: 3150406, name: 'Piedade dos Gerais' },
  { state: 'Minas Gerais', id: 3150505, name: 'Pimenta' },
  { state: 'Minas Gerais', id: 3150539, name: "Pingo d'Água" },
  { state: 'Minas Gerais', id: 3150570, name: 'Pintópolis' },
  { state: 'Minas Gerais', id: 3150604, name: 'Piracema' },
  { state: 'Minas Gerais', id: 3150703, name: 'Pirajuba' },
  { state: 'Minas Gerais', id: 3150802, name: 'Piranga' },
  { state: 'Minas Gerais', id: 3150901, name: 'Piranguçu' },
  { state: 'Minas Gerais', id: 3151008, name: 'Piranguinho' },
  { state: 'Minas Gerais', id: 3151107, name: 'Pirapetinga' },
  { state: 'Minas Gerais', id: 3151206, name: 'Pirapora' },
  { state: 'Minas Gerais', id: 3151305, name: 'Piraúba' },
  { state: 'Minas Gerais', id: 3151404, name: 'Pitangui' },
  { state: 'Minas Gerais', id: 3151503, name: 'Piumhi' },
  { state: 'Minas Gerais', id: 3151602, name: 'Planura' },
  { state: 'Minas Gerais', id: 3151701, name: 'Poço Fundo' },
  { state: 'Minas Gerais', id: 3151800, name: 'Poços de Caldas' },
  { state: 'Minas Gerais', id: 3151909, name: 'Pocrane' },
  { state: 'Minas Gerais', id: 3152006, name: 'Pompéu' },
  { state: 'Minas Gerais', id: 3152105, name: 'Ponte Nova' },
  { state: 'Minas Gerais', id: 3152131, name: 'Ponto Chique' },
  { state: 'Minas Gerais', id: 3152170, name: 'Ponto dos Volantes' },
  { state: 'Minas Gerais', id: 3152204, name: 'Porteirinha' },
  { state: 'Minas Gerais', id: 3152303, name: 'Porto Firme' },
  { state: 'Minas Gerais', id: 3152402, name: 'Poté' },
  { state: 'Minas Gerais', id: 3152501, name: 'Pouso Alegre' },
  { state: 'Minas Gerais', id: 3152600, name: 'Pouso Alto' },
  { state: 'Minas Gerais', id: 3152709, name: 'Prados' },
  { state: 'Minas Gerais', id: 3152808, name: 'Prata' },
  { state: 'Minas Gerais', id: 3152907, name: 'Pratápolis' },
  { state: 'Minas Gerais', id: 3153004, name: 'Pratinha' },
  { state: 'Minas Gerais', id: 3153103, name: 'Presidente Bernardes' },
  { state: 'Minas Gerais', id: 3153202, name: 'Presidente Juscelino' },
  { state: 'Minas Gerais', id: 3153301, name: 'Presidente Kubitschek' },
  { state: 'Minas Gerais', id: 3153400, name: 'Presidente Olegário' },
  { state: 'Minas Gerais', id: 3153608, name: 'Prudente de Morais' },
  { state: 'Minas Gerais', id: 3153707, name: 'Quartel Geral' },
  { state: 'Minas Gerais', id: 3153806, name: 'Queluzito' },
  { state: 'Minas Gerais', id: 3153905, name: 'Raposos' },
  { state: 'Minas Gerais', id: 3154002, name: 'Raul Soares' },
  { state: 'Minas Gerais', id: 3154101, name: 'Recreio' },
  { state: 'Minas Gerais', id: 3154150, name: 'Reduto' },
  { state: 'Minas Gerais', id: 3154200, name: 'Resende Costa' },
  { state: 'Minas Gerais', id: 3154309, name: 'Resplendor' },
  { state: 'Minas Gerais', id: 3154408, name: 'Ressaquinha' },
  { state: 'Minas Gerais', id: 3154457, name: 'Riachinho' },
  { state: 'Minas Gerais', id: 3154507, name: 'Riacho dos Machados' },
  { state: 'Minas Gerais', id: 3154606, name: 'Ribeirão das Neves' },
  { state: 'Minas Gerais', id: 3154705, name: 'Ribeirão Vermelho' },
  { state: 'Minas Gerais', id: 3154804, name: 'Rio Acima' },
  { state: 'Minas Gerais', id: 3154903, name: 'Rio Casca' },
  { state: 'Minas Gerais', id: 3155108, name: 'Rio do Prado' },
  { state: 'Minas Gerais', id: 3155009, name: 'Rio Doce' },
  { state: 'Minas Gerais', id: 3155207, name: 'Rio Espera' },
  { state: 'Minas Gerais', id: 3155306, name: 'Rio Manso' },
  { state: 'Minas Gerais', id: 3155405, name: 'Rio Novo' },
  { state: 'Minas Gerais', id: 3155504, name: 'Rio Paranaíba' },
  { state: 'Minas Gerais', id: 3155603, name: 'Rio Pardo de Minas' },
  { state: 'Minas Gerais', id: 3155702, name: 'Rio Piracicaba' },
  { state: 'Minas Gerais', id: 3155801, name: 'Rio Pomba' },
  { state: 'Minas Gerais', id: 3155900, name: 'Rio Preto' },
  { state: 'Minas Gerais', id: 3156007, name: 'Rio Vermelho' },
  { state: 'Minas Gerais', id: 3156106, name: 'Ritápolis' },
  { state: 'Minas Gerais', id: 3156205, name: 'Rochedo de Minas' },
  { state: 'Minas Gerais', id: 3156304, name: 'Rodeiro' },
  { state: 'Minas Gerais', id: 3156403, name: 'Romaria' },
  { state: 'Minas Gerais', id: 3156452, name: 'Rosário da Limeira' },
  { state: 'Minas Gerais', id: 3156502, name: 'Rubelita' },
  { state: 'Minas Gerais', id: 3156601, name: 'Rubim' },
  { state: 'Minas Gerais', id: 3156700, name: 'Sabará' },
  { state: 'Minas Gerais', id: 3156809, name: 'Sabinópolis' },
  { state: 'Minas Gerais', id: 3156908, name: 'Sacramento' },
  { state: 'Minas Gerais', id: 3157005, name: 'Salinas' },
  { state: 'Minas Gerais', id: 3157104, name: 'Salto da Divisa' },
  { state: 'Minas Gerais', id: 3157203, name: 'Santa Bárbara' },
  { state: 'Minas Gerais', id: 3157252, name: 'Santa Bárbara do Leste' },
  {
    state: 'Minas Gerais',
    id: 3157278,
    name: 'Santa Bárbara do Monte Verde',
  },
  { state: 'Minas Gerais', id: 3157302, name: 'Santa Bárbara do Tugúrio' },
  { state: 'Minas Gerais', id: 3157336, name: 'Santa Cruz de Minas' },
  { state: 'Minas Gerais', id: 3157377, name: 'Santa Cruz de Salinas' },
  { state: 'Minas Gerais', id: 3157401, name: 'Santa Cruz do Escalvado' },
  { state: 'Minas Gerais', id: 3157500, name: 'Santa Efigênia de Minas' },
  { state: 'Minas Gerais', id: 3157609, name: 'Santa Fé de Minas' },
  { state: 'Minas Gerais', id: 3157658, name: 'Santa Helena de Minas' },
  { state: 'Minas Gerais', id: 3157708, name: 'Santa Juliana' },
  { state: 'Minas Gerais', id: 3157807, name: 'Santa Luzia' },
  { state: 'Minas Gerais', id: 3157906, name: 'Santa Margarida' },
  { state: 'Minas Gerais', id: 3158003, name: 'Santa Maria de Itabira' },
  { state: 'Minas Gerais', id: 3158102, name: 'Santa Maria do Salto' },
  { state: 'Minas Gerais', id: 3158201, name: 'Santa Maria do Suaçuí' },
  { state: 'Minas Gerais', id: 3159209, name: 'Santa Rita de Caldas' },
  { state: 'Minas Gerais', id: 3159407, name: 'Santa Rita de Ibitipoca' },
  { state: 'Minas Gerais', id: 3159308, name: 'Santa Rita de Jacutinga' },
  { state: 'Minas Gerais', id: 3159357, name: 'Santa Rita de Minas' },
  { state: 'Minas Gerais', id: 3159506, name: 'Santa Rita do Itueto' },
  { state: 'Minas Gerais', id: 3159605, name: 'Santa Rita do Sapucaí' },
  { state: 'Minas Gerais', id: 3159704, name: 'Santa Rosa da Serra' },
  { state: 'Minas Gerais', id: 3159803, name: 'Santa Vitória' },
  { state: 'Minas Gerais', id: 3158300, name: 'Santana da Vargem' },
  { state: 'Minas Gerais', id: 3158409, name: 'Santana de Cataguases' },
  { state: 'Minas Gerais', id: 3158508, name: 'Santana de Pirapama' },
  { state: 'Minas Gerais', id: 3158607, name: 'Santana do Deserto' },
  { state: 'Minas Gerais', id: 3158706, name: 'Santana do Garambéu' },
  { state: 'Minas Gerais', id: 3158805, name: 'Santana do Jacaré' },
  { state: 'Minas Gerais', id: 3158904, name: 'Santana do Manhuaçu' },
  { state: 'Minas Gerais', id: 3158953, name: 'Santana do Paraíso' },
  { state: 'Minas Gerais', id: 3159001, name: 'Santana do Riacho' },
  { state: 'Minas Gerais', id: 3159100, name: 'Santana dos Montes' },
  { state: 'Minas Gerais', id: 3159902, name: 'Santo Antônio do Amparo' },
  {
    state: 'Minas Gerais',
    id: 3160009,
    name: 'Santo Antônio do Aventureiro',
  },
  { state: 'Minas Gerais', id: 3160108, name: 'Santo Antônio do Grama' },
  { state: 'Minas Gerais', id: 3160207, name: 'Santo Antônio do Itambé' },
  { state: 'Minas Gerais', id: 3160306, name: 'Santo Antônio do Jacinto' },
  { state: 'Minas Gerais', id: 3160405, name: 'Santo Antônio do Monte' },
  { state: 'Minas Gerais', id: 3160454, name: 'Santo Antônio do Retiro' },
  {
    state: 'Minas Gerais',
    id: 3160504,
    name: 'Santo Antônio do Rio Abaixo',
  },
  { state: 'Minas Gerais', id: 3160603, name: 'Santo Hipólito' },
  { state: 'Minas Gerais', id: 3160702, name: 'Santos Dumont' },
  { state: 'Minas Gerais', id: 3160801, name: 'São Bento Abade' },
  { state: 'Minas Gerais', id: 3160900, name: 'São Brás do Suaçuí' },
  { state: 'Minas Gerais', id: 3160959, name: 'São Domingos das Dores' },
  { state: 'Minas Gerais', id: 3161007, name: 'São Domingos do Prata' },
  { state: 'Minas Gerais', id: 3161056, name: 'São Félix de Minas' },
  { state: 'Minas Gerais', id: 3161106, name: 'São Francisco' },
  { state: 'Minas Gerais', id: 3161205, name: 'São Francisco de Paula' },
  { state: 'Minas Gerais', id: 3161304, name: 'São Francisco de Sales' },
  { state: 'Minas Gerais', id: 3161403, name: 'São Francisco do Glória' },
  { state: 'Minas Gerais', id: 3161502, name: 'São Geraldo' },
  { state: 'Minas Gerais', id: 3161601, name: 'São Geraldo da Piedade' },
  { state: 'Minas Gerais', id: 3161650, name: 'São Geraldo do Baixio' },
  { state: 'Minas Gerais', id: 3161700, name: 'São Gonçalo do Abaeté' },
  { state: 'Minas Gerais', id: 3161809, name: 'São Gonçalo do Pará' },
  { state: 'Minas Gerais', id: 3161908, name: 'São Gonçalo do Rio Abaixo' },
  { state: 'Minas Gerais', id: 3125507, name: 'São Gonçalo do Rio Preto' },
  { state: 'Minas Gerais', id: 3162005, name: 'São Gonçalo do Sapucaí' },
  { state: 'Minas Gerais', id: 3162104, name: 'São Gotardo' },
  { state: 'Minas Gerais', id: 3162203, name: 'São João Batista do Glória' },
  { state: 'Minas Gerais', id: 3162252, name: 'São João da Lagoa' },
  { state: 'Minas Gerais', id: 3162302, name: 'São João da Mata' },
  { state: 'Minas Gerais', id: 3162401, name: 'São João da Ponte' },
  { state: 'Minas Gerais', id: 3162450, name: 'São João das Missões' },
  { state: 'Minas Gerais', id: 3162500, name: 'São João del Rei' },
  { state: 'Minas Gerais', id: 3162559, name: 'São João do Manhuaçu' },
  { state: 'Minas Gerais', id: 3162575, name: 'São João do Manteninha' },
  { state: 'Minas Gerais', id: 3162609, name: 'São João do Oriente' },
  { state: 'Minas Gerais', id: 3162658, name: 'São João do Pacuí' },
  { state: 'Minas Gerais', id: 3162708, name: 'São João do Paraíso' },
  { state: 'Minas Gerais', id: 3162807, name: 'São João Evangelista' },
  { state: 'Minas Gerais', id: 3162906, name: 'São João Nepomuceno' },
  { state: 'Minas Gerais', id: 3162922, name: 'São Joaquim de Bicas' },
  { state: 'Minas Gerais', id: 3162948, name: 'São José da Barra' },
  { state: 'Minas Gerais', id: 3162955, name: 'São José da Lapa' },
  { state: 'Minas Gerais', id: 3163003, name: 'São José da Safira' },
  { state: 'Minas Gerais', id: 3163102, name: 'São José da Varginha' },
  { state: 'Minas Gerais', id: 3163201, name: 'São José do Alegre' },
  { state: 'Minas Gerais', id: 3163300, name: 'São José do Divino' },
  { state: 'Minas Gerais', id: 3163409, name: 'São José do Goiabal' },
  { state: 'Minas Gerais', id: 3163508, name: 'São José do Jacuri' },
  { state: 'Minas Gerais', id: 3163607, name: 'São José do Mantimento' },
  { state: 'Minas Gerais', id: 3163706, name: 'São Lourenço' },
  { state: 'Minas Gerais', id: 3163805, name: 'São Miguel do Anta' },
  { state: 'Minas Gerais', id: 3163904, name: 'São Pedro da União' },
  { state: 'Minas Gerais', id: 3164100, name: 'São Pedro do Suaçuí' },
  { state: 'Minas Gerais', id: 3164001, name: 'São Pedro dos Ferros' },
  { state: 'Minas Gerais', id: 3164209, name: 'São Romão' },
  { state: 'Minas Gerais', id: 3164308, name: 'São Roque de Minas' },
  {
    state: 'Minas Gerais',
    id: 3164407,
    name: 'São Sebastião da Bela Vista',
  },
  {
    state: 'Minas Gerais',
    id: 3164431,
    name: 'São Sebastião da Vargem Alegre',
  },
  { state: 'Minas Gerais', id: 3164472, name: 'São Sebastião do Anta' },
  { state: 'Minas Gerais', id: 3164506, name: 'São Sebastião do Maranhão' },
  { state: 'Minas Gerais', id: 3164605, name: 'São Sebastião do Oeste' },
  { state: 'Minas Gerais', id: 3164704, name: 'São Sebastião do Paraíso' },
  { state: 'Minas Gerais', id: 3164803, name: 'São Sebastião do Rio Preto' },
  { state: 'Minas Gerais', id: 3164902, name: 'São Sebastião do Rio Verde' },
  { state: 'Minas Gerais', id: 3165206, name: 'São Thomé das Letras' },
  { state: 'Minas Gerais', id: 3165008, name: 'São Tiago' },
  { state: 'Minas Gerais', id: 3165107, name: 'São Tomás de Aquino' },
  { state: 'Minas Gerais', id: 3165305, name: 'São Vicente de Minas' },
  { state: 'Minas Gerais', id: 3165404, name: 'Sapucaí-Mirim' },
  { state: 'Minas Gerais', id: 3165503, name: 'Sardoá' },
  { state: 'Minas Gerais', id: 3165537, name: 'Sarzedo' },
  { state: 'Minas Gerais', id: 3165560, name: 'Sem-Peixe' },
  { state: 'Minas Gerais', id: 3165578, name: 'Senador Amaral' },
  { state: 'Minas Gerais', id: 3165602, name: 'Senador Cortes' },
  { state: 'Minas Gerais', id: 3165701, name: 'Senador Firmino' },
  { state: 'Minas Gerais', id: 3165800, name: 'Senador José Bento' },
  {
    state: 'Minas Gerais',
    id: 3165909,
    name: 'Senador Modestino Gonçalves',
  },
  { state: 'Minas Gerais', id: 3166006, name: 'Senhora de Oliveira' },
  { state: 'Minas Gerais', id: 3166105, name: 'Senhora do Porto' },
  { state: 'Minas Gerais', id: 3166204, name: 'Senhora dos Remédios' },
  { state: 'Minas Gerais', id: 3166303, name: 'Sericita' },
  { state: 'Minas Gerais', id: 3166402, name: 'Seritinga' },
  { state: 'Minas Gerais', id: 3166501, name: 'Serra Azul de Minas' },
  { state: 'Minas Gerais', id: 3166600, name: 'Serra da Saudade' },
  { state: 'Minas Gerais', id: 3166808, name: 'Serra do Salitre' },
  { state: 'Minas Gerais', id: 3166709, name: 'Serra dos Aimorés' },
  { state: 'Minas Gerais', id: 3166907, name: 'Serrania' },
  { state: 'Minas Gerais', id: 3166956, name: 'Serranópolis de Minas' },
  { state: 'Minas Gerais', id: 3167004, name: 'Serranos' },
  { state: 'Minas Gerais', id: 3167103, name: 'Serro' },
  { state: 'Minas Gerais', id: 3167202, name: 'Sete Lagoas' },
  { state: 'Minas Gerais', id: 3165552, name: 'Setubinha' },
  { state: 'Minas Gerais', id: 3167301, name: 'Silveirânia' },
  { state: 'Minas Gerais', id: 3167400, name: 'Silvianópolis' },
  { state: 'Minas Gerais', id: 3167509, name: 'Simão Pereira' },
  { state: 'Minas Gerais', id: 3167608, name: 'Simonésia' },
  { state: 'Minas Gerais', id: 3167707, name: 'Sobrália' },
  { state: 'Minas Gerais', id: 3167806, name: 'Soledade de Minas' },
  { state: 'Minas Gerais', id: 3167905, name: 'Tabuleiro' },
  { state: 'Minas Gerais', id: 3168002, name: 'Taiobeiras' },
  { state: 'Minas Gerais', id: 3168051, name: 'Taparuba' },
  { state: 'Minas Gerais', id: 3168101, name: 'Tapira' },
  { state: 'Minas Gerais', id: 3168200, name: 'Tapiraí' },
  { state: 'Minas Gerais', id: 3168309, name: 'Taquaraçu de Minas' },
  { state: 'Minas Gerais', id: 3168408, name: 'Tarumirim' },
  { state: 'Minas Gerais', id: 3168507, name: 'Teixeiras' },
  { state: 'Minas Gerais', id: 3168606, name: 'Teófilo Otoni' },
  { state: 'Minas Gerais', id: 3168705, name: 'Timóteo' },
  { state: 'Minas Gerais', id: 3168804, name: 'Tiradentes' },
  { state: 'Minas Gerais', id: 3168903, name: 'Tiros' },
  { state: 'Minas Gerais', id: 3169000, name: 'Tocantins' },
  { state: 'Minas Gerais', id: 3169059, name: 'Tocos do Moji' },
  { state: 'Minas Gerais', id: 3169109, name: 'Toledo' },
  { state: 'Minas Gerais', id: 3169208, name: 'Tombos' },
  { state: 'Minas Gerais', id: 3169307, name: 'Três Corações' },
  { state: 'Minas Gerais', id: 3169356, name: 'Três Marias' },
  { state: 'Minas Gerais', id: 3169406, name: 'Três Pontas' },
  { state: 'Minas Gerais', id: 3169505, name: 'Tumiritinga' },
  { state: 'Minas Gerais', id: 3169604, name: 'Tupaciguara' },
  { state: 'Minas Gerais', id: 3169703, name: 'Turmalina' },
  { state: 'Minas Gerais', id: 3169802, name: 'Turvolândia' },
  { state: 'Minas Gerais', id: 3169901, name: 'Ubá' },
  { state: 'Minas Gerais', id: 3170008, name: 'Ubaí' },
  { state: 'Minas Gerais', id: 3170057, name: 'Ubaporanga' },
  { state: 'Minas Gerais', id: 3170107, name: 'Uberaba' },
  { state: 'Minas Gerais', id: 3170206, name: 'Uberlândia' },
  { state: 'Minas Gerais', id: 3170305, name: 'Umburatiba' },
  { state: 'Minas Gerais', id: 3170404, name: 'Unaí' },
  { state: 'Minas Gerais', id: 3170438, name: 'União de Minas' },
  { state: 'Minas Gerais', id: 3170479, name: 'Uruana de Minas' },
  { state: 'Minas Gerais', id: 3170503, name: 'Urucânia' },
  { state: 'Minas Gerais', id: 3170529, name: 'Urucuia' },
  { state: 'Minas Gerais', id: 3170578, name: 'Vargem Alegre' },
  { state: 'Minas Gerais', id: 3170602, name: 'Vargem Bonita' },
  { state: 'Minas Gerais', id: 3170651, name: 'Vargem Grande do Rio Pardo' },
  { state: 'Minas Gerais', id: 3170701, name: 'Varginha' },
  { state: 'Minas Gerais', id: 3170750, name: 'Varjão de Minas' },
  { state: 'Minas Gerais', id: 3170800, name: 'Várzea da Palma' },
  { state: 'Minas Gerais', id: 3170909, name: 'Varzelândia' },
  { state: 'Minas Gerais', id: 3171006, name: 'Vazante' },
  { state: 'Minas Gerais', id: 3171030, name: 'Verdelândia' },
  { state: 'Minas Gerais', id: 3171071, name: 'Veredinha' },
  { state: 'Minas Gerais', id: 3171105, name: 'Veríssimo' },
  { state: 'Minas Gerais', id: 3171154, name: 'Vermelho Novo' },
  { state: 'Minas Gerais', id: 3171204, name: 'Vespasiano' },
  { state: 'Minas Gerais', id: 3171303, name: 'Viçosa' },
  { state: 'Minas Gerais', id: 3171402, name: 'Vieiras' },
  { state: 'Minas Gerais', id: 3171600, name: 'Virgem da Lapa' },
  { state: 'Minas Gerais', id: 3171709, name: 'Virgínia' },
  { state: 'Minas Gerais', id: 3171808, name: 'Virginópolis' },
  { state: 'Minas Gerais', id: 3171907, name: 'Virgolândia' },
  { state: 'Minas Gerais', id: 3172004, name: 'Visconde do Rio Branco' },
  { state: 'Minas Gerais', id: 3172103, name: 'Volta Grande' },
  { state: 'Minas Gerais', id: 3172202, name: 'Wenceslau Braz' },
  { state: 'Espírito Santo', id: 3200102, name: 'Afonso Cláudio' },
  { state: 'Espírito Santo', id: 3200169, name: 'Água Doce do Norte' },
  { state: 'Espírito Santo', id: 3200136, name: 'Águia Branca' },
  { state: 'Espírito Santo', id: 3200201, name: 'Alegre' },
  { state: 'Espírito Santo', id: 3200300, name: 'Alfredo Chaves' },
  { state: 'Espírito Santo', id: 3200359, name: 'Alto Rio Novo' },
  { state: 'Espírito Santo', id: 3200409, name: 'Anchieta' },
  { state: 'Espírito Santo', id: 3200508, name: 'Apiacá' },
  { state: 'Espírito Santo', id: 3200607, name: 'Aracruz' },
  { state: 'Espírito Santo', id: 3200706, name: 'Atílio Vivacqua' },
  { state: 'Espírito Santo', id: 3200805, name: 'Baixo Guandu' },
  { state: 'Espírito Santo', id: 3200904, name: 'Barra de São Francisco' },
  { state: 'Espírito Santo', id: 3201001, name: 'Boa Esperança' },
  { state: 'Espírito Santo', id: 3201100, name: 'Bom Jesus do Norte' },
  { state: 'Espírito Santo', id: 3201159, name: 'Brejetuba' },
  { state: 'Espírito Santo', id: 3201209, name: 'Cachoeiro de Itapemirim' },
  { state: 'Espírito Santo', id: 3201308, name: 'Cariacica' },
  { state: 'Espírito Santo', id: 3201407, name: 'Castelo' },
  { state: 'Espírito Santo', id: 3201506, name: 'Colatina' },
  { state: 'Espírito Santo', id: 3201605, name: 'Conceição da Barra' },
  { state: 'Espírito Santo', id: 3201704, name: 'Conceição do Castelo' },
  { state: 'Espírito Santo', id: 3201803, name: 'Divino de São Lourenço' },
  { state: 'Espírito Santo', id: 3201902, name: 'Domingos Martins' },
  { state: 'Espírito Santo', id: 3202009, name: 'Dores do Rio Preto' },
  { state: 'Espírito Santo', id: 3202108, name: 'Ecoporanga' },
  { state: 'Espírito Santo', id: 3202207, name: 'Fundão' },
  { state: 'Espírito Santo', id: 3202256, name: 'Governador Lindenberg' },
  { state: 'Espírito Santo', id: 3202306, name: 'Guaçuí' },
  { state: 'Espírito Santo', id: 3202405, name: 'Guarapari' },
  { state: 'Espírito Santo', id: 3202454, name: 'Ibatiba' },
  { state: 'Espírito Santo', id: 3202504, name: 'Ibiraçu' },
  { state: 'Espírito Santo', id: 3202553, name: 'Ibitirama' },
  { state: 'Espírito Santo', id: 3202603, name: 'Iconha' },
  { state: 'Espírito Santo', id: 3202652, name: 'Irupi' },
  { state: 'Espírito Santo', id: 3202702, name: 'Itaguaçu' },
  { state: 'Espírito Santo', id: 3202801, name: 'Itapemirim' },
  { state: 'Espírito Santo', id: 3202900, name: 'Itarana' },
  { state: 'Espírito Santo', id: 3203007, name: 'Iúna' },
  { state: 'Espírito Santo', id: 3203056, name: 'Jaguaré' },
  { state: 'Espírito Santo', id: 3203106, name: 'Jerônimo Monteiro' },
  { state: 'Espírito Santo', id: 3203130, name: 'João Neiva' },
  { state: 'Espírito Santo', id: 3203163, name: 'Laranja da Terra' },
  { state: 'Espírito Santo', id: 3203205, name: 'Linhares' },
  { state: 'Espírito Santo', id: 3203304, name: 'Mantenópolis' },
  { state: 'Espírito Santo', id: 3203320, name: 'Marataízes' },
  { state: 'Espírito Santo', id: 3203346, name: 'Marechal Floriano' },
  { state: 'Espírito Santo', id: 3203353, name: 'Marilândia' },
  { state: 'Espírito Santo', id: 3203403, name: 'Mimoso do Sul' },
  { state: 'Espírito Santo', id: 3203502, name: 'Montanha' },
  { state: 'Espírito Santo', id: 3203601, name: 'Mucurici' },
  { state: 'Espírito Santo', id: 3203700, name: 'Muniz Freire' },
  { state: 'Espírito Santo', id: 3203809, name: 'Muqui' },
  { state: 'Espírito Santo', id: 3203908, name: 'Nova Venécia' },
  { state: 'Espírito Santo', id: 3204005, name: 'Pancas' },
  { state: 'Espírito Santo', id: 3204054, name: 'Pedro Canário' },
  { state: 'Espírito Santo', id: 3204104, name: 'Pinheiros' },
  { state: 'Espírito Santo', id: 3204203, name: 'Piúma' },
  { state: 'Espírito Santo', id: 3204252, name: 'Ponto Belo' },
  { state: 'Espírito Santo', id: 3204302, name: 'Presidente Kennedy' },
  { state: 'Espírito Santo', id: 3204351, name: 'Rio Bananal' },
  { state: 'Espírito Santo', id: 3204401, name: 'Rio Novo do Sul' },
  { state: 'Espírito Santo', id: 3204500, name: 'Santa Leopoldina' },
  { state: 'Espírito Santo', id: 3204559, name: 'Santa Maria de Jetibá' },
  { state: 'Espírito Santo', id: 3204609, name: 'Santa Teresa' },
  { state: 'Espírito Santo', id: 3204658, name: 'São Domingos do Norte' },
  { state: 'Espírito Santo', id: 3204708, name: 'São Gabriel da Palha' },
  { state: 'Espírito Santo', id: 3204807, name: 'São José do Calçado' },
  { state: 'Espírito Santo', id: 3204906, name: 'São Mateus' },
  { state: 'Espírito Santo', id: 3204955, name: 'São Roque do Canaã' },
  { state: 'Espírito Santo', id: 3205002, name: 'Serra' },
  { state: 'Espírito Santo', id: 3205010, name: 'Sooretama' },
  { state: 'Espírito Santo', id: 3205036, name: 'Vargem Alta' },
  { state: 'Espírito Santo', id: 3205069, name: 'Venda Nova do Imigrante' },
  { state: 'Espírito Santo', id: 3205101, name: 'Viana' },
  { state: 'Espírito Santo', id: 3205150, name: 'Vila Pavão' },
  { state: 'Espírito Santo', id: 3205176, name: 'Vila Valério' },
  { state: 'Espírito Santo', id: 3205200, name: 'Vila Velha' },
  { state: 'Espírito Santo', id: 3205309, name: 'Vitória' },
  { state: 'Rio de Janeiro', id: 3300100, name: 'Angra dos Reis' },
  { state: 'Rio de Janeiro', id: 3300159, name: 'Aperibé' },
  { state: 'Rio de Janeiro', id: 3300209, name: 'Araruama' },
  { state: 'Rio de Janeiro', id: 3300225, name: 'Areal' },
  { state: 'Rio de Janeiro', id: 3300233, name: 'Armação dos Búzios' },
  { state: 'Rio de Janeiro', id: 3300258, name: 'Arraial do Cabo' },
  { state: 'Rio de Janeiro', id: 3300308, name: 'Barra do Piraí' },
  { state: 'Rio de Janeiro', id: 3300407, name: 'Barra Mansa' },
  { state: 'Rio de Janeiro', id: 3300456, name: 'Belford Roxo' },
  { state: 'Rio de Janeiro', id: 3300506, name: 'Bom Jardim' },
  { state: 'Rio de Janeiro', id: 3300605, name: 'Bom Jesus do Itabapoana' },
  { state: 'Rio de Janeiro', id: 3300704, name: 'Cabo Frio' },
  { state: 'Rio de Janeiro', id: 3300803, name: 'Cachoeiras de Macacu' },
  { state: 'Rio de Janeiro', id: 3300902, name: 'Cambuci' },
  { state: 'Rio de Janeiro', id: 3301009, name: 'Campos dos Goytacazes' },
  { state: 'Rio de Janeiro', id: 3301108, name: 'Cantagalo' },
  { state: 'Rio de Janeiro', id: 3300936, name: 'Carapebus' },
  { state: 'Rio de Janeiro', id: 3301157, name: 'Cardoso Moreira' },
  { state: 'Rio de Janeiro', id: 3301207, name: 'Carmo' },
  { state: 'Rio de Janeiro', id: 3301306, name: 'Casimiro de Abreu' },
  {
    state: 'Rio de Janeiro',
    id: 3300951,
    name: 'Comendador Levy Gasparian',
  },
  { state: 'Rio de Janeiro', id: 3301405, name: 'Conceição de Macabu' },
  { state: 'Rio de Janeiro', id: 3301504, name: 'Cordeiro' },
  { state: 'Rio de Janeiro', id: 3301603, name: 'Duas Barras' },
  { state: 'Rio de Janeiro', id: 3301702, name: 'Duque de Caxias' },
  {
    state: 'Rio de Janeiro',
    id: 3301801,
    name: 'Engenheiro Paulo de Frontin',
  },
  { state: 'Rio de Janeiro', id: 3301850, name: 'Guapimirim' },
  { state: 'Rio de Janeiro', id: 3301876, name: 'Iguaba Grande' },
  { state: 'Rio de Janeiro', id: 3301900, name: 'Itaboraí' },
  { state: 'Rio de Janeiro', id: 3302007, name: 'Itaguaí' },
  { state: 'Rio de Janeiro', id: 3302056, name: 'Italva' },
  { state: 'Rio de Janeiro', id: 3302106, name: 'Itaocara' },
  { state: 'Rio de Janeiro', id: 3302205, name: 'Itaperuna' },
  { state: 'Rio de Janeiro', id: 3302254, name: 'Itatiaia' },
  { state: 'Rio de Janeiro', id: 3302270, name: 'Japeri' },
  { state: 'Rio de Janeiro', id: 3302304, name: 'Laje do Muriaé' },
  { state: 'Rio de Janeiro', id: 3302403, name: 'Macaé' },
  { state: 'Rio de Janeiro', id: 3302452, name: 'Macuco' },
  { state: 'Rio de Janeiro', id: 3302502, name: 'Magé' },
  { state: 'Rio de Janeiro', id: 3302601, name: 'Mangaratiba' },
  { state: 'Rio de Janeiro', id: 3302700, name: 'Maricá' },
  { state: 'Rio de Janeiro', id: 3302809, name: 'Mendes' },
  { state: 'Rio de Janeiro', id: 3302858, name: 'Mesquita' },
  { state: 'Rio de Janeiro', id: 3302908, name: 'Miguel Pereira' },
  { state: 'Rio de Janeiro', id: 3303005, name: 'Miracema' },
  { state: 'Rio de Janeiro', id: 3303104, name: 'Natividade' },
  { state: 'Rio de Janeiro', id: 3303203, name: 'Nilópolis' },
  { state: 'Rio de Janeiro', id: 3303302, name: 'Niterói' },
  { state: 'Rio de Janeiro', id: 3303401, name: 'Nova Friburgo' },
  { state: 'Rio de Janeiro', id: 3303500, name: 'Nova Iguaçu' },
  { state: 'Rio de Janeiro', id: 3303609, name: 'Paracambi' },
  { state: 'Rio de Janeiro', id: 3303708, name: 'Paraíba do Sul' },
  { state: 'Rio de Janeiro', id: 3303807, name: 'Parati' },
  { state: 'Rio de Janeiro', id: 3303856, name: 'Paty do Alferes' },
  { state: 'Rio de Janeiro', id: 3303906, name: 'Petrópolis' },
  { state: 'Rio de Janeiro', id: 3303955, name: 'Pinheiral' },
  { state: 'Rio de Janeiro', id: 3304003, name: 'Piraí' },
  { state: 'Rio de Janeiro', id: 3304102, name: 'Porciúncula' },
  { state: 'Rio de Janeiro', id: 3304110, name: 'Porto Real' },
  { state: 'Rio de Janeiro', id: 3304128, name: 'Quatis' },
  { state: 'Rio de Janeiro', id: 3304144, name: 'Queimados' },
  { state: 'Rio de Janeiro', id: 3304151, name: 'Quissamã' },
  { state: 'Rio de Janeiro', id: 3304201, name: 'Resende' },
  { state: 'Rio de Janeiro', id: 3304300, name: 'Rio Bonito' },
  { state: 'Rio de Janeiro', id: 3304409, name: 'Rio Claro' },
  { state: 'Rio de Janeiro', id: 3304508, name: 'Rio das Flores' },
  { state: 'Rio de Janeiro', id: 3304524, name: 'Rio das Ostras' },
  { state: 'Rio de Janeiro', id: 3304557, name: 'Rio de Janeiro' },
  { state: 'Rio de Janeiro', id: 3304607, name: 'Santa Maria Madalena' },
  { state: 'Rio de Janeiro', id: 3304706, name: 'Santo Antônio de Pádua' },
  { state: 'Rio de Janeiro', id: 3304805, name: 'São Fidélis' },
  {
    state: 'Rio de Janeiro',
    id: 3304755,
    name: 'São Francisco de Itabapoana',
  },
  { state: 'Rio de Janeiro', id: 3304904, name: 'São Gonçalo' },
  { state: 'Rio de Janeiro', id: 3305000, name: 'São João da Barra' },
  { state: 'Rio de Janeiro', id: 3305109, name: 'São João de Meriti' },
  { state: 'Rio de Janeiro', id: 3305133, name: 'São José de Ubá' },
  {
    state: 'Rio de Janeiro',
    id: 3305158,
    name: 'São José do Vale do Rio Preto',
  },
  { state: 'Rio de Janeiro', id: 3305208, name: 'São Pedro da Aldeia' },
  { state: 'Rio de Janeiro', id: 3305307, name: 'São Sebastião do Alto' },
  { state: 'Rio de Janeiro', id: 3305406, name: 'Sapucaia' },
  { state: 'Rio de Janeiro', id: 3305505, name: 'Saquarema' },
  { state: 'Rio de Janeiro', id: 3305554, name: 'Seropédica' },
  { state: 'Rio de Janeiro', id: 3305604, name: 'Silva Jardim' },
  { state: 'Rio de Janeiro', id: 3305703, name: 'Sumidouro' },
  { state: 'Rio de Janeiro', id: 3305752, name: 'Tanguá' },
  { state: 'Rio de Janeiro', id: 3305802, name: 'Teresópolis' },
  { state: 'Rio de Janeiro', id: 3305901, name: 'Trajano de Moraes' },
  { state: 'Rio de Janeiro', id: 3306008, name: 'Três Rios' },
  { state: 'Rio de Janeiro', id: 3306107, name: 'Valença' },
  { state: 'Rio de Janeiro', id: 3306156, name: 'Varre-Sai' },
  { state: 'Rio de Janeiro', id: 3306206, name: 'Vassouras' },
  { state: 'Rio de Janeiro', id: 3306305, name: 'Volta Redonda' },
  { state: 'São Paulo', id: 3500105, name: 'Adamantina' },
  { state: 'São Paulo', id: 3500204, name: 'Adolfo' },
  { state: 'São Paulo', id: 3500303, name: 'Aguaí' },
  { state: 'São Paulo', id: 3500402, name: 'Águas da Prata' },
  { state: 'São Paulo', id: 3500501, name: 'Águas de Lindóia' },
  { state: 'São Paulo', id: 3500550, name: 'Águas de Santa Bárbara' },
  { state: 'São Paulo', id: 3500600, name: 'Águas de São Pedro' },
  { state: 'São Paulo', id: 3500709, name: 'Agudos' },
  { state: 'São Paulo', id: 3500758, name: 'Alambari' },
  { state: 'São Paulo', id: 3500808, name: 'Alfredo Marcondes' },
  { state: 'São Paulo', id: 3500907, name: 'Altair' },
  { state: 'São Paulo', id: 3501004, name: 'Altinópolis' },
  { state: 'São Paulo', id: 3501103, name: 'Alto Alegre' },
  { state: 'São Paulo', id: 3501152, name: 'Alumínio' },
  { state: 'São Paulo', id: 3501202, name: 'Álvares Florence' },
  { state: 'São Paulo', id: 3501301, name: 'Álvares Machado' },
  { state: 'São Paulo', id: 3501400, name: 'Álvaro de Carvalho' },
  { state: 'São Paulo', id: 3501509, name: 'Alvinlândia' },
  { state: 'São Paulo', id: 3501608, name: 'Americana' },
  { state: 'São Paulo', id: 3501707, name: 'Américo Brasiliense' },
  { state: 'São Paulo', id: 3501806, name: 'Américo de Campos' },
  { state: 'São Paulo', id: 3501905, name: 'Amparo' },
  { state: 'São Paulo', id: 3502002, name: 'Analândia' },
  { state: 'São Paulo', id: 3502101, name: 'Andradina' },
  { state: 'São Paulo', id: 3502200, name: 'Angatuba' },
  { state: 'São Paulo', id: 3502309, name: 'Anhembi' },
  { state: 'São Paulo', id: 3502408, name: 'Anhumas' },
  { state: 'São Paulo', id: 3502507, name: 'Aparecida' },
  { state: 'São Paulo', id: 3502606, name: "Aparecida d'Oeste" },
  { state: 'São Paulo', id: 3502705, name: 'Apiaí' },
  { state: 'São Paulo', id: 3502754, name: 'Araçariguama' },
  { state: 'São Paulo', id: 3502804, name: 'Araçatuba' },
  { state: 'São Paulo', id: 3502903, name: 'Araçoiaba da Serra' },
  { state: 'São Paulo', id: 3503000, name: 'Aramina' },
  { state: 'São Paulo', id: 3503109, name: 'Arandu' },
  { state: 'São Paulo', id: 3503158, name: 'Arapeí' },
  { state: 'São Paulo', id: 3503208, name: 'Araraquara' },
  { state: 'São Paulo', id: 3503307, name: 'Araras' },
  { state: 'São Paulo', id: 3503356, name: 'Arco-Íris' },
  { state: 'São Paulo', id: 3503406, name: 'Arealva' },
  { state: 'São Paulo', id: 3503505, name: 'Areias' },
  { state: 'São Paulo', id: 3503604, name: 'Areiópolis' },
  { state: 'São Paulo', id: 3503703, name: 'Ariranha' },
  { state: 'São Paulo', id: 3503802, name: 'Artur Nogueira' },
  { state: 'São Paulo', id: 3503901, name: 'Arujá' },
  { state: 'São Paulo', id: 3503950, name: 'Aspásia' },
  { state: 'São Paulo', id: 3504008, name: 'Assis' },
  { state: 'São Paulo', id: 3504107, name: 'Atibaia' },
  { state: 'São Paulo', id: 3504206, name: 'Auriflama' },
  { state: 'São Paulo', id: 3504305, name: 'Avaí' },
  { state: 'São Paulo', id: 3504404, name: 'Avanhandava' },
  { state: 'São Paulo', id: 3504503, name: 'Avaré' },
  { state: 'São Paulo', id: 3504602, name: 'Bady Bassitt' },
  { state: 'São Paulo', id: 3504701, name: 'Balbinos' },
  { state: 'São Paulo', id: 3504800, name: 'Bálsamo' },
  { state: 'São Paulo', id: 3504909, name: 'Bananal' },
  { state: 'São Paulo', id: 3505005, name: 'Barão de Antonina' },
  { state: 'São Paulo', id: 3505104, name: 'Barbosa' },
  { state: 'São Paulo', id: 3505203, name: 'Bariri' },
  { state: 'São Paulo', id: 3505302, name: 'Barra Bonita' },
  { state: 'São Paulo', id: 3505351, name: 'Barra do Chapéu' },
  { state: 'São Paulo', id: 3505401, name: 'Barra do Turvo' },
  { state: 'São Paulo', id: 3505500, name: 'Barretos' },
  { state: 'São Paulo', id: 3505609, name: 'Barrinha' },
  { state: 'São Paulo', id: 3505708, name: 'Barueri' },
  { state: 'São Paulo', id: 3505807, name: 'Bastos' },
  { state: 'São Paulo', id: 3505906, name: 'Batatais' },
  { state: 'São Paulo', id: 3506003, name: 'Bauru' },
  { state: 'São Paulo', id: 3506102, name: 'Bebedouro' },
  { state: 'São Paulo', id: 3506201, name: 'Bento de Abreu' },
  { state: 'São Paulo', id: 3506300, name: 'Bernardino de Campos' },
  { state: 'São Paulo', id: 3506359, name: 'Bertioga' },
  { state: 'São Paulo', id: 3506409, name: 'Bilac' },
  { state: 'São Paulo', id: 3506508, name: 'Birigui' },
  { state: 'São Paulo', id: 3506607, name: 'Biritiba Mirim' },
  { state: 'São Paulo', id: 3506706, name: 'Boa Esperança do Sul' },
  { state: 'São Paulo', id: 3506805, name: 'Bocaina' },
  { state: 'São Paulo', id: 3506904, name: 'Bofete' },
  { state: 'São Paulo', id: 3507001, name: 'Boituva' },
  { state: 'São Paulo', id: 3507100, name: 'Bom Jesus dos Perdões' },
  { state: 'São Paulo', id: 3507159, name: 'Bom Sucesso de Itararé' },
  { state: 'São Paulo', id: 3507209, name: 'Borá' },
  { state: 'São Paulo', id: 3507308, name: 'Boracéia' },
  { state: 'São Paulo', id: 3507407, name: 'Borborema' },
  { state: 'São Paulo', id: 3507456, name: 'Borebi' },
  { state: 'São Paulo', id: 3507506, name: 'Botucatu' },
  { state: 'São Paulo', id: 3507605, name: 'Bragança Paulista' },
  { state: 'São Paulo', id: 3507704, name: 'Braúna' },
  { state: 'São Paulo', id: 3507753, name: 'Brejo Alegre' },
  { state: 'São Paulo', id: 3507803, name: 'Brodowski' },
  { state: 'São Paulo', id: 3507902, name: 'Brotas' },
  { state: 'São Paulo', id: 3508009, name: 'Buri' },
  { state: 'São Paulo', id: 3508108, name: 'Buritama' },
  { state: 'São Paulo', id: 3508207, name: 'Buritizal' },
  { state: 'São Paulo', id: 3508306, name: 'Cabrália Paulista' },
  { state: 'São Paulo', id: 3508405, name: 'Cabreúva' },
  { state: 'São Paulo', id: 3508504, name: 'Caçapava' },
  { state: 'São Paulo', id: 3508603, name: 'Cachoeira Paulista' },
  { state: 'São Paulo', id: 3508702, name: 'Caconde' },
  { state: 'São Paulo', id: 3508801, name: 'Cafelândia' },
  { state: 'São Paulo', id: 3508900, name: 'Caiabu' },
  { state: 'São Paulo', id: 3509007, name: 'Caieiras' },
  { state: 'São Paulo', id: 3509106, name: 'Caiuá' },
  { state: 'São Paulo', id: 3509205, name: 'Cajamar' },
  { state: 'São Paulo', id: 3509254, name: 'Cajati' },
  { state: 'São Paulo', id: 3509304, name: 'Cajobi' },
  { state: 'São Paulo', id: 3509403, name: 'Cajuru' },
  { state: 'São Paulo', id: 3509452, name: 'Campina do Monte Alegre' },
  { state: 'São Paulo', id: 3509502, name: 'Campinas' },
  { state: 'São Paulo', id: 3509601, name: 'Campo Limpo Paulista' },
  { state: 'São Paulo', id: 3509700, name: 'Campos do Jordão' },
  { state: 'São Paulo', id: 3509809, name: 'Campos Novos Paulista' },
  { state: 'São Paulo', id: 3509908, name: 'Cananéia' },
  { state: 'São Paulo', id: 3509957, name: 'Canas' },
  { state: 'São Paulo', id: 3510005, name: 'Cândido Mota' },
  { state: 'São Paulo', id: 3510104, name: 'Cândido Rodrigues' },
  { state: 'São Paulo', id: 3510153, name: 'Canitar' },
  { state: 'São Paulo', id: 3510203, name: 'Capão Bonito' },
  { state: 'São Paulo', id: 3510302, name: 'Capela do Alto' },
  { state: 'São Paulo', id: 3510401, name: 'Capivari' },
  { state: 'São Paulo', id: 3510500, name: 'Caraguatatuba' },
  { state: 'São Paulo', id: 3510609, name: 'Carapicuíba' },
  { state: 'São Paulo', id: 3510708, name: 'Cardoso' },
  { state: 'São Paulo', id: 3510807, name: 'Casa Branca' },
  { state: 'São Paulo', id: 3510906, name: 'Cássia dos Coqueiros' },
  { state: 'São Paulo', id: 3511003, name: 'Castilho' },
  { state: 'São Paulo', id: 3511102, name: 'Catanduva' },
  { state: 'São Paulo', id: 3511201, name: 'Catiguá' },
  { state: 'São Paulo', id: 3511300, name: 'Cedral' },
  { state: 'São Paulo', id: 3511409, name: 'Cerqueira César' },
  { state: 'São Paulo', id: 3511508, name: 'Cerquilho' },
  { state: 'São Paulo', id: 3511607, name: 'Cesário Lange' },
  { state: 'São Paulo', id: 3511706, name: 'Charqueada' },
  { state: 'São Paulo', id: 3557204, name: 'Chavantes' },
  { state: 'São Paulo', id: 3511904, name: 'Clementina' },
  { state: 'São Paulo', id: 3512001, name: 'Colina' },
  { state: 'São Paulo', id: 3512100, name: 'Colômbia' },
  { state: 'São Paulo', id: 3512209, name: 'Conchal' },
  { state: 'São Paulo', id: 3512308, name: 'Conchas' },
  { state: 'São Paulo', id: 3512407, name: 'Cordeirópolis' },
  { state: 'São Paulo', id: 3512506, name: 'Coroados' },
  { state: 'São Paulo', id: 3512605, name: 'Coronel Macedo' },
  { state: 'São Paulo', id: 3512704, name: 'Corumbataí' },
  { state: 'São Paulo', id: 3512803, name: 'Cosmópolis' },
  { state: 'São Paulo', id: 3512902, name: 'Cosmorama' },
  { state: 'São Paulo', id: 3513009, name: 'Cotia' },
  { state: 'São Paulo', id: 3513108, name: 'Cravinhos' },
  { state: 'São Paulo', id: 3513207, name: 'Cristais Paulista' },
  { state: 'São Paulo', id: 3513306, name: 'Cruzália' },
  { state: 'São Paulo', id: 3513405, name: 'Cruzeiro' },
  { state: 'São Paulo', id: 3513504, name: 'Cubatão' },
  { state: 'São Paulo', id: 3513603, name: 'Cunha' },
  { state: 'São Paulo', id: 3513702, name: 'Descalvado' },
  { state: 'São Paulo', id: 3513801, name: 'Diadema' },
  { state: 'São Paulo', id: 3513850, name: 'Dirce Reis' },
  { state: 'São Paulo', id: 3513900, name: 'Divinolândia' },
  { state: 'São Paulo', id: 3514007, name: 'Dobrada' },
  { state: 'São Paulo', id: 3514106, name: 'Dois Córregos' },
  { state: 'São Paulo', id: 3514205, name: 'Dolcinópolis' },
  { state: 'São Paulo', id: 3514304, name: 'Dourado' },
  { state: 'São Paulo', id: 3514403, name: 'Dracena' },
  { state: 'São Paulo', id: 3514502, name: 'Duartina' },
  { state: 'São Paulo', id: 3514601, name: 'Dumont' },
  { state: 'São Paulo', id: 3514700, name: 'Echaporã' },
  { state: 'São Paulo', id: 3514809, name: 'Eldorado' },
  { state: 'São Paulo', id: 3514908, name: 'Elias Fausto' },
  { state: 'São Paulo', id: 3514924, name: 'Elisiário' },
  { state: 'São Paulo', id: 3514957, name: 'Embaúba' },
  { state: 'São Paulo', id: 3515004, name: 'Embu das Artes' },
  { state: 'São Paulo', id: 3515103, name: 'Embu-Guaçu' },
  { state: 'São Paulo', id: 3515129, name: 'Emilianópolis' },
  { state: 'São Paulo', id: 3515152, name: 'Engenheiro Coelho' },
  { state: 'São Paulo', id: 3515186, name: 'Espírito Santo do Pinhal' },
  { state: 'São Paulo', id: 3515194, name: 'Espírito Santo do Turvo' },
  { state: 'São Paulo', id: 3557303, name: 'Estiva Gerbi' },
  { state: 'São Paulo', id: 3515301, name: 'Estrela do Norte' },
  { state: 'São Paulo', id: 3515202, name: "Estrela d'Oeste" },
  { state: 'São Paulo', id: 3515350, name: 'Euclides da Cunha Paulista' },
  { state: 'São Paulo', id: 3515400, name: 'Fartura' },
  { state: 'São Paulo', id: 3515608, name: 'Fernando Prestes' },
  { state: 'São Paulo', id: 3515509, name: 'Fernandópolis' },
  { state: 'São Paulo', id: 3515657, name: 'Fernão' },
  { state: 'São Paulo', id: 3515707, name: 'Ferraz de Vasconcelos' },
  { state: 'São Paulo', id: 3515806, name: 'Flora Rica' },
  { state: 'São Paulo', id: 3515905, name: 'Floreal' },
  { state: 'São Paulo', id: 3516002, name: 'Flórida Paulista' },
  { state: 'São Paulo', id: 3516101, name: 'Florínea' },
  { state: 'São Paulo', id: 3516200, name: 'Franca' },
  { state: 'São Paulo', id: 3516309, name: 'Francisco Morato' },
  { state: 'São Paulo', id: 3516408, name: 'Franco da Rocha' },
  { state: 'São Paulo', id: 3516507, name: 'Gabriel Monteiro' },
  { state: 'São Paulo', id: 3516606, name: 'Gália' },
  { state: 'São Paulo', id: 3516705, name: 'Garça' },
  { state: 'São Paulo', id: 3516804, name: 'Gastão Vidigal' },
  { state: 'São Paulo', id: 3516853, name: 'Gavião Peixoto' },
  { state: 'São Paulo', id: 3516903, name: 'General Salgado' },
  { state: 'São Paulo', id: 3517000, name: 'Getulina' },
  { state: 'São Paulo', id: 3517109, name: 'Glicério' },
  { state: 'São Paulo', id: 3517208, name: 'Guaiçara' },
  { state: 'São Paulo', id: 3517307, name: 'Guaimbê' },
  { state: 'São Paulo', id: 3517406, name: 'Guaíra' },
  { state: 'São Paulo', id: 3517505, name: 'Guapiaçu' },
  { state: 'São Paulo', id: 3517604, name: 'Guapiara' },
  { state: 'São Paulo', id: 3517703, name: 'Guará' },
  { state: 'São Paulo', id: 3517802, name: 'Guaraçaí' },
  { state: 'São Paulo', id: 3517901, name: 'Guaraci' },
  { state: 'São Paulo', id: 3518008, name: "Guarani d'Oeste" },
  { state: 'São Paulo', id: 3518107, name: 'Guarantã' },
  { state: 'São Paulo', id: 3518206, name: 'Guararapes' },
  { state: 'São Paulo', id: 3518305, name: 'Guararema' },
  { state: 'São Paulo', id: 3518404, name: 'Guaratinguetá' },
  { state: 'São Paulo', id: 3518503, name: 'Guareí' },
  { state: 'São Paulo', id: 3518602, name: 'Guariba' },
  { state: 'São Paulo', id: 3518701, name: 'Guarujá' },
  { state: 'São Paulo', id: 3518800, name: 'Guarulhos' },
  { state: 'São Paulo', id: 3518859, name: 'Guatapará' },
  { state: 'São Paulo', id: 3518909, name: 'Guzolândia' },
  { state: 'São Paulo', id: 3519006, name: 'Herculândia' },
  { state: 'São Paulo', id: 3519055, name: 'Holambra' },
  { state: 'São Paulo', id: 3519071, name: 'Hortolândia' },
  { state: 'São Paulo', id: 3519105, name: 'Iacanga' },
  { state: 'São Paulo', id: 3519204, name: 'Iacri' },
  { state: 'São Paulo', id: 3519253, name: 'Iaras' },
  { state: 'São Paulo', id: 3519303, name: 'Ibaté' },
  { state: 'São Paulo', id: 3519402, name: 'Ibirá' },
  { state: 'São Paulo', id: 3519501, name: 'Ibirarema' },
  { state: 'São Paulo', id: 3519600, name: 'Ibitinga' },
  { state: 'São Paulo', id: 3519709, name: 'Ibiúna' },
  { state: 'São Paulo', id: 3519808, name: 'Icém' },
  { state: 'São Paulo', id: 3519907, name: 'Iepê' },
  { state: 'São Paulo', id: 3520004, name: 'Igaraçu do Tietê' },
  { state: 'São Paulo', id: 3520103, name: 'Igarapava' },
  { state: 'São Paulo', id: 3520202, name: 'Igaratá' },
  { state: 'São Paulo', id: 3520301, name: 'Iguape' },
  { state: 'São Paulo', id: 3520426, name: 'Ilha Comprida' },
  { state: 'São Paulo', id: 3520442, name: 'Ilha Solteira' },
  { state: 'São Paulo', id: 3520400, name: 'Ilhabela' },
  { state: 'São Paulo', id: 3520509, name: 'Indaiatuba' },
  { state: 'São Paulo', id: 3520608, name: 'Indiana' },
  { state: 'São Paulo', id: 3520707, name: 'Indiaporã' },
  { state: 'São Paulo', id: 3520806, name: 'Inúbia Paulista' },
  { state: 'São Paulo', id: 3520905, name: 'Ipaussu' },
  { state: 'São Paulo', id: 3521002, name: 'Iperó' },
  { state: 'São Paulo', id: 3521101, name: 'Ipeúna' },
  { state: 'São Paulo', id: 3521150, name: 'Ipiguá' },
  { state: 'São Paulo', id: 3521200, name: 'Iporanga' },
  { state: 'São Paulo', id: 3521309, name: 'Ipuã' },
  { state: 'São Paulo', id: 3521408, name: 'Iracemápolis' },
  { state: 'São Paulo', id: 3521507, name: 'Irapuã' },
  { state: 'São Paulo', id: 3521606, name: 'Irapuru' },
  { state: 'São Paulo', id: 3521705, name: 'Itaberá' },
  { state: 'São Paulo', id: 3521804, name: 'Itaí' },
  { state: 'São Paulo', id: 3521903, name: 'Itajobi' },
  { state: 'São Paulo', id: 3522000, name: 'Itaju' },
  { state: 'São Paulo', id: 3522109, name: 'Itanhaém' },
  { state: 'São Paulo', id: 3522158, name: 'Itaoca' },
  { state: 'São Paulo', id: 3522208, name: 'Itapecerica da Serra' },
  { state: 'São Paulo', id: 3522307, name: 'Itapetininga' },
  { state: 'São Paulo', id: 3522406, name: 'Itapeva' },
  { state: 'São Paulo', id: 3522505, name: 'Itapevi' },
  { state: 'São Paulo', id: 3522604, name: 'Itapira' },
  { state: 'São Paulo', id: 3522653, name: 'Itapirapuã Paulista' },
  { state: 'São Paulo', id: 3522703, name: 'Itápolis' },
  { state: 'São Paulo', id: 3522802, name: 'Itaporanga' },
  { state: 'São Paulo', id: 3522901, name: 'Itapuí' },
  { state: 'São Paulo', id: 3523008, name: 'Itapura' },
  { state: 'São Paulo', id: 3523107, name: 'Itaquaquecetuba' },
  { state: 'São Paulo', id: 3523206, name: 'Itararé' },
  { state: 'São Paulo', id: 3523305, name: 'Itariri' },
  { state: 'São Paulo', id: 3523404, name: 'Itatiba' },
  { state: 'São Paulo', id: 3523503, name: 'Itatinga' },
  { state: 'São Paulo', id: 3523602, name: 'Itirapina' },
  { state: 'São Paulo', id: 3523701, name: 'Itirapuã' },
  { state: 'São Paulo', id: 3523800, name: 'Itobi' },
  { state: 'São Paulo', id: 3523909, name: 'Itu' },
  { state: 'São Paulo', id: 3524006, name: 'Itupeva' },
  { state: 'São Paulo', id: 3524105, name: 'Ituverava' },
  { state: 'São Paulo', id: 3524204, name: 'Jaborandi' },
  { state: 'São Paulo', id: 3524303, name: 'Jaboticabal' },
  { state: 'São Paulo', id: 3524402, name: 'Jacareí' },
  { state: 'São Paulo', id: 3524501, name: 'Jaci' },
  { state: 'São Paulo', id: 3524600, name: 'Jacupiranga' },
  { state: 'São Paulo', id: 3524709, name: 'Jaguariúna' },
  { state: 'São Paulo', id: 3524808, name: 'Jales' },
  { state: 'São Paulo', id: 3524907, name: 'Jambeiro' },
  { state: 'São Paulo', id: 3525003, name: 'Jandira' },
  { state: 'São Paulo', id: 3525102, name: 'Jardinópolis' },
  { state: 'São Paulo', id: 3525201, name: 'Jarinu' },
  { state: 'São Paulo', id: 3525300, name: 'Jaú' },
  { state: 'São Paulo', id: 3525409, name: 'Jeriquara' },
  { state: 'São Paulo', id: 3525508, name: 'Joanópolis' },
  { state: 'São Paulo', id: 3525607, name: 'João Ramalho' },
  { state: 'São Paulo', id: 3525706, name: 'José Bonifácio' },
  { state: 'São Paulo', id: 3525805, name: 'Júlio Mesquita' },
  { state: 'São Paulo', id: 3525854, name: 'Jumirim' },
  { state: 'São Paulo', id: 3525904, name: 'Jundiaí' },
  { state: 'São Paulo', id: 3526001, name: 'Junqueirópolis' },
  { state: 'São Paulo', id: 3526100, name: 'Juquiá' },
  { state: 'São Paulo', id: 3526209, name: 'Juquitiba' },
  { state: 'São Paulo', id: 3526308, name: 'Lagoinha' },
  { state: 'São Paulo', id: 3526407, name: 'Laranjal Paulista' },
  { state: 'São Paulo', id: 3526506, name: 'Lavínia' },
  { state: 'São Paulo', id: 3526605, name: 'Lavrinhas' },
  { state: 'São Paulo', id: 3526704, name: 'Leme' },
  { state: 'São Paulo', id: 3526803, name: 'Lençóis Paulista' },
  { state: 'São Paulo', id: 3526902, name: 'Limeira' },
  { state: 'São Paulo', id: 3527009, name: 'Lindóia' },
  { state: 'São Paulo', id: 3527108, name: 'Lins' },
  { state: 'São Paulo', id: 3527207, name: 'Lorena' },
  { state: 'São Paulo', id: 3527256, name: 'Lourdes' },
  { state: 'São Paulo', id: 3527306, name: 'Louveira' },
  { state: 'São Paulo', id: 3527405, name: 'Lucélia' },
  { state: 'São Paulo', id: 3527504, name: 'Lucianópolis' },
  { state: 'São Paulo', id: 3527603, name: 'Luís Antônio' },
  { state: 'São Paulo', id: 3527702, name: 'Luiziânia' },
  { state: 'São Paulo', id: 3527801, name: 'Lupércio' },
  { state: 'São Paulo', id: 3527900, name: 'Lutécia' },
  { state: 'São Paulo', id: 3528007, name: 'Macatuba' },
  { state: 'São Paulo', id: 3528106, name: 'Macaubal' },
  { state: 'São Paulo', id: 3528205, name: 'Macedônia' },
  { state: 'São Paulo', id: 3528304, name: 'Magda' },
  { state: 'São Paulo', id: 3528403, name: 'Mairinque' },
  { state: 'São Paulo', id: 3528502, name: 'Mairiporã' },
  { state: 'São Paulo', id: 3528601, name: 'Manduri' },
  { state: 'São Paulo', id: 3528700, name: 'Marabá Paulista' },
  { state: 'São Paulo', id: 3528809, name: 'Maracaí' },
  { state: 'São Paulo', id: 3528858, name: 'Marapoama' },
  { state: 'São Paulo', id: 3528908, name: 'Mariápolis' },
  { state: 'São Paulo', id: 3529005, name: 'Marília' },
  { state: 'São Paulo', id: 3529104, name: 'Marinópolis' },
  { state: 'São Paulo', id: 3529203, name: 'Martinópolis' },
  { state: 'São Paulo', id: 3529302, name: 'Matão' },
  { state: 'São Paulo', id: 3529401, name: 'Mauá' },
  { state: 'São Paulo', id: 3529500, name: 'Mendonça' },
  { state: 'São Paulo', id: 3529609, name: 'Meridiano' },
  { state: 'São Paulo', id: 3529658, name: 'Mesópolis' },
  { state: 'São Paulo', id: 3529708, name: 'Miguelópolis' },
  { state: 'São Paulo', id: 3529807, name: 'Mineiros do Tietê' },
  { state: 'São Paulo', id: 3530003, name: 'Mira Estrela' },
  { state: 'São Paulo', id: 3529906, name: 'Miracatu' },
  { state: 'São Paulo', id: 3530102, name: 'Mirandópolis' },
  { state: 'São Paulo', id: 3530201, name: 'Mirante do Paranapanema' },
  { state: 'São Paulo', id: 3530300, name: 'Mirassol' },
  { state: 'São Paulo', id: 3530409, name: 'Mirassolândia' },
  { state: 'São Paulo', id: 3530508, name: 'Mococa' },
  { state: 'São Paulo', id: 3530607, name: 'Mogi das Cruzes' },
  { state: 'São Paulo', id: 3530706, name: 'Mogi Guaçu' },
  { state: 'São Paulo', id: 3530805, name: 'Mogi Mirim' },
  { state: 'São Paulo', id: 3530904, name: 'Mombuca' },
  { state: 'São Paulo', id: 3531001, name: 'Monções' },
  { state: 'São Paulo', id: 3531100, name: 'Mongaguá' },
  { state: 'São Paulo', id: 3531209, name: 'Monte Alegre do Sul' },
  { state: 'São Paulo', id: 3531308, name: 'Monte Alto' },
  { state: 'São Paulo', id: 3531407, name: 'Monte Aprazível' },
  { state: 'São Paulo', id: 3531506, name: 'Monte Azul Paulista' },
  { state: 'São Paulo', id: 3531605, name: 'Monte Castelo' },
  { state: 'São Paulo', id: 3531803, name: 'Monte Mor' },
  { state: 'São Paulo', id: 3531704, name: 'Monteiro Lobato' },
  { state: 'São Paulo', id: 3531902, name: 'Morro Agudo' },
  { state: 'São Paulo', id: 3532009, name: 'Morungaba' },
  { state: 'São Paulo', id: 3532058, name: 'Motuca' },
  { state: 'São Paulo', id: 3532108, name: 'Murutinga do Sul' },
  { state: 'São Paulo', id: 3532157, name: 'Nantes' },
  { state: 'São Paulo', id: 3532207, name: 'Narandiba' },
  { state: 'São Paulo', id: 3532306, name: 'Natividade da Serra' },
  { state: 'São Paulo', id: 3532405, name: 'Nazaré Paulista' },
  { state: 'São Paulo', id: 3532504, name: 'Neves Paulista' },
  { state: 'São Paulo', id: 3532603, name: 'Nhandeara' },
  { state: 'São Paulo', id: 3532702, name: 'Nipoã' },
  { state: 'São Paulo', id: 3532801, name: 'Nova Aliança' },
  { state: 'São Paulo', id: 3532827, name: 'Nova Campina' },
  { state: 'São Paulo', id: 3532843, name: 'Nova Canaã Paulista' },
  { state: 'São Paulo', id: 3532868, name: 'Nova Castilho' },
  { state: 'São Paulo', id: 3532900, name: 'Nova Europa' },
  { state: 'São Paulo', id: 3533007, name: 'Nova Granada' },
  { state: 'São Paulo', id: 3533106, name: 'Nova Guataporanga' },
  { state: 'São Paulo', id: 3533205, name: 'Nova Independência' },
  { state: 'São Paulo', id: 3533304, name: 'Nova Luzitânia' },
  { state: 'São Paulo', id: 3533403, name: 'Nova Odessa' },
  { state: 'São Paulo', id: 3533254, name: 'Novais' },
  { state: 'São Paulo', id: 3533502, name: 'Novo Horizonte' },
  { state: 'São Paulo', id: 3533601, name: 'Nuporanga' },
  { state: 'São Paulo', id: 3533700, name: 'Ocauçu' },
  { state: 'São Paulo', id: 3533809, name: 'Óleo' },
  { state: 'São Paulo', id: 3533908, name: 'Olímpia' },
  { state: 'São Paulo', id: 3534005, name: 'Onda Verde' },
  { state: 'São Paulo', id: 3534104, name: 'Oriente' },
  { state: 'São Paulo', id: 3534203, name: 'Orindiúva' },
  { state: 'São Paulo', id: 3534302, name: 'Orlândia' },
  { state: 'São Paulo', id: 3534401, name: 'Osasco' },
  { state: 'São Paulo', id: 3534500, name: 'Oscar Bressane' },
  { state: 'São Paulo', id: 3534609, name: 'Osvaldo Cruz' },
  { state: 'São Paulo', id: 3534708, name: 'Ourinhos' },
  { state: 'São Paulo', id: 3534807, name: 'Ouro Verde' },
  { state: 'São Paulo', id: 3534757, name: 'Ouroeste' },
  { state: 'São Paulo', id: 3534906, name: 'Pacaembu' },
  { state: 'São Paulo', id: 3535002, name: 'Palestina' },
  { state: 'São Paulo', id: 3535101, name: 'Palmares Paulista' },
  { state: 'São Paulo', id: 3535200, name: "Palmeira d'Oeste" },
  { state: 'São Paulo', id: 3535309, name: 'Palmital' },
  { state: 'São Paulo', id: 3535408, name: 'Panorama' },
  { state: 'São Paulo', id: 3535507, name: 'Paraguaçu Paulista' },
  { state: 'São Paulo', id: 3535606, name: 'Paraibuna' },
  { state: 'São Paulo', id: 3535705, name: 'Paraíso' },
  { state: 'São Paulo', id: 3535804, name: 'Paranapanema' },
  { state: 'São Paulo', id: 3535903, name: 'Paranapuã' },
  { state: 'São Paulo', id: 3536000, name: 'Parapuã' },
  { state: 'São Paulo', id: 3536109, name: 'Pardinho' },
  { state: 'São Paulo', id: 3536208, name: 'Pariquera-Açu' },
  { state: 'São Paulo', id: 3536257, name: 'Parisi' },
  { state: 'São Paulo', id: 3536307, name: 'Patrocínio Paulista' },
  { state: 'São Paulo', id: 3536406, name: 'Paulicéia' },
  { state: 'São Paulo', id: 3536505, name: 'Paulínia' },
  { state: 'São Paulo', id: 3536570, name: 'Paulistânia' },
  { state: 'São Paulo', id: 3536604, name: 'Paulo de Faria' },
  { state: 'São Paulo', id: 3536703, name: 'Pederneiras' },
  { state: 'São Paulo', id: 3536802, name: 'Pedra Bela' },
  { state: 'São Paulo', id: 3536901, name: 'Pedranópolis' },
  { state: 'São Paulo', id: 3537008, name: 'Pedregulho' },
  { state: 'São Paulo', id: 3537107, name: 'Pedreira' },
  { state: 'São Paulo', id: 3537156, name: 'Pedrinhas Paulista' },
  { state: 'São Paulo', id: 3537206, name: 'Pedro de Toledo' },
  { state: 'São Paulo', id: 3537305, name: 'Penápolis' },
  { state: 'São Paulo', id: 3537404, name: 'Pereira Barreto' },
  { state: 'São Paulo', id: 3537503, name: 'Pereiras' },
  { state: 'São Paulo', id: 3537602, name: 'Peruíbe' },
  { state: 'São Paulo', id: 3537701, name: 'Piacatu' },
  { state: 'São Paulo', id: 3537800, name: 'Piedade' },
  { state: 'São Paulo', id: 3537909, name: 'Pilar do Sul' },
  { state: 'São Paulo', id: 3538006, name: 'Pindamonhangaba' },
  { state: 'São Paulo', id: 3538105, name: 'Pindorama' },
  { state: 'São Paulo', id: 3538204, name: 'Pinhalzinho' },
  { state: 'São Paulo', id: 3538303, name: 'Piquerobi' },
  { state: 'São Paulo', id: 3538501, name: 'Piquete' },
  { state: 'São Paulo', id: 3538600, name: 'Piracaia' },
  { state: 'São Paulo', id: 3538709, name: 'Piracicaba' },
  { state: 'São Paulo', id: 3538808, name: 'Piraju' },
  { state: 'São Paulo', id: 3538907, name: 'Pirajuí' },
  { state: 'São Paulo', id: 3539004, name: 'Pirangi' },
  { state: 'São Paulo', id: 3539103, name: 'Pirapora do Bom Jesus' },
  { state: 'São Paulo', id: 3539202, name: 'Pirapozinho' },
  { state: 'São Paulo', id: 3539301, name: 'Pirassununga' },
  { state: 'São Paulo', id: 3539400, name: 'Piratininga' },
  { state: 'São Paulo', id: 3539509, name: 'Pitangueiras' },
  { state: 'São Paulo', id: 3539608, name: 'Planalto' },
  { state: 'São Paulo', id: 3539707, name: 'Platina' },
  { state: 'São Paulo', id: 3539806, name: 'Poá' },
  { state: 'São Paulo', id: 3539905, name: 'Poloni' },
  { state: 'São Paulo', id: 3540002, name: 'Pompéia' },
  { state: 'São Paulo', id: 3540101, name: 'Pongaí' },
  { state: 'São Paulo', id: 3540200, name: 'Pontal' },
  { state: 'São Paulo', id: 3540259, name: 'Pontalinda' },
  { state: 'São Paulo', id: 3540309, name: 'Pontes Gestal' },
  { state: 'São Paulo', id: 3540408, name: 'Populina' },
  { state: 'São Paulo', id: 3540507, name: 'Porangaba' },
  { state: 'São Paulo', id: 3540606, name: 'Porto Feliz' },
  { state: 'São Paulo', id: 3540705, name: 'Porto Ferreira' },
  { state: 'São Paulo', id: 3540754, name: 'Potim' },
  { state: 'São Paulo', id: 3540804, name: 'Potirendaba' },
  { state: 'São Paulo', id: 3540853, name: 'Pracinha' },
  { state: 'São Paulo', id: 3540903, name: 'Pradópolis' },
  { state: 'São Paulo', id: 3541000, name: 'Praia Grande' },
  { state: 'São Paulo', id: 3541059, name: 'Pratânia' },
  { state: 'São Paulo', id: 3541109, name: 'Presidente Alves' },
  { state: 'São Paulo', id: 3541208, name: 'Presidente Bernardes' },
  { state: 'São Paulo', id: 3541307, name: 'Presidente Epitácio' },
  { state: 'São Paulo', id: 3541406, name: 'Presidente Prudente' },
  { state: 'São Paulo', id: 3541505, name: 'Presidente Venceslau' },
  { state: 'São Paulo', id: 3541604, name: 'Promissão' },
  { state: 'São Paulo', id: 3541653, name: 'Quadra' },
  { state: 'São Paulo', id: 3541703, name: 'Quatá' },
  { state: 'São Paulo', id: 3541802, name: 'Queiroz' },
  { state: 'São Paulo', id: 3541901, name: 'Queluz' },
  { state: 'São Paulo', id: 3542008, name: 'Quintana' },
  { state: 'São Paulo', id: 3542107, name: 'Rafard' },
  { state: 'São Paulo', id: 3542206, name: 'Rancharia' },
  { state: 'São Paulo', id: 3542305, name: 'Redenção da Serra' },
  { state: 'São Paulo', id: 3542404, name: 'Regente Feijó' },
  { state: 'São Paulo', id: 3542503, name: 'Reginópolis' },
  { state: 'São Paulo', id: 3542602, name: 'Registro' },
  { state: 'São Paulo', id: 3542701, name: 'Restinga' },
  { state: 'São Paulo', id: 3542800, name: 'Ribeira' },
  { state: 'São Paulo', id: 3542909, name: 'Ribeirão Bonito' },
  { state: 'São Paulo', id: 3543006, name: 'Ribeirão Branco' },
  { state: 'São Paulo', id: 3543105, name: 'Ribeirão Corrente' },
  { state: 'São Paulo', id: 3543204, name: 'Ribeirão do Sul' },
  { state: 'São Paulo', id: 3543238, name: 'Ribeirão dos Índios' },
  { state: 'São Paulo', id: 3543253, name: 'Ribeirão Grande' },
  { state: 'São Paulo', id: 3543303, name: 'Ribeirão Pires' },
  { state: 'São Paulo', id: 3543402, name: 'Ribeirão Preto' },
  { state: 'São Paulo', id: 3543600, name: 'Rifaina' },
  { state: 'São Paulo', id: 3543709, name: 'Rincão' },
  { state: 'São Paulo', id: 3543808, name: 'Rinópolis' },
  { state: 'São Paulo', id: 3543907, name: 'Rio Claro' },
  { state: 'São Paulo', id: 3544004, name: 'Rio das Pedras' },
  { state: 'São Paulo', id: 3544103, name: 'Rio Grande da Serra' },
  { state: 'São Paulo', id: 3544202, name: 'Riolândia' },
  { state: 'São Paulo', id: 3543501, name: 'Riversul' },
  { state: 'São Paulo', id: 3544251, name: 'Rosana' },
  { state: 'São Paulo', id: 3544301, name: 'Roseira' },
  { state: 'São Paulo', id: 3544400, name: 'Rubiácea' },
  { state: 'São Paulo', id: 3544509, name: 'Rubinéia' },
  { state: 'São Paulo', id: 3544608, name: 'Sabino' },
  { state: 'São Paulo', id: 3544707, name: 'Sagres' },
  { state: 'São Paulo', id: 3544806, name: 'Sales' },
  { state: 'São Paulo', id: 3544905, name: 'Sales Oliveira' },
  { state: 'São Paulo', id: 3545001, name: 'Salesópolis' },
  { state: 'São Paulo', id: 3545100, name: 'Salmourão' },
  { state: 'São Paulo', id: 3545159, name: 'Saltinho' },
  { state: 'São Paulo', id: 3545209, name: 'Salto' },
  { state: 'São Paulo', id: 3545308, name: 'Salto de Pirapora' },
  { state: 'São Paulo', id: 3545407, name: 'Salto Grande' },
  { state: 'São Paulo', id: 3545506, name: 'Sandovalina' },
  { state: 'São Paulo', id: 3545605, name: 'Santa Adélia' },
  { state: 'São Paulo', id: 3545704, name: 'Santa Albertina' },
  { state: 'São Paulo', id: 3545803, name: "Santa Bárbara d'Oeste" },
  { state: 'São Paulo', id: 3546009, name: 'Santa Branca' },
  { state: 'São Paulo', id: 3546108, name: "Santa Clara d'Oeste" },
  { state: 'São Paulo', id: 3546207, name: 'Santa Cruz da Conceição' },
  { state: 'São Paulo', id: 3546256, name: 'Santa Cruz da Esperança' },
  { state: 'São Paulo', id: 3546306, name: 'Santa Cruz das Palmeiras' },
  { state: 'São Paulo', id: 3546405, name: 'Santa Cruz do Rio Pardo' },
  { state: 'São Paulo', id: 3546504, name: 'Santa Ernestina' },
  { state: 'São Paulo', id: 3546603, name: 'Santa Fé do Sul' },
  { state: 'São Paulo', id: 3546702, name: 'Santa Gertrudes' },
  { state: 'São Paulo', id: 3546801, name: 'Santa Isabel' },
  { state: 'São Paulo', id: 3546900, name: 'Santa Lúcia' },
  { state: 'São Paulo', id: 3547007, name: 'Santa Maria da Serra' },
  { state: 'São Paulo', id: 3547106, name: 'Santa Mercedes' },
  { state: 'São Paulo', id: 3547502, name: 'Santa Rita do Passa Quatro' },
  { state: 'São Paulo', id: 3547403, name: "Santa Rita d'Oeste" },
  { state: 'São Paulo', id: 3547601, name: 'Santa Rosa de Viterbo' },
  { state: 'São Paulo', id: 3547650, name: 'Santa Salete' },
  { state: 'São Paulo', id: 3547205, name: 'Santana da Ponte Pensa' },
  { state: 'São Paulo', id: 3547304, name: 'Santana de Parnaíba' },
  { state: 'São Paulo', id: 3547700, name: 'Santo Anastácio' },
  { state: 'São Paulo', id: 3547809, name: 'Santo André' },
  { state: 'São Paulo', id: 3547908, name: 'Santo Antônio da Alegria' },
  { state: 'São Paulo', id: 3548005, name: 'Santo Antônio de Posse' },
  { state: 'São Paulo', id: 3548054, name: 'Santo Antônio do Aracanguá' },
  { state: 'São Paulo', id: 3548104, name: 'Santo Antônio do Jardim' },
  { state: 'São Paulo', id: 3548203, name: 'Santo Antônio do Pinhal' },
  { state: 'São Paulo', id: 3548302, name: 'Santo Expedito' },
  { state: 'São Paulo', id: 3548401, name: 'Santópolis do Aguapeí' },
  { state: 'São Paulo', id: 3548500, name: 'Santos' },
  { state: 'São Paulo', id: 3548609, name: 'São Bento do Sapucaí' },
  { state: 'São Paulo', id: 3548708, name: 'São Bernardo do Campo' },
  { state: 'São Paulo', id: 3548807, name: 'São Caetano do Sul' },
  { state: 'São Paulo', id: 3548906, name: 'São Carlos' },
  { state: 'São Paulo', id: 3549003, name: 'São Francisco' },
  { state: 'São Paulo', id: 3549102, name: 'São João da Boa Vista' },
  { state: 'São Paulo', id: 3549201, name: 'São João das Duas Pontes' },
  { state: 'São Paulo', id: 3549250, name: 'São João de Iracema' },
  { state: 'São Paulo', id: 3549300, name: "São João do Pau d'Alho" },
  { state: 'São Paulo', id: 3549409, name: 'São Joaquim da Barra' },
  { state: 'São Paulo', id: 3549508, name: 'São José da Bela Vista' },
  { state: 'São Paulo', id: 3549607, name: 'São José do Barreiro' },
  { state: 'São Paulo', id: 3549706, name: 'São José do Rio Pardo' },
  { state: 'São Paulo', id: 3549805, name: 'São José do Rio Preto' },
  { state: 'São Paulo', id: 3549904, name: 'São José dos Campos' },
  { state: 'São Paulo', id: 3549953, name: 'São Lourenço da Serra' },
  { state: 'São Paulo', id: 3550001, name: 'São Luiz do Paraitinga' },
  { state: 'São Paulo', id: 3550100, name: 'São Manuel' },
  { state: 'São Paulo', id: 3550209, name: 'São Miguel Arcanjo' },
  { state: 'São Paulo', id: 3550308, name: 'São Paulo' },
  { state: 'São Paulo', id: 3550407, name: 'São Pedro' },
  { state: 'São Paulo', id: 3550506, name: 'São Pedro do Turvo' },
  { state: 'São Paulo', id: 3550605, name: 'São Roque' },
  { state: 'São Paulo', id: 3550704, name: 'São Sebastião' },
  { state: 'São Paulo', id: 3550803, name: 'São Sebastião da Grama' },
  { state: 'São Paulo', id: 3550902, name: 'São Simão' },
  { state: 'São Paulo', id: 3551009, name: 'São Vicente' },
  { state: 'São Paulo', id: 3551108, name: 'Sarapuí' },
  { state: 'São Paulo', id: 3551207, name: 'Sarutaiá' },
  { state: 'São Paulo', id: 3551306, name: 'Sebastianópolis do Sul' },
  { state: 'São Paulo', id: 3551405, name: 'Serra Azul' },
  { state: 'São Paulo', id: 3551603, name: 'Serra Negra' },
  { state: 'São Paulo', id: 3551504, name: 'Serrana' },
  { state: 'São Paulo', id: 3551702, name: 'Sertãozinho' },
  { state: 'São Paulo', id: 3551801, name: 'Sete Barras' },
  { state: 'São Paulo', id: 3551900, name: 'Severínia' },
  { state: 'São Paulo', id: 3552007, name: 'Silveiras' },
  { state: 'São Paulo', id: 3552106, name: 'Socorro' },
  { state: 'São Paulo', id: 3552205, name: 'Sorocaba' },
  { state: 'São Paulo', id: 3552304, name: 'Sud Mennucci' },
  { state: 'São Paulo', id: 3552403, name: 'Sumaré' },
  { state: 'São Paulo', id: 3552551, name: 'Suzanápolis' },
  { state: 'São Paulo', id: 3552502, name: 'Suzano' },
  { state: 'São Paulo', id: 3552601, name: 'Tabapuã' },
  { state: 'São Paulo', id: 3552700, name: 'Tabatinga' },
  { state: 'São Paulo', id: 3552809, name: 'Taboão da Serra' },
  { state: 'São Paulo', id: 3552908, name: 'Taciba' },
  { state: 'São Paulo', id: 3553005, name: 'Taguaí' },
  { state: 'São Paulo', id: 3553104, name: 'Taiaçu' },
  { state: 'São Paulo', id: 3553203, name: 'Taiúva' },
  { state: 'São Paulo', id: 3553302, name: 'Tambaú' },
  { state: 'São Paulo', id: 3553401, name: 'Tanabi' },
  { state: 'São Paulo', id: 3553500, name: 'Tapiraí' },
  { state: 'São Paulo', id: 3553609, name: 'Tapiratiba' },
  { state: 'São Paulo', id: 3553658, name: 'Taquaral' },
  { state: 'São Paulo', id: 3553708, name: 'Taquaritinga' },
  { state: 'São Paulo', id: 3553807, name: 'Taquarituba' },
  { state: 'São Paulo', id: 3553856, name: 'Taquarivaí' },
  { state: 'São Paulo', id: 3553906, name: 'Tarabai' },
  { state: 'São Paulo', id: 3553955, name: 'Tarumã' },
  { state: 'São Paulo', id: 3554003, name: 'Tatuí' },
  { state: 'São Paulo', id: 3554102, name: 'Taubaté' },
  { state: 'São Paulo', id: 3554201, name: 'Tejupá' },
  { state: 'São Paulo', id: 3554300, name: 'Teodoro Sampaio' },
  { state: 'São Paulo', id: 3554409, name: 'Terra Roxa' },
  { state: 'São Paulo', id: 3554508, name: 'Tietê' },
  { state: 'São Paulo', id: 3554607, name: 'Timburi' },
  { state: 'São Paulo', id: 3554656, name: 'Torre de Pedra' },
  { state: 'São Paulo', id: 3554706, name: 'Torrinha' },
  { state: 'São Paulo', id: 3554755, name: 'Trabiju' },
  { state: 'São Paulo', id: 3554805, name: 'Tremembé' },
  { state: 'São Paulo', id: 3554904, name: 'Três Fronteiras' },
  { state: 'São Paulo', id: 3554953, name: 'Tuiuti' },
  { state: 'São Paulo', id: 3555000, name: 'Tupã' },
  { state: 'São Paulo', id: 3555109, name: 'Tupi Paulista' },
  { state: 'São Paulo', id: 3555208, name: 'Turiúba' },
  { state: 'São Paulo', id: 3555307, name: 'Turmalina' },
  { state: 'São Paulo', id: 3555356, name: 'Ubarana' },
  { state: 'São Paulo', id: 3555406, name: 'Ubatuba' },
  { state: 'São Paulo', id: 3555505, name: 'Ubirajara' },
  { state: 'São Paulo', id: 3555604, name: 'Uchoa' },
  { state: 'São Paulo', id: 3555703, name: 'União Paulista' },
  { state: 'São Paulo', id: 3555802, name: 'Urânia' },
  { state: 'São Paulo', id: 3555901, name: 'Uru' },
  { state: 'São Paulo', id: 3556008, name: 'Urupês' },
  { state: 'São Paulo', id: 3556107, name: 'Valentim Gentil' },
  { state: 'São Paulo', id: 3556206, name: 'Valinhos' },
  { state: 'São Paulo', id: 3556305, name: 'Valparaíso' },
  { state: 'São Paulo', id: 3556354, name: 'Vargem' },
  { state: 'São Paulo', id: 3556404, name: 'Vargem Grande do Sul' },
  { state: 'São Paulo', id: 3556453, name: 'Vargem Grande Paulista' },
  { state: 'São Paulo', id: 3556503, name: 'Várzea Paulista' },
  { state: 'São Paulo', id: 3556602, name: 'Vera Cruz' },
  { state: 'São Paulo', id: 3556701, name: 'Vinhedo' },
  { state: 'São Paulo', id: 3556800, name: 'Viradouro' },
  { state: 'São Paulo', id: 3556909, name: 'Vista Alegre do Alto' },
  { state: 'São Paulo', id: 3556958, name: 'Vitória Brasil' },
  { state: 'São Paulo', id: 3557006, name: 'Votorantim' },
  { state: 'São Paulo', id: 3557105, name: 'Votuporanga' },
  { state: 'São Paulo', id: 3557154, name: 'Zacarias' },
  { state: 'Paraná', id: 4100103, name: 'Abatiá' },
  { state: 'Paraná', id: 4100202, name: 'Adrianópolis' },
  { state: 'Paraná', id: 4100301, name: 'Agudos do Sul' },
  { state: 'Paraná', id: 4100400, name: 'Almirante Tamandaré' },
  { state: 'Paraná', id: 4100459, name: 'Altamira do Paraná' },
  { state: 'Paraná', id: 4128625, name: 'Alto Paraíso' },
  { state: 'Paraná', id: 4100608, name: 'Alto Paraná' },
  { state: 'Paraná', id: 4100707, name: 'Alto Piquiri' },
  { state: 'Paraná', id: 4100509, name: 'Altônia' },
  { state: 'Paraná', id: 4100806, name: 'Alvorada do Sul' },
  { state: 'Paraná', id: 4100905, name: 'Amaporã' },
  { state: 'Paraná', id: 4101002, name: 'Ampére' },
  { state: 'Paraná', id: 4101051, name: 'Anahy' },
  { state: 'Paraná', id: 4101101, name: 'Andirá' },
  { state: 'Paraná', id: 4101150, name: 'Ângulo' },
  { state: 'Paraná', id: 4101200, name: 'Antonina' },
  { state: 'Paraná', id: 4101309, name: 'Antônio Olinto' },
  { state: 'Paraná', id: 4101408, name: 'Apucarana' },
  { state: 'Paraná', id: 4101507, name: 'Arapongas' },
  { state: 'Paraná', id: 4101606, name: 'Arapoti' },
  { state: 'Paraná', id: 4101655, name: 'Arapuã' },
  { state: 'Paraná', id: 4101705, name: 'Araruna' },
  { state: 'Paraná', id: 4101804, name: 'Araucária' },
  { state: 'Paraná', id: 4101853, name: 'Ariranha do Ivaí' },
  { state: 'Paraná', id: 4101903, name: 'Assaí' },
  { state: 'Paraná', id: 4102000, name: 'Assis Chateaubriand' },
  { state: 'Paraná', id: 4102109, name: 'Astorga' },
  { state: 'Paraná', id: 4102208, name: 'Atalaia' },
  { state: 'Paraná', id: 4102307, name: 'Balsa Nova' },
  { state: 'Paraná', id: 4102406, name: 'Bandeirantes' },
  { state: 'Paraná', id: 4102505, name: 'Barbosa Ferraz' },
  { state: 'Paraná', id: 4102703, name: 'Barra do Jacaré' },
  { state: 'Paraná', id: 4102604, name: 'Barracão' },
  { state: 'Paraná', id: 4102752, name: 'Bela Vista da Caroba' },
  { state: 'Paraná', id: 4102802, name: 'Bela Vista do Paraíso' },
  { state: 'Paraná', id: 4102901, name: 'Bituruna' },
  { state: 'Paraná', id: 4103008, name: 'Boa Esperança' },
  { state: 'Paraná', id: 4103024, name: 'Boa Esperança do Iguaçu' },
  { state: 'Paraná', id: 4103040, name: 'Boa Ventura de São Roque' },
  { state: 'Paraná', id: 4103057, name: 'Boa Vista da Aparecida' },
  { state: 'Paraná', id: 4103107, name: 'Bocaiúva do Sul' },
  { state: 'Paraná', id: 4103156, name: 'Bom Jesus do Sul' },
  { state: 'Paraná', id: 4103206, name: 'Bom Sucesso' },
  { state: 'Paraná', id: 4103222, name: 'Bom Sucesso do Sul' },
  { state: 'Paraná', id: 4103305, name: 'Borrazópolis' },
  { state: 'Paraná', id: 4103354, name: 'Braganey' },
  { state: 'Paraná', id: 4103370, name: 'Brasilândia do Sul' },
  { state: 'Paraná', id: 4103404, name: 'Cafeara' },
  { state: 'Paraná', id: 4103453, name: 'Cafelândia' },
  { state: 'Paraná', id: 4103479, name: 'Cafezal do Sul' },
  { state: 'Paraná', id: 4103503, name: 'Califórnia' },
  { state: 'Paraná', id: 4103602, name: 'Cambará' },
  { state: 'Paraná', id: 4103701, name: 'Cambé' },
  { state: 'Paraná', id: 4103800, name: 'Cambira' },
  { state: 'Paraná', id: 4103909, name: 'Campina da Lagoa' },
  { state: 'Paraná', id: 4103958, name: 'Campina do Simão' },
  { state: 'Paraná', id: 4104006, name: 'Campina Grande do Sul' },
  { state: 'Paraná', id: 4104055, name: 'Campo Bonito' },
  { state: 'Paraná', id: 4104105, name: 'Campo do Tenente' },
  { state: 'Paraná', id: 4104204, name: 'Campo Largo' },
  { state: 'Paraná', id: 4104253, name: 'Campo Magro' },
  { state: 'Paraná', id: 4104303, name: 'Campo Mourão' },
  { state: 'Paraná', id: 4104402, name: 'Cândido de Abreu' },
  { state: 'Paraná', id: 4104428, name: 'Candói' },
  { state: 'Paraná', id: 4104451, name: 'Cantagalo' },
  { state: 'Paraná', id: 4104501, name: 'Capanema' },
  { state: 'Paraná', id: 4104600, name: 'Capitão Leônidas Marques' },
  { state: 'Paraná', id: 4104659, name: 'Carambeí' },
  { state: 'Paraná', id: 4104709, name: 'Carlópolis' },
  { state: 'Paraná', id: 4104808, name: 'Cascavel' },
  { state: 'Paraná', id: 4104907, name: 'Castro' },
  { state: 'Paraná', id: 4105003, name: 'Catanduvas' },
  { state: 'Paraná', id: 4105102, name: 'Centenário do Sul' },
  { state: 'Paraná', id: 4105201, name: 'Cerro Azul' },
  { state: 'Paraná', id: 4105300, name: 'Céu Azul' },
  { state: 'Paraná', id: 4105409, name: 'Chopinzinho' },
  { state: 'Paraná', id: 4105508, name: 'Cianorte' },
  { state: 'Paraná', id: 4105607, name: 'Cidade Gaúcha' },
  { state: 'Paraná', id: 4105706, name: 'Clevelândia' },
  { state: 'Paraná', id: 4105805, name: 'Colombo' },
  { state: 'Paraná', id: 4105904, name: 'Colorado' },
  { state: 'Paraná', id: 4106001, name: 'Congonhinhas' },
  { state: 'Paraná', id: 4106100, name: 'Conselheiro Mairinck' },
  { state: 'Paraná', id: 4106209, name: 'Contenda' },
  { state: 'Paraná', id: 4106308, name: 'Corbélia' },
  { state: 'Paraná', id: 4106407, name: 'Cornélio Procópio' },
  { state: 'Paraná', id: 4106456, name: 'Coronel Domingos Soares' },
  { state: 'Paraná', id: 4106506, name: 'Coronel Vivida' },
  { state: 'Paraná', id: 4106555, name: 'Corumbataí do Sul' },
  { state: 'Paraná', id: 4106803, name: 'Cruz Machado' },
  { state: 'Paraná', id: 4106571, name: 'Cruzeiro do Iguaçu' },
  { state: 'Paraná', id: 4106605, name: 'Cruzeiro do Oeste' },
  { state: 'Paraná', id: 4106704, name: 'Cruzeiro do Sul' },
  { state: 'Paraná', id: 4106852, name: 'Cruzmaltina' },
  { state: 'Paraná', id: 4106902, name: 'Curitiba' },
  { state: 'Paraná', id: 4107009, name: 'Curiúva' },
  { state: 'Paraná', id: 4107108, name: 'Diamante do Norte' },
  { state: 'Paraná', id: 4107124, name: 'Diamante do Sul' },
  { state: 'Paraná', id: 4107157, name: "Diamante D'Oeste" },
  { state: 'Paraná', id: 4107207, name: 'Dois Vizinhos' },
  { state: 'Paraná', id: 4107256, name: 'Douradina' },
  { state: 'Paraná', id: 4107306, name: 'Doutor Camargo' },
  { state: 'Paraná', id: 4128633, name: 'Doutor Ulysses' },
  { state: 'Paraná', id: 4107405, name: 'Enéas Marques' },
  { state: 'Paraná', id: 4107504, name: 'Engenheiro Beltrão' },
  { state: 'Paraná', id: 4107538, name: 'Entre Rios do Oeste' },
  { state: 'Paraná', id: 4107520, name: 'Esperança Nova' },
  { state: 'Paraná', id: 4107546, name: 'Espigão Alto do Iguaçu' },
  { state: 'Paraná', id: 4107553, name: 'Farol' },
  { state: 'Paraná', id: 4107603, name: 'Faxinal' },
  { state: 'Paraná', id: 4107652, name: 'Fazenda Rio Grande' },
  { state: 'Paraná', id: 4107702, name: 'Fênix' },
  { state: 'Paraná', id: 4107736, name: 'Fernandes Pinheiro' },
  { state: 'Paraná', id: 4107751, name: 'Figueira' },
  { state: 'Paraná', id: 4107850, name: 'Flor da Serra do Sul' },
  { state: 'Paraná', id: 4107801, name: 'Floraí' },
  { state: 'Paraná', id: 4107900, name: 'Floresta' },
  { state: 'Paraná', id: 4108007, name: 'Florestópolis' },
  { state: 'Paraná', id: 4108106, name: 'Flórida' },
  { state: 'Paraná', id: 4108205, name: 'Formosa do Oeste' },
  { state: 'Paraná', id: 4108304, name: 'Foz do Iguaçu' },
  { state: 'Paraná', id: 4108452, name: 'Foz do Jordão' },
  { state: 'Paraná', id: 4108320, name: 'Francisco Alves' },
  { state: 'Paraná', id: 4108403, name: 'Francisco Beltrão' },
  { state: 'Paraná', id: 4108502, name: 'General Carneiro' },
  { state: 'Paraná', id: 4108551, name: 'Godoy Moreira' },
  { state: 'Paraná', id: 4108601, name: 'Goioerê' },
  { state: 'Paraná', id: 4108650, name: 'Goioxim' },
  { state: 'Paraná', id: 4108700, name: 'Grandes Rios' },
  { state: 'Paraná', id: 4108809, name: 'Guaíra' },
  { state: 'Paraná', id: 4108908, name: 'Guairaçá' },
  { state: 'Paraná', id: 4108957, name: 'Guamiranga' },
  { state: 'Paraná', id: 4109005, name: 'Guapirama' },
  { state: 'Paraná', id: 4109104, name: 'Guaporema' },
  { state: 'Paraná', id: 4109203, name: 'Guaraci' },
  { state: 'Paraná', id: 4109302, name: 'Guaraniaçu' },
  { state: 'Paraná', id: 4109401, name: 'Guarapuava' },
  { state: 'Paraná', id: 4109500, name: 'Guaraqueçaba' },
  { state: 'Paraná', id: 4109609, name: 'Guaratuba' },
  { state: 'Paraná', id: 4109658, name: 'Honório Serpa' },
  { state: 'Paraná', id: 4109708, name: 'Ibaiti' },
  { state: 'Paraná', id: 4109757, name: 'Ibema' },
  { state: 'Paraná', id: 4109807, name: 'Ibiporã' },
  { state: 'Paraná', id: 4109906, name: 'Icaraíma' },
  { state: 'Paraná', id: 4110003, name: 'Iguaraçu' },
  { state: 'Paraná', id: 4110052, name: 'Iguatu' },
  { state: 'Paraná', id: 4110078, name: 'Imbaú' },
  { state: 'Paraná', id: 4110102, name: 'Imbituva' },
  { state: 'Paraná', id: 4110201, name: 'Inácio Martins' },
  { state: 'Paraná', id: 4110300, name: 'Inajá' },
  { state: 'Paraná', id: 4110409, name: 'Indianópolis' },
  { state: 'Paraná', id: 4110508, name: 'Ipiranga' },
  { state: 'Paraná', id: 4110607, name: 'Iporã' },
  { state: 'Paraná', id: 4110656, name: 'Iracema do Oeste' },
  { state: 'Paraná', id: 4110706, name: 'Irati' },
  { state: 'Paraná', id: 4110805, name: 'Iretama' },
  { state: 'Paraná', id: 4110904, name: 'Itaguajé' },
  { state: 'Paraná', id: 4110953, name: 'Itaipulândia' },
  { state: 'Paraná', id: 4111001, name: 'Itambaracá' },
  { state: 'Paraná', id: 4111100, name: 'Itambé' },
  { state: 'Paraná', id: 4111209, name: "Itapejara d'Oeste" },
  { state: 'Paraná', id: 4111258, name: 'Itaperuçu' },
  { state: 'Paraná', id: 4111308, name: 'Itaúna do Sul' },
  { state: 'Paraná', id: 4111407, name: 'Ivaí' },
  { state: 'Paraná', id: 4111506, name: 'Ivaiporã' },
  { state: 'Paraná', id: 4111555, name: 'Ivaté' },
  { state: 'Paraná', id: 4111605, name: 'Ivatuba' },
  { state: 'Paraná', id: 4111704, name: 'Jaboti' },
  { state: 'Paraná', id: 4111803, name: 'Jacarezinho' },
  { state: 'Paraná', id: 4111902, name: 'Jaguapitã' },
  { state: 'Paraná', id: 4112009, name: 'Jaguariaíva' },
  { state: 'Paraná', id: 4112108, name: 'Jandaia do Sul' },
  { state: 'Paraná', id: 4112207, name: 'Janiópolis' },
  { state: 'Paraná', id: 4112306, name: 'Japira' },
  { state: 'Paraná', id: 4112405, name: 'Japurá' },
  { state: 'Paraná', id: 4112504, name: 'Jardim Alegre' },
  { state: 'Paraná', id: 4112603, name: 'Jardim Olinda' },
  { state: 'Paraná', id: 4112702, name: 'Jataizinho' },
  { state: 'Paraná', id: 4112751, name: 'Jesuítas' },
  { state: 'Paraná', id: 4112801, name: 'Joaquim Távora' },
  { state: 'Paraná', id: 4112900, name: 'Jundiaí do Sul' },
  { state: 'Paraná', id: 4112959, name: 'Juranda' },
  { state: 'Paraná', id: 4113007, name: 'Jussara' },
  { state: 'Paraná', id: 4113106, name: 'Kaloré' },
  { state: 'Paraná', id: 4113205, name: 'Lapa' },
  { state: 'Paraná', id: 4113254, name: 'Laranjal' },
  { state: 'Paraná', id: 4113304, name: 'Laranjeiras do Sul' },
  { state: 'Paraná', id: 4113403, name: 'Leópolis' },
  { state: 'Paraná', id: 4113429, name: 'Lidianópolis' },
  { state: 'Paraná', id: 4113452, name: 'Lindoeste' },
  { state: 'Paraná', id: 4113502, name: 'Loanda' },
  { state: 'Paraná', id: 4113601, name: 'Lobato' },
  { state: 'Paraná', id: 4113700, name: 'Londrina' },
  { state: 'Paraná', id: 4113734, name: 'Luiziana' },
  { state: 'Paraná', id: 4113759, name: 'Lunardelli' },
  { state: 'Paraná', id: 4113809, name: 'Lupionópolis' },
  { state: 'Paraná', id: 4113908, name: 'Mallet' },
  { state: 'Paraná', id: 4114005, name: 'Mamborê' },
  { state: 'Paraná', id: 4114104, name: 'Mandaguaçu' },
  { state: 'Paraná', id: 4114203, name: 'Mandaguari' },
  { state: 'Paraná', id: 4114302, name: 'Mandirituba' },
  { state: 'Paraná', id: 4114351, name: 'Manfrinópolis' },
  { state: 'Paraná', id: 4114401, name: 'Mangueirinha' },
  { state: 'Paraná', id: 4114500, name: 'Manoel Ribas' },
  { state: 'Paraná', id: 4114609, name: 'Marechal Cândido Rondon' },
  { state: 'Paraná', id: 4114708, name: 'Maria Helena' },
  { state: 'Paraná', id: 4114807, name: 'Marialva' },
  { state: 'Paraná', id: 4114906, name: 'Marilândia do Sul' },
  { state: 'Paraná', id: 4115002, name: 'Marilena' },
  { state: 'Paraná', id: 4115101, name: 'Mariluz' },
  { state: 'Paraná', id: 4115200, name: 'Maringá' },
  { state: 'Paraná', id: 4115309, name: 'Mariópolis' },
  { state: 'Paraná', id: 4115358, name: 'Maripá' },
  { state: 'Paraná', id: 4115408, name: 'Marmeleiro' },
  { state: 'Paraná', id: 4115457, name: 'Marquinho' },
  { state: 'Paraná', id: 4115507, name: 'Marumbi' },
  { state: 'Paraná', id: 4115606, name: 'Matelândia' },
  { state: 'Paraná', id: 4115705, name: 'Matinhos' },
  { state: 'Paraná', id: 4115739, name: 'Mato Rico' },
  { state: 'Paraná', id: 4115754, name: 'Mauá da Serra' },
  { state: 'Paraná', id: 4115804, name: 'Medianeira' },
  { state: 'Paraná', id: 4115853, name: 'Mercedes' },
  { state: 'Paraná', id: 4115903, name: 'Mirador' },
  { state: 'Paraná', id: 4116000, name: 'Miraselva' },
  { state: 'Paraná', id: 4116059, name: 'Missal' },
  { state: 'Paraná', id: 4116109, name: 'Moreira Sales' },
  { state: 'Paraná', id: 4116208, name: 'Morretes' },
  { state: 'Paraná', id: 4116307, name: 'Munhoz de Melo' },
  { state: 'Paraná', id: 4116406, name: 'Nossa Senhora das Graças' },
  { state: 'Paraná', id: 4116505, name: 'Nova Aliança do Ivaí' },
  { state: 'Paraná', id: 4116604, name: 'Nova América da Colina' },
  { state: 'Paraná', id: 4116703, name: 'Nova Aurora' },
  { state: 'Paraná', id: 4116802, name: 'Nova Cantu' },
  { state: 'Paraná', id: 4116901, name: 'Nova Esperança' },
  { state: 'Paraná', id: 4116950, name: 'Nova Esperança do Sudoeste' },
  { state: 'Paraná', id: 4117008, name: 'Nova Fátima' },
  { state: 'Paraná', id: 4117057, name: 'Nova Laranjeiras' },
  { state: 'Paraná', id: 4117107, name: 'Nova Londrina' },
  { state: 'Paraná', id: 4117206, name: 'Nova Olímpia' },
  { state: 'Paraná', id: 4117255, name: 'Nova Prata do Iguaçu' },
  { state: 'Paraná', id: 4117214, name: 'Nova Santa Bárbara' },
  { state: 'Paraná', id: 4117222, name: 'Nova Santa Rosa' },
  { state: 'Paraná', id: 4117271, name: 'Nova Tebas' },
  { state: 'Paraná', id: 4117297, name: 'Novo Itacolomi' },
  { state: 'Paraná', id: 4117305, name: 'Ortigueira' },
  { state: 'Paraná', id: 4117404, name: 'Ourizona' },
  { state: 'Paraná', id: 4117453, name: 'Ouro Verde do Oeste' },
  { state: 'Paraná', id: 4117503, name: 'Paiçandu' },
  { state: 'Paraná', id: 4117602, name: 'Palmas' },
  { state: 'Paraná', id: 4117701, name: 'Palmeira' },
  { state: 'Paraná', id: 4117800, name: 'Palmital' },
  { state: 'Paraná', id: 4117909, name: 'Palotina' },
  { state: 'Paraná', id: 4118006, name: 'Paraíso do Norte' },
  { state: 'Paraná', id: 4118105, name: 'Paranacity' },
  { state: 'Paraná', id: 4118204, name: 'Paranaguá' },
  { state: 'Paraná', id: 4118303, name: 'Paranapoema' },
  { state: 'Paraná', id: 4118402, name: 'Paranavaí' },
  { state: 'Paraná', id: 4118451, name: 'Pato Bragado' },
  { state: 'Paraná', id: 4118501, name: 'Pato Branco' },
  { state: 'Paraná', id: 4118600, name: 'Paula Freitas' },
  { state: 'Paraná', id: 4118709, name: 'Paulo Frontin' },
  { state: 'Paraná', id: 4118808, name: 'Peabiru' },
  { state: 'Paraná', id: 4118857, name: 'Perobal' },
  { state: 'Paraná', id: 4118907, name: 'Pérola' },
  { state: 'Paraná', id: 4119004, name: "Pérola d'Oeste" },
  { state: 'Paraná', id: 4119103, name: 'Piên' },
  { state: 'Paraná', id: 4119152, name: 'Pinhais' },
  { state: 'Paraná', id: 4119251, name: 'Pinhal de São Bento' },
  { state: 'Paraná', id: 4119202, name: 'Pinhalão' },
  { state: 'Paraná', id: 4119301, name: 'Pinhão' },
  { state: 'Paraná', id: 4119400, name: 'Piraí do Sul' },
  { state: 'Paraná', id: 4119509, name: 'Piraquara' },
  { state: 'Paraná', id: 4119608, name: 'Pitanga' },
  { state: 'Paraná', id: 4119657, name: 'Pitangueiras' },
  { state: 'Paraná', id: 4119707, name: 'Planaltina do Paraná' },
  { state: 'Paraná', id: 4119806, name: 'Planalto' },
  { state: 'Paraná', id: 4119905, name: 'Ponta Grossa' },
  { state: 'Paraná', id: 4119954, name: 'Pontal do Paraná' },
  { state: 'Paraná', id: 4120002, name: 'Porecatu' },
  { state: 'Paraná', id: 4120101, name: 'Porto Amazonas' },
  { state: 'Paraná', id: 4120150, name: 'Porto Barreiro' },
  { state: 'Paraná', id: 4120200, name: 'Porto Rico' },
  { state: 'Paraná', id: 4120309, name: 'Porto Vitória' },
  { state: 'Paraná', id: 4120333, name: 'Prado Ferreira' },
  { state: 'Paraná', id: 4120358, name: 'Pranchita' },
  { state: 'Paraná', id: 4120408, name: 'Presidente Castelo Branco' },
  { state: 'Paraná', id: 4120507, name: 'Primeiro de Maio' },
  { state: 'Paraná', id: 4120606, name: 'Prudentópolis' },
  { state: 'Paraná', id: 4120655, name: 'Quarto Centenário' },
  { state: 'Paraná', id: 4120705, name: 'Quatiguá' },
  { state: 'Paraná', id: 4120804, name: 'Quatro Barras' },
  { state: 'Paraná', id: 4120853, name: 'Quatro Pontes' },
  { state: 'Paraná', id: 4120903, name: 'Quedas do Iguaçu' },
  { state: 'Paraná', id: 4121000, name: 'Querência do Norte' },
  { state: 'Paraná', id: 4121109, name: 'Quinta do Sol' },
  { state: 'Paraná', id: 4121208, name: 'Quitandinha' },
  { state: 'Paraná', id: 4121257, name: 'Ramilândia' },
  { state: 'Paraná', id: 4121307, name: 'Rancho Alegre' },
  { state: 'Paraná', id: 4121356, name: "Rancho Alegre D'Oeste" },
  { state: 'Paraná', id: 4121406, name: 'Realeza' },
  { state: 'Paraná', id: 4121505, name: 'Rebouças' },
  { state: 'Paraná', id: 4121604, name: 'Renascença' },
  { state: 'Paraná', id: 4121703, name: 'Reserva' },
  { state: 'Paraná', id: 4121752, name: 'Reserva do Iguaçu' },
  { state: 'Paraná', id: 4121802, name: 'Ribeirão Claro' },
  { state: 'Paraná', id: 4121901, name: 'Ribeirão do Pinhal' },
  { state: 'Paraná', id: 4122008, name: 'Rio Azul' },
  { state: 'Paraná', id: 4122107, name: 'Rio Bom' },
  { state: 'Paraná', id: 4122156, name: 'Rio Bonito do Iguaçu' },
  { state: 'Paraná', id: 4122172, name: 'Rio Branco do Ivaí' },
  { state: 'Paraná', id: 4122206, name: 'Rio Branco do Sul' },
  { state: 'Paraná', id: 4122305, name: 'Rio Negro' },
  { state: 'Paraná', id: 4122404, name: 'Rolândia' },
  { state: 'Paraná', id: 4122503, name: 'Roncador' },
  { state: 'Paraná', id: 4122602, name: 'Rondon' },
  { state: 'Paraná', id: 4122651, name: 'Rosário do Ivaí' },
  { state: 'Paraná', id: 4122701, name: 'Sabáudia' },
  { state: 'Paraná', id: 4122800, name: 'Salgado Filho' },
  { state: 'Paraná', id: 4122909, name: 'Salto do Itararé' },
  { state: 'Paraná', id: 4123006, name: 'Salto do Lontra' },
  { state: 'Paraná', id: 4123105, name: 'Santa Amélia' },
  { state: 'Paraná', id: 4123204, name: 'Santa Cecília do Pavão' },
  { state: 'Paraná', id: 4123303, name: 'Santa Cruz de Monte Castelo' },
  { state: 'Paraná', id: 4123402, name: 'Santa Fé' },
  { state: 'Paraná', id: 4123501, name: 'Santa Helena' },
  { state: 'Paraná', id: 4123600, name: 'Santa Inês' },
  { state: 'Paraná', id: 4123709, name: 'Santa Isabel do Ivaí' },
  { state: 'Paraná', id: 4123808, name: 'Santa Izabel do Oeste' },
  { state: 'Paraná', id: 4123824, name: 'Santa Lúcia' },
  { state: 'Paraná', id: 4123857, name: 'Santa Maria do Oeste' },
  { state: 'Paraná', id: 4123907, name: 'Santa Mariana' },
  { state: 'Paraná', id: 4123956, name: 'Santa Mônica' },
  { state: 'Paraná', id: 4124020, name: 'Santa Tereza do Oeste' },
  { state: 'Paraná', id: 4124053, name: 'Santa Terezinha de Itaipu' },
  { state: 'Paraná', id: 4124004, name: 'Santana do Itararé' },
  { state: 'Paraná', id: 4124103, name: 'Santo Antônio da Platina' },
  { state: 'Paraná', id: 4124202, name: 'Santo Antônio do Caiuá' },
  { state: 'Paraná', id: 4124301, name: 'Santo Antônio do Paraíso' },
  { state: 'Paraná', id: 4124400, name: 'Santo Antônio do Sudoeste' },
  { state: 'Paraná', id: 4124509, name: 'Santo Inácio' },
  { state: 'Paraná', id: 4124608, name: 'São Carlos do Ivaí' },
  { state: 'Paraná', id: 4124707, name: 'São Jerônimo da Serra' },
  { state: 'Paraná', id: 4124806, name: 'São João' },
  { state: 'Paraná', id: 4124905, name: 'São João do Caiuá' },
  { state: 'Paraná', id: 4125001, name: 'São João do Ivaí' },
  { state: 'Paraná', id: 4125100, name: 'São João do Triunfo' },
  { state: 'Paraná', id: 4125308, name: 'São Jorge do Ivaí' },
  { state: 'Paraná', id: 4125357, name: 'São Jorge do Patrocínio' },
  { state: 'Paraná', id: 4125209, name: "São Jorge d'Oeste" },
  { state: 'Paraná', id: 4125407, name: 'São José da Boa Vista' },
  { state: 'Paraná', id: 4125456, name: 'São José das Palmeiras' },
  { state: 'Paraná', id: 4125506, name: 'São José dos Pinhais' },
  { state: 'Paraná', id: 4125555, name: 'São Manoel do Paraná' },
  { state: 'Paraná', id: 4125605, name: 'São Mateus do Sul' },
  { state: 'Paraná', id: 4125704, name: 'São Miguel do Iguaçu' },
  { state: 'Paraná', id: 4125753, name: 'São Pedro do Iguaçu' },
  { state: 'Paraná', id: 4125803, name: 'São Pedro do Ivaí' },
  { state: 'Paraná', id: 4125902, name: 'São Pedro do Paraná' },
  { state: 'Paraná', id: 4126009, name: 'São Sebastião da Amoreira' },
  { state: 'Paraná', id: 4126108, name: 'São Tomé' },
  { state: 'Paraná', id: 4126207, name: 'Sapopema' },
  { state: 'Paraná', id: 4126256, name: 'Sarandi' },
  { state: 'Paraná', id: 4126272, name: 'Saudade do Iguaçu' },
  { state: 'Paraná', id: 4126306, name: 'Sengés' },
  { state: 'Paraná', id: 4126355, name: 'Serranópolis do Iguaçu' },
  { state: 'Paraná', id: 4126405, name: 'Sertaneja' },
  { state: 'Paraná', id: 4126504, name: 'Sertanópolis' },
  { state: 'Paraná', id: 4126603, name: 'Siqueira Campos' },
  { state: 'Paraná', id: 4126652, name: 'Sulina' },
  { state: 'Paraná', id: 4126678, name: 'Tamarana' },
  { state: 'Paraná', id: 4126702, name: 'Tamboara' },
  { state: 'Paraná', id: 4126801, name: 'Tapejara' },
  { state: 'Paraná', id: 4126900, name: 'Tapira' },
  { state: 'Paraná', id: 4127007, name: 'Teixeira Soares' },
  { state: 'Paraná', id: 4127106, name: 'Telêmaco Borba' },
  { state: 'Paraná', id: 4127205, name: 'Terra Boa' },
  { state: 'Paraná', id: 4127304, name: 'Terra Rica' },
  { state: 'Paraná', id: 4127403, name: 'Terra Roxa' },
  { state: 'Paraná', id: 4127502, name: 'Tibagi' },
  { state: 'Paraná', id: 4127601, name: 'Tijucas do Sul' },
  { state: 'Paraná', id: 4127700, name: 'Toledo' },
  { state: 'Paraná', id: 4127809, name: 'Tomazina' },
  { state: 'Paraná', id: 4127858, name: 'Três Barras do Paraná' },
  { state: 'Paraná', id: 4127882, name: 'Tunas do Paraná' },
  { state: 'Paraná', id: 4127908, name: 'Tuneiras do Oeste' },
  { state: 'Paraná', id: 4127957, name: 'Tupãssi' },
  { state: 'Paraná', id: 4127965, name: 'Turvo' },
  { state: 'Paraná', id: 4128005, name: 'Ubiratã' },
  { state: 'Paraná', id: 4128104, name: 'Umuarama' },
  { state: 'Paraná', id: 4128203, name: 'União da Vitória' },
  { state: 'Paraná', id: 4128302, name: 'Uniflor' },
  { state: 'Paraná', id: 4128401, name: 'Uraí' },
  { state: 'Paraná', id: 4128534, name: 'Ventania' },
  { state: 'Paraná', id: 4128559, name: 'Vera Cruz do Oeste' },
  { state: 'Paraná', id: 4128609, name: 'Verê' },
  { state: 'Paraná', id: 4128658, name: 'Virmond' },
  { state: 'Paraná', id: 4128708, name: 'Vitorino' },
  { state: 'Paraná', id: 4128500, name: 'Wenceslau Braz' },
  { state: 'Paraná', id: 4128807, name: 'Xambrê' },
  { state: 'Santa Catarina', id: 4200051, name: 'Abdon Batista' },
  { state: 'Santa Catarina', id: 4200101, name: 'Abelardo Luz' },
  { state: 'Santa Catarina', id: 4200200, name: 'Agrolândia' },
  { state: 'Santa Catarina', id: 4200309, name: 'Agronômica' },
  { state: 'Santa Catarina', id: 4200408, name: 'Água Doce' },
  { state: 'Santa Catarina', id: 4200507, name: 'Águas de Chapecó' },
  { state: 'Santa Catarina', id: 4200556, name: 'Águas Frias' },
  { state: 'Santa Catarina', id: 4200606, name: 'Águas Mornas' },
  { state: 'Santa Catarina', id: 4200705, name: 'Alfredo Wagner' },
  { state: 'Santa Catarina', id: 4200754, name: 'Alto Bela Vista' },
  { state: 'Santa Catarina', id: 4200804, name: 'Anchieta' },
  { state: 'Santa Catarina', id: 4200903, name: 'Angelina' },
  { state: 'Santa Catarina', id: 4201000, name: 'Anita Garibaldi' },
  { state: 'Santa Catarina', id: 4201109, name: 'Anitápolis' },
  { state: 'Santa Catarina', id: 4201208, name: 'Antônio Carlos' },
  { state: 'Santa Catarina', id: 4201257, name: 'Apiúna' },
  { state: 'Santa Catarina', id: 4201273, name: 'Arabutã' },
  { state: 'Santa Catarina', id: 4201307, name: 'Araquari' },
  { state: 'Santa Catarina', id: 4201406, name: 'Araranguá' },
  { state: 'Santa Catarina', id: 4201505, name: 'Armazém' },
  { state: 'Santa Catarina', id: 4201604, name: 'Arroio Trinta' },
  { state: 'Santa Catarina', id: 4201653, name: 'Arvoredo' },
  { state: 'Santa Catarina', id: 4201703, name: 'Ascurra' },
  { state: 'Santa Catarina', id: 4201802, name: 'Atalanta' },
  { state: 'Santa Catarina', id: 4201901, name: 'Aurora' },
  {
    state: 'Santa Catarina',
    id: 4201950,
    name: 'Balneário Arroio do Silva',
  },
  { state: 'Santa Catarina', id: 4202057, name: 'Balneário Barra do Sul' },
  { state: 'Santa Catarina', id: 4202008, name: 'Balneário Camboriú' },
  { state: 'Santa Catarina', id: 4202073, name: 'Balneário Gaivota' },
  { state: 'Santa Catarina', id: 4212809, name: 'Balneário Piçarras' },
  { state: 'Santa Catarina', id: 4220000, name: 'Balneário Rincão' },
  { state: 'Santa Catarina', id: 4202081, name: 'Bandeirante' },
  { state: 'Santa Catarina', id: 4202099, name: 'Barra Bonita' },
  { state: 'Santa Catarina', id: 4202107, name: 'Barra Velha' },
  { state: 'Santa Catarina', id: 4202131, name: 'Bela Vista do Toldo' },
  { state: 'Santa Catarina', id: 4202156, name: 'Belmonte' },
  { state: 'Santa Catarina', id: 4202206, name: 'Benedito Novo' },
  { state: 'Santa Catarina', id: 4202305, name: 'Biguaçu' },
  { state: 'Santa Catarina', id: 4202404, name: 'Blumenau' },
  { state: 'Santa Catarina', id: 4202438, name: 'Bocaina do Sul' },
  { state: 'Santa Catarina', id: 4202503, name: 'Bom Jardim da Serra' },
  { state: 'Santa Catarina', id: 4202537, name: 'Bom Jesus' },
  { state: 'Santa Catarina', id: 4202578, name: 'Bom Jesus do Oeste' },
  { state: 'Santa Catarina', id: 4202602, name: 'Bom Retiro' },
  { state: 'Santa Catarina', id: 4202453, name: 'Bombinhas' },
  { state: 'Santa Catarina', id: 4202701, name: 'Botuverá' },
  { state: 'Santa Catarina', id: 4202800, name: 'Braço do Norte' },
  { state: 'Santa Catarina', id: 4202859, name: 'Braço do Trombudo' },
  { state: 'Santa Catarina', id: 4202875, name: 'Brunópolis' },
  { state: 'Santa Catarina', id: 4202909, name: 'Brusque' },
  { state: 'Santa Catarina', id: 4203006, name: 'Caçador' },
  { state: 'Santa Catarina', id: 4203105, name: 'Caibi' },
  { state: 'Santa Catarina', id: 4203154, name: 'Calmon' },
  { state: 'Santa Catarina', id: 4203204, name: 'Camboriú' },
  { state: 'Santa Catarina', id: 4203303, name: 'Campo Alegre' },
  { state: 'Santa Catarina', id: 4203402, name: 'Campo Belo do Sul' },
  { state: 'Santa Catarina', id: 4203501, name: 'Campo Erê' },
  { state: 'Santa Catarina', id: 4203600, name: 'Campos Novos' },
  { state: 'Santa Catarina', id: 4203709, name: 'Canelinha' },
  { state: 'Santa Catarina', id: 4203808, name: 'Canoinhas' },
  { state: 'Santa Catarina', id: 4203253, name: 'Capão Alto' },
  { state: 'Santa Catarina', id: 4203907, name: 'Capinzal' },
  { state: 'Santa Catarina', id: 4203956, name: 'Capivari de Baixo' },
  { state: 'Santa Catarina', id: 4204004, name: 'Catanduvas' },
  { state: 'Santa Catarina', id: 4204103, name: 'Caxambu do Sul' },
  { state: 'Santa Catarina', id: 4204152, name: 'Celso Ramos' },
  { state: 'Santa Catarina', id: 4204178, name: 'Cerro Negro' },
  { state: 'Santa Catarina', id: 4204194, name: 'Chapadão do Lageado' },
  { state: 'Santa Catarina', id: 4204202, name: 'Chapecó' },
  { state: 'Santa Catarina', id: 4204251, name: 'Cocal do Sul' },
  { state: 'Santa Catarina', id: 4204301, name: 'Concórdia' },
  { state: 'Santa Catarina', id: 4204350, name: 'Cordilheira Alta' },
  { state: 'Santa Catarina', id: 4204400, name: 'Coronel Freitas' },
  { state: 'Santa Catarina', id: 4204459, name: 'Coronel Martins' },
  { state: 'Santa Catarina', id: 4204558, name: 'Correia Pinto' },
  { state: 'Santa Catarina', id: 4204509, name: 'Corupá' },
  { state: 'Santa Catarina', id: 4204608, name: 'Criciúma' },
  { state: 'Santa Catarina', id: 4204707, name: 'Cunha Porã' },
  { state: 'Santa Catarina', id: 4204756, name: 'Cunhataí' },
  { state: 'Santa Catarina', id: 4204806, name: 'Curitibanos' },
  { state: 'Santa Catarina', id: 4204905, name: 'Descanso' },
  { state: 'Santa Catarina', id: 4205001, name: 'Dionísio Cerqueira' },
  { state: 'Santa Catarina', id: 4205100, name: 'Dona Emma' },
  { state: 'Santa Catarina', id: 4205159, name: 'Doutor Pedrinho' },
  { state: 'Santa Catarina', id: 4205175, name: 'Entre Rios' },
  { state: 'Santa Catarina', id: 4205191, name: 'Ermo' },
  { state: 'Santa Catarina', id: 4205209, name: 'Erval Velho' },
  { state: 'Santa Catarina', id: 4205308, name: 'Faxinal dos Guedes' },
  { state: 'Santa Catarina', id: 4205357, name: 'Flor do Sertão' },
  { state: 'Santa Catarina', id: 4205407, name: 'Florianópolis' },
  { state: 'Santa Catarina', id: 4205431, name: 'Formosa do Sul' },
  { state: 'Santa Catarina', id: 4205456, name: 'Forquilhinha' },
  { state: 'Santa Catarina', id: 4205506, name: 'Fraiburgo' },
  { state: 'Santa Catarina', id: 4205555, name: 'Frei Rogério' },
  { state: 'Santa Catarina', id: 4205605, name: 'Galvão' },
  { state: 'Santa Catarina', id: 4205704, name: 'Garopaba' },
  { state: 'Santa Catarina', id: 4205803, name: 'Garuva' },
  { state: 'Santa Catarina', id: 4205902, name: 'Gaspar' },
  { state: 'Santa Catarina', id: 4206009, name: 'Governador Celso Ramos' },
  { state: 'Santa Catarina', id: 4206108, name: 'Grão Pará' },
  { state: 'Santa Catarina', id: 4206207, name: 'Gravatal' },
  { state: 'Santa Catarina', id: 4206306, name: 'Guabiruba' },
  { state: 'Santa Catarina', id: 4206405, name: 'Guaraciaba' },
  { state: 'Santa Catarina', id: 4206504, name: 'Guaramirim' },
  { state: 'Santa Catarina', id: 4206603, name: 'Guarujá do Sul' },
  { state: 'Santa Catarina', id: 4206652, name: 'Guatambú' },
  { state: 'Santa Catarina', id: 4206702, name: "Herval d'Oeste" },
  { state: 'Santa Catarina', id: 4206751, name: 'Ibiam' },
  { state: 'Santa Catarina', id: 4206801, name: 'Ibicaré' },
  { state: 'Santa Catarina', id: 4206900, name: 'Ibirama' },
  { state: 'Santa Catarina', id: 4207007, name: 'Içara' },
  { state: 'Santa Catarina', id: 4207106, name: 'Ilhota' },
  { state: 'Santa Catarina', id: 4207205, name: 'Imaruí' },
  { state: 'Santa Catarina', id: 4207304, name: 'Imbituba' },
  { state: 'Santa Catarina', id: 4207403, name: 'Imbuia' },
  { state: 'Santa Catarina', id: 4207502, name: 'Indaial' },
  { state: 'Santa Catarina', id: 4207577, name: 'Iomerê' },
  { state: 'Santa Catarina', id: 4207601, name: 'Ipira' },
  { state: 'Santa Catarina', id: 4207650, name: 'Iporã do Oeste' },
  { state: 'Santa Catarina', id: 4207684, name: 'Ipuaçu' },
  { state: 'Santa Catarina', id: 4207700, name: 'Ipumirim' },
  { state: 'Santa Catarina', id: 4207759, name: 'Iraceminha' },
  { state: 'Santa Catarina', id: 4207809, name: 'Irani' },
  { state: 'Santa Catarina', id: 4207858, name: 'Irati' },
  { state: 'Santa Catarina', id: 4207908, name: 'Irineópolis' },
  { state: 'Santa Catarina', id: 4208005, name: 'Itá' },
  { state: 'Santa Catarina', id: 4208104, name: 'Itaiópolis' },
  { state: 'Santa Catarina', id: 4208203, name: 'Itajaí' },
  { state: 'Santa Catarina', id: 4208302, name: 'Itapema' },
  { state: 'Santa Catarina', id: 4208401, name: 'Itapiranga' },
  { state: 'Santa Catarina', id: 4208450, name: 'Itapoá' },
  { state: 'Santa Catarina', id: 4208500, name: 'Ituporanga' },
  { state: 'Santa Catarina', id: 4208609, name: 'Jaborá' },
  { state: 'Santa Catarina', id: 4208708, name: 'Jacinto Machado' },
  { state: 'Santa Catarina', id: 4208807, name: 'Jaguaruna' },
  { state: 'Santa Catarina', id: 4208906, name: 'Jaraguá do Sul' },
  { state: 'Santa Catarina', id: 4208955, name: 'Jardinópolis' },
  { state: 'Santa Catarina', id: 4209003, name: 'Joaçaba' },
  { state: 'Santa Catarina', id: 4209102, name: 'Joinville' },
  { state: 'Santa Catarina', id: 4209151, name: 'José Boiteux' },
  { state: 'Santa Catarina', id: 4209177, name: 'Jupiá' },
  { state: 'Santa Catarina', id: 4209201, name: 'Lacerdópolis' },
  { state: 'Santa Catarina', id: 4209300, name: 'Lages' },
  { state: 'Santa Catarina', id: 4209409, name: 'Laguna' },
  { state: 'Santa Catarina', id: 4209458, name: 'Lajeado Grande' },
  { state: 'Santa Catarina', id: 4209508, name: 'Laurentino' },
  { state: 'Santa Catarina', id: 4209607, name: 'Lauro Müller' },
  { state: 'Santa Catarina', id: 4209706, name: 'Lebon Régis' },
  { state: 'Santa Catarina', id: 4209805, name: 'Leoberto Leal' },
  { state: 'Santa Catarina', id: 4209854, name: 'Lindóia do Sul' },
  { state: 'Santa Catarina', id: 4209904, name: 'Lontras' },
  { state: 'Santa Catarina', id: 4210001, name: 'Luiz Alves' },
  { state: 'Santa Catarina', id: 4210035, name: 'Luzerna' },
  { state: 'Santa Catarina', id: 4210050, name: 'Macieira' },
  { state: 'Santa Catarina', id: 4210100, name: 'Mafra' },
  { state: 'Santa Catarina', id: 4210209, name: 'Major Gercino' },
  { state: 'Santa Catarina', id: 4210308, name: 'Major Vieira' },
  { state: 'Santa Catarina', id: 4210407, name: 'Maracajá' },
  { state: 'Santa Catarina', id: 4210506, name: 'Maravilha' },
  { state: 'Santa Catarina', id: 4210555, name: 'Marema' },
  { state: 'Santa Catarina', id: 4210605, name: 'Massaranduba' },
  { state: 'Santa Catarina', id: 4210704, name: 'Matos Costa' },
  { state: 'Santa Catarina', id: 4210803, name: 'Meleiro' },
  { state: 'Santa Catarina', id: 4210852, name: 'Mirim Doce' },
  { state: 'Santa Catarina', id: 4210902, name: 'Modelo' },
  { state: 'Santa Catarina', id: 4211009, name: 'Mondaí' },
  { state: 'Santa Catarina', id: 4211058, name: 'Monte Carlo' },
  { state: 'Santa Catarina', id: 4211108, name: 'Monte Castelo' },
  { state: 'Santa Catarina', id: 4211207, name: 'Morro da Fumaça' },
  { state: 'Santa Catarina', id: 4211256, name: 'Morro Grande' },
  { state: 'Santa Catarina', id: 4211306, name: 'Navegantes' },
  { state: 'Santa Catarina', id: 4211405, name: 'Nova Erechim' },
  { state: 'Santa Catarina', id: 4211454, name: 'Nova Itaberaba' },
  { state: 'Santa Catarina', id: 4211504, name: 'Nova Trento' },
  { state: 'Santa Catarina', id: 4211603, name: 'Nova Veneza' },
  { state: 'Santa Catarina', id: 4211652, name: 'Novo Horizonte' },
  { state: 'Santa Catarina', id: 4211702, name: 'Orleans' },
  { state: 'Santa Catarina', id: 4211751, name: 'Otacílio Costa' },
  { state: 'Santa Catarina', id: 4211801, name: 'Ouro' },
  { state: 'Santa Catarina', id: 4211850, name: 'Ouro Verde' },
  { state: 'Santa Catarina', id: 4211876, name: 'Paial' },
  { state: 'Santa Catarina', id: 4211892, name: 'Painel' },
  { state: 'Santa Catarina', id: 4211900, name: 'Palhoça' },
  { state: 'Santa Catarina', id: 4212007, name: 'Palma Sola' },
  { state: 'Santa Catarina', id: 4212056, name: 'Palmeira' },
  { state: 'Santa Catarina', id: 4212106, name: 'Palmitos' },
  { state: 'Santa Catarina', id: 4212205, name: 'Papanduva' },
  { state: 'Santa Catarina', id: 4212239, name: 'Paraíso' },
  { state: 'Santa Catarina', id: 4212254, name: 'Passo de Torres' },
  { state: 'Santa Catarina', id: 4212270, name: 'Passos Maia' },
  { state: 'Santa Catarina', id: 4212304, name: 'Paulo Lopes' },
  { state: 'Santa Catarina', id: 4212403, name: 'Pedras Grandes' },
  { state: 'Santa Catarina', id: 4212502, name: 'Penha' },
  { state: 'Santa Catarina', id: 4212601, name: 'Peritiba' },
  { state: 'Santa Catarina', id: 4212650, name: 'Pescaria Brava' },
  { state: 'Santa Catarina', id: 4212700, name: 'Petrolândia' },
  { state: 'Santa Catarina', id: 4212908, name: 'Pinhalzinho' },
  { state: 'Santa Catarina', id: 4213005, name: 'Pinheiro Preto' },
  { state: 'Santa Catarina', id: 4213104, name: 'Piratuba' },
  { state: 'Santa Catarina', id: 4213153, name: 'Planalto Alegre' },
  { state: 'Santa Catarina', id: 4213203, name: 'Pomerode' },
  { state: 'Santa Catarina', id: 4213302, name: 'Ponte Alta' },
  { state: 'Santa Catarina', id: 4213351, name: 'Ponte Alta do Norte' },
  { state: 'Santa Catarina', id: 4213401, name: 'Ponte Serrada' },
  { state: 'Santa Catarina', id: 4213500, name: 'Porto Belo' },
  { state: 'Santa Catarina', id: 4213609, name: 'Porto União' },
  { state: 'Santa Catarina', id: 4213708, name: 'Pouso Redondo' },
  { state: 'Santa Catarina', id: 4213807, name: 'Praia Grande' },
  {
    state: 'Santa Catarina',
    id: 4213906,
    name: 'Presidente Castello Branco',
  },
  { state: 'Santa Catarina', id: 4214003, name: 'Presidente Getúlio' },
  { state: 'Santa Catarina', id: 4214102, name: 'Presidente Nereu' },
  { state: 'Santa Catarina', id: 4214151, name: 'Princesa' },
  { state: 'Santa Catarina', id: 4214201, name: 'Quilombo' },
  { state: 'Santa Catarina', id: 4214300, name: 'Rancho Queimado' },
  { state: 'Santa Catarina', id: 4214409, name: 'Rio das Antas' },
  { state: 'Santa Catarina', id: 4214508, name: 'Rio do Campo' },
  { state: 'Santa Catarina', id: 4214607, name: 'Rio do Oeste' },
  { state: 'Santa Catarina', id: 4214805, name: 'Rio do Sul' },
  { state: 'Santa Catarina', id: 4214706, name: 'Rio dos Cedros' },
  { state: 'Santa Catarina', id: 4214904, name: 'Rio Fortuna' },
  { state: 'Santa Catarina', id: 4215000, name: 'Rio Negrinho' },
  { state: 'Santa Catarina', id: 4215059, name: 'Rio Rufino' },
  { state: 'Santa Catarina', id: 4215075, name: 'Riqueza' },
  { state: 'Santa Catarina', id: 4215109, name: 'Rodeio' },
  { state: 'Santa Catarina', id: 4215208, name: 'Romelândia' },
  { state: 'Santa Catarina', id: 4215307, name: 'Salete' },
  { state: 'Santa Catarina', id: 4215356, name: 'Saltinho' },
  { state: 'Santa Catarina', id: 4215406, name: 'Salto Veloso' },
  { state: 'Santa Catarina', id: 4215455, name: 'Sangão' },
  { state: 'Santa Catarina', id: 4215505, name: 'Santa Cecília' },
  { state: 'Santa Catarina', id: 4215554, name: 'Santa Helena' },
  { state: 'Santa Catarina', id: 4215604, name: 'Santa Rosa de Lima' },
  { state: 'Santa Catarina', id: 4215653, name: 'Santa Rosa do Sul' },
  { state: 'Santa Catarina', id: 4215679, name: 'Santa Terezinha' },
  {
    state: 'Santa Catarina',
    id: 4215687,
    name: 'Santa Terezinha do Progresso',
  },
  { state: 'Santa Catarina', id: 4215695, name: 'Santiago do Sul' },
  {
    state: 'Santa Catarina',
    id: 4215703,
    name: 'Santo Amaro da Imperatriz',
  },
  { state: 'Santa Catarina', id: 4215802, name: 'São Bento do Sul' },
  { state: 'Santa Catarina', id: 4215752, name: 'São Bernardino' },
  { state: 'Santa Catarina', id: 4215901, name: 'São Bonifácio' },
  { state: 'Santa Catarina', id: 4216008, name: 'São Carlos' },
  { state: 'Santa Catarina', id: 4216057, name: 'São Cristóvão do Sul' },
  { state: 'Santa Catarina', id: 4216107, name: 'São Domingos' },
  { state: 'Santa Catarina', id: 4216206, name: 'São Francisco do Sul' },
  { state: 'Santa Catarina', id: 4216305, name: 'São João Batista' },
  { state: 'Santa Catarina', id: 4216354, name: 'São João do Itaperiú' },
  { state: 'Santa Catarina', id: 4216255, name: 'São João do Oeste' },
  { state: 'Santa Catarina', id: 4216404, name: 'São João do Sul' },
  { state: 'Santa Catarina', id: 4216503, name: 'São Joaquim' },
  { state: 'Santa Catarina', id: 4216602, name: 'São José' },
  { state: 'Santa Catarina', id: 4216701, name: 'São José do Cedro' },
  { state: 'Santa Catarina', id: 4216800, name: 'São José do Cerrito' },
  { state: 'Santa Catarina', id: 4216909, name: 'São Lourenço do Oeste' },
  { state: 'Santa Catarina', id: 4217006, name: 'São Ludgero' },
  { state: 'Santa Catarina', id: 4217105, name: 'São Martinho' },
  { state: 'Santa Catarina', id: 4217154, name: 'São Miguel da Boa Vista' },
  { state: 'Santa Catarina', id: 4217204, name: 'São Miguel do Oeste' },
  { state: 'Santa Catarina', id: 4217253, name: 'São Pedro de Alcântara' },
  { state: 'Santa Catarina', id: 4217303, name: 'Saudades' },
  { state: 'Santa Catarina', id: 4217402, name: 'Schroeder' },
  { state: 'Santa Catarina', id: 4217501, name: 'Seara' },
  { state: 'Santa Catarina', id: 4217550, name: 'Serra Alta' },
  { state: 'Santa Catarina', id: 4217600, name: 'Siderópolis' },
  { state: 'Santa Catarina', id: 4217709, name: 'Sombrio' },
  { state: 'Santa Catarina', id: 4217758, name: 'Sul Brasil' },
  { state: 'Santa Catarina', id: 4217808, name: 'Taió' },
  { state: 'Santa Catarina', id: 4217907, name: 'Tangará' },
  { state: 'Santa Catarina', id: 4217956, name: 'Tigrinhos' },
  { state: 'Santa Catarina', id: 4218004, name: 'Tijucas' },
  { state: 'Santa Catarina', id: 4218103, name: 'Timbé do Sul' },
  { state: 'Santa Catarina', id: 4218202, name: 'Timbó' },
  { state: 'Santa Catarina', id: 4218251, name: 'Timbó Grande' },
  { state: 'Santa Catarina', id: 4218301, name: 'Três Barras' },
  { state: 'Santa Catarina', id: 4218350, name: 'Treviso' },
  { state: 'Santa Catarina', id: 4218400, name: 'Treze de Maio' },
  { state: 'Santa Catarina', id: 4218509, name: 'Treze Tílias' },
  { state: 'Santa Catarina', id: 4218608, name: 'Trombudo Central' },
  { state: 'Santa Catarina', id: 4218707, name: 'Tubarão' },
  { state: 'Santa Catarina', id: 4218756, name: 'Tunápolis' },
  { state: 'Santa Catarina', id: 4218806, name: 'Turvo' },
  { state: 'Santa Catarina', id: 4218855, name: 'União do Oeste' },
  { state: 'Santa Catarina', id: 4218905, name: 'Urubici' },
  { state: 'Santa Catarina', id: 4218954, name: 'Urupema' },
  { state: 'Santa Catarina', id: 4219002, name: 'Urussanga' },
  { state: 'Santa Catarina', id: 4219101, name: 'Vargeão' },
  { state: 'Santa Catarina', id: 4219150, name: 'Vargem' },
  { state: 'Santa Catarina', id: 4219176, name: 'Vargem Bonita' },
  { state: 'Santa Catarina', id: 4219200, name: 'Vidal Ramos' },
  { state: 'Santa Catarina', id: 4219309, name: 'Videira' },
  { state: 'Santa Catarina', id: 4219358, name: 'Vitor Meireles' },
  { state: 'Santa Catarina', id: 4219408, name: 'Witmarsum' },
  { state: 'Santa Catarina', id: 4219507, name: 'Xanxerê' },
  { state: 'Santa Catarina', id: 4219606, name: 'Xavantina' },
  { state: 'Santa Catarina', id: 4219705, name: 'Xaxim' },
  { state: 'Santa Catarina', id: 4219853, name: 'Zortéa' },
  { state: 'Rio Grande do Sul', id: 4300034, name: 'Aceguá' },
  { state: 'Rio Grande do Sul', id: 4300059, name: 'Água Santa' },
  { state: 'Rio Grande do Sul', id: 4300109, name: 'Agudo' },
  { state: 'Rio Grande do Sul', id: 4300208, name: 'Ajuricaba' },
  { state: 'Rio Grande do Sul', id: 4300307, name: 'Alecrim' },
  { state: 'Rio Grande do Sul', id: 4300406, name: 'Alegrete' },
  { state: 'Rio Grande do Sul', id: 4300455, name: 'Alegria' },
  {
    state: 'Rio Grande do Sul',
    id: 4300471,
    name: 'Almirante Tamandaré do Sul',
  },
  { state: 'Rio Grande do Sul', id: 4300505, name: 'Alpestre' },
  { state: 'Rio Grande do Sul', id: 4300554, name: 'Alto Alegre' },
  { state: 'Rio Grande do Sul', id: 4300570, name: 'Alto Feliz' },
  { state: 'Rio Grande do Sul', id: 4300604, name: 'Alvorada' },
  { state: 'Rio Grande do Sul', id: 4300638, name: 'Amaral Ferrador' },
  { state: 'Rio Grande do Sul', id: 4300646, name: 'Ametista do Sul' },
  { state: 'Rio Grande do Sul', id: 4300661, name: 'André da Rocha' },
  { state: 'Rio Grande do Sul', id: 4300703, name: 'Anta Gorda' },
  { state: 'Rio Grande do Sul', id: 4300802, name: 'Antônio Prado' },
  { state: 'Rio Grande do Sul', id: 4300851, name: 'Arambaré' },
  { state: 'Rio Grande do Sul', id: 4300877, name: 'Araricá' },
  { state: 'Rio Grande do Sul', id: 4300901, name: 'Aratiba' },
  { state: 'Rio Grande do Sul', id: 4301008, name: 'Arroio do Meio' },
  { state: 'Rio Grande do Sul', id: 4301073, name: 'Arroio do Padre' },
  { state: 'Rio Grande do Sul', id: 4301057, name: 'Arroio do Sal' },
  { state: 'Rio Grande do Sul', id: 4301206, name: 'Arroio do Tigre' },
  { state: 'Rio Grande do Sul', id: 4301107, name: 'Arroio dos Ratos' },
  { state: 'Rio Grande do Sul', id: 4301305, name: 'Arroio Grande' },
  { state: 'Rio Grande do Sul', id: 4301404, name: 'Arvorezinha' },
  { state: 'Rio Grande do Sul', id: 4301503, name: 'Augusto Pestana' },
  { state: 'Rio Grande do Sul', id: 4301552, name: 'Áurea' },
  { state: 'Rio Grande do Sul', id: 4301602, name: 'Bagé' },
  { state: 'Rio Grande do Sul', id: 4301636, name: 'Balneário Pinhal' },
  { state: 'Rio Grande do Sul', id: 4301651, name: 'Barão' },
  { state: 'Rio Grande do Sul', id: 4301701, name: 'Barão de Cotegipe' },
  { state: 'Rio Grande do Sul', id: 4301750, name: 'Barão do Triunfo' },
  { state: 'Rio Grande do Sul', id: 4301859, name: 'Barra do Guarita' },
  { state: 'Rio Grande do Sul', id: 4301875, name: 'Barra do Quaraí' },
  { state: 'Rio Grande do Sul', id: 4301909, name: 'Barra do Ribeiro' },
  { state: 'Rio Grande do Sul', id: 4301925, name: 'Barra do Rio Azul' },
  { state: 'Rio Grande do Sul', id: 4301958, name: 'Barra Funda' },
  { state: 'Rio Grande do Sul', id: 4301800, name: 'Barracão' },
  { state: 'Rio Grande do Sul', id: 4302006, name: 'Barros Cassal' },
  {
    state: 'Rio Grande do Sul',
    id: 4302055,
    name: 'Benjamin Constant do Sul',
  },
  { state: 'Rio Grande do Sul', id: 4302105, name: 'Bento Gonçalves' },
  { state: 'Rio Grande do Sul', id: 4302154, name: 'Boa Vista das Missões' },
  { state: 'Rio Grande do Sul', id: 4302204, name: 'Boa Vista do Buricá' },
  { state: 'Rio Grande do Sul', id: 4302220, name: 'Boa Vista do Cadeado' },
  { state: 'Rio Grande do Sul', id: 4302238, name: 'Boa Vista do Incra' },
  { state: 'Rio Grande do Sul', id: 4302253, name: 'Boa Vista do Sul' },
  { state: 'Rio Grande do Sul', id: 4302303, name: 'Bom Jesus' },
  { state: 'Rio Grande do Sul', id: 4302352, name: 'Bom Princípio' },
  { state: 'Rio Grande do Sul', id: 4302378, name: 'Bom Progresso' },
  { state: 'Rio Grande do Sul', id: 4302402, name: 'Bom Retiro do Sul' },
  { state: 'Rio Grande do Sul', id: 4302451, name: 'Boqueirão do Leão' },
  { state: 'Rio Grande do Sul', id: 4302501, name: 'Bossoroca' },
  { state: 'Rio Grande do Sul', id: 4302584, name: 'Bozano' },
  { state: 'Rio Grande do Sul', id: 4302600, name: 'Braga' },
  { state: 'Rio Grande do Sul', id: 4302659, name: 'Brochier' },
  { state: 'Rio Grande do Sul', id: 4302709, name: 'Butiá' },
  { state: 'Rio Grande do Sul', id: 4302808, name: 'Caçapava do Sul' },
  { state: 'Rio Grande do Sul', id: 4302907, name: 'Cacequi' },
  { state: 'Rio Grande do Sul', id: 4303004, name: 'Cachoeira do Sul' },
  { state: 'Rio Grande do Sul', id: 4303103, name: 'Cachoeirinha' },
  { state: 'Rio Grande do Sul', id: 4303202, name: 'Cacique Doble' },
  { state: 'Rio Grande do Sul', id: 4303301, name: 'Caibaté' },
  { state: 'Rio Grande do Sul', id: 4303400, name: 'Caiçara' },
  { state: 'Rio Grande do Sul', id: 4303509, name: 'Camaquã' },
  { state: 'Rio Grande do Sul', id: 4303558, name: 'Camargo' },
  { state: 'Rio Grande do Sul', id: 4303608, name: 'Cambará do Sul' },
  { state: 'Rio Grande do Sul', id: 4303673, name: 'Campestre da Serra' },
  { state: 'Rio Grande do Sul', id: 4303707, name: 'Campina das Missões' },
  { state: 'Rio Grande do Sul', id: 4303806, name: 'Campinas do Sul' },
  { state: 'Rio Grande do Sul', id: 4303905, name: 'Campo Bom' },
  { state: 'Rio Grande do Sul', id: 4304002, name: 'Campo Novo' },
  { state: 'Rio Grande do Sul', id: 4304101, name: 'Campos Borges' },
  { state: 'Rio Grande do Sul', id: 4304200, name: 'Candelária' },
  { state: 'Rio Grande do Sul', id: 4304309, name: 'Cândido Godói' },
  { state: 'Rio Grande do Sul', id: 4304358, name: 'Candiota' },
  { state: 'Rio Grande do Sul', id: 4304408, name: 'Canela' },
  { state: 'Rio Grande do Sul', id: 4304507, name: 'Canguçu' },
  { state: 'Rio Grande do Sul', id: 4304606, name: 'Canoas' },
  { state: 'Rio Grande do Sul', id: 4304614, name: 'Canudos do Vale' },
  { state: 'Rio Grande do Sul', id: 4304622, name: 'Capão Bonito do Sul' },
  { state: 'Rio Grande do Sul', id: 4304630, name: 'Capão da Canoa' },
  { state: 'Rio Grande do Sul', id: 4304655, name: 'Capão do Cipó' },
  { state: 'Rio Grande do Sul', id: 4304663, name: 'Capão do Leão' },
  { state: 'Rio Grande do Sul', id: 4304689, name: 'Capela de Santana' },
  { state: 'Rio Grande do Sul', id: 4304697, name: 'Capitão' },
  { state: 'Rio Grande do Sul', id: 4304671, name: 'Capivari do Sul' },
  { state: 'Rio Grande do Sul', id: 4304713, name: 'Caraá' },
  { state: 'Rio Grande do Sul', id: 4304705, name: 'Carazinho' },
  { state: 'Rio Grande do Sul', id: 4304804, name: 'Carlos Barbosa' },
  { state: 'Rio Grande do Sul', id: 4304853, name: 'Carlos Gomes' },
  { state: 'Rio Grande do Sul', id: 4304903, name: 'Casca' },
  { state: 'Rio Grande do Sul', id: 4304952, name: 'Caseiros' },
  { state: 'Rio Grande do Sul', id: 4305009, name: 'Catuípe' },
  { state: 'Rio Grande do Sul', id: 4305108, name: 'Caxias do Sul' },
  { state: 'Rio Grande do Sul', id: 4305116, name: 'Centenário' },
  { state: 'Rio Grande do Sul', id: 4305124, name: 'Cerrito' },
  { state: 'Rio Grande do Sul', id: 4305132, name: 'Cerro Branco' },
  { state: 'Rio Grande do Sul', id: 4305157, name: 'Cerro Grande' },
  { state: 'Rio Grande do Sul', id: 4305173, name: 'Cerro Grande do Sul' },
  { state: 'Rio Grande do Sul', id: 4305207, name: 'Cerro Largo' },
  { state: 'Rio Grande do Sul', id: 4305306, name: 'Chapada' },
  { state: 'Rio Grande do Sul', id: 4305355, name: 'Charqueadas' },
  { state: 'Rio Grande do Sul', id: 4305371, name: 'Charrua' },
  { state: 'Rio Grande do Sul', id: 4305405, name: 'Chiapetta' },
  { state: 'Rio Grande do Sul', id: 4305439, name: 'Chuí' },
  { state: 'Rio Grande do Sul', id: 4305447, name: 'Chuvisca' },
  { state: 'Rio Grande do Sul', id: 4305454, name: 'Cidreira' },
  { state: 'Rio Grande do Sul', id: 4305504, name: 'Ciríaco' },
  { state: 'Rio Grande do Sul', id: 4305587, name: 'Colinas' },
  { state: 'Rio Grande do Sul', id: 4305603, name: 'Colorado' },
  { state: 'Rio Grande do Sul', id: 4305702, name: 'Condor' },
  { state: 'Rio Grande do Sul', id: 4305801, name: 'Constantina' },
  { state: 'Rio Grande do Sul', id: 4305835, name: 'Coqueiro Baixo' },
  { state: 'Rio Grande do Sul', id: 4305850, name: 'Coqueiros do Sul' },
  { state: 'Rio Grande do Sul', id: 4305871, name: 'Coronel Barros' },
  { state: 'Rio Grande do Sul', id: 4305900, name: 'Coronel Bicaco' },
  { state: 'Rio Grande do Sul', id: 4305934, name: 'Coronel Pilar' },
  { state: 'Rio Grande do Sul', id: 4305959, name: 'Cotiporã' },
  { state: 'Rio Grande do Sul', id: 4305975, name: 'Coxilha' },
  { state: 'Rio Grande do Sul', id: 4306007, name: 'Crissiumal' },
  { state: 'Rio Grande do Sul', id: 4306056, name: 'Cristal' },
  { state: 'Rio Grande do Sul', id: 4306072, name: 'Cristal do Sul' },
  { state: 'Rio Grande do Sul', id: 4306106, name: 'Cruz Alta' },
  { state: 'Rio Grande do Sul', id: 4306130, name: 'Cruzaltense' },
  { state: 'Rio Grande do Sul', id: 4306205, name: 'Cruzeiro do Sul' },
  { state: 'Rio Grande do Sul', id: 4306304, name: 'David Canabarro' },
  { state: 'Rio Grande do Sul', id: 4306320, name: 'Derrubadas' },
  { state: 'Rio Grande do Sul', id: 4306353, name: 'Dezesseis de Novembro' },
  { state: 'Rio Grande do Sul', id: 4306379, name: 'Dilermando de Aguiar' },
  { state: 'Rio Grande do Sul', id: 4306403, name: 'Dois Irmãos' },
  {
    state: 'Rio Grande do Sul',
    id: 4306429,
    name: 'Dois Irmãos das Missões',
  },
  { state: 'Rio Grande do Sul', id: 4306452, name: 'Dois Lajeados' },
  { state: 'Rio Grande do Sul', id: 4306502, name: 'Dom Feliciano' },
  { state: 'Rio Grande do Sul', id: 4306601, name: 'Dom Pedrito' },
  {
    state: 'Rio Grande do Sul',
    id: 4306551,
    name: 'Dom Pedro de Alcântara',
  },
  { state: 'Rio Grande do Sul', id: 4306700, name: 'Dona Francisca' },
  {
    state: 'Rio Grande do Sul',
    id: 4306734,
    name: 'Doutor Maurício Cardoso',
  },
  { state: 'Rio Grande do Sul', id: 4306759, name: 'Doutor Ricardo' },
  { state: 'Rio Grande do Sul', id: 4306767, name: 'Eldorado do Sul' },
  { state: 'Rio Grande do Sul', id: 4306809, name: 'Encantado' },
  { state: 'Rio Grande do Sul', id: 4306908, name: 'Encruzilhada do Sul' },
  { state: 'Rio Grande do Sul', id: 4306924, name: 'Engenho Velho' },
  { state: 'Rio Grande do Sul', id: 4306957, name: 'Entre Rios do Sul' },
  { state: 'Rio Grande do Sul', id: 4306932, name: 'Entre-Ijuís' },
  { state: 'Rio Grande do Sul', id: 4306973, name: 'Erebango' },
  { state: 'Rio Grande do Sul', id: 4307005, name: 'Erechim' },
  { state: 'Rio Grande do Sul', id: 4307054, name: 'Ernestina' },
  { state: 'Rio Grande do Sul', id: 4307203, name: 'Erval Grande' },
  { state: 'Rio Grande do Sul', id: 4307302, name: 'Erval Seco' },
  { state: 'Rio Grande do Sul', id: 4307401, name: 'Esmeralda' },
  { state: 'Rio Grande do Sul', id: 4307450, name: 'Esperança do Sul' },
  { state: 'Rio Grande do Sul', id: 4307500, name: 'Espumoso' },
  { state: 'Rio Grande do Sul', id: 4307559, name: 'Estação' },
  { state: 'Rio Grande do Sul', id: 4307609, name: 'Estância Velha' },
  { state: 'Rio Grande do Sul', id: 4307708, name: 'Esteio' },
  { state: 'Rio Grande do Sul', id: 4307807, name: 'Estrela' },
  { state: 'Rio Grande do Sul', id: 4307815, name: 'Estrela Velha' },
  { state: 'Rio Grande do Sul', id: 4307831, name: 'Eugênio de Castro' },
  { state: 'Rio Grande do Sul', id: 4307864, name: 'Fagundes Varela' },
  { state: 'Rio Grande do Sul', id: 4307906, name: 'Farroupilha' },
  { state: 'Rio Grande do Sul', id: 4308003, name: 'Faxinal do Soturno' },
  { state: 'Rio Grande do Sul', id: 4308052, name: 'Faxinalzinho' },
  { state: 'Rio Grande do Sul', id: 4308078, name: 'Fazenda Vilanova' },
  { state: 'Rio Grande do Sul', id: 4308102, name: 'Feliz' },
  { state: 'Rio Grande do Sul', id: 4308201, name: 'Flores da Cunha' },
  { state: 'Rio Grande do Sul', id: 4308250, name: 'Floriano Peixoto' },
  { state: 'Rio Grande do Sul', id: 4308300, name: 'Fontoura Xavier' },
  { state: 'Rio Grande do Sul', id: 4308409, name: 'Formigueiro' },
  { state: 'Rio Grande do Sul', id: 4308433, name: 'Forquetinha' },
  { state: 'Rio Grande do Sul', id: 4308458, name: 'Fortaleza dos Valos' },
  { state: 'Rio Grande do Sul', id: 4308508, name: 'Frederico Westphalen' },
  { state: 'Rio Grande do Sul', id: 4308607, name: 'Garibaldi' },
  { state: 'Rio Grande do Sul', id: 4308656, name: 'Garruchos' },
  { state: 'Rio Grande do Sul', id: 4308706, name: 'Gaurama' },
  { state: 'Rio Grande do Sul', id: 4308805, name: 'General Câmara' },
  { state: 'Rio Grande do Sul', id: 4308854, name: 'Gentil' },
  { state: 'Rio Grande do Sul', id: 4308904, name: 'Getúlio Vargas' },
  { state: 'Rio Grande do Sul', id: 4309001, name: 'Giruá' },
  { state: 'Rio Grande do Sul', id: 4309050, name: 'Glorinha' },
  { state: 'Rio Grande do Sul', id: 4309100, name: 'Gramado' },
  { state: 'Rio Grande do Sul', id: 4309126, name: 'Gramado dos Loureiros' },
  { state: 'Rio Grande do Sul', id: 4309159, name: 'Gramado Xavier' },
  { state: 'Rio Grande do Sul', id: 4309209, name: 'Gravataí' },
  { state: 'Rio Grande do Sul', id: 4309258, name: 'Guabiju' },
  { state: 'Rio Grande do Sul', id: 4309308, name: 'Guaíba' },
  { state: 'Rio Grande do Sul', id: 4309407, name: 'Guaporé' },
  { state: 'Rio Grande do Sul', id: 4309506, name: 'Guarani das Missões' },
  { state: 'Rio Grande do Sul', id: 4309555, name: 'Harmonia' },
  { state: 'Rio Grande do Sul', id: 4307104, name: 'Herval' },
  { state: 'Rio Grande do Sul', id: 4309571, name: 'Herveiras' },
  { state: 'Rio Grande do Sul', id: 4309605, name: 'Horizontina' },
  { state: 'Rio Grande do Sul', id: 4309654, name: 'Hulha Negra' },
  { state: 'Rio Grande do Sul', id: 4309704, name: 'Humaitá' },
  { state: 'Rio Grande do Sul', id: 4309753, name: 'Ibarama' },
  { state: 'Rio Grande do Sul', id: 4309803, name: 'Ibiaçá' },
  { state: 'Rio Grande do Sul', id: 4309902, name: 'Ibiraiaras' },
  { state: 'Rio Grande do Sul', id: 4309951, name: 'Ibirapuitã' },
  { state: 'Rio Grande do Sul', id: 4310009, name: 'Ibirubá' },
  { state: 'Rio Grande do Sul', id: 4310108, name: 'Igrejinha' },
  { state: 'Rio Grande do Sul', id: 4310207, name: 'Ijuí' },
  { state: 'Rio Grande do Sul', id: 4310306, name: 'Ilópolis' },
  { state: 'Rio Grande do Sul', id: 4310330, name: 'Imbé' },
  { state: 'Rio Grande do Sul', id: 4310363, name: 'Imigrante' },
  { state: 'Rio Grande do Sul', id: 4310405, name: 'Independência' },
  { state: 'Rio Grande do Sul', id: 4310413, name: 'Inhacorá' },
  { state: 'Rio Grande do Sul', id: 4310439, name: 'Ipê' },
  { state: 'Rio Grande do Sul', id: 4310462, name: 'Ipiranga do Sul' },
  { state: 'Rio Grande do Sul', id: 4310504, name: 'Iraí' },
  { state: 'Rio Grande do Sul', id: 4310538, name: 'Itaara' },
  { state: 'Rio Grande do Sul', id: 4310553, name: 'Itacurubi' },
  { state: 'Rio Grande do Sul', id: 4310579, name: 'Itapuca' },
  { state: 'Rio Grande do Sul', id: 4310603, name: 'Itaqui' },
  { state: 'Rio Grande do Sul', id: 4310652, name: 'Itati' },
  { state: 'Rio Grande do Sul', id: 4310702, name: 'Itatiba do Sul' },
  { state: 'Rio Grande do Sul', id: 4310751, name: 'Ivorá' },
  { state: 'Rio Grande do Sul', id: 4310801, name: 'Ivoti' },
  { state: 'Rio Grande do Sul', id: 4310850, name: 'Jaboticaba' },
  { state: 'Rio Grande do Sul', id: 4310876, name: 'Jacuizinho' },
  { state: 'Rio Grande do Sul', id: 4310900, name: 'Jacutinga' },
  { state: 'Rio Grande do Sul', id: 4311007, name: 'Jaguarão' },
  { state: 'Rio Grande do Sul', id: 4311106, name: 'Jaguari' },
  { state: 'Rio Grande do Sul', id: 4311122, name: 'Jaquirana' },
  { state: 'Rio Grande do Sul', id: 4311130, name: 'Jari' },
  { state: 'Rio Grande do Sul', id: 4311155, name: 'Jóia' },
  { state: 'Rio Grande do Sul', id: 4311205, name: 'Júlio de Castilhos' },
  { state: 'Rio Grande do Sul', id: 4311239, name: 'Lagoa Bonita do Sul' },
  { state: 'Rio Grande do Sul', id: 4311270, name: 'Lagoa dos Três Cantos' },
  { state: 'Rio Grande do Sul', id: 4311304, name: 'Lagoa Vermelha' },
  { state: 'Rio Grande do Sul', id: 4311254, name: 'Lagoão' },
  { state: 'Rio Grande do Sul', id: 4311403, name: 'Lajeado' },
  { state: 'Rio Grande do Sul', id: 4311429, name: 'Lajeado do Bugre' },
  { state: 'Rio Grande do Sul', id: 4311502, name: 'Lavras do Sul' },
  { state: 'Rio Grande do Sul', id: 4311601, name: 'Liberato Salzano' },
  { state: 'Rio Grande do Sul', id: 4311627, name: 'Lindolfo Collor' },
  { state: 'Rio Grande do Sul', id: 4311643, name: 'Linha Nova' },
  { state: 'Rio Grande do Sul', id: 4311718, name: 'Maçambará' },
  { state: 'Rio Grande do Sul', id: 4311700, name: 'Machadinho' },
  { state: 'Rio Grande do Sul', id: 4311734, name: 'Mampituba' },
  { state: 'Rio Grande do Sul', id: 4311759, name: 'Manoel Viana' },
  { state: 'Rio Grande do Sul', id: 4311775, name: 'Maquiné' },
  { state: 'Rio Grande do Sul', id: 4311791, name: 'Maratá' },
  { state: 'Rio Grande do Sul', id: 4311809, name: 'Marau' },
  { state: 'Rio Grande do Sul', id: 4311908, name: 'Marcelino Ramos' },
  { state: 'Rio Grande do Sul', id: 4311981, name: 'Mariana Pimentel' },
  { state: 'Rio Grande do Sul', id: 4312005, name: 'Mariano Moro' },
  { state: 'Rio Grande do Sul', id: 4312054, name: 'Marques de Souza' },
  { state: 'Rio Grande do Sul', id: 4312104, name: 'Mata' },
  { state: 'Rio Grande do Sul', id: 4312138, name: 'Mato Castelhano' },
  { state: 'Rio Grande do Sul', id: 4312153, name: 'Mato Leitão' },
  { state: 'Rio Grande do Sul', id: 4312179, name: 'Mato Queimado' },
  {
    state: 'Rio Grande do Sul',
    id: 4312203,
    name: 'Maximiliano de Almeida',
  },
  { state: 'Rio Grande do Sul', id: 4312252, name: 'Minas do Leão' },
  { state: 'Rio Grande do Sul', id: 4312302, name: 'Miraguaí' },
  { state: 'Rio Grande do Sul', id: 4312351, name: 'Montauri' },
  {
    state: 'Rio Grande do Sul',
    id: 4312377,
    name: 'Monte Alegre dos Campos',
  },
  { state: 'Rio Grande do Sul', id: 4312385, name: 'Monte Belo do Sul' },
  { state: 'Rio Grande do Sul', id: 4312401, name: 'Montenegro' },
  { state: 'Rio Grande do Sul', id: 4312427, name: 'Mormaço' },
  { state: 'Rio Grande do Sul', id: 4312443, name: 'Morrinhos do Sul' },
  { state: 'Rio Grande do Sul', id: 4312450, name: 'Morro Redondo' },
  { state: 'Rio Grande do Sul', id: 4312476, name: 'Morro Reuter' },
  { state: 'Rio Grande do Sul', id: 4312500, name: 'Mostardas' },
  { state: 'Rio Grande do Sul', id: 4312609, name: 'Muçum' },
  { state: 'Rio Grande do Sul', id: 4312617, name: 'Muitos Capões' },
  { state: 'Rio Grande do Sul', id: 4312625, name: 'Muliterno' },
  { state: 'Rio Grande do Sul', id: 4312658, name: 'Não-Me-Toque' },
  { state: 'Rio Grande do Sul', id: 4312674, name: 'Nicolau Vergueiro' },
  { state: 'Rio Grande do Sul', id: 4312708, name: 'Nonoai' },
  { state: 'Rio Grande do Sul', id: 4312757, name: 'Nova Alvorada' },
  { state: 'Rio Grande do Sul', id: 4312807, name: 'Nova Araçá' },
  { state: 'Rio Grande do Sul', id: 4312906, name: 'Nova Bassano' },
  { state: 'Rio Grande do Sul', id: 4312955, name: 'Nova Boa Vista' },
  { state: 'Rio Grande do Sul', id: 4313003, name: 'Nova Bréscia' },
  { state: 'Rio Grande do Sul', id: 4313011, name: 'Nova Candelária' },
  { state: 'Rio Grande do Sul', id: 4313037, name: 'Nova Esperança do Sul' },
  { state: 'Rio Grande do Sul', id: 4313060, name: 'Nova Hartz' },
  { state: 'Rio Grande do Sul', id: 4313086, name: 'Nova Pádua' },
  { state: 'Rio Grande do Sul', id: 4313102, name: 'Nova Palma' },
  { state: 'Rio Grande do Sul', id: 4313201, name: 'Nova Petrópolis' },
  { state: 'Rio Grande do Sul', id: 4313300, name: 'Nova Prata' },
  { state: 'Rio Grande do Sul', id: 4313334, name: 'Nova Ramada' },
  { state: 'Rio Grande do Sul', id: 4313359, name: 'Nova Roma do Sul' },
  { state: 'Rio Grande do Sul', id: 4313375, name: 'Nova Santa Rita' },
  { state: 'Rio Grande do Sul', id: 4313490, name: 'Novo Barreiro' },
  { state: 'Rio Grande do Sul', id: 4313391, name: 'Novo Cabrais' },
  { state: 'Rio Grande do Sul', id: 4313409, name: 'Novo Hamburgo' },
  { state: 'Rio Grande do Sul', id: 4313425, name: 'Novo Machado' },
  { state: 'Rio Grande do Sul', id: 4313441, name: 'Novo Tiradentes' },
  { state: 'Rio Grande do Sul', id: 4313466, name: 'Novo Xingu' },
  { state: 'Rio Grande do Sul', id: 4313508, name: 'Osório' },
  { state: 'Rio Grande do Sul', id: 4313607, name: 'Paim Filho' },
  { state: 'Rio Grande do Sul', id: 4313656, name: 'Palmares do Sul' },
  { state: 'Rio Grande do Sul', id: 4313706, name: 'Palmeira das Missões' },
  { state: 'Rio Grande do Sul', id: 4313805, name: 'Palmitinho' },
  { state: 'Rio Grande do Sul', id: 4313904, name: 'Panambi' },
  { state: 'Rio Grande do Sul', id: 4313953, name: 'Pantano Grande' },
  { state: 'Rio Grande do Sul', id: 4314001, name: 'Paraí' },
  { state: 'Rio Grande do Sul', id: 4314027, name: 'Paraíso do Sul' },
  { state: 'Rio Grande do Sul', id: 4314035, name: 'Pareci Novo' },
  { state: 'Rio Grande do Sul', id: 4314050, name: 'Parobé' },
  { state: 'Rio Grande do Sul', id: 4314068, name: 'Passa Sete' },
  { state: 'Rio Grande do Sul', id: 4314076, name: 'Passo do Sobrado' },
  { state: 'Rio Grande do Sul', id: 4314100, name: 'Passo Fundo' },
  { state: 'Rio Grande do Sul', id: 4314134, name: 'Paulo Bento' },
  { state: 'Rio Grande do Sul', id: 4314159, name: 'Paverama' },
  { state: 'Rio Grande do Sul', id: 4314175, name: 'Pedras Altas' },
  { state: 'Rio Grande do Sul', id: 4314209, name: 'Pedro Osório' },
  { state: 'Rio Grande do Sul', id: 4314308, name: 'Pejuçara' },
  { state: 'Rio Grande do Sul', id: 4314407, name: 'Pelotas' },
  { state: 'Rio Grande do Sul', id: 4314423, name: 'Picada Café' },
  { state: 'Rio Grande do Sul', id: 4314456, name: 'Pinhal' },
  { state: 'Rio Grande do Sul', id: 4314464, name: 'Pinhal da Serra' },
  { state: 'Rio Grande do Sul', id: 4314472, name: 'Pinhal Grande' },
  { state: 'Rio Grande do Sul', id: 4314498, name: 'Pinheirinho do Vale' },
  { state: 'Rio Grande do Sul', id: 4314506, name: 'Pinheiro Machado' },
  { state: 'Rio Grande do Sul', id: 4314548, name: 'Pinto Bandeira' },
  { state: 'Rio Grande do Sul', id: 4314555, name: 'Pirapó' },
  { state: 'Rio Grande do Sul', id: 4314605, name: 'Piratini' },
  { state: 'Rio Grande do Sul', id: 4314704, name: 'Planalto' },
  { state: 'Rio Grande do Sul', id: 4314753, name: 'Poço das Antas' },
  { state: 'Rio Grande do Sul', id: 4314779, name: 'Pontão' },
  { state: 'Rio Grande do Sul', id: 4314787, name: 'Ponte Preta' },
  { state: 'Rio Grande do Sul', id: 4314803, name: 'Portão' },
  { state: 'Rio Grande do Sul', id: 4314902, name: 'Porto Alegre' },
  { state: 'Rio Grande do Sul', id: 4315008, name: 'Porto Lucena' },
  { state: 'Rio Grande do Sul', id: 4315057, name: 'Porto Mauá' },
  { state: 'Rio Grande do Sul', id: 4315073, name: 'Porto Vera Cruz' },
  { state: 'Rio Grande do Sul', id: 4315107, name: 'Porto Xavier' },
  { state: 'Rio Grande do Sul', id: 4315131, name: 'Pouso Novo' },
  { state: 'Rio Grande do Sul', id: 4315149, name: 'Presidente Lucena' },
  { state: 'Rio Grande do Sul', id: 4315156, name: 'Progresso' },
  { state: 'Rio Grande do Sul', id: 4315172, name: 'Protásio Alves' },
  { state: 'Rio Grande do Sul', id: 4315206, name: 'Putinga' },
  { state: 'Rio Grande do Sul', id: 4315305, name: 'Quaraí' },
  { state: 'Rio Grande do Sul', id: 4315313, name: 'Quatro Irmãos' },
  { state: 'Rio Grande do Sul', id: 4315321, name: 'Quevedos' },
  { state: 'Rio Grande do Sul', id: 4315354, name: 'Quinze de Novembro' },
  { state: 'Rio Grande do Sul', id: 4315404, name: 'Redentora' },
  { state: 'Rio Grande do Sul', id: 4315453, name: 'Relvado' },
  { state: 'Rio Grande do Sul', id: 4315503, name: 'Restinga Sêca' },
  { state: 'Rio Grande do Sul', id: 4315552, name: 'Rio dos Índios' },
  { state: 'Rio Grande do Sul', id: 4315602, name: 'Rio Grande' },
  { state: 'Rio Grande do Sul', id: 4315701, name: 'Rio Pardo' },
  { state: 'Rio Grande do Sul', id: 4315750, name: 'Riozinho' },
  { state: 'Rio Grande do Sul', id: 4315800, name: 'Roca Sales' },
  { state: 'Rio Grande do Sul', id: 4315909, name: 'Rodeio Bonito' },
  { state: 'Rio Grande do Sul', id: 4315958, name: 'Rolador' },
  { state: 'Rio Grande do Sul', id: 4316006, name: 'Rolante' },
  { state: 'Rio Grande do Sul', id: 4316105, name: 'Ronda Alta' },
  { state: 'Rio Grande do Sul', id: 4316204, name: 'Rondinha' },
  { state: 'Rio Grande do Sul', id: 4316303, name: 'Roque Gonzales' },
  { state: 'Rio Grande do Sul', id: 4316402, name: 'Rosário do Sul' },
  { state: 'Rio Grande do Sul', id: 4316428, name: 'Sagrada Família' },
  { state: 'Rio Grande do Sul', id: 4316436, name: 'Saldanha Marinho' },
  { state: 'Rio Grande do Sul', id: 4316451, name: 'Salto do Jacuí' },
  { state: 'Rio Grande do Sul', id: 4316477, name: 'Salvador das Missões' },
  { state: 'Rio Grande do Sul', id: 4316501, name: 'Salvador do Sul' },
  { state: 'Rio Grande do Sul', id: 4316600, name: 'Sananduva' },
  { state: 'Rio Grande do Sul', id: 4316709, name: 'Santa Bárbara do Sul' },
  { state: 'Rio Grande do Sul', id: 4316733, name: 'Santa Cecília do Sul' },
  { state: 'Rio Grande do Sul', id: 4316758, name: 'Santa Clara do Sul' },
  { state: 'Rio Grande do Sul', id: 4316808, name: 'Santa Cruz do Sul' },
  {
    state: 'Rio Grande do Sul',
    id: 4316972,
    name: 'Santa Margarida do Sul',
  },
  { state: 'Rio Grande do Sul', id: 4316907, name: 'Santa Maria' },
  { state: 'Rio Grande do Sul', id: 4316956, name: 'Santa Maria do Herval' },
  { state: 'Rio Grande do Sul', id: 4317202, name: 'Santa Rosa' },
  { state: 'Rio Grande do Sul', id: 4317251, name: 'Santa Tereza' },
  {
    state: 'Rio Grande do Sul',
    id: 4317301,
    name: 'Santa Vitória do Palmar',
  },
  { state: 'Rio Grande do Sul', id: 4317004, name: 'Santana da Boa Vista' },
  {
    state: 'Rio Grande do Sul',
    id: 4317103,
    name: "Sant'Ana do Livramento",
  },
  { state: 'Rio Grande do Sul', id: 4317400, name: 'Santiago' },
  { state: 'Rio Grande do Sul', id: 4317509, name: 'Santo Ângelo' },
  {
    state: 'Rio Grande do Sul',
    id: 4317608,
    name: 'Santo Antônio da Patrulha',
  },
  {
    state: 'Rio Grande do Sul',
    id: 4317707,
    name: 'Santo Antônio das Missões',
  },
  {
    state: 'Rio Grande do Sul',
    id: 4317558,
    name: 'Santo Antônio do Palma',
  },
  {
    state: 'Rio Grande do Sul',
    id: 4317756,
    name: 'Santo Antônio do Planalto',
  },
  { state: 'Rio Grande do Sul', id: 4317806, name: 'Santo Augusto' },
  { state: 'Rio Grande do Sul', id: 4317905, name: 'Santo Cristo' },
  { state: 'Rio Grande do Sul', id: 4317954, name: 'Santo Expedito do Sul' },
  { state: 'Rio Grande do Sul', id: 4318002, name: 'São Borja' },
  { state: 'Rio Grande do Sul', id: 4318051, name: 'São Domingos do Sul' },
  {
    state: 'Rio Grande do Sul',
    id: 4318101,
    name: 'São Francisco de Assis',
  },
  {
    state: 'Rio Grande do Sul',
    id: 4318200,
    name: 'São Francisco de Paula',
  },
  { state: 'Rio Grande do Sul', id: 4318309, name: 'São Gabriel' },
  { state: 'Rio Grande do Sul', id: 4318408, name: 'São Jerônimo' },
  { state: 'Rio Grande do Sul', id: 4318424, name: 'São João da Urtiga' },
  { state: 'Rio Grande do Sul', id: 4318432, name: 'São João do Polêsine' },
  { state: 'Rio Grande do Sul', id: 4318440, name: 'São Jorge' },
  { state: 'Rio Grande do Sul', id: 4318457, name: 'São José das Missões' },
  { state: 'Rio Grande do Sul', id: 4318465, name: 'São José do Herval' },
  { state: 'Rio Grande do Sul', id: 4318481, name: 'São José do Hortêncio' },
  { state: 'Rio Grande do Sul', id: 4318499, name: 'São José do Inhacorá' },
  { state: 'Rio Grande do Sul', id: 4318507, name: 'São José do Norte' },
  { state: 'Rio Grande do Sul', id: 4318606, name: 'São José do Ouro' },
  { state: 'Rio Grande do Sul', id: 4318614, name: 'São José do Sul' },
  { state: 'Rio Grande do Sul', id: 4318622, name: 'São José dos Ausentes' },
  { state: 'Rio Grande do Sul', id: 4318705, name: 'São Leopoldo' },
  { state: 'Rio Grande do Sul', id: 4318804, name: 'São Lourenço do Sul' },
  { state: 'Rio Grande do Sul', id: 4318903, name: 'São Luiz Gonzaga' },
  { state: 'Rio Grande do Sul', id: 4319000, name: 'São Marcos' },
  { state: 'Rio Grande do Sul', id: 4319109, name: 'São Martinho' },
  { state: 'Rio Grande do Sul', id: 4319125, name: 'São Martinho da Serra' },
  {
    state: 'Rio Grande do Sul',
    id: 4319158,
    name: 'São Miguel das Missões',
  },
  { state: 'Rio Grande do Sul', id: 4319208, name: 'São Nicolau' },
  { state: 'Rio Grande do Sul', id: 4319307, name: 'São Paulo das Missões' },
  { state: 'Rio Grande do Sul', id: 4319356, name: 'São Pedro da Serra' },
  { state: 'Rio Grande do Sul', id: 4319364, name: 'São Pedro das Missões' },
  { state: 'Rio Grande do Sul', id: 4319372, name: 'São Pedro do Butiá' },
  { state: 'Rio Grande do Sul', id: 4319406, name: 'São Pedro do Sul' },
  { state: 'Rio Grande do Sul', id: 4319505, name: 'São Sebastião do Caí' },
  { state: 'Rio Grande do Sul', id: 4319604, name: 'São Sepé' },
  { state: 'Rio Grande do Sul', id: 4319703, name: 'São Valentim' },
  { state: 'Rio Grande do Sul', id: 4319711, name: 'São Valentim do Sul' },
  { state: 'Rio Grande do Sul', id: 4319737, name: 'São Valério do Sul' },
  { state: 'Rio Grande do Sul', id: 4319752, name: 'São Vendelino' },
  { state: 'Rio Grande do Sul', id: 4319802, name: 'São Vicente do Sul' },
  { state: 'Rio Grande do Sul', id: 4319901, name: 'Sapiranga' },
  { state: 'Rio Grande do Sul', id: 4320008, name: 'Sapucaia do Sul' },
  { state: 'Rio Grande do Sul', id: 4320107, name: 'Sarandi' },
  { state: 'Rio Grande do Sul', id: 4320206, name: 'Seberi' },
  { state: 'Rio Grande do Sul', id: 4320230, name: 'Sede Nova' },
  { state: 'Rio Grande do Sul', id: 4320263, name: 'Segredo' },
  { state: 'Rio Grande do Sul', id: 4320305, name: 'Selbach' },
  { state: 'Rio Grande do Sul', id: 4320321, name: 'Senador Salgado Filho' },
  { state: 'Rio Grande do Sul', id: 4320354, name: 'Sentinela do Sul' },
  { state: 'Rio Grande do Sul', id: 4320404, name: 'Serafina Corrêa' },
  { state: 'Rio Grande do Sul', id: 4320453, name: 'Sério' },
  { state: 'Rio Grande do Sul', id: 4320503, name: 'Sertão' },
  { state: 'Rio Grande do Sul', id: 4320552, name: 'Sertão Santana' },
  { state: 'Rio Grande do Sul', id: 4320578, name: 'Sete de Setembro' },
  { state: 'Rio Grande do Sul', id: 4320602, name: 'Severiano de Almeida' },
  { state: 'Rio Grande do Sul', id: 4320651, name: 'Silveira Martins' },
  { state: 'Rio Grande do Sul', id: 4320677, name: 'Sinimbu' },
  { state: 'Rio Grande do Sul', id: 4320701, name: 'Sobradinho' },
  { state: 'Rio Grande do Sul', id: 4320800, name: 'Soledade' },
  { state: 'Rio Grande do Sul', id: 4320859, name: 'Tabaí' },
  { state: 'Rio Grande do Sul', id: 4320909, name: 'Tapejara' },
  { state: 'Rio Grande do Sul', id: 4321006, name: 'Tapera' },
  { state: 'Rio Grande do Sul', id: 4321105, name: 'Tapes' },
  { state: 'Rio Grande do Sul', id: 4321204, name: 'Taquara' },
  { state: 'Rio Grande do Sul', id: 4321303, name: 'Taquari' },
  { state: 'Rio Grande do Sul', id: 4321329, name: 'Taquaruçu do Sul' },
  { state: 'Rio Grande do Sul', id: 4321352, name: 'Tavares' },
  { state: 'Rio Grande do Sul', id: 4321402, name: 'Tenente Portela' },
  { state: 'Rio Grande do Sul', id: 4321436, name: 'Terra de Areia' },
  { state: 'Rio Grande do Sul', id: 4321451, name: 'Teutônia' },
  { state: 'Rio Grande do Sul', id: 4321469, name: 'Tio Hugo' },
  { state: 'Rio Grande do Sul', id: 4321477, name: 'Tiradentes do Sul' },
  { state: 'Rio Grande do Sul', id: 4321493, name: 'Toropi' },
  { state: 'Rio Grande do Sul', id: 4321501, name: 'Torres' },
  { state: 'Rio Grande do Sul', id: 4321600, name: 'Tramandaí' },
  { state: 'Rio Grande do Sul', id: 4321626, name: 'Travesseiro' },
  { state: 'Rio Grande do Sul', id: 4321634, name: 'Três Arroios' },
  { state: 'Rio Grande do Sul', id: 4321667, name: 'Três Cachoeiras' },
  { state: 'Rio Grande do Sul', id: 4321709, name: 'Três Coroas' },
  { state: 'Rio Grande do Sul', id: 4321808, name: 'Três de Maio' },
  { state: 'Rio Grande do Sul', id: 4321832, name: 'Três Forquilhas' },
  { state: 'Rio Grande do Sul', id: 4321857, name: 'Três Palmeiras' },
  { state: 'Rio Grande do Sul', id: 4321907, name: 'Três Passos' },
  { state: 'Rio Grande do Sul', id: 4321956, name: 'Trindade do Sul' },
  { state: 'Rio Grande do Sul', id: 4322004, name: 'Triunfo' },
  { state: 'Rio Grande do Sul', id: 4322103, name: 'Tucunduva' },
  { state: 'Rio Grande do Sul', id: 4322152, name: 'Tunas' },
  { state: 'Rio Grande do Sul', id: 4322186, name: 'Tupanci do Sul' },
  { state: 'Rio Grande do Sul', id: 4322202, name: 'Tupanciretã' },
  { state: 'Rio Grande do Sul', id: 4322251, name: 'Tupandi' },
  { state: 'Rio Grande do Sul', id: 4322301, name: 'Tuparendi' },
  { state: 'Rio Grande do Sul', id: 4322327, name: 'Turuçu' },
  { state: 'Rio Grande do Sul', id: 4322343, name: 'Ubiretama' },
  { state: 'Rio Grande do Sul', id: 4322350, name: 'União da Serra' },
  { state: 'Rio Grande do Sul', id: 4322376, name: 'Unistalda' },
  { state: 'Rio Grande do Sul', id: 4322400, name: 'Uruguaiana' },
  { state: 'Rio Grande do Sul', id: 4322509, name: 'Vacaria' },
  { state: 'Rio Grande do Sul', id: 4322533, name: 'Vale do Sol' },
  { state: 'Rio Grande do Sul', id: 4322541, name: 'Vale Real' },
  { state: 'Rio Grande do Sul', id: 4322525, name: 'Vale Verde' },
  { state: 'Rio Grande do Sul', id: 4322558, name: 'Vanini' },
  { state: 'Rio Grande do Sul', id: 4322608, name: 'Venâncio Aires' },
  { state: 'Rio Grande do Sul', id: 4322707, name: 'Vera Cruz' },
  { state: 'Rio Grande do Sul', id: 4322806, name: 'Veranópolis' },
  { state: 'Rio Grande do Sul', id: 4322855, name: 'Vespasiano Corrêa' },
  { state: 'Rio Grande do Sul', id: 4322905, name: 'Viadutos' },
  { state: 'Rio Grande do Sul', id: 4323002, name: 'Viamão' },
  { state: 'Rio Grande do Sul', id: 4323101, name: 'Vicente Dutra' },
  { state: 'Rio Grande do Sul', id: 4323200, name: 'Victor Graeff' },
  { state: 'Rio Grande do Sul', id: 4323309, name: 'Vila Flores' },
  { state: 'Rio Grande do Sul', id: 4323358, name: 'Vila Lângaro' },
  { state: 'Rio Grande do Sul', id: 4323408, name: 'Vila Maria' },
  { state: 'Rio Grande do Sul', id: 4323457, name: 'Vila Nova do Sul' },
  { state: 'Rio Grande do Sul', id: 4323507, name: 'Vista Alegre' },
  { state: 'Rio Grande do Sul', id: 4323606, name: 'Vista Alegre do Prata' },
  { state: 'Rio Grande do Sul', id: 4323705, name: 'Vista Gaúcha' },
  { state: 'Rio Grande do Sul', id: 4323754, name: 'Vitória das Missões' },
  { state: 'Rio Grande do Sul', id: 4323770, name: 'Westfália' },
  { state: 'Rio Grande do Sul', id: 4323804, name: 'Xangri-lá' },
  { state: 'Mato Grosso do Sul', id: 5000203, name: 'Água Clara' },
  { state: 'Mato Grosso do Sul', id: 5000252, name: 'Alcinópolis' },
  { state: 'Mato Grosso do Sul', id: 5000609, name: 'Amambai' },
  { state: 'Mato Grosso do Sul', id: 5000708, name: 'Anastácio' },
  { state: 'Mato Grosso do Sul', id: 5000807, name: 'Anaurilândia' },
  { state: 'Mato Grosso do Sul', id: 5000856, name: 'Angélica' },
  { state: 'Mato Grosso do Sul', id: 5000906, name: 'Antônio João' },
  { state: 'Mato Grosso do Sul', id: 5001003, name: 'Aparecida do Taboado' },
  { state: 'Mato Grosso do Sul', id: 5001102, name: 'Aquidauana' },
  { state: 'Mato Grosso do Sul', id: 5001243, name: 'Aral Moreira' },
  { state: 'Mato Grosso do Sul', id: 5001508, name: 'Bandeirantes' },
  { state: 'Mato Grosso do Sul', id: 5001904, name: 'Bataguassu' },
  { state: 'Mato Grosso do Sul', id: 5002001, name: 'Batayporã' },
  { state: 'Mato Grosso do Sul', id: 5002100, name: 'Bela Vista' },
  { state: 'Mato Grosso do Sul', id: 5002159, name: 'Bodoquena' },
  { state: 'Mato Grosso do Sul', id: 5002209, name: 'Bonito' },
  { state: 'Mato Grosso do Sul', id: 5002308, name: 'Brasilândia' },
  { state: 'Mato Grosso do Sul', id: 5002407, name: 'Caarapó' },
  { state: 'Mato Grosso do Sul', id: 5002605, name: 'Camapuã' },
  { state: 'Mato Grosso do Sul', id: 5002704, name: 'Campo Grande' },
  { state: 'Mato Grosso do Sul', id: 5002803, name: 'Caracol' },
  { state: 'Mato Grosso do Sul', id: 5002902, name: 'Cassilândia' },
  { state: 'Mato Grosso do Sul', id: 5002951, name: 'Chapadão do Sul' },
  { state: 'Mato Grosso do Sul', id: 5003108, name: 'Corguinho' },
  { state: 'Mato Grosso do Sul', id: 5003157, name: 'Coronel Sapucaia' },
  { state: 'Mato Grosso do Sul', id: 5003207, name: 'Corumbá' },
  { state: 'Mato Grosso do Sul', id: 5003256, name: 'Costa Rica' },
  { state: 'Mato Grosso do Sul', id: 5003306, name: 'Coxim' },
  { state: 'Mato Grosso do Sul', id: 5003454, name: 'Deodápolis' },
  {
    state: 'Mato Grosso do Sul',
    id: 5003488,
    name: 'Dois Irmãos do Buriti',
  },
  { state: 'Mato Grosso do Sul', id: 5003504, name: 'Douradina' },
  { state: 'Mato Grosso do Sul', id: 5003702, name: 'Dourados' },
  { state: 'Mato Grosso do Sul', id: 5003751, name: 'Eldorado' },
  { state: 'Mato Grosso do Sul', id: 5003801, name: 'Fátima do Sul' },
  { state: 'Mato Grosso do Sul', id: 5003900, name: 'Figueirão' },
  { state: 'Mato Grosso do Sul', id: 5004007, name: 'Glória de Dourados' },
  { state: 'Mato Grosso do Sul', id: 5004106, name: 'Guia Lopes da Laguna' },
  { state: 'Mato Grosso do Sul', id: 5004304, name: 'Iguatemi' },
  { state: 'Mato Grosso do Sul', id: 5004403, name: 'Inocência' },
  { state: 'Mato Grosso do Sul', id: 5004502, name: 'Itaporã' },
  { state: 'Mato Grosso do Sul', id: 5004601, name: 'Itaquiraí' },
  { state: 'Mato Grosso do Sul', id: 5004700, name: 'Ivinhema' },
  { state: 'Mato Grosso do Sul', id: 5004809, name: 'Japorã' },
  { state: 'Mato Grosso do Sul', id: 5004908, name: 'Jaraguari' },
  { state: 'Mato Grosso do Sul', id: 5005004, name: 'Jardim' },
  { state: 'Mato Grosso do Sul', id: 5005103, name: 'Jateí' },
  { state: 'Mato Grosso do Sul', id: 5005152, name: 'Juti' },
  { state: 'Mato Grosso do Sul', id: 5005202, name: 'Ladário' },
  { state: 'Mato Grosso do Sul', id: 5005251, name: 'Laguna Carapã' },
  { state: 'Mato Grosso do Sul', id: 5005400, name: 'Maracaju' },
  { state: 'Mato Grosso do Sul', id: 5005608, name: 'Miranda' },
  { state: 'Mato Grosso do Sul', id: 5005681, name: 'Mundo Novo' },
  { state: 'Mato Grosso do Sul', id: 5005707, name: 'Naviraí' },
  { state: 'Mato Grosso do Sul', id: 5005806, name: 'Nioaque' },
  { state: 'Mato Grosso do Sul', id: 5006002, name: 'Nova Alvorada do Sul' },
  { state: 'Mato Grosso do Sul', id: 5006200, name: 'Nova Andradina' },
  {
    state: 'Mato Grosso do Sul',
    id: 5006259,
    name: 'Novo Horizonte do Sul',
  },
  { state: 'Mato Grosso do Sul', id: 5006275, name: 'Paraíso das Águas' },
  { state: 'Mato Grosso do Sul', id: 5006309, name: 'Paranaíba' },
  { state: 'Mato Grosso do Sul', id: 5006358, name: 'Paranhos' },
  { state: 'Mato Grosso do Sul', id: 5006408, name: 'Pedro Gomes' },
  { state: 'Mato Grosso do Sul', id: 5006606, name: 'Ponta Porã' },
  { state: 'Mato Grosso do Sul', id: 5006903, name: 'Porto Murtinho' },
  { state: 'Mato Grosso do Sul', id: 5007109, name: 'Ribas do Rio Pardo' },
  { state: 'Mato Grosso do Sul', id: 5007208, name: 'Rio Brilhante' },
  { state: 'Mato Grosso do Sul', id: 5007307, name: 'Rio Negro' },
  {
    state: 'Mato Grosso do Sul',
    id: 5007406,
    name: 'Rio Verde de Mato Grosso',
  },
  { state: 'Mato Grosso do Sul', id: 5007505, name: 'Rochedo' },
  { state: 'Mato Grosso do Sul', id: 5007554, name: 'Santa Rita do Pardo' },
  { state: 'Mato Grosso do Sul', id: 5007695, name: 'São Gabriel do Oeste' },
  { state: 'Mato Grosso do Sul', id: 5007802, name: 'Selvíria' },
  { state: 'Mato Grosso do Sul', id: 5007703, name: 'Sete Quedas' },
  { state: 'Mato Grosso do Sul', id: 5007901, name: 'Sidrolândia' },
  { state: 'Mato Grosso do Sul', id: 5007935, name: 'Sonora' },
  { state: 'Mato Grosso do Sul', id: 5007950, name: 'Tacuru' },
  { state: 'Mato Grosso do Sul', id: 5007976, name: 'Taquarussu' },
  { state: 'Mato Grosso do Sul', id: 5008008, name: 'Terenos' },
  { state: 'Mato Grosso do Sul', id: 5008305, name: 'Três Lagoas' },
  { state: 'Mato Grosso do Sul', id: 5008404, name: 'Vicentina' },
  { state: 'Mato Grosso', id: 5100102, name: 'Acorizal' },
  { state: 'Mato Grosso', id: 5100201, name: 'Água Boa' },
  { state: 'Mato Grosso', id: 5100250, name: 'Alta Floresta' },
  { state: 'Mato Grosso', id: 5100300, name: 'Alto Araguaia' },
  { state: 'Mato Grosso', id: 5100359, name: 'Alto Boa Vista' },
  { state: 'Mato Grosso', id: 5100409, name: 'Alto Garças' },
  { state: 'Mato Grosso', id: 5100508, name: 'Alto Paraguai' },
  { state: 'Mato Grosso', id: 5100607, name: 'Alto Taquari' },
  { state: 'Mato Grosso', id: 5100805, name: 'Apiacás' },
  { state: 'Mato Grosso', id: 5101001, name: 'Araguaiana' },
  { state: 'Mato Grosso', id: 5101209, name: 'Araguainha' },
  { state: 'Mato Grosso', id: 5101258, name: 'Araputanga' },
  { state: 'Mato Grosso', id: 5101308, name: 'Arenápolis' },
  { state: 'Mato Grosso', id: 5101407, name: 'Aripuanã' },
  { state: 'Mato Grosso', id: 5101605, name: 'Barão de Melgaço' },
  { state: 'Mato Grosso', id: 5101704, name: 'Barra do Bugres' },
  { state: 'Mato Grosso', id: 5101803, name: 'Barra do Garças' },
  { state: 'Mato Grosso', id: 5101852, name: 'Bom Jesus do Araguaia' },
  { state: 'Mato Grosso', id: 5101902, name: 'Brasnorte' },
  { state: 'Mato Grosso', id: 5102504, name: 'Cáceres' },
  { state: 'Mato Grosso', id: 5102603, name: 'Campinápolis' },
  { state: 'Mato Grosso', id: 5102637, name: 'Campo Novo do Parecis' },
  { state: 'Mato Grosso', id: 5102678, name: 'Campo Verde' },
  { state: 'Mato Grosso', id: 5102686, name: 'Campos de Júlio' },
  { state: 'Mato Grosso', id: 5102694, name: 'Canabrava do Norte' },
  { state: 'Mato Grosso', id: 5102702, name: 'Canarana' },
  { state: 'Mato Grosso', id: 5102793, name: 'Carlinda' },
  { state: 'Mato Grosso', id: 5102850, name: 'Castanheira' },
  { state: 'Mato Grosso', id: 5103007, name: 'Chapada dos Guimarães' },
  { state: 'Mato Grosso', id: 5103056, name: 'Cláudia' },
  { state: 'Mato Grosso', id: 5103106, name: 'Cocalinho' },
  { state: 'Mato Grosso', id: 5103205, name: 'Colíder' },
  { state: 'Mato Grosso', id: 5103254, name: 'Colniza' },
  { state: 'Mato Grosso', id: 5103304, name: 'Comodoro' },
  { state: 'Mato Grosso', id: 5103353, name: 'Confresa' },
  { state: 'Mato Grosso', id: 5103361, name: "Conquista D'Oeste" },
  { state: 'Mato Grosso', id: 5103379, name: 'Cotriguaçu' },
  { state: 'Mato Grosso', id: 5103403, name: 'Cuiabá' },
  { state: 'Mato Grosso', id: 5103437, name: 'Curvelândia' },
  { state: 'Mato Grosso', id: 5103452, name: 'Denise' },
  { state: 'Mato Grosso', id: 5103502, name: 'Diamantino' },
  { state: 'Mato Grosso', id: 5103601, name: 'Dom Aquino' },
  { state: 'Mato Grosso', id: 5103700, name: 'Feliz Natal' },
  { state: 'Mato Grosso', id: 5103809, name: "Figueirópolis D'Oeste" },
  { state: 'Mato Grosso', id: 5103858, name: 'Gaúcha do Norte' },
  { state: 'Mato Grosso', id: 5103908, name: 'General Carneiro' },
  { state: 'Mato Grosso', id: 5103957, name: "Glória D'Oeste" },
  { state: 'Mato Grosso', id: 5104104, name: 'Guarantã do Norte' },
  { state: 'Mato Grosso', id: 5104203, name: 'Guiratinga' },
  { state: 'Mato Grosso', id: 5104500, name: 'Indiavaí' },
  { state: 'Mato Grosso', id: 5104526, name: 'Ipiranga do Norte' },
  { state: 'Mato Grosso', id: 5104542, name: 'Itanhangá' },
  { state: 'Mato Grosso', id: 5104559, name: 'Itaúba' },
  { state: 'Mato Grosso', id: 5104609, name: 'Itiquira' },
  { state: 'Mato Grosso', id: 5104807, name: 'Jaciara' },
  { state: 'Mato Grosso', id: 5104906, name: 'Jangada' },
  { state: 'Mato Grosso', id: 5105002, name: 'Jauru' },
  { state: 'Mato Grosso', id: 5105101, name: 'Juara' },
  { state: 'Mato Grosso', id: 5105150, name: 'Juína' },
  { state: 'Mato Grosso', id: 5105176, name: 'Juruena' },
  { state: 'Mato Grosso', id: 5105200, name: 'Juscimeira' },
  { state: 'Mato Grosso', id: 5105234, name: "Lambari D'Oeste" },
  { state: 'Mato Grosso', id: 5105259, name: 'Lucas do Rio Verde' },
  { state: 'Mato Grosso', id: 5105309, name: 'Luciara' },
  { state: 'Mato Grosso', id: 5105580, name: 'Marcelândia' },
  { state: 'Mato Grosso', id: 5105606, name: 'Matupá' },
  { state: 'Mato Grosso', id: 5105622, name: "Mirassol d'Oeste" },
  { state: 'Mato Grosso', id: 5105903, name: 'Nobres' },
  { state: 'Mato Grosso', id: 5106000, name: 'Nortelândia' },
  { state: 'Mato Grosso', id: 5106109, name: 'Nossa Senhora do Livramento' },
  { state: 'Mato Grosso', id: 5106158, name: 'Nova Bandeirantes' },
  { state: 'Mato Grosso', id: 5106208, name: 'Nova Brasilândia' },
  { state: 'Mato Grosso', id: 5106216, name: 'Nova Canaã do Norte' },
  { state: 'Mato Grosso', id: 5108808, name: 'Nova Guarita' },
  { state: 'Mato Grosso', id: 5106182, name: 'Nova Lacerda' },
  { state: 'Mato Grosso', id: 5108857, name: 'Nova Marilândia' },
  { state: 'Mato Grosso', id: 5108907, name: 'Nova Maringá' },
  { state: 'Mato Grosso', id: 5108956, name: 'Nova Monte Verde' },
  { state: 'Mato Grosso', id: 5106224, name: 'Nova Mutum' },
  { state: 'Mato Grosso', id: 5106174, name: 'Nova Nazaré' },
  { state: 'Mato Grosso', id: 5106232, name: 'Nova Olímpia' },
  { state: 'Mato Grosso', id: 5106190, name: 'Nova Santa Helena' },
  { state: 'Mato Grosso', id: 5106240, name: 'Nova Ubiratã' },
  { state: 'Mato Grosso', id: 5106257, name: 'Nova Xavantina' },
  { state: 'Mato Grosso', id: 5106273, name: 'Novo Horizonte do Norte' },
  { state: 'Mato Grosso', id: 5106265, name: 'Novo Mundo' },
  { state: 'Mato Grosso', id: 5106315, name: 'Novo Santo Antônio' },
  { state: 'Mato Grosso', id: 5106281, name: 'Novo São Joaquim' },
  { state: 'Mato Grosso', id: 5106299, name: 'Paranaíta' },
  { state: 'Mato Grosso', id: 5106307, name: 'Paranatinga' },
  { state: 'Mato Grosso', id: 5106372, name: 'Pedra Preta' },
  { state: 'Mato Grosso', id: 5106422, name: 'Peixoto de Azevedo' },
  { state: 'Mato Grosso', id: 5106455, name: 'Planalto da Serra' },
  { state: 'Mato Grosso', id: 5106505, name: 'Poconé' },
  { state: 'Mato Grosso', id: 5106653, name: 'Pontal do Araguaia' },
  { state: 'Mato Grosso', id: 5106703, name: 'Ponte Branca' },
  { state: 'Mato Grosso', id: 5106752, name: 'Pontes e Lacerda' },
  { state: 'Mato Grosso', id: 5106778, name: 'Porto Alegre do Norte' },
  { state: 'Mato Grosso', id: 5106802, name: 'Porto dos Gaúchos' },
  { state: 'Mato Grosso', id: 5106828, name: 'Porto Esperidião' },
  { state: 'Mato Grosso', id: 5106851, name: 'Porto Estrela' },
  { state: 'Mato Grosso', id: 5107008, name: 'Poxoréu' },
  { state: 'Mato Grosso', id: 5107040, name: 'Primavera do Leste' },
  { state: 'Mato Grosso', id: 5107065, name: 'Querência' },
  { state: 'Mato Grosso', id: 5107156, name: 'Reserva do Cabaçal' },
  { state: 'Mato Grosso', id: 5107180, name: 'Ribeirão Cascalheira' },
  { state: 'Mato Grosso', id: 5107198, name: 'Ribeirãozinho' },
  { state: 'Mato Grosso', id: 5107206, name: 'Rio Branco' },
  { state: 'Mato Grosso', id: 5107578, name: 'Rondolândia' },
  { state: 'Mato Grosso', id: 5107602, name: 'Rondonópolis' },
  { state: 'Mato Grosso', id: 5107701, name: 'Rosário Oeste' },
  { state: 'Mato Grosso', id: 5107750, name: 'Salto do Céu' },
  { state: 'Mato Grosso', id: 5107248, name: 'Santa Carmem' },
  { state: 'Mato Grosso', id: 5107743, name: 'Santa Cruz do Xingu' },
  { state: 'Mato Grosso', id: 5107768, name: 'Santa Rita do Trivelato' },
  { state: 'Mato Grosso', id: 5107776, name: 'Santa Terezinha' },
  { state: 'Mato Grosso', id: 5107263, name: 'Santo Afonso' },
  { state: 'Mato Grosso', id: 5107792, name: 'Santo Antônio do Leste' },
  { state: 'Mato Grosso', id: 5107800, name: 'Santo Antônio do Leverger' },
  { state: 'Mato Grosso', id: 5107859, name: 'São Félix do Araguaia' },
  { state: 'Mato Grosso', id: 5107297, name: 'São José do Povo' },
  { state: 'Mato Grosso', id: 5107305, name: 'São José do Rio Claro' },
  { state: 'Mato Grosso', id: 5107354, name: 'São José do Xingu' },
  { state: 'Mato Grosso', id: 5107107, name: 'São José dos Quatro Marcos' },
  { state: 'Mato Grosso', id: 5107404, name: 'São Pedro da Cipa' },
  { state: 'Mato Grosso', id: 5107875, name: 'Sapezal' },
  { state: 'Mato Grosso', id: 5107883, name: 'Serra Nova Dourada' },
  { state: 'Mato Grosso', id: 5107909, name: 'Sinop' },
  { state: 'Mato Grosso', id: 5107925, name: 'Sorriso' },
  { state: 'Mato Grosso', id: 5107941, name: 'Tabaporã' },
  { state: 'Mato Grosso', id: 5107958, name: 'Tangará da Serra' },
  { state: 'Mato Grosso', id: 5108006, name: 'Tapurah' },
  { state: 'Mato Grosso', id: 5108055, name: 'Terra Nova do Norte' },
  { state: 'Mato Grosso', id: 5108105, name: 'Tesouro' },
  { state: 'Mato Grosso', id: 5108204, name: 'Torixoréu' },
  { state: 'Mato Grosso', id: 5108303, name: 'União do Sul' },
  { state: 'Mato Grosso', id: 5108352, name: 'Vale de São Domingos' },
  { state: 'Mato Grosso', id: 5108402, name: 'Várzea Grande' },
  { state: 'Mato Grosso', id: 5108501, name: 'Vera' },
  {
    state: 'Mato Grosso',
    id: 5105507,
    name: 'Vila Bela da Santíssima Trindade',
  },
  { state: 'Mato Grosso', id: 5108600, name: 'Vila Rica' },
  { state: 'Goiás', id: 5200050, name: 'Abadia de Goiás' },
  { state: 'Goiás', id: 5200100, name: 'Abadiânia' },
  { state: 'Goiás', id: 5200134, name: 'Acreúna' },
  { state: 'Goiás', id: 5200159, name: 'Adelândia' },
  { state: 'Goiás', id: 5200175, name: 'Água Fria de Goiás' },
  { state: 'Goiás', id: 5200209, name: 'Água Limpa' },
  { state: 'Goiás', id: 5200258, name: 'Águas Lindas de Goiás' },
  { state: 'Goiás', id: 5200308, name: 'Alexânia' },
  { state: 'Goiás', id: 5200506, name: 'Aloândia' },
  { state: 'Goiás', id: 5200555, name: 'Alto Horizonte' },
  { state: 'Goiás', id: 5200605, name: 'Alto Paraíso de Goiás' },
  { state: 'Goiás', id: 5200803, name: 'Alvorada do Norte' },
  { state: 'Goiás', id: 5200829, name: 'Amaralina' },
  { state: 'Goiás', id: 5200852, name: 'Americano do Brasil' },
  { state: 'Goiás', id: 5200902, name: 'Amorinópolis' },
  { state: 'Goiás', id: 5201108, name: 'Anápolis' },
  { state: 'Goiás', id: 5201207, name: 'Anhanguera' },
  { state: 'Goiás', id: 5201306, name: 'Anicuns' },
  { state: 'Goiás', id: 5201405, name: 'Aparecida de Goiânia' },
  { state: 'Goiás', id: 5201454, name: 'Aparecida do Rio Doce' },
  { state: 'Goiás', id: 5201504, name: 'Aporé' },
  { state: 'Goiás', id: 5201603, name: 'Araçu' },
  { state: 'Goiás', id: 5201702, name: 'Aragarças' },
  { state: 'Goiás', id: 5201801, name: 'Aragoiânia' },
  { state: 'Goiás', id: 5202155, name: 'Araguapaz' },
  { state: 'Goiás', id: 5202353, name: 'Arenópolis' },
  { state: 'Goiás', id: 5202502, name: 'Aruanã' },
  { state: 'Goiás', id: 5202601, name: 'Aurilândia' },
  { state: 'Goiás', id: 5202809, name: 'Avelinópolis' },
  { state: 'Goiás', id: 5203104, name: 'Baliza' },
  { state: 'Goiás', id: 5203203, name: 'Barro Alto' },
  { state: 'Goiás', id: 5203302, name: 'Bela Vista de Goiás' },
  { state: 'Goiás', id: 5203401, name: 'Bom Jardim de Goiás' },
  { state: 'Goiás', id: 5203500, name: 'Bom Jesus de Goiás' },
  { state: 'Goiás', id: 5203559, name: 'Bonfinópolis' },
  { state: 'Goiás', id: 5203575, name: 'Bonópolis' },
  { state: 'Goiás', id: 5203609, name: 'Brazabrantes' },
  { state: 'Goiás', id: 5203807, name: 'Britânia' },
  { state: 'Goiás', id: 5203906, name: 'Buriti Alegre' },
  { state: 'Goiás', id: 5203939, name: 'Buriti de Goiás' },
  { state: 'Goiás', id: 5203962, name: 'Buritinópolis' },
  { state: 'Goiás', id: 5204003, name: 'Cabeceiras' },
  { state: 'Goiás', id: 5204102, name: 'Cachoeira Alta' },
  { state: 'Goiás', id: 5204201, name: 'Cachoeira de Goiás' },
  { state: 'Goiás', id: 5204250, name: 'Cachoeira Dourada' },
  { state: 'Goiás', id: 5204300, name: 'Caçu' },
  { state: 'Goiás', id: 5204409, name: 'Caiapônia' },
  { state: 'Goiás', id: 5204508, name: 'Caldas Novas' },
  { state: 'Goiás', id: 5204557, name: 'Caldazinha' },
  { state: 'Goiás', id: 5204607, name: 'Campestre de Goiás' },
  { state: 'Goiás', id: 5204656, name: 'Campinaçu' },
  { state: 'Goiás', id: 5204706, name: 'Campinorte' },
  { state: 'Goiás', id: 5204805, name: 'Campo Alegre de Goiás' },
  { state: 'Goiás', id: 5204854, name: 'Campo Limpo de Goiás' },
  { state: 'Goiás', id: 5204904, name: 'Campos Belos' },
  { state: 'Goiás', id: 5204953, name: 'Campos Verdes' },
  { state: 'Goiás', id: 5205000, name: 'Carmo do Rio Verde' },
  { state: 'Goiás', id: 5205059, name: 'Castelândia' },
  { state: 'Goiás', id: 5205109, name: 'Catalão' },
  { state: 'Goiás', id: 5205208, name: 'Caturaí' },
  { state: 'Goiás', id: 5205307, name: 'Cavalcante' },
  { state: 'Goiás', id: 5205406, name: 'Ceres' },
  { state: 'Goiás', id: 5205455, name: 'Cezarina' },
  { state: 'Goiás', id: 5205471, name: 'Chapadão do Céu' },
  { state: 'Goiás', id: 5205497, name: 'Cidade Ocidental' },
  { state: 'Goiás', id: 5205513, name: 'Cocalzinho de Goiás' },
  { state: 'Goiás', id: 5205521, name: 'Colinas do Sul' },
  { state: 'Goiás', id: 5205703, name: 'Córrego do Ouro' },
  { state: 'Goiás', id: 5205802, name: 'Corumbá de Goiás' },
  { state: 'Goiás', id: 5205901, name: 'Corumbaíba' },
  { state: 'Goiás', id: 5206206, name: 'Cristalina' },
  { state: 'Goiás', id: 5206305, name: 'Cristianópolis' },
  { state: 'Goiás', id: 5206404, name: 'Crixás' },
  { state: 'Goiás', id: 5206503, name: 'Cromínia' },
  { state: 'Goiás', id: 5206602, name: 'Cumari' },
  { state: 'Goiás', id: 5206701, name: 'Damianópolis' },
  { state: 'Goiás', id: 5206800, name: 'Damolândia' },
  { state: 'Goiás', id: 5206909, name: 'Davinópolis' },
  { state: 'Goiás', id: 5207105, name: 'Diorama' },
  { state: 'Goiás', id: 5208301, name: 'Divinópolis de Goiás' },
  { state: 'Goiás', id: 5207253, name: 'Doverlândia' },
  { state: 'Goiás', id: 5207352, name: 'Edealina' },
  { state: 'Goiás', id: 5207402, name: 'Edéia' },
  { state: 'Goiás', id: 5207501, name: 'Estrela do Norte' },
  { state: 'Goiás', id: 5207535, name: 'Faina' },
  { state: 'Goiás', id: 5207600, name: 'Fazenda Nova' },
  { state: 'Goiás', id: 5207808, name: 'Firminópolis' },
  { state: 'Goiás', id: 5207907, name: 'Flores de Goiás' },
  { state: 'Goiás', id: 5208004, name: 'Formosa' },
  { state: 'Goiás', id: 5208103, name: 'Formoso' },
  { state: 'Goiás', id: 5208152, name: 'Gameleira de Goiás' },
  { state: 'Goiás', id: 5208400, name: 'Goianápolis' },
  { state: 'Goiás', id: 5208509, name: 'Goiandira' },
  { state: 'Goiás', id: 5208608, name: 'Goianésia' },
  { state: 'Goiás', id: 5208707, name: 'Goiânia' },
  { state: 'Goiás', id: 5208806, name: 'Goianira' },
  { state: 'Goiás', id: 5208905, name: 'Goiás' },
  { state: 'Goiás', id: 5209101, name: 'Goiatuba' },
  { state: 'Goiás', id: 5209150, name: 'Gouvelândia' },
  { state: 'Goiás', id: 5209200, name: 'Guapó' },
  { state: 'Goiás', id: 5209291, name: 'Guaraíta' },
  { state: 'Goiás', id: 5209408, name: 'Guarani de Goiás' },
  { state: 'Goiás', id: 5209457, name: 'Guarinos' },
  { state: 'Goiás', id: 5209606, name: 'Heitoraí' },
  { state: 'Goiás', id: 5209705, name: 'Hidrolândia' },
  { state: 'Goiás', id: 5209804, name: 'Hidrolina' },
  { state: 'Goiás', id: 5209903, name: 'Iaciara' },
  { state: 'Goiás', id: 5209937, name: 'Inaciolândia' },
  { state: 'Goiás', id: 5209952, name: 'Indiara' },
  { state: 'Goiás', id: 5210000, name: 'Inhumas' },
  { state: 'Goiás', id: 5210109, name: 'Ipameri' },
  { state: 'Goiás', id: 5210158, name: 'Ipiranga de Goiás' },
  { state: 'Goiás', id: 5210208, name: 'Iporá' },
  { state: 'Goiás', id: 5210307, name: 'Israelândia' },
  { state: 'Goiás', id: 5210406, name: 'Itaberaí' },
  { state: 'Goiás', id: 5210562, name: 'Itaguari' },
  { state: 'Goiás', id: 5210604, name: 'Itaguaru' },
  { state: 'Goiás', id: 5210802, name: 'Itajá' },
  { state: 'Goiás', id: 5210901, name: 'Itapaci' },
  { state: 'Goiás', id: 5211008, name: 'Itapirapuã' },
  { state: 'Goiás', id: 5211206, name: 'Itapuranga' },
  { state: 'Goiás', id: 5211305, name: 'Itarumã' },
  { state: 'Goiás', id: 5211404, name: 'Itauçu' },
  { state: 'Goiás', id: 5211503, name: 'Itumbiara' },
  { state: 'Goiás', id: 5211602, name: 'Ivolândia' },
  { state: 'Goiás', id: 5211701, name: 'Jandaia' },
  { state: 'Goiás', id: 5211800, name: 'Jaraguá' },
  { state: 'Goiás', id: 5211909, name: 'Jataí' },
  { state: 'Goiás', id: 5212006, name: 'Jaupaci' },
  { state: 'Goiás', id: 5212055, name: 'Jesúpolis' },
  { state: 'Goiás', id: 5212105, name: 'Joviânia' },
  { state: 'Goiás', id: 5212204, name: 'Jussara' },
  { state: 'Goiás', id: 5212253, name: 'Lagoa Santa' },
  { state: 'Goiás', id: 5212303, name: 'Leopoldo de Bulhões' },
  { state: 'Goiás', id: 5212501, name: 'Luziânia' },
  { state: 'Goiás', id: 5212600, name: 'Mairipotaba' },
  { state: 'Goiás', id: 5212709, name: 'Mambaí' },
  { state: 'Goiás', id: 5212808, name: 'Mara Rosa' },
  { state: 'Goiás', id: 5212907, name: 'Marzagão' },
  { state: 'Goiás', id: 5212956, name: 'Matrinchã' },
  { state: 'Goiás', id: 5213004, name: 'Maurilândia' },
  { state: 'Goiás', id: 5213053, name: 'Mimoso de Goiás' },
  { state: 'Goiás', id: 5213087, name: 'Minaçu' },
  { state: 'Goiás', id: 5213103, name: 'Mineiros' },
  { state: 'Goiás', id: 5213400, name: 'Moiporá' },
  { state: 'Goiás', id: 5213509, name: 'Monte Alegre de Goiás' },
  { state: 'Goiás', id: 5213707, name: 'Montes Claros de Goiás' },
  { state: 'Goiás', id: 5213756, name: 'Montividiu' },
  { state: 'Goiás', id: 5213772, name: 'Montividiu do Norte' },
  { state: 'Goiás', id: 5213806, name: 'Morrinhos' },
  { state: 'Goiás', id: 5213855, name: 'Morro Agudo de Goiás' },
  { state: 'Goiás', id: 5213905, name: 'Mossâmedes' },
  { state: 'Goiás', id: 5214002, name: 'Mozarlândia' },
  { state: 'Goiás', id: 5214051, name: 'Mundo Novo' },
  { state: 'Goiás', id: 5214101, name: 'Mutunópolis' },
  { state: 'Goiás', id: 5214408, name: 'Nazário' },
  { state: 'Goiás', id: 5214507, name: 'Nerópolis' },
  { state: 'Goiás', id: 5214606, name: 'Niquelândia' },
  { state: 'Goiás', id: 5214705, name: 'Nova América' },
  { state: 'Goiás', id: 5214804, name: 'Nova Aurora' },
  { state: 'Goiás', id: 5214838, name: 'Nova Crixás' },
  { state: 'Goiás', id: 5214861, name: 'Nova Glória' },
  { state: 'Goiás', id: 5214879, name: 'Nova Iguaçu de Goiás' },
  { state: 'Goiás', id: 5214903, name: 'Nova Roma' },
  { state: 'Goiás', id: 5215009, name: 'Nova Veneza' },
  { state: 'Goiás', id: 5215207, name: 'Novo Brasil' },
  { state: 'Goiás', id: 5215231, name: 'Novo Gama' },
  { state: 'Goiás', id: 5215256, name: 'Novo Planalto' },
  { state: 'Goiás', id: 5215306, name: 'Orizona' },
  { state: 'Goiás', id: 5215405, name: 'Ouro Verde de Goiás' },
  { state: 'Goiás', id: 5215504, name: 'Ouvidor' },
  { state: 'Goiás', id: 5215603, name: 'Padre Bernardo' },
  { state: 'Goiás', id: 5215652, name: 'Palestina de Goiás' },
  { state: 'Goiás', id: 5215702, name: 'Palmeiras de Goiás' },
  { state: 'Goiás', id: 5215801, name: 'Palmelo' },
  { state: 'Goiás', id: 5215900, name: 'Palminópolis' },
  { state: 'Goiás', id: 5216007, name: 'Panamá' },
  { state: 'Goiás', id: 5216304, name: 'Paranaiguara' },
  { state: 'Goiás', id: 5216403, name: 'Paraúna' },
  { state: 'Goiás', id: 5216452, name: 'Perolândia' },
  { state: 'Goiás', id: 5216809, name: 'Petrolina de Goiás' },
  { state: 'Goiás', id: 5216908, name: 'Pilar de Goiás' },
  { state: 'Goiás', id: 5217104, name: 'Piracanjuba' },
  { state: 'Goiás', id: 5217203, name: 'Piranhas' },
  { state: 'Goiás', id: 5217302, name: 'Pirenópolis' },
  { state: 'Goiás', id: 5217401, name: 'Pires do Rio' },
  { state: 'Goiás', id: 5217609, name: 'Planaltina' },
  { state: 'Goiás', id: 5217708, name: 'Pontalina' },
  { state: 'Goiás', id: 5218003, name: 'Porangatu' },
  { state: 'Goiás', id: 5218052, name: 'Porteirão' },
  { state: 'Goiás', id: 5218102, name: 'Portelândia' },
  { state: 'Goiás', id: 5218300, name: 'Posse' },
  { state: 'Goiás', id: 5218391, name: 'Professor Jamil' },
  { state: 'Goiás', id: 5218508, name: 'Quirinópolis' },
  { state: 'Goiás', id: 5218607, name: 'Rialma' },
  { state: 'Goiás', id: 5218706, name: 'Rianápolis' },
  { state: 'Goiás', id: 5218789, name: 'Rio Quente' },
  { state: 'Goiás', id: 5218805, name: 'Rio Verde' },
  { state: 'Goiás', id: 5218904, name: 'Rubiataba' },
  { state: 'Goiás', id: 5219001, name: 'Sanclerlândia' },
  { state: 'Goiás', id: 5219100, name: 'Santa Bárbara de Goiás' },
  { state: 'Goiás', id: 5219209, name: 'Santa Cruz de Goiás' },
  { state: 'Goiás', id: 5219258, name: 'Santa Fé de Goiás' },
  { state: 'Goiás', id: 5219308, name: 'Santa Helena de Goiás' },
  { state: 'Goiás', id: 5219357, name: 'Santa Isabel' },
  { state: 'Goiás', id: 5219407, name: 'Santa Rita do Araguaia' },
  { state: 'Goiás', id: 5219456, name: 'Santa Rita do Novo Destino' },
  { state: 'Goiás', id: 5219506, name: 'Santa Rosa de Goiás' },
  { state: 'Goiás', id: 5219605, name: 'Santa Tereza de Goiás' },
  { state: 'Goiás', id: 5219704, name: 'Santa Terezinha de Goiás' },
  { state: 'Goiás', id: 5219712, name: 'Santo Antônio da Barra' },
  { state: 'Goiás', id: 5219738, name: 'Santo Antônio de Goiás' },
  { state: 'Goiás', id: 5219753, name: 'Santo Antônio do Descoberto' },
  { state: 'Goiás', id: 5219803, name: 'São Domingos' },
  { state: 'Goiás', id: 5219902, name: 'São Francisco de Goiás' },
  { state: 'Goiás', id: 5220058, name: 'São João da Paraúna' },
  { state: 'Goiás', id: 5220009, name: "São João d'Aliança" },
  { state: 'Goiás', id: 5220108, name: 'São Luís de Montes Belos' },
  { state: 'Goiás', id: 5220157, name: 'São Luiz do Norte' },
  { state: 'Goiás', id: 5220207, name: 'São Miguel do Araguaia' },
  { state: 'Goiás', id: 5220264, name: 'São Miguel do Passa Quatro' },
  { state: 'Goiás', id: 5220280, name: 'São Patrício' },
  { state: 'Goiás', id: 5220405, name: 'São Simão' },
  { state: 'Goiás', id: 5220454, name: 'Senador Canedo' },
  { state: 'Goiás', id: 5220504, name: 'Serranópolis' },
  { state: 'Goiás', id: 5220603, name: 'Silvânia' },
  { state: 'Goiás', id: 5220686, name: 'Simolândia' },
  { state: 'Goiás', id: 5220702, name: "Sítio d'Abadia" },
  { state: 'Goiás', id: 5221007, name: 'Taquaral de Goiás' },
  { state: 'Goiás', id: 5221080, name: 'Teresina de Goiás' },
  { state: 'Goiás', id: 5221197, name: 'Terezópolis de Goiás' },
  { state: 'Goiás', id: 5221304, name: 'Três Ranchos' },
  { state: 'Goiás', id: 5221403, name: 'Trindade' },
  { state: 'Goiás', id: 5221452, name: 'Trombas' },
  { state: 'Goiás', id: 5221502, name: 'Turvânia' },
  { state: 'Goiás', id: 5221551, name: 'Turvelândia' },
  { state: 'Goiás', id: 5221577, name: 'Uirapuru' },
  { state: 'Goiás', id: 5221601, name: 'Uruaçu' },
  { state: 'Goiás', id: 5221700, name: 'Uruana' },
  { state: 'Goiás', id: 5221809, name: 'Urutaí' },
  { state: 'Goiás', id: 5221858, name: 'Valparaíso de Goiás' },
  { state: 'Goiás', id: 5221908, name: 'Varjão' },
  { state: 'Goiás', id: 5222005, name: 'Vianópolis' },
  { state: 'Goiás', id: 5222054, name: 'Vicentinópolis' },
  { state: 'Goiás', id: 5222203, name: 'Vila Boa' },
  { state: 'Goiás', id: 5222302, name: 'Vila Propício' },
  { state: 'Distrito Federal', id: 5300108, name: 'Brasília' },
  { state: 'Distrito Federal', id: 5300109, name: 'Águas Claras' },
  { state: 'Distrito Federal', id: 5300110, name: 'Arniqueira' },
  { state: 'Distrito Federal', id: 5300111, name: 'Brazlândia' },
  { state: 'Distrito Federal', id: 5300112, name: 'Candangolândia' },
  { state: 'Distrito Federal', id: 5300113, name: 'Ceilândia' },
  { state: 'Distrito Federal', id: 5300114, name: 'Cruzeiro' },
  { state: 'Distrito Federal', id: 5300115, name: 'Fercal' },
  { state: 'Distrito Federal', id: 5300116, name: 'Gama' },
  { state: 'Distrito Federal', id: 5300117, name: 'Guará' },
  { state: 'Distrito Federal', id: 5300118, name: 'Itapoã' },
  { state: 'Distrito Federal', id: 5300119, name: 'Jardim Botânico' },
  { state: 'Distrito Federal', id: 5300120, name: 'Lago Norte' },
  { state: 'Distrito Federal', id: 5300121, name: 'Lago Sul' },
  { state: 'Distrito Federal', id: 5300122, name: 'Núcleo Bandeirante' },
  { state: 'Distrito Federal', id: 5300123, name: 'Paranoá' },
  { state: 'Distrito Federal', id: 5300124, name: 'Park Way' },
  { state: 'Distrito Federal', id: 5300125, name: 'Planaltina' },
  { state: 'Distrito Federal', id: 5300126, name: 'Plano Piloto' },
  { state: 'Distrito Federal', id: 5300127, name: 'Recanto das Emas' },
  { state: 'Distrito Federal', id: 5300128, name: 'Riacho Fundo' },
  { state: 'Distrito Federal', id: 5300129, name: 'Riacho Fundo II' },
  { state: 'Distrito Federal', id: 5300130, name: 'Samambaia' },
  { state: 'Distrito Federal', id: 5300131, name: 'Santa Maria' },
  { state: 'Distrito Federal', id: 5300132, name: 'São Sebastião' },
  { state: 'Distrito Federal', id: 5300133, name: 'Estrutural' },
  { state: 'Distrito Federal', id: 5300134, name: 'SIA' },
  { state: 'Distrito Federal', id: 5300135, name: 'Sobradinho' },
  { state: 'Distrito Federal', id: 5300136, name: 'Sobradinho II' },
  { state: 'Distrito Federal', id: 5300137, name: 'Sol Nascente' },
  { state: 'Distrito Federal', id: 5300138, name: 'Pôr do Sol' },
  { state: 'Distrito Federal', id: 5300139, name: 'Sudoeste' },
  { state: 'Distrito Federal', id: 5300140, name: 'Octogonal' },
  { state: 'Distrito Federal', id: 5300141, name: 'Taguatinga' },
  { state: 'Distrito Federal', id: 5300142, name: 'Varjão' },
  { state: 'Distrito Federal', id: 5300143, name: 'Vicente Pires' },
];

export const citiesOptions = (state: string): City[] =>
  cities.filter(({ state }) => state === state);
