import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Autocomplete, Box, Button, Typography } from '@mui/material';

import { AccordionRoot } from '@/components/Accordion';
import {
  AttachmentsService,
  DesignersService,
  ProjectsService,
} from '@/services';
import {
  DialogButtonClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/Dialog';
import { getCookies } from '@/utils/cookies';
import { httpClient } from '@/infra';
import { Project } from '@/models/Project';
import { TextField } from '@/components/TextField';
import {
  ApportionmentSection,
  SetupSection,
  LocationSection,
  IdentifySection,
  TechnicalDataSection,
} from './components';
import { Steps } from '../..';
import './styles.scss';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { isRoleManager } from '@/utils/roles';

type ViewProjectDetailsProps = {
  data: Project.GetUniqueProject | undefined;
  handleClickFile: (key: string) => void;
  handleChangeStep: (step: Steps) => void;
  onClose: () => void;
};

type DesignerOption = {
  label: string;
  id: string;
};

export const ViewProjectDetails = ({
  data,
  handleClickFile,
  handleChangeStep,
  onClose,
}: ViewProjectDetailsProps) => {
  const attachmentsServices = new AttachmentsService(httpClient());
  const projectsServices = new ProjectsService(httpClient());
  const designersServices = new DesignersService(httpClient());

  const [projectId, setProjectId] = useState<string>(String(data?.id));
  const [isManager] = useState<boolean>(() => isRoleManager());
  const [hadAccepted, setHadAccepted] = useState<boolean>(false);
  const [optionsDesigner, setOptionsDesigner] = useState<DesignerOption[]>([]);
  const [selectedDesigner, setSelectedDesigner] =
    useState<DesignerOption | null>(null);

  useEffect(() => {
    setProjectId(String(data?.id));
  }, [projectId]);

  const onAcceptProject = useMutation({
    mutationFn: async () =>
      await projectsServices.acceptProject({ id: projectId }),
    onSuccess: async () => {
      const designers = await designersServices.getDesigners({
        page: 0,
        size: 100,
      });

      const options = designers.content?.map(({ firstName, id, lastName }) => ({
        label: `${firstName} ${lastName}`,
        id: String(id),
      }));

      setOptionsDesigner(options);

      setHadAccepted(true);
    },
  });

  const onRejectProject = useMutation({
    mutationFn: async () =>
      await projectsServices.rejectProject({ id: projectId }),
    onSuccess: () => {
      onClose();
    },
  });

  const onSelectDesignerToProject = useMutation({
    mutationFn: async () =>
      await projectsServices.selectDesignerToProject({
        projectId,
        designerId: selectedDesigner?.id as unknown as string,
      }),
    onSuccess: () => {
      onClose();
    },
  });

  const onOpenFile = useMutation({
    mutationFn: async (key: string) =>
      await attachmentsServices.getUrlAccessFile({ key }),
    onSuccess: ({ preSignedUrl }) => {
      window.open(preSignedUrl, '_blank');
    },
  });

  return (
    <DialogContent
      className={
        onOpenFile.isPending
          ? 'ModalProjectInfoContent LoadingFile'
          : 'ModalProjectInfoContent'
      }
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
          <ArrowLeftIcon onClick={() => handleChangeStep('SELECT_STEP')} />
          <Typography>Informações do Projeto</Typography>
        </Box>
      </DialogTitle>

      <DialogDescription asChild>
        <div>
          <AccordionRoot type='multiple' defaultValue={['identificacao']}>
            <IdentifySection data={data} handleClickFile={handleClickFile} />
            <TechnicalDataSection
              data={data}
              handleClickFile={handleClickFile}
            />
            <LocationSection data={data} />
            <ApportionmentSection
              data={data}
              handleClickFile={handleClickFile}
            />
            <SetupSection data={data} handleClickFile={handleClickFile} />
          </AccordionRoot>

          {hadAccepted && (
            <Box marginTop={'20px'} display={'flex'} gap={'8px'}>
              <Autocomplete
                disablePortal
                id='combo-box-demo'
                options={optionsDesigner}
                sx={{ flex: 1 }}
                value={selectedDesigner}
                onChange={(event, newValue) => {
                  setSelectedDesigner(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Selecione um projetista' />
                )}
              />

              <Button
                variant='contained'
                size='small'
                style={{ backgroundColor: '#dcaf07' }}
                onClick={() => onSelectDesignerToProject.mutate()}
                disabled={!selectedDesigner}
              >
                Confirmar
              </Button>
            </Box>
          )}

          {isManager && !hadAccepted && (
            <Box
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
              gap={'8px'}
            >
              <Button
                variant='contained'
                size='medium'
                style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
                onClick={() => onRejectProject.mutate()}
              >
                Rejeitar
              </Button>
              <Button
                variant='contained'
                size='medium'
                style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
                onClick={() => onAcceptProject.mutate()}
              >
                Aceitar
              </Button>
            </Box>
          )}
        </div>
      </DialogDescription>

      <DialogButtonClose className='ButtonClose' />
    </DialogContent>
  );
};
