import React from 'react';
import { Typography } from '@mui/material';

import { FileInputAws } from '@/components/FileInputAws';
import { TextField } from '@/components/TextField';
import { ChipSelect } from '@/components/ChipSelect';

import {
  polesOptions,
  polesChains,
  tensions,
  branchTypes,
  voltage,
} from '@/constants/breakerOptions';

import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { useFormAddProject } from '../../../context';
import { maskFloatNumber } from '@/utils/masks';
import { Dropzone } from '@/components/Dropzone';

export const TechnicalData = () => {
  const { t } = useTranslation();

  const {
    technicalData: {
      register,
      control,
      formState: { errors },
    },
  } = useFormAddProject();

  return (
    <div id='main-uc-form'>
      <Typography variant='h6'>
        {t('AddProjectsPage.step-techData.mainUc')}
      </Typography>

      <div className='uc-identify'>
        <TextField
          id='uc-number'
          type='text'
          placeholder={t('AddProjectsPage.step-techData.ucNumber') as string}
          label={t('AddProjectsPage.step-techData.ucNumber')}
          error={!!errors.ucNumber}
          helperText={errors.ucNumber?.message}
          {...register('ucNumber')}
        />

        <TextField
          id='uc-potency'
          placeholder={t('AddProjectsPage.step-techData.ucPotency') as string}
          label={t('AddProjectsPage.step-techData.ucPotency')}
          error={!!errors.ucPotency}
          helperText={errors.ucPotency?.message}
          {...register('ucPotency', {
            onChange: (event) => {
              event.target.value = maskFloatNumber(event.target.value);
            },
          })}
        />

        <Controller
          name='ucBreakerPoles'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ChipSelect
              id='uc-breaker-poles'
              placeholder={
                t('AddProjectsPage.step-techData.ucBreakerPoles') as string
              }
              label={t('AddProjectsPage.step-techData.ucBreakerPoles')}
              options={polesOptions}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          name='ucBreakerChains'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ChipSelect
              id='uc-breaker-chains'
              placeholder={
                t('AddProjectsPage.step-techData.ucBreakerChain') as string
              }
              label={t('AddProjectsPage.step-techData.ucBreakerChain')}
              options={polesChains}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          name='ucTension'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ChipSelect
              id='uc-tension'
              placeholder={
                t('AddProjectsPage.step-techData.ucTension') as string
              }
              label={t('AddProjectsPage.step-techData.ucTension')}
              options={tensions}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          name='ucVoltage'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ChipSelect
              id='uc-voltage'
              placeholder={'Voltagem'}
              label={'Voltagem'}
              options={voltage}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <Controller
          name='ucBranchType'
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <ChipSelect
              id='uc-branch-type'
              placeholder={
                t('AddProjectsPage.step-techData.ucBranchType') as string
              }
              label={t('AddProjectsPage.step-techData.ucBranchType')}
              options={branchTypes}
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />

        <TextField
          id='uc-obs'
          type='text'
          placeholder={t('AddProjectsPage.step-techData.observation') as string}
          label={t('AddProjectsPage.step-techData.observation')}
          error={!!errors.observation}
          helperText={errors.observation?.message}
          {...register('observation')}
        />
      </div>

      <Typography variant='h6'>Anexo - Conta de Energia</Typography>

      <div>
        <Controller
          name='attachmentEnergyAccount'
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Dropzone
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>
    </div>
  );
};
