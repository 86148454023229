import React, { ChangeEvent, MouseEvent } from 'react';

import { EyeOpenIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';

import { Dealership, Dealerships } from '@/models/Dealerships';
import { OrderType, EnhancedTableToolbar, TableHead } from '@/components/Table';
import { headCells } from '../constants/headers';
import { UseMutationResult } from '@tanstack/react-query';
import { Steps } from '..';

type DealershipsPresentationProps = {
  order: OrderType;
  orderBy: keyof Dealership;
  selected: number | null;
  page: number;
  rowsPerPage: number;
  rows: Dealership[];
  dealerships: Dealerships.GetDealerShips | undefined;
  handleRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof Dealership,
  ) => void;
  handleSelectProject: (id: number) => void;
  handleViewDealershipDetails: UseMutationResult<
    Dealership,
    unknown,
    string,
    unknown
  >;
  handleOpenCreateDealership: () => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;

  onDeleteDealership: UseMutationResult<void, unknown, string, unknown>;
};

export const DealershipsPresentation = ({
  order,
  orderBy,
  selected,
  page,
  rowsPerPage,
  rows,
  dealerships,
  handleRequestSort,
  handleSelectProject,
  handleViewDealershipDetails,
  handleOpenCreateDealership,
  handleChangePage,
  handleChangeRowsPerPage,
  onDeleteDealership,
}: DealershipsPresentationProps) => {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar
            title='Concessionária'
            numSelected={selected}
            handleDelete={() => onDeleteDealership.mutate(String(selected))}
          />

          <Button
            variant='contained'
            size='small'
            className='!bg-[#dcaf07] !ml-3 !my-3'
            onClick={handleOpenCreateDealership}
          >
            Criar concessionária
          </Button>

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={'small'}
            >
              <TableHead<Dealership>
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headers={headCells}
              />

              <TableBody>
                {rows?.map((row, index) => {
                  const isItemSelected = selected === row.id;
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      key={row.id}
                      hover
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          onChange={() => handleSelectProject(row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell align='left'>{row.fantasyName}</TableCell>
                      <TableCell align='left'>{row.state}</TableCell>
                      <TableCell align='left'>{row.emailMiniMicroDG}</TableCell>
                      <TableCell align='left'>
                        <a
                          href={row.urlTechnicalStandards}
                          target='_blank'
                          rel='noreferrer'
                          className='underline cursor-pointer'
                        >
                          Acessar
                        </a>
                      </TableCell>
                      <TableCell align='left'>
                        <EyeOpenIcon
                          onClick={() =>
                            handleViewDealershipDetails.mutate(String(row.id))
                          }
                          className='cursor-pointer'
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={dealerships?.totalElements ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};
