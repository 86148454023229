import React from 'react';

import { useTranslation } from 'react-i18next';

import { ChipSelect } from '@/components/ChipSelect';
import { TextField } from '@/components/TextField';
import { invertersTypesOptions } from '@/constants/breakerOptions';
import { useFormAddProject } from '@/pages/AddProjectPage/context';
import { Controller, useFieldArray } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { onlyNumbers } from '@/utils/masks';
import { Dropzone } from '@/components/Dropzone';

export const InvertersForm = () => {
  const { t } = useTranslation();
  const {
    equipment: {
      register,
      control,
      formState: { errors },
    },
  } = useFormAddProject();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'inverters',
  });

  return (
    <>
      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <Typography variant='inherit'>Inversor nº {index + 1}</Typography>

          <div className='project-identify'>
            <Controller
              name={`inverters.${index}.type`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <ChipSelect
                  id='uc-inverters-type'
                  placeholder={t('AddProjectsPage.step-setup.ucTypes')}
                  label={t('AddProjectsPage.step-setup.ucTypes')}
                  error={error?.message}
                  value={value}
                  options={invertersTypesOptions}
                  onChange={onChange}
                />
              )}
            />

            <TextField
              id={`uc-inverters-brand-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucBrand') as string}
              label={t('AddProjectsPage.step-setup.ucBrand')}
              error={
                Array.isArray(errors.inverters) &&
                !!errors.inverters[index]?.brand?.message
              }
              helperText={
                Array.isArray(errors.inverters) &&
                errors.inverters[index]?.brand?.message
              }
              {...register(`inverters.${index}.brand`)}
            />

            <TextField
              id={`uc-inverters-model-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucModules') as string}
              label={t('AddProjectsPage.step-setup.ucModules')}
              error={
                Array.isArray(errors.inverters) &&
                !!errors.inverters[index]?.model?.message
              }
              helperText={
                Array.isArray(errors.inverters) &&
                errors.inverters[index]?.model?.message
              }
              {...register(`inverters.${index}.model`)}
            />

            <TextField
              id={`uc-inverters-potency-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucOutput') as string}
              label={t('AddProjectsPage.step-setup.ucOutput')}
              error={
                Array.isArray(errors.inverters) &&
                !!errors.inverters[index]?.potency?.message
              }
              helperText={
                Array.isArray(errors.inverters) &&
                errors.inverters[index]?.potency?.message
              }
              {...register(`inverters.${index}.potency`, {
                onChange: (event) => {
                  event.target.value = onlyNumbers(event.target.value);
                },
              })}
            />

            <TextField
              id={`uc-inverters-quantity-${index}`}
              placeholder={t('AddProjectsPage.step-setup.ucAmount') as string}
              label={t('AddProjectsPage.step-setup.ucAmount')}
              error={
                Array.isArray(errors.inverters) &&
                !!errors.inverters[index]?.quantity?.message
              }
              helperText={
                Array.isArray(errors.inverters) &&
                errors.inverters[index]?.quantity?.message
              }
              {...register(`inverters.${index}.quantity`, {
                onChange: (event) => {
                  event.target.value = onlyNumbers(event.target.value);
                },
              })}
            />

            <TextField
              id={`uc-inverters-warranty-${index}`}
              placeholder={
                t('AddProjectsPage.step-setup.ucDefectWarranty') as string
              }
              label={t('AddProjectsPage.step-setup.ucDefectWarranty')}
              error={
                Array.isArray(errors.inverters) &&
                !!errors.inverters[index]?.warranty?.message
              }
              helperText={
                Array.isArray(errors.inverters) &&
                errors.inverters[index]?.warranty?.message
              }
              {...register(`inverters.${index}.warranty`, {
                onChange: (event) => {
                  event.target.value = onlyNumbers(event.target.value);
                },
              })}
            />

            <div>
              <div className='space-y-1'>
                <span className='ml-1'>
                  {t('AddProjectsPage.step-setup.ucDataSheets')}
                </span>
              </div>
              <div>
                <Controller
                  name={`inverters.${index}.datasheetAttachment`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Dropzone
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div>
              <div className='space-y-1'>
                <span className='ml-1'>
                  {t('AddProjectsPage.step-setup.ucInmetroCertification')}
                </span>
              </div>
              <div>
                <Controller
                  name={`inverters.${index}.certificateAttachment`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Dropzone
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div>
            <Button
              variant='contained'
              size='small'
              style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
              onClick={() => remove(index)}
            >
              Remover inversor
            </Button>
          </div>
        </React.Fragment>
      ))}

      <Button
        variant='contained'
        size='small'
        style={{ backgroundColor: '#dcaf07', marginTop: '12px' }}
        onClick={() =>
          append({
            type: '',
            brand: '',
            model: '',
            potency: '',
            quantity: '',
            warranty: '',
            datasheetAttachment: '',
            certificateAttachment: '',
          })
        }
      >
        Adicionar inversor
      </Button>
    </>
  );
};
