import axios, { AxiosInstance } from 'axios';

import {
  AttachmentsServiceSkeleton,
  UploadFile,
} from '../AttachmentsServiceSkeleton';
import { InfosToUpload, UrlAccessFile } from '@/models/Attachments';

export class AttachmentsService implements AttachmentsServiceSkeleton {
  constructor(private httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getUrlAccessFile({ key }: Pick<InfosToUpload, 'key'>) {
    const { data } = await this.httpClient.get<UrlAccessFile>('/attachments', {
      params: { key },
    });

    return data;
  }

  async getInfosToUpload({ format }: Pick<InfosToUpload, 'format'>) {
    const { data } = await this.httpClient.get<InfosToUpload>(
      '/attachments/url-upload',
      {
        params: { format },
      },
    );

    return data;
  }

  async uploadFile({ preSignedUrl, file }: UploadFile) {
    const data = await axios({
      method: 'put',
      url: preSignedUrl,
      headers: {
        'Content-Type': file.type,
      },
      data: file,
    });

    return data;
  }

  async deleteFile({ key }: Pick<InfosToUpload, 'key'>) {
    const { data } = await this.httpClient.delete('/attachments', {
      params: { key },
    });

    return data;
  }
}
