import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { useNavigate } from 'react-router';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { AttachmentsService, ProjectsService } from '@/services';
import { unmask } from '@/utils/regex';

import {
  StepsForms,
  IdentifyForm,
  TechnicalDataForm,
  PhotosForm,
  LocationForm,
  ApportionmentForm,
  SetupForm,
} from '../types';
import {
  schemaIdentify,
  schemaTechnicalData,
  schemaLocation,
  schemaPhotos,
  schemaApportionment,
  schemaSetup,
} from '../schemas';
import {
  defaultValueSetupForm,
  SetupFormOutput,
} from '../common/defaultValueSetupForm';
import {
  formatAndUploadApportionment,
  formatAndUploadPhotos,
  formatAndUploadSystem,
  transformFilelistToArray,
  uploadListFiles,
} from '../helpers';
import {
  transformedBranchTypes,
  transformedPolesChains,
  transformedPolesOptions,
  transformedTensions,
  transformedVoltage,
} from '../helpers/TechnicalData';
import { t } from 'i18next';
type Factorys = {
  attachmentsService: AttachmentsService;
  projectsService: ProjectsService;
};

interface FormAddProjectProviderProps {
  children: ReactNode;
  factory: Factorys;
}

interface FormAddProjectContextData {
  activeStep: StepsForms;
  setActiveStep: Dispatch<SetStateAction<StepsForms>>;
  completedSteps: boolean[];
  isLoadingUpload: boolean;
  identify: UseFormReturn<IdentifyForm, any, undefined>;
  technicalData: UseFormReturn<TechnicalDataForm, any, undefined>;
  location: UseFormReturn<LocationForm, any, undefined>;
  photos: UseFormReturn<PhotosForm, any, undefined>;
  apportionment: UseFormReturn<ApportionmentForm, any, undefined>;
  equipment: UseFormReturn<SetupForm, any, undefined>;
  handleSubmit: {
    IDENTIFICATION: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    TECHNICAL_DATA: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    LOCALIZATION: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    PHOTOS: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    APPORTIONMENT: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
    EQUIPMENT: (
      e?: React.BaseSyntheticEvent<object, any, any> | undefined,
    ) => Promise<void>;
  };
}

export const titleStepsForms = {
  IDENTIFICATION: t('AddProjectsPage.steps.identify'),
  TECHNICAL_DATA: t('AddProjectsPage.steps.mainUc'),
  LOCALIZATION: t('AddProjectsPage.steps.locationUc'),
  PHOTOS: t('AddProjectsPage.steps.photosUc'),
  APPORTIONMENT: t('AddProjectsPage.steps.ratioUcs'),
  EQUIPMENT: t('AddProjectsPage.steps.setup'),
};

const FormAddProjectContext = createContext({} as FormAddProjectContextData);

export const useFormAddProject = () => useContext(FormAddProjectContext);

export const FormAddProjectProvider = ({
  children,
  factory,
}: FormAddProjectProviderProps) => {
  const navigate = useNavigate();

  const [services] = useState(factory);

  const [activeStep, setActiveStep] = useState<StepsForms>('IDENTIFICATION');
  const [completedSteps, setCompletedSteps] = useState<boolean[]>(
    new Array(Object.keys(titleStepsForms).length).fill(false),
  );
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [valuesSetupForm, setValuesSetupForm] = useState<SetupFormOutput>(
    defaultValueSetupForm,
  );

  const [storeFilesToView, setStoreFilesToView] = useState({
    identify: { attachment: [] },
  });

  const identify = useForm<IdentifyForm>({
    mode: 'all',
    resolver: zodResolver(schemaIdentify),
    defaultValues: { attachment: '' },
  });
  const technicalData = useForm<TechnicalDataForm>({
    mode: 'all',
    defaultValues: {
      ucNumber: '',
      ucBreakerPoles: '',
      ucBreakerChains: '',
      ucPotency: 0,
      ucTension: '',
      ucBranchType: '',
      observation: '',
      attachmentEnergyAccount: '',
    },
    resolver: zodResolver(schemaTechnicalData),
  });
  const location = useForm<LocationForm>({
    mode: 'all',
    defaultValues: {
      zip: '',
      street: '',
      number: '',
      neighborhood: '',
      complement: '',
      state: '',
      city: '',
      location: '',
    },
    resolver: zodResolver(schemaLocation),
  });
  const photos = useForm<PhotosForm>({
    mode: 'all',
    resolver: zodResolver(schemaPhotos),
  });
  const apportionment = useForm<ApportionmentForm>({
    mode: 'all',
    resolver: zodResolver(schemaApportionment),
  });
  const equipment = useForm<SetupForm>({
    mode: 'all',
    resolver: zodResolver(schemaSetup),
  });

  const handleSubmit = {
    IDENTIFICATION: identify.handleSubmit(
      async ({
        attachment,
        holderCPF: cpf,
        holderEmail: email,
        holderName: name,
        holderPhone: phone,
        holderRG: rg,
        projectName,
        projectStart,
      }) => {
        try {
          setIsLoadingUpload(true);

          setStoreFilesToView((prevState) => ({
            ...prevState,
            identify: { attachment },
          }));

          const files = await uploadListFiles(attachment);

          setValuesSetupForm((prevState) => ({
            ...prevState,
            name: projectName,
            deadline: projectStart,
            mainUc: {
              ...prevState.mainUc,
              holder: {
                cpf: unmask(cpf),
                email,
                name,
                phone: unmask(phone),
                rg: unmask(rg),
              },
              holderAttachmentList: files,
            },
          }));

          setCompletedSteps((prev) => {
            const newCompletedSteps = [...prev];
            newCompletedSteps[0] = true;
            return newCompletedSteps;
          });

          setActiveStep('TECHNICAL_DATA');

          setIsLoadingUpload(false);
        } catch (error) {
          setIsLoadingUpload(false);
          console.log('error :>> ', error);
        }
      },
    ),
    TECHNICAL_DATA: technicalData.handleSubmit(
      async ({
        attachmentEnergyAccount,
        ucTension,
        ucVoltage,
        ucPotency,
        ucNumber,
        ucBreakerPoles,
        ucBreakerChains,
        ucBranchType,
        observation,
      }) => {
        try {
          setIsLoadingUpload(true);

          const files = await uploadListFiles(attachmentEnergyAccount);

          setValuesSetupForm((prevState) => ({
            ...prevState,
            mainUc: {
              ...prevState.mainUc,
              mainUcCode: ucNumber,
              installedPotency: ucPotency,
              voltageLevel: transformedTensions[ucTension],
              voltage: transformedVoltage[ucVoltage],
              circuitBreakerPolarity: transformedPolesOptions[ucBreakerPoles],
              connectingBranchType: transformedBranchTypes[ucBranchType],
              circuitBreakerCurrent: transformedPolesChains[ucBreakerChains],
              comments: observation,
              mainUcAttachmentList: files,
            },
          }));

          setCompletedSteps((prev) => {
            const newCompletedSteps = [...prev];
            newCompletedSteps[1] = true;
            return newCompletedSteps;
          });

          setActiveStep('LOCALIZATION');

          setIsLoadingUpload(false);
        } catch (error) {
          setIsLoadingUpload(false);
          console.log('error :>> ', error);
        }
      },
    ),
    LOCALIZATION: location.handleSubmit(
      async ({
        city,
        complement,
        location,
        neighborhood,
        number,
        state,
        street,
        zip,
      }) => {
        setValuesSetupForm((prevState) => ({
          ...prevState,
          mainUc: {
            ...prevState.mainUc,
            locationUrl: location,
            address: {
              address: street,
              number: Number(number),
              complement,
              district: neighborhood,
              cep: unmask(zip),
              city,
              state,
            },
          },
        }));

        setCompletedSteps((prev) => {
          const newCompletedSteps = [...prev];
          newCompletedSteps[2] = true;
          return newCompletedSteps;
        });

        setActiveStep('PHOTOS');
      },
    ),
    PHOTOS: photos.handleSubmit(async ({ projects, ...rest }) => {
      try {
        setIsLoadingUpload(true);

        const uploadPhotos = await formatAndUploadPhotos({ ...rest });

        const fileAttachmentList = await uploadListFiles(projects);

        setValuesSetupForm((prevState) => ({
          ...prevState,
          pictureAttachmentList: [...uploadPhotos],
          fileAttachmentList,
        }));

        setCompletedSteps((prev) => {
          const newCompletedSteps = [...prev];
          newCompletedSteps[3] = true;
          return newCompletedSteps;
        });

        setActiveStep('APPORTIONMENT');

        setIsLoadingUpload(false);
      } catch (error) {
        setIsLoadingUpload(false);
        console.log('error :>> ', error);
      }
    }),
    APPORTIONMENT: apportionment.handleSubmit(async (data) => {
      try {
        setIsLoadingUpload(true);

        const apportionments = await formatAndUploadApportionment(data);

        setValuesSetupForm((prevState) => ({
          ...prevState,
          apportionmentUCList: [...apportionments],
        }));

        setCompletedSteps((prev) => {
          const newCompletedSteps = [...prev];
          newCompletedSteps[4] = true;
          return newCompletedSteps;
        });

        setActiveStep('EQUIPMENT');

        setIsLoadingUpload(false);
      } catch (error) {
        setIsLoadingUpload(false);
        console.log('An error occurred:', error);
      }
    }),
    EQUIPMENT: equipment.handleSubmit(async (data) => {
      try {
        setIsLoadingUpload(true);

        const system = await formatAndUploadSystem(data);

        setValuesSetupForm((prevState) => ({
          ...prevState,
          system,
        }));

        const combinedValues = {
          ...valuesSetupForm,
          system: { ...system },
        };

        if (
          !!system.inverterList.length &&
          !!system.moduleList.length &&
          !!system.structureList.length
        ) {
          await services.projectsService.createProjects(combinedValues);

          setCompletedSteps((prev) => {
            const newCompletedSteps = [...prev];
            newCompletedSteps[5] = true;
            return newCompletedSteps;
          });

          setIsLoadingUpload(false);

          navigate('/client-page/projects');
        }
      } catch (error) {
        setIsLoadingUpload(false);
        console.log('An error occurred:', error);
      }
    }),
  };

  return (
    <FormAddProjectContext.Provider
      value={{
        activeStep,
        completedSteps,
        setActiveStep,
        isLoadingUpload,
        identify,
        technicalData,
        location,
        photos,
        apportionment,
        equipment,
        handleSubmit,
      }}
    >
      {children}
    </FormAddProjectContext.Provider>
  );
};
