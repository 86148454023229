import React from 'react';
import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Dropzone } from '@/components/Dropzone';
import { useFormEditProject } from '@/pages/EditProjectPage/context';

export const Photos = () => {
  const { t } = useTranslation();

  const {
    photos: { control },
  } = useFormEditProject();

  return (
    <div id='uc-photos'>
      <Typography variant='h6'>
        Anexo - Documento de identificação do titular
      </Typography>

      <div>
        <Controller
          name='files'
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Dropzone
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>

      <Typography variant='h6'>
        Anexo - Documento de identificação do titular
      </Typography>

      <div>
        <Controller
          name='pictures'
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Dropzone
              value={value}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </div>
    </div>
  );
};
