import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@mui/material';

import { InvertersForm } from './components/InvertersForm';
import { PanelsForm } from './components/PanelsForm';
import { StructureForm } from './components/StructureForm';

export const SetupForm = () => {
  const { t } = useTranslation();

  return (
    <div id='project-form'>
      <Typography variant='h6'>
        {t('AddProjectsPage.step-setup.ucInverters')}
      </Typography>
      <InvertersForm />

      <Divider />

      <Typography variant='h6'>
        {t('AddProjectsPage.step-setup.ucPanels')}
      </Typography>
      <PanelsForm />

      <Divider />

      <Typography variant='h6'>
        {t('AddProjectsPage.step-setup.ucStructure')}
      </Typography>
      <StructureForm />
    </div>
  );
};
