import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import './styles.scss';

export const AccordionRoot = React.forwardRef<
  HTMLDivElement,
  Accordion.AccordionSingleProps | Accordion.AccordionMultipleProps
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Root
    className={classNames('AccordionItem', className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Root>
));

export const AccordionItem = React.forwardRef<
  HTMLDivElement,
  Accordion.AccordionItemProps
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Item
    className={classNames('AccordionRoot', className)}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Item>
));

export const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  Accordion.AccordionTriggerProps
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className='AccordionHeader'>
    <Accordion.Trigger
      className={classNames('AccordionTrigger', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon className='AccordionChevron' aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));

export const AccordionContent = React.forwardRef<
  HTMLDivElement,
  Accordion.AccordionContentProps
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames('AccordionContent', className)}
    {...props}
    ref={forwardedRef}
  >
    <div className='AccordionContentText'>{children}</div>
  </Accordion.Content>
));
