import React from 'react';
import NavBar from './parts/NavBar';

type LandingPageLayoutType = {
  children: any;
};

function LandingPageLayout(props: LandingPageLayoutType) {
  const { children } = props;

  return (
    <div className='landing-page-layout'>
      <NavBar />
      <div>{children}</div>
    </div>
  );
}

export default LandingPageLayout;
