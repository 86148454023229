import React from 'react';

import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Box } from '@mui/material';

import {
  Apportionment,
  Identify,
  Location,
  Photos,
  SetupForm,
  TechnicalData,
} from '../components/Steps';
import { StepsNonLinear } from '../components/Stepper';

import { useFormAddProject } from '../context';

const titleStepsForms = {
  IDENTIFICATION: t('AddProjectsPage.steps.identify'),
  TECHNICAL_DATA: t('AddProjectsPage.steps.mainUc'),
  LOCALIZATION: t('AddProjectsPage.steps.locationUc'),
  PHOTOS: t('AddProjectsPage.steps.photosUc'),
  APPORTIONMENT: t('AddProjectsPage.steps.ratioUcs'),
  EQUIPMENT: t('AddProjectsPage.steps.setup'),
};

export const AddProjectPresentation = () => {
  const { t } = useTranslation();

  const { activeStep, isLoadingUpload, handleSubmit } = useFormAddProject();

  const forms = {
    IDENTIFICATION: <Identify key={1} />,
    TECHNICAL_DATA: <TechnicalData key={2} />,
    LOCALIZATION: <Location key={3} />,
    PHOTOS: <Photos key={4} />,
    APPORTIONMENT: <Apportionment key={5} />,
    EQUIPMENT: <SetupForm key={6} />,
  };

  return (
    <>
      <form onSubmit={handleSubmit[activeStep]} id='add-project-page'>
        <StepsNonLinear
          activeStep={activeStep}
          steps={titleStepsForms}
          forms={forms}
        />

        <Box className='action-buttons'>
          <Button
            type='submit'
            variant='contained'
            className='submit-button'
            disabled={isLoadingUpload}
          >
            {isLoadingUpload
              ? 'Carregando upload...'
              : t('system.common.submit')}
          </Button>
        </Box>
      </form>
    </>
  );
};
