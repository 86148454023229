import { z } from 'zod';
import { createFileSchema } from '@/pages/AddProjectPage/common/file';

export const schemaTechnicalData = z
  .object({
    ucNumber: z
      .string()
      .min(1, { message: 'A unidade consumidora é obrigatória!' }),
    ucPotency: z
      .string()
      .transform((value) => parseFloat(value))
      .refine((val) => !isNaN(val), { message: 'Valor inválido' })
      .refine((val) => val >= 0, {
        message: 'A potência deve ser um valor não negativo!',
      })
      .optional(),
    ucBreakerPoles: z.string().min(1, { message: 'Selecione o tipo de polo!' }),
    ucBreakerChains: z
      .string()
      .min(1, { message: 'Selecione a corrente nominal!' }),
    ucTension: z
      .string()
      .min(1, { message: 'Selecione o nível de tensão de atendimento!' }),
    ucVoltage: z.string().min(1, { message: 'Selecione a voltagem!' }),
    ucBranchType: z
      .string()
      .min(1, { message: 'Selecione o tipo do ramal de ligação!' }),
    observation: z.string().optional(),
    attachmentEnergyAccount: createFileSchema({}),
  })
  .required();
