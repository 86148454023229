import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import CardWithPicture from '../../../../components/CardWithPicture';

type DepositionsBoxType = {
  id: string;
};

function DepositionsBox({ id }: DepositionsBoxType) {
  const { t } = useTranslation();

  const depositionA = {
    name: t('LandingPage.DepositionsBox.depositionA.name'),
    role: t('LandingPage.DepositionsBox.depositionA.role'),
    picture: () => require('../../../../assets/landingPage/dias-solar.jpeg'),
    paragraph: t('LandingPage.DepositionsBox.depositionA.paragraph'),
    alt: t('LandingPage.DepositionsBox.depositionA.name'),
  };

  return (
    <section id={id}>
      <Box className='depositions-box'>
        <Typography variant='h3' className='title'>
          {t('LandingPage.DepositionsBox.title')}
        </Typography>
        <Box className='flex'>
          <CardWithPicture {...depositionA} />
        </Box>
      </Box>
    </section>
  );
}

export default DepositionsBox;
