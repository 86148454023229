import React from 'react';
import { Typography } from '@mui/material';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Project } from '@/models/Project';
import { maskCEP } from '@/utils/masks';

type LocationSectionProps = {
  data: Project.GetUniqueProject | undefined;
};

export const LocationSection = ({ data }: LocationSectionProps) => {
  return (
    <AccordionItem value='endereco'>
      <AccordionTrigger>
        <Typography>Endereço</Typography>
      </AccordionTrigger>

      <AccordionContent>
        <Typography>
          CEP: {maskCEP(String(data?.mainUc.address.cep))}
        </Typography>
        <Typography>Rua/Av: {data?.mainUc.address.address}</Typography>
        <Typography>Número: {data?.mainUc.address.number}</Typography>
        <Typography>Complemento: {data?.mainUc.address.complement}</Typography>
        <Typography>Bairro: {data?.mainUc.address.district}</Typography>
        <Typography>Cidade: {data?.mainUc.address.city}</Typography>
        <Typography>Estado: {data?.mainUc.address.state}</Typography>
        <Typography>
          Localização:{' '}
          <a
            href={data?.mainUc.locationUrl}
            target='_blank'
            rel='noreferrer'
            className='cursor-pointer underline'
          >
            {data?.mainUc.locationUrl}
          </a>
        </Typography>
      </AccordionContent>
    </AccordionItem>
  );
};
