import {
  Address,
  ApportionmentUCList,
  FileAttachmentList,
  Holder,
} from '../types';

export type SetupFormOutput = {
  name: string;
  deadline: string;
  mainUc: MainUc;
  system: System;
  apportionmentUCList: ApportionmentUCList[];
  pictureAttachmentList: FileAttachmentList[];
  fileAttachmentList: FileAttachmentList[];
};

export type MainUc = {
  mainUcCode: string;
  dealership: Dealership;
  installedPotency: number;
  voltageLevel: string;
  voltage: string;
  connectingBranchType: string;
  circuitBreakerCurrent: number;
  locationUrl: string;
  comments: string;
  holder: { name: string; cpf: string };
  address: Address;
  holderAttachmentList: FileAttachmentList[];
  mainUcAttachmentList: FileAttachmentList[];
};

export type Dealership = {
  id: number;
};

export type System = {
  structureList: StructureList[];
  inverterList: InverterList[];
  moduleList: ModuleList[];
};

export type InverterList = {
  brand: string;
  type: string;
  model: string;
  potency: number;
  quantity: number;
  warranty: number;
  certificateAttachmentList: FileAttachmentList[];
  datasheetAttachmentList: FileAttachmentList[];
};

export type ModuleList = {
  brand: string;
  model: string;
  potency: number;
  quantity: number;
  dimension: string;
  weight: number | undefined;
  warrantyAgainstFactoryDefect: number;
  warranty80PercentEfficiency: number;
  certificateAttachmentList: FileAttachmentList[];
  datasheetAttachmentList: FileAttachmentList[];
};

export type StructureList = {
  structureBrand: string;
  structureType: string;
  detailsAttachmentList: FileAttachmentList[];
  datasheetAttachmentList: FileAttachmentList[];
};

export const defaultValueSetupForm = {
  name: '',
  deadline: '',
  mainUc: {
    mainUcCode: '',
    dealership: {
      id: 1,
    },
    voltageLevel: '',
    voltage: '',
    installedPotency: 0,
    connectingBranchType: '',
    circuitBreakerCurrent: 0,
    locationUrl: '',
    comments: '',
    holder: {
      name: '',
      cpf: '',
    },
    address: {
      address: '',
      number: 0,
      complement: '',
      district: '',
      cep: '',
      city: '',
      state: '',
    },
    holderAttachmentList: [],
    mainUcAttachmentList: [],
  },
  system: {
    structureList: [],
    inverterList: [],
    moduleList: [],
  },
  apportionmentUCList: [],
  pictureAttachmentList: [],
  fileAttachmentList: [],
};
