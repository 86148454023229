import { z } from 'zod';

export const schemaLocation = z
  .object({
    zip: z
      .string()
      .min(1, { message: 'CEP é obrigatório!' })
      .refine((cep) => /^[0-9]{5}-?[0-9]{3}$/.test(cep), {
        message: 'Digite um CEP válido!',
      }),
    street: z.string().min(1, { message: 'Rua é obrigatório!' }),
    number: z.string().min(1, { message: 'Número é obrigatório!' }),
    neighborhood: z.string().min(1, { message: 'Bairro é obrigatório!' }),
    state: z.string().min(1, { message: 'Estado é obrigatório!' }),
    city: z.string(),
    complement: z.string().min(1, { message: 'Complemento é obrigatório!' }),
    location: z
      .string()
      .min(1, { message: 'Link é obrigatório!' })
      .url({ message: 'Insira um link válido!' }),
  })
  .required();
