import * as React from 'react';
import IndicatorCard from '../../components/IndicatorCard';
import { Widget } from '@/components/Feedback/components/Widget';

export const DashBoardPage = () => {
  return (
    <>
      <div id='dashboard-page'>
        <div className='d-flex'>
          <IndicatorCard status='OPEN' />
          <IndicatorCard status='ACCEPTED' />
          <IndicatorCard status='IN_PROGRESS' />
          <IndicatorCard status='FINISHED' />
        </div>
      </div>

      <Widget />
    </>
  );
};
