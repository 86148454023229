import React from 'react';

import LandingPageLayout from '../../layouts/LandingPage';
import ServicesBox from './parts/ServicesBox';
import RightInfoBox from './parts/RightInfoBox';
import LeftInfoBox from './parts/LeftInfoBox';
import FoundersBox from './parts/FoundersBox';
import DepositionsBox from './parts/DepositionsBox';
import ContactBox from './parts/ContactBox';

import CoverSheet from '../../assets/landingPage/cover-sheet.png';
import WelcomeToSunEnergy from '../../assets/landingPage/welcome-to-sun-energy-world.png';

import anchors from '../../constants/anchors';

export const LandingPage = () => {
  return (
    <LandingPageLayout>
      <div className='landing-page'>
        <img
          id={anchors[0]}
          src={WelcomeToSunEnergy}
          alt='welcome-to-sun-energy'
          className='welcome-to-sun-energy'
        />

        <ServicesBox id={anchors[1]} />

        <RightInfoBox id={anchors[2]} />

        <LeftInfoBox id={anchors[3]} />

        <FoundersBox />

        <img src={CoverSheet} alt='cover-sheet' className='cover-sheet' />

        <DepositionsBox id={anchors[4]} />

        <ContactBox id={anchors[5]} />
      </div>
    </LandingPageLayout>
  );
};
