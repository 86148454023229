import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';

import { Box, Button } from '@mui/material';

import { Dealerships } from '@/models/Dealerships';
import { TextField } from '@/components/TextField';
import { ChipSelect } from '@/components/ChipSelect';
import { sendTypeAnalysis } from '@/constants/dealerships';
import { DealershipsService } from '@/services';
import { httpClient } from '@/infra';
import { maskPhone } from '@/utils/masks';

import { DealershipForm, schemaDealership } from './schema';
import { useNavigate } from 'react-router';
import { transformedSendTypeAnalysis } from './helper';
import { Widget } from '@/components/Feedback/components/Widget';

export const CreateDealership = () => {
  const navigate = useNavigate();

  const dealershipsServices = new DealershipsService(httpClient());

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<DealershipForm>({
    mode: 'all',
    resolver: zodResolver(schemaDealership),
  });

  const onCreateDealership = useMutation({
    mutationFn: async (dealership: Dealerships.CreateDealerShip) =>
      await dealershipsServices.createDealership(dealership),
    onSuccess: () => {
      navigate('/client-page/dealerships');
    },
  });

  const onSubmit: SubmitHandler<DealershipForm> = ({
    sendTypeAnalysis,
    ...values
  }) => {
    onCreateDealership.mutate({
      id: 0,
      sendTypeAnalysis: transformedSendTypeAnalysis[sendTypeAnalysis],
      ...values,
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='h-full flex flex-col justify-between'
      >
        <Box className='grid grid-cols-1 sm:grid-cols-2 gap-2.5 bg-white p-2 rounded-lg'>
          <TextField
            id='fantasyName'
            type='text'
            placeholder={'Digite o nome fantasia'}
            label={'Nome fantasia'}
            error={!!errors.fantasyName}
            helperText={errors.fantasyName?.message}
            {...register('fantasyName')}
          />

          <TextField
            id='corporateName'
            type='text'
            placeholder={'Digite a razão social'}
            label={'Razão social'}
            error={!!errors.corporateName}
            helperText={errors.corporateName?.message}
            {...register('corporateName')}
          />

          <TextField
            id='state'
            type='text'
            placeholder={'Digite o estado'}
            label={'Estado'}
            error={!!errors.state}
            helperText={errors.state?.message}
            {...register('state')}
          />

          <Controller
            name='sendTypeAnalysis'
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <ChipSelect
                id='sendTypeAnalysis'
                placeholder={
                  'Selecione o de meio de envio do projeto para análise'
                }
                label={'Meio de envio do projeto'}
                options={sendTypeAnalysis}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />

          <TextField
            id='emailMiniMicroDG'
            type='email'
            placeholder={'Digite o email'}
            label={'Email'}
            error={!!errors.emailMiniMicroDG}
            helperText={errors.emailMiniMicroDG?.message}
            {...register('emailMiniMicroDG')}
          />

          <TextField
            id='emailDG'
            type='email'
            placeholder={'Digite o Email de Geração Distribuída'}
            label={'Email de Geração Distribuída'}
            error={!!errors.emailDG}
            helperText={errors.emailDG?.message}
            {...register('emailDG')}
          />

          <TextField
            id='emailOthers'
            type='email'
            placeholder={'Digite outro email de contato'}
            label={'Outro email'}
            error={!!errors.emailOthers}
            helperText={errors.emailOthers?.message}
            {...register('emailOthers')}
          />

          <TextField
            id='phoneMiniMicroDG'
            type='text'
            placeholder={'Digite o telefone'}
            label={'Telefone'}
            error={!!errors.phoneMiniMicroDG}
            helperText={errors.phoneMiniMicroDG?.message}
            {...register('phoneMiniMicroDG', {
              onChange: (event) => {
                event.target.value = maskPhone(event.target.value);
              },
            })}
          />

          <TextField
            id='phoneDG'
            type='text'
            placeholder={'Telefone de Geração Distribuída'}
            label={'Telefone de Geração Distribuída'}
            error={!!errors.phoneDG}
            helperText={errors.phoneDG?.message}
            {...register('phoneDG', {
              onChange: (event) => {
                event.target.value = maskPhone(event.target.value);
              },
            })}
          />

          <TextField
            id='phoneOthers'
            type='text'
            placeholder={'Digite outro telefone de contato'}
            label={'Outro telefone'}
            error={!!errors.phoneOthers}
            helperText={errors.phoneOthers?.message}
            {...register('phoneOthers', {
              onChange: (event) => {
                event.target.value = maskPhone(event.target.value);
              },
            })}
          />

          <TextField
            id='urlTechnicalStandards'
            type='text'
            placeholder={'Digite a URL para as documentações técnicas'}
            label={'URL de documentações técnicas'}
            error={!!errors.urlTechnicalStandards}
            helperText={errors.urlTechnicalStandards?.message}
            {...register('urlTechnicalStandards')}
          />
        </Box>

        <Box className='p-2 flex justify-end items-center bg-white'>
          <Button
            type='submit'
            variant='contained'
            size='medium'
            className='!bg-[#dcaf07]'
          >
            Cadastrar concessionária
          </Button>
        </Box>
      </form>

      <Widget />
    </>
  );
};
