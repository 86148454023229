import { Box } from '@mui/system';
import * as React from 'react';
import { getStatusSum } from '../../services/dashboard';
import { parseStatus, ParsedStatus } from '../../interfaces/projects';
import { Chip, LinearProgress, LinearProgressProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserType, getUser } from '../../interfaces/user';

type IndicatorType = {
  quantityStatus: number;
  totalProjects: number;
};

type IndicatorCardType = {
  status: statusType;
};

type statusType = 'OPEN' | 'ACCEPTED' | 'IN_PROGRESS' | 'FINISHED';

function IndicatorCard({ status }: IndicatorCardType) {
  const { t } = useTranslation();

  const user = getUser();

  const [indicator, setIndicator] = React.useState<IndicatorType | null>(null);
  const [parsedStatus] = React.useState<ParsedStatus>(parseStatus(status));

  React.useEffect(() => {
    fetchIndicator();
  }, []);

  const fetchIndicator = async () => {
    const { data: current } = await getStatusSum(
      user?.accessToken || '',
      status,
    );
    setIndicator(current);
  };

  const percent = indicator
    ? Math.floor((indicator.quantityStatus / indicator.totalProjects) * 100)
    : 0;

  const barLabel = percent
    ? t('components.IndicatorCard.filled').replace(
        '{percent}',
        percent.toString(),
      )
    : t('components.IndicatorCard.empty');

  return (
    <Box className='indicator-card' sx={{ boxShadow: 2 }}>
      {indicator && (
        <div className='indicator-title'>
          <div className='title'>
            <Chip
              label={parsedStatus.status}
              color={parsedStatus.color}
              className='indicator-chip'
            />
            <label className='label'>
              {indicator && indicator.quantityStatus}{' '}
            </label>
          </div>

          <div className='progress-bar'>
            <span className='bar-label'>{barLabel}</span>
            <LinearProgress
              variant='determinate'
              value={percent}
              color={parsedStatus.color as LinearProgressProps['color']}
            />
          </div>
        </div>
      )}
    </Box>
  );
}

export default IndicatorCard;
