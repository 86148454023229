import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { StepIconProps, StepLabel } from '@mui/material';
import { StepsForms } from '@/pages/AddProjectPage/types';
import { useFormAddProject } from '../../context';
import LockIcon from '@mui/icons-material/Lock';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CalculateIcon from '@mui/icons-material/Calculate';
import ConstructionIcon from '@mui/icons-material/Construction';

type FormsType = {
  IDENTIFICATION: JSX.Element;
  TECHNICAL_DATA: JSX.Element;
  LOCALIZATION: JSX.Element;
  PHOTOS: JSX.Element;
  APPORTIONMENT: JSX.Element;
  EQUIPMENT: JSX.Element;
};

type StepsType = {
  IDENTIFICATION: string;
  TECHNICAL_DATA: string;
  LOCALIZATION: string;
  PHOTOS: string;
  APPORTIONMENT: string;
  EQUIPMENT: string;
};

type StepsNonLinearType = {
  forms: FormsType;
  steps: StepsType;
  activeStep: StepsForms;
};

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(228, 196, 107) 0%,rgb(223, 186, 80) 50%,rgb(218, 175, 53) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(228, 196, 107) 0%,rgb(223, 186, 80) 50%,rgb(218, 175, 53) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg,rgb(228, 196, 107) 0%,rgb(223, 186, 80) 50%,rgb(218, 175, 53) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg,rgb(228, 196, 107) 0%,rgb(223, 186, 80) 50%,rgb(218, 175, 53) 100%)',
  }),
}));

function StepIcon({ active, completed, className, icon }: StepIconProps) {
  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <SettingsIcon />,
    3: <LocationOnIcon />,
    4: <AddAPhotoIcon />,
    5: <CalculateIcon />,
    6: <ConstructionIcon />,
  };

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(icon)]}
    </StepIconRoot>
  );
}

export const StepsNonLinear = ({
  forms,
  steps,
  activeStep,
}: StepsNonLinearType) => {
  const { setActiveStep, completedSteps } = useFormAddProject();

  const stepsInKeys = Object.keys(steps);
  const currentStep = stepsInKeys.indexOf(activeStep);

  const handleStepClick = (stepKey: string, stepIndex: number) => {
    const previousStepsComplete = completedSteps
      .slice(0, stepIndex)
      .every(Boolean);

    if (previousStepsComplete) {
      setActiveStep(stepKey as StepsForms);
    } else {
      console.warn('Complete os steps anteriores primeiro!');
    }
  };

  return (
    <Box className='steps-non-linear' sx={{ boxShadow: 2 }}>
      <Stepper
        alternativeLabel
        activeStep={currentStep}
        connector={<Connector />}
      >
        {Object.entries(steps).map(([key, value], index) => (
          <Step
            key={key}
            completed={completedSteps[index]}
            onClick={() => handleStepClick(key, index)}
          >
            <StepLabel StepIconComponent={StepIcon}>{value}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box className='active-step-body'>
        <Fragment>{forms[activeStep]}</Fragment>
      </Box>
    </Box>
  );
};
