/* eslint-disable react/prop-types */
import React, { ChangeEvent, MouseEvent, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import { Chip } from '@mui/material';
import { EyeOpenIcon } from '@radix-ui/react-icons';
import { fetchProjects, ParsedProject } from '../../interfaces/projects';
import { ModalProjectInfo } from './components/ModalProjectInfo';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { OrderType } from '@/components/Table/types';
import { getComparator, stableSort } from '@/utils/tables';
import { EnhancedTableToolbar } from '@/components/Table/components/toolbar';
import { httpClient } from '@/infra';
import { ProjectsService } from '@/services';
import { TableHead } from '@/components/Table/components/head';
import { useDisclosure } from '@/hooks/useDisclosure';

import { headCells } from './constants/heders';
import { Widget } from '@/components/Feedback/components/Widget';

interface Data {
  id: number;
  name: number;
  holderName: number;
  city: string;
  status: number;
}

export const ProjectsPage = () => {
  const projectsServices = new ProjectsService(httpClient());

  const [order, setOrder] = useState<OrderType>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedToViewDetails, setSelectedToViewDetails] = useState<
    number | null
  >(null);

  const modalProjectInfoDisclosure = useDisclosure();

  const { data, isLoading, refetch, isError } = useQuery({
    queryKey: ['projects', page, rowsPerPage],
    queryFn: async () => await fetchProjects({ page, size: rowsPerPage }),
    initialData: { total: 0, quantityItems: 0, projects: [] },
    refetchOnWindowFocus: false,
  });

  const onDeleteProject = useMutation({
    mutationFn: async (id: string) =>
      await projectsServices.deleteUniqueProject({ id }),
    onSuccess: () => {
      modalProjectInfoDisclosure.onClose();
      setSelectedToViewDetails(null);
      refetch();
    },
  });

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) =>
    setPage(newPage);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewProjectDatails = (id: number) => {
    setSelectedToViewDetails(id);
    modalProjectInfoDisclosure.onOpen();
  };

  const visibleRows = useMemo(
    () =>
      stableSort<ParsedProject>(
        data?.projects ?? [],
        getComparator(order, orderBy),
      ),
    [order, orderBy, page, rowsPerPage, data],
  );

  if (isLoading) {
    return <span>carregando...</span>;
  }

  if (isError) {
    <span>Não foi possível listar os projetos</span>;
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar title='Projeto' />

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={'small'}
            >
              <TableHead<Data>
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headers={headCells}
                hasHeaderToCheckbox={false}
              />

              <TableBody>
                {visibleRows?.map(
                  ({ city, deadline, holderName, id, name, props, status }) => {
                    return (
                      <TableRow key={id} hover role='checkbox' tabIndex={-1}>
                        <TableCell align='left'>{id}</TableCell>
                        <TableCell align='left'>{name}</TableCell>
                        <TableCell align='left'>{holderName}</TableCell>
                        <TableCell align='left'>{city}</TableCell>
                        <TableCell align='left'>{deadline}</TableCell>
                        <TableCell align='left'>
                          <Chip
                            label={status}
                            color={props.color}
                            className='indicator-chip'
                          />
                        </TableCell>
                        <TableCell align='left'>
                          <EyeOpenIcon
                            className='cursor-pointer'
                            onClick={() => handleViewProjectDatails(id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={data?.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <ModalProjectInfo
        isOpen={modalProjectInfoDisclosure.isOpen}
        onClose={modalProjectInfoDisclosure.onClose}
        selectedProject={selectedToViewDetails}
        handleDeleteProject={onDeleteProject}
      />

      <Widget />
    </>
  );
};
