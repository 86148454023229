import axios, { AxiosInstance } from 'axios';

import { destroyCookie, getCookies, setAuthCookies } from '@/utils/cookies';

const baseURL =
  process.env.REACT_APP_BASE_URL || 'https://homologacao.ssgsolucoes.com/api';

let isRefreshing = false;
let failedRequestsQueue: any[] = [];

export const httpClient = (): AxiosInstance => {
  const {
    'soaressantana-accessToken': accessToken,
    'soaressantana-refreshToken': refreshToken,
  } = getCookies();

  const api = axios.create({
    baseURL,
  });

  if (accessToken)
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 498) {
        const originalRequest = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          const refreshApi = axios.create({
            baseURL,
          });

          refreshApi
            .post('/auth/refresh', {
              accessToken,
              refreshToken,
            })
            .then((response) => {
              const { accessToken, refreshToken } = response.data;

              destroyCookie('soaressantana-accessToken', { path: '/' });
              destroyCookie('soaressantana-refreshToken', { path: '/' });

              setAuthCookies({ accessToken, refreshToken });

              api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

              failedRequestsQueue.forEach((request) =>
                request.onSuccess(accessToken),
              );

              failedRequestsQueue = [];
            })
            .catch((err) => {
              failedRequestsQueue.forEach((request) => request.onFailure(err));
              failedRequestsQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;

              resolve(api(originalRequest));
            },
            onFailure: (err: Error) => {
              reject(err);
              if (typeof window !== 'undefined') {
                destroyCookie('soaressantana-accessToken', { path: '/' });
                destroyCookie('soaressantana-refreshToken', { path: '/' });

                window.location.href = '/login';
              }
            },
          });
        });
      } else {
        return Promise.reject(error);
      }
    },
  );

  return api;
};

export const customApi = (baseURL: string) => {
  const http = axios.create({ baseURL });

  return http;
};
