import { z } from 'zod';
import { createFileSchema } from '@/pages/AddProjectPage/common/file';

const dimensionSchema = (name: string) => {
  return z.preprocess(
    (args) => (args === '' ? undefined : args),
    z.coerce
      .number({ invalid_type_error: `A ${name} deve ser um número!` })
      .positive(`A ${name} deve ser positiva!`)
      .refine((number) => number > 0, `A ${name} deve ser maior que 0`)
      .optional(),
  );
};

const schemaInvertersSetup = z.object({
  type: z.string().min(1, { message: 'O tipo é obrigatório!' }),
  brand: z.string().min(1, { message: 'A marca é obrigatória!' }),
  model: z.string().min(1, { message: 'O modelo é obrigatório!' }),
  potency: z.string().min(1, { message: 'A potência é obrigatória!' }),
  quantity: z.string().min(1, { message: 'A quantidade é obrigatória!' }),
  warranty: z.string().min(1, { message: 'O peso é obrigatório!' }),
  datasheetAttachment: createFileSchema({ required: true }),
  certificateAttachment: createFileSchema({ required: true }),
});

const schemaPanelsSetup = z.object({
  brand: z.string().min(1, { message: 'A marca é obrigatória!' }),
  model: z.string().min(1, { message: 'O modelo é obrigatório!' }),
  potency: z.string().min(1, { message: 'A potência é obrigatória!' }),
  quantity: z.string().min(1, { message: 'A quantidade é obrigatória!' }),
  dimension: z.object({
    width: dimensionSchema('largura'),
    height: dimensionSchema('altura'),
    depth: dimensionSchema('profundidade'),
  }),
  weight: z
    .string()
    .transform((value) => parseFloat(value))
    .refine((val) => !isNaN(val), { message: 'Valor inválido' })
    .optional(),
  warrantyAgainstFactoryDefect: z
    .string()
    .min(1, { message: 'A garantia é obrigatória!' }),
  warranty80PercentEfficiency: z
    .string()
    .min(1, { message: 'A garantia é obrigatória!' }),
  datasheetAttachment: createFileSchema({ required: true }),
  certificateAttachment: createFileSchema({ required: true }),
});

const schemaStructureSetup = z.object({
  structureType: z
    .string()
    .min(1, { message: 'O tipo de estrutura é obrigatório!' }),
  structureBrand: z
    .string()
    .min(1, { message: 'A marca da estrutura é obrigatória!' }),
  datasheetAttachment: createFileSchema({ required: false }),
  detailsAttachment: createFileSchema({ required: false }),
});

export const schemaSetup = z.object({
  inverters: z.array(schemaInvertersSetup),
  panels: z.array(schemaPanelsSetup),
  structure: z.array(schemaStructureSetup),
});
