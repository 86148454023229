import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useGlobalContext } from '@/context';
import { useTranslation } from 'react-i18next';

// import Link from '@mui/material/Link'
// import { useNavigate } from 'react-router'

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

function BreadCrumbBar() {
  // let navigate = useNavigate()
  const { t } = useTranslation();
  const { currentRoute } = useGlobalContext();

  return (
    <div role='presentation' className='breadcrumb-bar' onClick={handleClick}>
      <Breadcrumbs aria-label='breadcrumb'>
        <Typography color='text.primary'>
          {t(`MainPage.routes.${currentRoute}`)}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}

export default BreadCrumbBar;
