import { z } from 'zod';

import { isValidCpf } from '@/validations/tests/document';

import { createFileSchema } from '../../common/file';

export const schemaApportionment = z
  .object({
    form: z
      .object({
        percentual: z.preprocess(
          (args) => (args === '' ? undefined : args),
          z.coerce
            .number({ invalid_type_error: 'Porcentagem é obrigatório!' })
            .positive('Porcentagem deve ser positivo!')
            .min(1, { message: 'Porcentagem deve ser maior ou igual a 1' })
            .max(100, {
              message: 'Porcentagem deve ser menor ou igual a 100',
            }),
        ),
        apportionmentUcCode: z
          .string()
          .min(1, { message: 'Código de UC é obrigatório!' }),
        name: z.string().min(1, { message: 'Nome é obrigatório!' }),
        cpf: z
          .string()
          .min(1, { message: 'CPF é obrigatório!' })
          .refine(isValidCpf, {
            message: 'CPF inválido!',
          }),
        address: z.string().min(1, { message: 'Rua é obrigatória!' }),
        number: z.string().min(1, { message: 'Número é obrigatório!' }),
        complement: z
          .string()
          .min(1, { message: 'Complemento é obrigatório!' }),
        district: z.string().min(1, { message: 'Bairro é obrigatório!' }),
        cep: z
          .string()
          .min(1, { message: 'CEP é obrigatório!' })
          .refine((cep) => /^[0-9]{5}-?[0-9]{3}$/.test(cep), {
            message: 'Digite um CEP válido!',
          }),
        city: z.string().min(1, { message: 'Cidade é obrigatória!' }),
        state: z.string().min(1, { message: 'Estado é obrigatório!' }),
        attachment: createFileSchema({}),
      })
      .array(),
  })
  .required();
