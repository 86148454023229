import {
  branchTypes,
  polesChains,
  polesOptions,
} from '@/constants/breakerOptions';

type ObjectWithString = {
  [key: string]: string;
};

type ObjectWithNumber = {
  [key: string]: number;
};

const transformedPolesOptions = polesOptions.reduce<ObjectWithString>(
  (acc, { key, value }) => {
    acc[key] = value;
    return acc;
  },
  {},
);
const transformedPolesChains = polesChains.reduce<ObjectWithNumber>(
  (acc, { key, value }) => {
    acc[key] = value;
    return acc;
  },
  {},
);
const transformedBranchTypes = branchTypes.reduce<ObjectWithString>(
  (acc, { key, value }) => {
    acc[key] = value;
    return acc;
  },
  {},
);

export {
  transformedBranchTypes,
  transformedPolesChains,
  transformedPolesOptions,
};
