import React from 'react';
import { Typography } from '@mui/material';

import { Dealership } from '@/models/Dealerships';
import {
  DialogButtonClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/Dialog';

type ViewDetailsProps = {
  dealership: Dealership;
};

export const ViewDetails = ({
  dealership: {
    corporateName,
    emailDG,
    emailMiniMicroDG,
    emailOthers,
    fantasyName,
    phoneDG,
    phoneMiniMicroDG,
    phoneOthers,
    sendTypeAnalysis,
    state,
    urlTechnicalStandards,
  },
}: ViewDetailsProps) => {
  return (
    <DialogContent
      className={'ModalProjectInfoContent'}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle asChild>
        <Typography>Detalhes da concessionária:</Typography>
      </DialogTitle>

      <DialogDescription asChild>
        <div className='space-y-1'>
          <Typography>Nome fantasia: {fantasyName}</Typography>
          <Typography>Razão social: {corporateName}</Typography>
          <Typography>Estado: {state}</Typography>
          <Typography>Meio de envio: {sendTypeAnalysis}</Typography>
          <Typography>E-mail (mini/micro): {emailMiniMicroDG}</Typography>
          <Typography>E-mail: {emailDG}</Typography>
          <Typography>Outros e-mails: {emailOthers}</Typography>
          <Typography>Telefone (mini/micro): {phoneMiniMicroDG}</Typography>
          <Typography>Telefone: {phoneDG}</Typography>
          <Typography>Outros telefones: {phoneOthers}</Typography>
          <Typography>URL: {urlTechnicalStandards}</Typography>
        </div>
      </DialogDescription>

      <DialogButtonClose className='ButtonClose' />
    </DialogContent>
  );
};
