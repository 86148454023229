import React from 'react';
import { useTheme } from '@mui/material/styles';

type BoxWithTitleFooterType = {
  src: string;
  alt: string;
  label?: string;
  footer?: string;
};

function BoxWithTitleFooter(props: BoxWithTitleFooterType) {
  const { src, alt, label, footer } = props;

  const theme = useTheme();
  const themeClass = `theme-${theme.palette.mode}`;

  return (
    <div className={`image-with-footer ${themeClass}`}>
      <label className='label'>{label}</label>

      <img src={src} alt={alt} />

      <label className='footer'>{footer}</label>
    </div>
  );
}

export default BoxWithTitleFooter;
