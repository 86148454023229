export const anchors: Array<string> = [
  'home',
  'services',
  'platform-ses',
  'about',
  'depositions',
  'contact',
];

export default anchors;
