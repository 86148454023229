import React from 'react';
import { Typography } from '@mui/material';

import {
  DialogButtonClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/Dialog';
import { IDesigner } from '@/models/Designer';
import { maskCEP, maskCPF } from '@/utils/masks';

type ViewDetailsProps = {
  designer: IDesigner;
};

export const ViewDetails = ({
  designer: {
    address: { address, cep, city, complement, district, number, state },
    birthdate,
    cellphone,
    classCouncil,
    cpf,
    firstName,
    lastName,
    profession,
    professionalEmail,
    rg,
    rnpNumber,
    telephone,
  },
}: ViewDetailsProps) => {
  return (
    <DialogContent
      className={'ModalProjectInfoContent'}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle asChild>
        <Typography>Detalhes do projetista:</Typography>
      </DialogTitle>

      <DialogDescription asChild>
        <div className='space-y-1'>
          <Typography>
            Nome: {firstName} {lastName}
          </Typography>
          <Typography>RG: {rg}</Typography>
          <Typography>CPF: {maskCPF(cpf)}</Typography>
          <Typography>Data: {birthdate}</Typography>
          <Typography>Telefone: {telephone}</Typography>
          <Typography>Celular: {cellphone}</Typography>
          <Typography>Profissão: {profession}</Typography>
          <Typography>E-mail: {professionalEmail}</Typography>
          <Typography>Conselho de Classe: {classCouncil}</Typography>
          <Typography>Registro Nacional Profissional: {rnpNumber}</Typography>

          <Typography>Rua: {address}</Typography>
          <Typography>Número: {number}</Typography>
          <Typography>Complemento: {complement}</Typography>
          <Typography>Bairro: {district}</Typography>
          <Typography>Cidade: {city}</Typography>
          <Typography>Estado: {state}</Typography>
          <Typography>CEP: {maskCEP(cep)}</Typography>
        </div>
      </DialogDescription>

      <DialogButtonClose className='ButtonClose' />
    </DialogContent>
  );
};
