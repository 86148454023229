import React from 'react';
import './styles.scss';

const EnvironmentIndicator = () => {
  const environment = process.env.REACT_APP_ENV_DEPLOY || 'dev';

  return (
    <div className={`environment-indicator ${environment}`}>
      {environment.toUpperCase()}
    </div>
  );
};

export default EnvironmentIndicator;
