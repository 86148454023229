import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Project } from '@/models/Project';
import {
  invertersTypesOptions,
  structuresTypesOptions,
} from '@/constants/breakerOptions';

type SetupSectionProps = {
  data: Project.GetUniqueProject | undefined;
  handleClickFile: (key: string) => void;
};

export const SetupSection = ({ data, handleClickFile }: SetupSectionProps) => {
  return (
    <AccordionItem value='equipamentos'>
      <AccordionTrigger>
        <Typography>Equipamentos</Typography>
      </AccordionTrigger>

      <AccordionContent>
        <Box>
          {data?.system.moduleList.map(
            (
              {
                brand,
                dimension,
                model,
                potency,
                quantity,
                warranty80PercentEfficiency,
                warrantyAgainstFactoryDefect,
                weight,
                certificateAttachmentList,
                datasheetAttachmentList,
              },
              index,
            ) => (
              <Box
                marginTop={(index + 1) % 2 === 0 ? '12px' : '0px'}
                key={`panel-${index}`}
              >
                <Typography marginBottom={'4px'}>
                  Painel nº {index + 1}:
                </Typography>

                <Box marginLeft={'12px'}>
                  <Typography>Marca: {brand}</Typography>
                  <Typography>Dimensão: {dimension}</Typography>
                  <Typography>Modelo: {model}</Typography>
                  <Typography>Potência: {potency}</Typography>
                  <Typography>Quantidade: {quantity}</Typography>
                  <Typography>
                    Garantia de eficiência: {warranty80PercentEfficiency}
                  </Typography>
                  <Typography>
                    Garantia de defeito: {warrantyAgainstFactoryDefect}
                  </Typography>
                  {!!weight && <Typography>Peso: {weight}</Typography>}

                  <Box className='flex flex-col'>
                    <Typography>Anexo(s) de certificado:</Typography>
                    <ul className='ml-3 space-y-2'>
                      {certificateAttachmentList?.length === 0 && (
                        <li>
                          <Typography>
                            Não há arquivos a serem exibidos.
                          </Typography>
                        </li>
                      )}
                      {certificateAttachmentList?.map(({ key, fileName }) => (
                        <li
                          key={key}
                          onClick={() => handleClickFile(key)}
                          className='cursor-pointer underline'
                        >
                          {fileName}
                        </li>
                      ))}
                    </ul>
                  </Box>

                  <Box className='flex flex-col'>
                    <Typography>Anexo(s) de detalhamento:</Typography>
                    <ul className='ml-3 space-y-2'>
                      {datasheetAttachmentList?.length === 0 && (
                        <li>
                          <Typography>
                            Não há arquivos a serem exibidos.
                          </Typography>
                        </li>
                      )}
                      {datasheetAttachmentList?.map(({ key, fileName }) => (
                        <li
                          key={key}
                          onClick={() => handleClickFile(key)}
                          className='cursor-pointer underline'
                        >
                          {fileName}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              </Box>
            ),
          )}
        </Box>

        <Divider style={{ margin: '8px 0' }} />

        <Box>
          {data?.system.inverterList.map(
            (
              {
                brand,
                model,
                potency,
                quantity,
                type,
                warranty,
                certificateAttachmentList,
                datasheetAttachmentList,
              },
              index,
            ) => (
              <Box
                marginTop={(index + 1) % 2 === 0 ? '12px' : '0px'}
                key={`inverter-${index}`}
              >
                <Typography marginBottom={'4px'}>
                  Inversor nº {index + 1}:
                </Typography>

                <Box marginLeft={'12px'}>
                  <Typography>Marca: {brand}</Typography>
                  <Typography>Modelo: {model}</Typography>
                  <Typography>Potência: {potency}</Typography>
                  <Typography>Quantidade: {quantity}</Typography>
                  <Typography>
                    Tipo:{' '}
                    {
                      invertersTypesOptions.find(
                        (inverter) => inverter.value === type,
                      )?.key
                    }
                  </Typography>
                  <Typography>Garantia: {warranty}</Typography>

                  <Box className='flex flex-col'>
                    <Typography>Anexo(s) de certificado:</Typography>
                    <ul className='ml-3 space-y-2'>
                      {certificateAttachmentList?.length === 0 && (
                        <li>
                          <Typography>
                            Não há arquivos a serem exibidos.
                          </Typography>
                        </li>
                      )}
                      {certificateAttachmentList?.map(({ key, fileName }) => (
                        <li
                          key={key}
                          onClick={() => handleClickFile(key)}
                          className='cursor-pointer underline'
                        >
                          {fileName}
                        </li>
                      ))}
                    </ul>
                  </Box>

                  <Box className='flex flex-col'>
                    <Typography>Anexo(s) de detalhamento:</Typography>
                    <ul className='ml-3 space-y-2'>
                      {datasheetAttachmentList?.length === 0 && (
                        <li>
                          <Typography>
                            Não há arquivos a serem exibidos.
                          </Typography>
                        </li>
                      )}
                      {datasheetAttachmentList?.map(({ key, fileName }) => (
                        <li
                          key={key}
                          onClick={() => handleClickFile(key)}
                          className='cursor-pointer underline'
                        >
                          {fileName}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              </Box>
            ),
          )}
        </Box>

        <Divider style={{ margin: '8px 0' }} />

        <Box>
          {data?.system.structureList.map(
            (
              {
                structureBrand,
                structureType,
                datasheetAttachmentList,
                detailsAttachmentList,
              },
              index,
            ) => (
              <Box
                marginTop={(index + 1) % 2 === 0 ? '12px' : '0px'}
                key={`structure-${index}`}
              >
                <Typography marginBottom={'4px'}>
                  Estrutura nº {index + 1}:
                </Typography>

                <Box marginLeft={'12px'}>
                  <Typography>Marca: {structureBrand}</Typography>

                  <Typography>
                    Tipo:{' '}
                    {
                      structuresTypesOptions.find(
                        (structure) => structure.value === structureType,
                      )?.key
                    }
                  </Typography>

                  <Box className='flex flex-col'>
                    <Typography>Anexo(s) de datasheet:</Typography>
                    <ul className='ml-3 space-y-2'>
                      {datasheetAttachmentList?.length === 0 && (
                        <li>
                          <Typography>
                            Não há arquivos a serem exibidos.
                          </Typography>
                        </li>
                      )}
                      {datasheetAttachmentList?.map(({ key, fileName }) => (
                        <li
                          key={key}
                          onClick={() => handleClickFile(key)}
                          className='cursor-pointer underline'
                        >
                          {fileName}
                        </li>
                      ))}
                    </ul>
                  </Box>

                  <Box className='flex flex-col'>
                    <Typography>Anexo(s) de detalhamento:</Typography>
                    <ul className='ml-3 space-y-2'>
                      {detailsAttachmentList?.length === 0 && (
                        <li>
                          <Typography>
                            Não há arquivos a serem exibidos.
                          </Typography>
                        </li>
                      )}
                      {detailsAttachmentList?.map(({ key, fileName }) => (
                        <li
                          key={key}
                          onClick={() => handleClickFile(key)}
                          className='cursor-pointer underline'
                        >
                          {fileName}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              </Box>
            ),
          )}
        </Box>
      </AccordionContent>
    </AccordionItem>
  );
};
