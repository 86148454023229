import { isValidCpf } from '@/validations/tests/document';
import { z } from 'zod';

export const schemaDesigner = z
  .object({
    firstName: z.string().min(1, { message: 'O nome é obrigatório!' }),
    lastName: z.string().min(1, { message: 'O sobrenome é obrigatório!' }),
    rg: z.string().min(1, { message: 'O RG é obrigatório!' }),
    cpf: z
      .string()
      .min(1, { message: 'O CPF é obrigatório!' })
      .refine(isValidCpf, {
        message: 'Insira um CPF válido!',
      }),
    birthdate: z
      .string()
      .min(1, { message: 'A data de nascimento é obrigatória!' }),
    profession: z.string().min(1, { message: 'A profissão é obrigatória!' }),
    classCouncil: z
      .string()
      .min(1, { message: 'Conselho de Classe é obrigatório!' }),
    rnpNumber: z
      .string()
      .min(1, { message: 'Registro Nacional Profissional é obrigatório!' }),
    address: z.string().min(1, { message: 'A rua é obrigatória!' }),
    number: z.string().min(1, { message: 'O número é obrigatório!' }),
    complement: z.string().min(1, { message: 'O complemento é obrigatório!' }),
    district: z.string().min(1, { message: 'O bairro é obrigatório!' }),
    cep: z.string().min(1, { message: 'CEP é obrigatório!' }),
    city: z.string().min(1, { message: 'A cidade é obrigatória!' }),
    state: z.string().min(1, { message: 'O estado é obrigatória!' }),
    telephone: z.string().min(1, { message: 'O telefone é obrigatório!' }),
    cellphone: z.string().min(1, { message: 'O celular é obrigatório!' }),
    professionalEmail: z
      .string()
      .min(1, { message: 'O e-mail é obrigatório!' })
      .email({ message: 'Insira uma e-mail válido' }),
  })
  .required();

export type DesignerForm = z.infer<typeof schemaDesigner>;
