import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import AnalyticsPhone from '../../../../assets/landingPage/analytics-phone.jpg';

type RightInfoBoxType = {
  id: string;
};

function RightInfoBox({ id }: RightInfoBoxType) {
  const { t } = useTranslation();

  return (
    <section id={id}>
      <Box sx={{ display: 'flex' }}>
        <div id='analytics-phone'>
          <img
            src={AnalyticsPhone}
            alt='analytics-phone'
            className='analytics-phone'
          />
        </div>

        <Box className='yellow-info'>
          <Typography variant='h1' className='title'>
            {t('LandingPage.RightInfoBox.title')}
          </Typography>

          <Typography variant='body1' className='paragraph'>
            {t('LandingPage.RightInfoBox.paragraph-1')}
          </Typography>

          <Typography variant='body1' className='paragraph'>
            {t('LandingPage.RightInfoBox.paragraph-2')}
          </Typography>

          <Typography variant='body1' className='paragraph'>
            {t('LandingPage.RightInfoBox.paragraph-3')}
          </Typography>
        </Box>
      </Box>
    </section>
  );
}

export default RightInfoBox;
