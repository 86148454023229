export const polesOptions = [
  { key: 'Monopolar', value: 'MONO' },
  { key: 'Bipolar', value: 'BI' },
  { key: 'Tripolar', value: 'TRI' },
];

export const polesChains = [
  { key: '10 A', value: 10 },
  { key: '15 A', value: 15 },
  { key: '16 A', value: 16 },
  { key: '20 A', value: 20 },
  { key: '25 A', value: 25 },
  { key: '30 A', value: 30 },
  { key: '32 A', value: 32 },
  { key: '40 A', value: 40 },
  { key: '50 A', value: 50 },
  { key: '60 A', value: 60 },
  { key: '70 A', value: 70 },
  { key: '80 A', value: 80 },
  { key: '90 A', value: 90 },
  { key: '100 A', value: 100 },
  { key: '125 A', value: 125 },
  { key: '150 A', value: 150 },
  { key: '200 A', value: 200 },
];

export const voltage = [
  { key: '220/127V', value: '220/127V' },
  { key: '230/115V', value: '230/115V' },
  { key: '240/120V', value: '240/120V' },
  { key: '254/127V', value: '254/127V' },
  { key: '380/220V', value: '380/220V' },
  { key: '440/220V', value: '440/220V' },
];

export const tensions = [
  { key: 'Baixa', value: 'LOW' },
  { key: 'Média', value: 'MEDIUM' },
  { key: 'Alta', value: 'HIGH' },
];

export const branchTypes = [
  { key: 'Aéreo', value: 'AERIAL' },
  { key: 'Subterrâneo', value: 'UNDERGROUND' },
];

export const invertersTypesOptions = [
  { key: 'Microinversores', value: 'MICRO_INVERTER' },
  { key: 'Inversor comum', value: 'INVERTER' },
  { key: 'Optimizador', value: 'OPTIMIZER' },
];

export const structuresTypesOptions = [
  { key: 'Solo', value: 'GROUND' },
  { key: 'Laje', value: 'SLAB' },
  { key: 'Telha Romana', value: 'CERAMIC_TILE' },
  { key: 'Telha de Fibrocimento', value: 'FIBER_CEMENT_TILE' },
  { key: 'Trackers', value: 'TRACKERS' },
];
