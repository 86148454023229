import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import { Project } from '@/models/Project';
import { maskCPF } from '@/utils/masks';

type ApportionmentSectionProps = {
  data: Project.GetUniqueProject | undefined;
  handleClickFile: (key: string) => void;
};

export const ApportionmentSection = ({
  data,
  handleClickFile,
}: ApportionmentSectionProps) => {
  return (
    <AccordionItem value='rateios'>
      <AccordionTrigger>
        <Typography>Rateios</Typography>
      </AccordionTrigger>

      <AccordionContent>
        {data?.apportionmentUCList.map(
          (
            {
              apportionmentUcCode,
              percentageGiven,
              holder,
              address,
              apportionmentUcAttachmentList,
            },
            index,
          ) => (
            <>
              {index > 0 && <Divider style={{ margin: '4px 0' }} />}

              <Box
                marginTop={(index + 1) % 2 === 0 ? '20px' : '0px'}
                key={apportionmentUcCode}
              >
                <Typography>Rateio nº {index + 1}:</Typography>

                <Typography>UC: {apportionmentUcCode}</Typography>
                <Typography>
                  Porcentagem de repasse: {percentageGiven}%
                </Typography>
                <Typography>
                  Nome do(a) proprietário(a): {holder.holderName}
                </Typography>
                <Typography>
                  CPF do(a) proprietário(a): {maskCPF(holder.holderCpf)}
                </Typography>

                <Box marginTop={'8px'}>
                  <Typography marginBottom={'4px'}>
                    Endereço da residência:
                  </Typography>

                  <Typography>Rua: {address.address}</Typography>
                  <Typography>Número: {address.number}</Typography>
                  <Typography>Complemento: {address.complement}</Typography>
                  <Typography>Bairro: {address.district}</Typography>
                  <Typography>Cidade: {address.city}</Typography>
                  <Typography>Estado: {address.state}</Typography>
                  <Typography>CEP: {address.cep}</Typography>
                </Box>

                <Box className='flex flex-col'>
                  <Typography>Anexo(s) da unidade {index + 1}: </Typography>
                  <ul className='ml-3 space-y-2'>
                    {apportionmentUcAttachmentList?.length === 0 && (
                      <li>
                        <Typography>
                          Não há arquivos a serem exibidos.
                        </Typography>
                      </li>
                    )}
                    {apportionmentUcAttachmentList?.map(({ key, fileName }) => (
                      <li
                        key={key}
                        onClick={() => handleClickFile(key)}
                        className='cursor-pointer underline'
                      >
                        {fileName}
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </>
          ),
        )}
      </AccordionContent>
    </AccordionItem>
  );
};
