import {
  destroyCookie as destroy,
  parseCookies,
  setCookie as set,
} from 'nookies';

import { UserToken } from '@/models/User';

const getCookies = () => parseCookies();

const setCookie = (name: string, value: string, maxAge = 60 * 60 * 24 * 30) => {
  set(undefined, name, value, {
    maxAge,
    path: '/',
  });
};

const setAuthCookies = ({ accessToken, refreshToken }: UserToken) => {
  setCookie('soaressantana-accessToken', accessToken);
  setCookie('soaressantana-refreshToken', refreshToken);
};

const destroyCookie = (name: string, options?: any) => {
  destroy(undefined, name, options);
};

export { getCookies, setCookie, setAuthCookies, destroyCookie };
