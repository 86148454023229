import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import { citiesOptions, City } from '@/constants/cities';
import { FormHelperText } from '@mui/material';
import { FieldError } from 'react-hook-form';

type CitiesSelectType = {
  id: string;
  label: string;
  placeholder: string;
  state: string;
  value: string;
  onChange: Function;
  error: FieldError | undefined;
  isValid?: Function | null;
  isClearable?: boolean;
  disabled?: boolean;
};

function CitiesSelect({
  value,
  state,
  onChange,
  isValid = null,
  error,
  isClearable = true,
  disabled = false,
  id,
  label,
  placeholder,
}: CitiesSelectType) {
  const options: Array<City> = citiesOptions(state);

  const [hasError, setHasError] = React.useState<boolean>(false);

  React.useEffect(() => {
    setHasError(!!error);
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }, [error]);

  const handleChange = (e: SelectChangeEvent) => {
    const newValue = e.target.value.toString();

    if (isValid) setHasError(!isValid(newValue));
    onChange(newValue);
  };

  const handleClear = () => {
    onChange('');
  };

  const clearButton = () =>
    isClearable &&
    value && <CloseIcon className='close-icon' onClick={handleClear} />;

  const hasValueClass = value ? 'has-value' : '';
  const hasErrorClass = hasError ? 'has-error' : '';

  return (
    <FormControl
      margin='dense'
      size='small'
      className={`chip-select ${hasValueClass} ${hasErrorClass}`}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        id={id}
        defaultValue=''
        value={value}
        onChange={handleChange}
        renderValue={(value) => (
          <Chip
            label={value}
            className='chip'
            variant='outlined'
            color='warning'
          />
        )}
        input={
          <OutlinedInput
            label={label}
            placeholder={placeholder}
            endAdornment={clearButton()}
          />
        }
        disabled={disabled}
      >
        {options.map(({ id, name }) => (
          <MenuItem key={String(id)} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}

export default CitiesSelect;
