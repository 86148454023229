import React, { useEffect, useState } from 'react';
import {
  UseMutationResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { AttachmentsService, ProjectsService } from '@/services';
import { DialogOverlay, DialogPortal, DialogRoot } from '@/components/Dialog';
import { httpClient } from '@/infra';

import { ViewProjectDetails } from './Steps/ViewDetails';
import { SelectStep } from './Steps/SelectStep';
import { useNavigate } from 'react-router';

export type Steps =
  | 'SELECT_STEP'
  | 'VIEW_DETAILS'
  | 'EDIT_IMAGES'
  | 'EDIT_PROJECT'
  | 'DELETE_PROJECT';

type ModalProjectInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedProject: number | null;
  handleDeleteProject: UseMutationResult<any, unknown, string, unknown>;
};

export const ModalProjectInfo = ({
  isOpen,
  onClose,
  selectedProject,
  handleDeleteProject,
}: ModalProjectInfoProps) => {
  const attachmentsServices = new AttachmentsService(httpClient());
  const projectsServices = new ProjectsService(httpClient());

  const navigate = useNavigate();

  const [project, setProject] = useState<number | null>(selectedProject);
  const [activeStep, setActiveStep] = useState<Steps>('SELECT_STEP');

  useEffect(() => {
    setProject(selectedProject);
  }, [selectedProject]);

  const { data, isError, isFetching } = useQuery({
    queryKey: ['projectInfo', project],
    queryFn: async () =>
      await projectsServices.getUniqueProject({ id: String(project) }),
    enabled: project !== null,
    refetchOnWindowFocus: false,
  });

  const onOpenFile = useMutation({
    mutationFn: async (key: string) =>
      await attachmentsServices.getUrlAccessFile({ key }),
    onSuccess: ({ preSignedUrl }) => {
      window.open(preSignedUrl, '_blank');
    },
  });

  const handleClickFile = (key: string) => {
    onOpenFile.mutate(key);
  };

  const handleChangeStep = async (step: Steps) => {
    if (step === 'EDIT_PROJECT') {
      navigate(`/client-page/edit-project/${project}`);
      onClose();
      return;
    }

    if (step === 'EDIT_IMAGES') {
      navigate(`/client-page/edit-images/${project}`);
      onClose();
      return;
    }

    if (step === 'DELETE_PROJECT') {
      await handleDeleteProject.mutate(String(project));
      onClose();
      return;
    }

    setActiveStep(step);
  };

  const handleCloseModal = () => {
    setProject(null);
    onClose();
    setActiveStep('SELECT_STEP');
  };

  const stepsToShow = {
    SELECT_STEP: <SelectStep handleChangeStep={handleChangeStep} />,
    VIEW_DETAILS: (
      <ViewProjectDetails
        data={data}
        handleClickFile={handleClickFile}
        handleChangeStep={handleChangeStep}
        onClose={handleCloseModal}
      />
    ),
    EDIT_IMAGES: <></>,
    EDIT_PROJECT: <></>,
    DELETE_PROJECT: <></>,
  };

  if (isFetching) {
    return <></>;
  }

  if (isError) {
    return <span>Ocorreu algum erro ao requisitar os dados!</span>;
  }

  return (
    <DialogRoot open={isOpen} onOpenChange={handleCloseModal}>
      <DialogPortal>
        <DialogOverlay />
        {stepsToShow[activeStep]}
      </DialogPortal>
    </DialogRoot>
  );
};
