import React from 'react';
import { UseMutationResult } from '@tanstack/react-query';

import { DialogOverlay, DialogPortal, DialogRoot } from '@/components/Dialog';

import { ViewDetails } from './steps/ViewDetails';
import { StepsDesigner } from '../..';
import { IDesigner } from '@/models/Designer';
import { CreateDesigner } from './steps/CreateDesigner';

type ModalDesignerInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  activeStep: StepsDesigner;
  designerToView: IDesigner;
  onCreateDesigner: UseMutationResult<IDesigner, unknown, IDesigner, unknown>;
};

export const ModalDesigner = ({
  isOpen,
  onClose,
  activeStep,
  designerToView,
  onCreateDesigner,
}: ModalDesignerInfoProps) => {
  const stepsToShow = {
    CREATE_DESIGNER: <CreateDesigner onCreateDesigner={onCreateDesigner} />,
    VIEW_DETAILS: <ViewDetails designer={designerToView} />,
    NONE: <></>,
  };

  if (activeStep === 'NONE') {
    return <></>;
  }

  return (
    <DialogRoot open={isOpen} onOpenChange={onClose}>
      <DialogPortal>
        <DialogOverlay />
        {stepsToShow[activeStep]}
      </DialogPortal>
    </DialogRoot>
  );
};
