import {
  invertersTypesOptions,
  structuresTypesOptions,
} from '@/constants/breakerOptions';
import {
  InverterList,
  ModuleList,
  SetupForm,
  StructureList,
  System,
} from '../../types';
import { uploadListFiles } from '../common';

export const formatAndUploadSystem = async ({
  inverters,
  panels,
  structure,
}: SetupForm): Promise<System> => {
  const inverterList: InverterList[] = [];
  const moduleList: ModuleList[] = [];
  const structureList: StructureList[] = [];

  for (const {
    brand,
    model,
    potency,
    quantity,
    type,
    warranty,
    certificateAttachment,
    datasheetAttachment,
  } of inverters) {
    const uploadResultCertificate = await uploadListFiles(
      certificateAttachment,
    );
    const uploadResultDatasheet = await uploadListFiles(datasheetAttachment);

    if (uploadResultCertificate && uploadResultDatasheet) {
      const inverterToPush: InverterList = {
        brand,
        type: invertersTypesOptions.find((inverter) => inverter.key === type)
          ?.value as string,
        model,
        potency: Number(potency),
        quantity: Number(quantity),
        warranty: Number(warranty),
        certificateAttachmentList: uploadResultCertificate,
        datasheetAttachmentList: uploadResultDatasheet,
      };

      inverterList.push(inverterToPush);
    }
  }

  for (const {
    brand,
    dimension: { depth, height, width },
    model,
    potency,
    quantity,
    warranty80PercentEfficiency,
    warrantyAgainstFactoryDefect,
    weight,
    certificateAttachment,
    datasheetAttachment,
  } of panels) {
    const uploadResultCertificate = await uploadListFiles(
      certificateAttachment,
    );
    const uploadResultDatasheet = await uploadListFiles(datasheetAttachment);

    if (uploadResultCertificate && uploadResultDatasheet) {
      const moduleToPush: ModuleList = {
        brand,
        dimension: `${height}x${width}x${depth}mm`,
        model,
        potency: Number(potency),
        quantity: Number(quantity),
        warranty80PercentEfficiency: Number(warranty80PercentEfficiency),
        warrantyAgainstFactoryDefect: Number(warrantyAgainstFactoryDefect),
        weight: weight,
        certificateAttachmentList: uploadResultCertificate,
        datasheetAttachmentList: uploadResultDatasheet,
      };

      moduleList.push(moduleToPush);
    }
  }

  for (const {
    structureBrand,
    structureType: type,
    detailsAttachment,
    datasheetAttachment,
  } of structure) {
    const uploadResultDetails = await uploadListFiles(detailsAttachment);
    const uploadResultDatasheet = await uploadListFiles(datasheetAttachment);

    if (uploadResultDetails && uploadResultDatasheet) {
      const structureToPush: StructureList = {
        structureBrand,
        structureType: structuresTypesOptions.find(
          (structure) => structure.key === type,
        )?.value as string,
        detailsAttachmentList: uploadResultDetails,
        datasheetAttachmentList: uploadResultDatasheet,
      };

      structureList.push(structureToPush);
    }
  }

  return { inverterList, moduleList, structureList };
};
