import * as React from 'react';

import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Typography, Divider } from '@mui/material';
import * as MuiIcons from '@mui/icons-material';

import { menuItems } from '@/constants/menu-items';

import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useMenuContext } from '../../context';
import { useNavigate } from 'react-router';
import { isRoleManager } from '@/utils/roles';

function Drawer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleMinimize, minimized } = useMenuContext();

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#222020',
      },
    },
  });

  const themeClass = `theme-${theme.palette.mode}`;
  const ChevronLeft = MuiIcons['ChevronLeft' as keyof typeof MuiIcons] as any;
  const rotate = minimized ? 'rotate' : '';

  return (
    <ThemeProvider theme={theme}>
      <Paper className={`main-menu ${themeClass}`}>
        <MenuList>
          <MenuItem className='menu-title'>
            {!minimized ? (
              <Typography variant='h6'>{t('LandingPage.title')}</Typography>
            ) : (
              <Typography variant='h6'>S&S</Typography>
            )}
          </MenuItem>

          <ChevronLeft
            className={`minimize-menu ${rotate}`}
            onClick={handleMinimize}
          />

          <Divider />

          {menuItems.map(({ icon: iconName, name, path, onlyManager }) => {
            const isManager = isRoleManager();

            if (!isManager && onlyManager) return;

            const icon = MuiIcons[iconName as keyof typeof MuiIcons] as any;

            return (
              <MenuItem key={name} onClick={() => navigate(path)}>
                <ListItemIcon className='menu-icon'>
                  {icon.type.render()}
                </ListItemIcon>

                <ListItemText className='menu-text'>
                  {t(`ClientPage.menu.${name}`)}
                </ListItemText>
              </MenuItem>
            );
          })}

          <Divider />
        </MenuList>
      </Paper>
    </ThemeProvider>
  );
}

export default Drawer;
