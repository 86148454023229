import { z } from 'zod';

import { isValidCpf } from '@/validations/tests/document';
import { createFileSchema } from '@/pages/AddProjectPage/common/file';

export const schemaIdentify = z
  .object({
    projectName: z
      .string()
      .min(1, { message: 'Nome do Projeto é obrigatório!' }),
    projectStart: z
      .string()
      .min(1, { message: 'Data do Projeto é obrigatório!' }),
    holderName: z.string().min(1, { message: 'Nome é obrigatório!' }),
    holderEmail: z.string().min(1, { message: 'Email é obrigatório!' }).email({
      message: 'Deve ser um email válido!',
    }),
    holderPhone: z.string().min(1, { message: 'Telefone é obrigatório!' }),
    holderCPF: z
      .string()
      .min(1, { message: 'CPF é obrigatório!' })
      .refine(isValidCpf, {
        message: 'CPF inválido!',
      }),
    holderRG: z.string().min(1, { message: 'RG é obrigatório!' }),
    attachment: createFileSchema({}),
  })
  .required();
