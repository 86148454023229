/* eslint-disable react/jsx-no-constructed-context-values */
import React, { ReactNode } from 'react';

import { AlertProps } from '@mui/material';
import { getCurrentLocation } from '../interfaces/geo-location';
import Snackbar from '../components/SnackBar';

type GlobalProviderType = {
  children: ReactNode;
};

type GlobalContextType = {
  currentRoute: string;
  setCurrentRoute: Function;
  handleAlert: Function;
  latitude: number;
  longitude: number;
};

export type AlertPropsType = {
  color: AlertProps['severity'];
  open: boolean;
  message: string;
  setOpen: Function;
};

const GlobalContext = React.createContext<GlobalContextType | null>(null);

function GlobalProvider({ children }: GlobalProviderType) {
  const [currentRoute, setCurrentRoute] = React.useState<string>('');

  const [color, setColor] = React.useState<AlertProps['severity']>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [latitude, setLatitude] = React.useState<number>(0);
  const [longitude, setLongitude] = React.useState<number>(0);

  React.useEffect(() => {
    getCurrentLocation((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  const handleAlert = (status: string, message: string) => {
    const colors = {
      success: 'success',
      'api-error': 'error',
      'validation-error': 'warning',
    };
    const color = colors[status as keyof typeof colors] || 'error';

    setMessage(message);
    setColor(color as AlertProps['severity']);
    setOpen(true);
  };

  const alertProps: AlertPropsType = {
    color,
    open,
    message,
    setOpen,
  };

  return (
    <GlobalContext.Provider
      value={{
        currentRoute,
        setCurrentRoute,
        handleAlert,
        latitude,
        longitude,
      }}
    >
      {children}
      <Snackbar {...alertProps} />
    </GlobalContext.Provider>
  );
}
const useGlobalContext = () =>
  React.useContext(GlobalContext) as GlobalContextType;

export { GlobalContext, useGlobalContext, GlobalProvider };
