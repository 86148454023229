type MenuItemType = {
  name: string;
  icon: string;
  path: string;
  onlyManager?: boolean;
};

export const menuItems: Array<MenuItemType> = [
  {
    name: 'dashboard',
    path: 'dashboard',
    icon: 'SpeedRounded',
    onlyManager: false,
  },
  { name: 'projects', path: 'projects', icon: 'ListAlt', onlyManager: false },
  {
    name: 'add-project',
    path: 'add-project',
    icon: 'PostAdd',
    onlyManager: false,
  },
  {
    name: 'dealerships',
    path: 'dealerships',
    icon: 'Bolt',
    onlyManager: true,
  },
  {
    name: 'designers',
    path: 'designers',
    icon: 'Person',
    onlyManager: true,
  },
  {
    name: 'accounting',
    path: 'accounting',
    icon: 'AccountBalanceWallet',
    onlyManager: true,
  },
];
