import axios, { AxiosInstance } from 'axios';

import { InfosToUpload, UrlAccessFile } from '@/models/Attachments';
import { User, UsersServiceSkeleton } from '../UsersServiceSkeleton';

export class UsersService implements UsersServiceSkeleton {
  constructor(private httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  async getUniqueDealership({ id }: { id: string }) {
    const { data } = await this.httpClient.get<User>(`/users/${id}`);

    return data;
  }
}
