import { z, ZodSchema } from 'zod';
import { FileAttachmentList } from '@/pages/EditProjectPage/types';

export const MAX_FILE_SIZE = 2000000;
export const ACCEPTED_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'application/pdf',
];

type FileSchemaOptions = {
  required?: boolean;
  checkSize?: boolean;
  checkType?: boolean;
};

export const createFileSchema = ({
  required = true,
  checkSize = true,
  checkType = true,
}: FileSchemaOptions): ZodSchema => {
  let schema: ZodSchema = z.any();

  if (required) {
    schema = schema.refine(
      (files: Array<File | FileAttachmentList>) => files?.length >= 1,
      {
        message: 'Pelo menos um arquivo é obrigatório!',
      },
    );
  }

  if (checkSize) {
    schema = schema.refine(
      (files: Array<File | FileAttachmentList>) => {
        if (!files?.length) return true;

        return files.every((file) => {
          if (file instanceof File) {
            return file.size <= MAX_FILE_SIZE;
          }

          return true;
        });
      },
      {
        message: `O tamanho máximo do(s) arquivo(s) é de 2MB.`,
      },
    );
  }

  if (checkType) {
    schema = schema.refine(
      (files: Array<File | FileAttachmentList>) => {
        if (!files?.length) return true;

        return files.every((file) => {
          if (file instanceof File) {
            return ACCEPTED_TYPES.includes(file.type);
          }

          return true;
        });
      },
      {
        message: 'Somente os formatos .jpeg, .png, .webp e .pdf são aceitos.',
      },
    );
  }

  return schema;
};
