import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import SearchInput from '../SearchInput';
import UserMenu from '../UserMenu';
import { useMenuContext } from '../../context';

function NavBar() {
  const { handleDrawerToggle } = useMenuContext();

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#222020',
      },
    },
  });

  const themeClass = `theme-${theme.palette.mode}`;

  return (
    <ThemeProvider theme={theme}>
      <Box className={`client-page-nav-bar ${themeClass}`}>
        <AppBar>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              className='drawer-menu'
              aria-label='open drawer'
              onClick={() => handleDrawerToggle()}
            >
              <MenuIcon />
            </IconButton>

            <div className='d-flex'>
              <UserMenu />
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}

export default NavBar;
